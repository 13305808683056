import React, { useState } from 'react';

import './selectoStyle.scss';
import s from './TimeItem.module.scss';
import amIcon from './images/amIcon.svg';
import pmIcon from './images/pmIcon.svg';
import addIcon from './images/addIcon.svg';

export const amTime = {
  start: ['00:00', '06:00'],
  timeList: [
    ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00'],
    ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
  ],
};

export const pmTime = {
  start: ['12:00', '18:00'],
  timeList: [
    ['13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
    ['19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
  ],
};

export interface ITimeData {
  id: string;
  begin: string;
  end: string;
}

interface IProps {
  type: 'am' | 'pm';
  selectedTime: ITimeData[];
  timeCardHeight?: number;
  colorType?: 'yellow' | 'green';
}

function TimeItem(props: IProps) {
  const time = props.type === 'am' ? amTime : pmTime;
  return (
    <div className={s.timeContainer}>
      <div>
        <img src={props.type === 'am' ? amIcon : pmIcon} alt="" />
        <span>{props.type === 'am' ? '上午' : '下午'}</span>
      </div>
      <div>
        <TimeBox
          startTime={time.start[0]}
          timeList={time.timeList[0]}
          timePosition="top"
          selected={props.selectedTime}
          timeCardHeight={props.timeCardHeight}
        />
        <TimeBox
          startTime={time.start[1]}
          timeList={time.timeList[1]}
          timePosition="bottom"
          selected={props.selectedTime}
          timeCardHeight={props.timeCardHeight}
        />
      </div>
    </div>
  );
}

export default TimeItem;

interface ITimeBoxProps {
  startTime: string;
  timeList: string[];
  timePosition: 'top' | 'bottom';
  selected: ITimeData[];
  timeCardHeight?: number;
}

function TimeBox(props: ITimeBoxProps) {
  const timeClassName = props.timePosition === 'top' ? s.timeTop : s.timeBottom;
  const checkDefaultSelected = (hour: any) => {
    let selected = {
      '00-15': false,
      '15-30': false,
      '30-45': false,
      '45-60': false,
    };
    props.selected.forEach((item: ITimeData) => {
      const [beginH, beginM] = item.begin.split(':');
      const endStr = item.end === '23:59' ? '24:00' : item.end;
      const [endH, endM] = endStr.split(':');
      const beginNum = parseInt(beginH) * 60 + parseInt(beginM);
      const endNum = parseInt(endH) * 60 + parseInt(endM);
      if (hour * 60 + 0 >= beginNum && hour * 60 + 15 <= endNum) {
        selected = {
          ...selected,
          '00-15': true,
        };
      }
      if (hour * 60 + 15 >= beginNum && hour * 60 + 30 <= endNum) {
        selected = {
          ...selected,
          '15-30': true,
        };
      }
      if (hour * 60 + 30 >= beginNum && hour * 60 + 45 <= endNum) {
        selected = {
          ...selected,
          '30-45': true,
        };
      }
      if (hour * 60 + 45 >= beginNum && hour * 60 + 60 <= endNum) {
        selected = {
          ...selected,
          '45-60': true,
        };
      }
    });

    return selected;
  };
  return (
    <div className={s.timeBox}>
      <span className={`${timeClassName} ${s.startTime}`}>{props.startTime}</span>
      {props.timeList.map((item, index) => {
        const value = index === 0 ? props.startTime : props.timeList[index - 1];
        const hour = value.split(':')[0];
        const defaultSelected = checkDefaultSelected(hour);
        const items = [
          {
            id: `${hour}-00-15`,
            selected: defaultSelected['00-15'],
            lastItem: null,
            lastItemStyle: null,
          },
          {
            id: `${hour}-15-30`,
            selected: defaultSelected['15-30'],
            lastItem: null,
            lastItemStyle: null,
          },
          {
            id: `${hour}-30-45`,
            selected: defaultSelected['30-45'],
            lastItem: null,
            lastItemStyle: null,
          },
          {
            id: `${hour}-45-60`,
            selected: defaultSelected['45-60'],
            lastItem: item,
            lastItemStyle: timeClassName,
          },
        ];
        return (
          <div key={item} className={s.timeItem}>
            {items.map((item) => (
              <TimeCard
                key={item.id}
                id={item.id}
                selected={item.selected}
                lastItem={item.lastItem}
                lastItemStyle={item.lastItemStyle}
                height={props.timeCardHeight}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}

interface ITimeCardProps {
  id: string;
  selected: boolean;
  lastItem?: any;
  lastItemStyle?: any;
  height?: number;
}

function TimeCard(props: ITimeCardProps) {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`${props.lastItem ? s.last : ''} cube ${
        props.selected ? 'selected' : ''
      }`}
      style={{ height: props.height ? `${props.height}px` : '60px' }}
      id={props.id}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {props.lastItem && <span className={props.lastItemStyle}>{props.lastItem}</span>}
      {isHover && !props.selected && (
        <img
          src={addIcon}
          alt=""
          className={props.height && props.height < 60 ? s.smallAddIcon : ''}
        />
      )}
    </div>
  );
}
