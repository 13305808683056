import React from 'react';

import Download from './pages/Download/Download';
import Price from './pages/Price/Price';
import Feedback from './pages/Feedback/Feedback';
import Activity from './pages/Activity/Activity';
import About from './pages/About/About';
import Agreement from './pages/Agreement/Agreement';
import Sem from './pages/Sem/Sem';
import MobileTips from './mobile/pages/MobileTips/MobileTips';
import NewsDetail from './pages/NewsDetail/NewsDetail';
import News from './pages/News/News';
// import UpdateLog from './pages/UpdateLog/UpdateLog';
import Home from './pages/Home/Home';
import Faq from './pages/Faq/Faq';

import QsRoute from '../commonComponents/Auth/QsRoute';
import InvitationActivity from './pages/InvitationActivity/InvitationActivity';
import RainyDoload from './pages/RainyDownload/RainyDownload';
import Policy from './pages/Policy/Policy';

export default function homePages() {
  const pages = [
    {
      link: '/',
      content: <Home />,
      exact: true,
      mobile: true,
    },
    {
      link: '/downloads',
      content: <Download />,
    },
    {
      link: '/price',
      content: <Price />,
    },
    {
      link: '/feedback',
      content: <Feedback />,
    },
    {
      link: '/activity',
      content: <Activity />,
    },
    {
      link: '/about',
      content: <About />,
    },
    {
      link: '/agreement',
      content: <Agreement />,
      mobile: true,
    },
    {
      link: '/policy',
      content: <Policy />,
      mobile: true,
    },
    {
      link: '/sem',
      content: <Sem />,
    },
    {
      link: '/mobileTips',
      content: <MobileTips />,
      mobile: true,
    },
    {
      link: '/newsDetail',
      content: <NewsDetail />,
    },
    {
      link: '/news',
      content: <News />,
    },
    // {
    //   link: '/changelog',
    //   content: <UpdateLog />,
    // },
    {
      link: '/faq',
      content: <Faq />,
    },
    {
      link: '/invitationActivity',
      content: <InvitationActivity />,
    },
    {
      link: '/rainyDownload',
      content: <RainyDoload />,
      mobile: true,
    },
  ];

  return pages.map((page) => (
    <QsRoute exact={!!page.exact} path={page.link} key={page.link} mobile={!!page.mobile}>
      {page.content}
    </QsRoute>
  ));
}
