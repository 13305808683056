import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchDelConfig,
  fetchGetConfig,
  fetchPatchConfig,
  fetchPostConfig,
  fetchPutConfig,
  fetchPostFormDataConfig,
} from '../common';
import {
  GET_BLOCK_SITES_DETAILS,
  UPDATE_DEFAULT_WEBSITES,
  GET_TOGGLE_WEB_ACL,
  UPDATE_TOGGLE_WEB_ACL,
  GET_BLACK_EXCEPTION,
  ADD_BLACK_EXCEPTION,
  DELETE_BLACK_EXCEPTION,
  UPDATE_BLACK_EXCEPTION,
  RESET_UPDATE_BLACK_EXCEPTION,
  RESET_DELETE_BLACK_EXCEPTION,
  RESET_ADD_BLACK_EXCEPTION,
  RESET_UPDATE_DEFAULT_WEBSITES,
  RESET_UPDATE_TOGGLE_WEB_ACL,
  GET_WHITE_LIST,
  ADD_WHITE_LIST,
  DELETE_WHITE_LIST,
  UPDATE_WHITE_LIST,
  RESET_UPDATE_WHITE_LIST,
  RESET_ADD_WHITE_LIST,
  RESET_DELETE_WHITE_LIST,
  GET_CUSTOM_BLACK_LIST,
  ADD_CUSTOM_BLACK_LIST,
  RESET_ADD_CUSTOM_BLACK_LIST,
  DELETE_CUSTOM_BLACK_LIST,
  RESET_DELETE_CUSTOM_BLACK_LIST,
  UPDATE_CUSTOM_BLACK_LIST,
  RESET_UPDATE_CUSTOM_BLACK_LIST,
  TOGGLE_CUSTOM_BLACK_LIST,
  RESET_TOGGLE_CUSTOM_BLACK_LIST,
  GET_CUSTOM_FILE,
  UPLOAD_DOMAIN_FILE,
  UPDATE_CUSTOM_FILE,
  DELETE_CUSTOM_FILE,
  GET_WEB_CTRL_EFFECTIVE_TIME,
  UPDATE_WEB_CTRL_EFFECTIVE_TIME,
  RESET_UPDATE_WEB_CTRL_EFFECTIVE_TIME,
  DELETE_WEB_CTRL_EFFECTIVE_TIME,
} from './constants';

function getBlockSitesTotal() {
  return fetch(`/services/tianxun/apiv2/BlockSite/BlockSitesWithDomain`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

async function getBlockSitesDetails() {
  const resp = await getBlockSitesTotal();
  const total = resp.siteMap;
  const result = Object.keys(total)
    .map((item) => {
      return {
        catagory: item,
        forbidden: total[item].forbidden,
        name: total[item].name,
        image: total[item].image,
        no: total[item].no,
      };
    })
    .sort((a, b) => a.no - b.no);

  return Promise.resolve({ websites: result });
}

export const getBlockSitesDetailsAction = createActionAsync(
  GET_BLOCK_SITES_DETAILS,
  getBlockSitesDetails,
  defaultActionOption
);

export const getBlockSitesDetailsReducer = createReducerAsync(getBlockSitesDetailsAction);

function updateDefaultWebsites(toggle, categories) {
  const data = {
    toggle,
    categories,
  };
  return fetch(
    '/services/tianxun/apiv2/blockSite/togglePreSetCategory',
    fetchPutConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update default websites success' };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateDefaultWebsitesAction = createActionAsync(
  UPDATE_DEFAULT_WEBSITES,
  updateDefaultWebsites,
  defaultActionOption
);

function resetUpdateDefaultWebsites() {
  return Promise.resolve({});
}

export const resetUpdateDefaultWebsitesAction = createActionAsync(
  RESET_UPDATE_DEFAULT_WEBSITES,
  resetUpdateDefaultWebsites,
  defaultActionOption
);

export const updateDefaultWebsitesReducer = createReducerAsync([
  updateDefaultWebsitesAction,
  resetUpdateDefaultWebsitesAction,
]);

function toggleWebACL() {
  return fetch(`/services/tianxun/apiv2/blockSite/toggleWebACL`, fetchGetConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const toggleWebACLAction = createActionAsync(
  GET_TOGGLE_WEB_ACL,
  toggleWebACL,
  defaultActionOption
);

export const toggleWebACLReducer = createReducerAsync(toggleWebACLAction);

function updateToggleWebACL(enable) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/toggleWebACL`,
    fetchPutConfig({ enable })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update toggle web ACL success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const updateToggleWebACLAction = createActionAsync(
  UPDATE_TOGGLE_WEB_ACL,
  updateToggleWebACL,
  defaultActionOption
);

function resetUpdateToggleWebACL() {
  return Promise.resolve({});
}

export const resetUpdateToggleWebACLAction = createActionAsync(
  RESET_UPDATE_TOGGLE_WEB_ACL,
  resetUpdateToggleWebACL,
  defaultActionOption
);

export const updateToggleWebACLReducer = createReducerAsync([
  updateToggleWebACLAction,
  resetUpdateToggleWebACLAction,
]);

function getBlackException() {
  return fetch(`/services/tianxun/apiv2/blockSiteException`, fetchGetConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return {
        exceptions: data.exceptions.reverse(),
      };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const getBlackExceptionAction = createActionAsync(
  GET_BLACK_EXCEPTION,
  getBlackException,
  defaultActionOption
);

export const getBlackExceptionReducer = createReducerAsync(getBlackExceptionAction);

function addBlackException(name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSiteException`,
    fetchPostConfig({ name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'add exception success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const addBlackExceptionAction = createActionAsync(
  ADD_BLACK_EXCEPTION,
  addBlackException,
  defaultActionOption
);

function resetAddBlackException() {
  return Promise.resolve({});
}

export const resetAddBlackExceptionAction = createActionAsync(
  RESET_ADD_BLACK_EXCEPTION,
  resetAddBlackException,
  defaultActionOption
);

export const addBlackExceptionReducer = createReducerAsync([
  addBlackExceptionAction,
  resetAddBlackExceptionAction,
]);

function deleteBlackException(uid) {
  return fetch(
    `/services/tianxun/apiv2/blockSiteException/remove`,
    fetchPostConfig({ uid })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'delete success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const deleteBlackExceptionAction = createActionAsync(
  DELETE_BLACK_EXCEPTION,
  deleteBlackException,
  defaultActionOption
);

function resetDeleteBlackException() {
  return Promise.resolve({});
}

export const resetDeleteBlackExceptionAction = createActionAsync(
  RESET_DELETE_BLACK_EXCEPTION,
  resetDeleteBlackException,
  defaultActionOption
);

export const deleteBlackExceptionReducer = createReducerAsync([
  deleteBlackExceptionAction,
  resetDeleteBlackExceptionAction,
]);

function updateBlackException(uid, name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSiteException`,
    fetchPatchConfig({ uid, name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update exception success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const updateBlackExceptionAction = createActionAsync(
  UPDATE_BLACK_EXCEPTION,
  updateBlackException,
  defaultActionOption
);

function resetUpdateBlackException() {
  return Promise.resolve({});
}

export const resetUpdateBlackExceptionAction = createActionAsync(
  RESET_UPDATE_BLACK_EXCEPTION,
  resetUpdateBlackException,
  defaultActionOption
);

export const updateBlackExceptionReducer = createReducerAsync([
  updateBlackExceptionAction,
  resetUpdateBlackExceptionAction,
]);

function getCustomBlackList() {
  return fetch(`/services/tianxun/apiv2/blockSite/customBlockSites`, fetchGetConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return {
        sites: data.sites.reverse(),
      };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const getCustomBlackListAction = createActionAsync(
  GET_CUSTOM_BLACK_LIST,
  getCustomBlackList,
  defaultActionOption
);

export const getCustomBlackListReducer = createReducerAsync(getCustomBlackListAction);

function addCustomBlackList(name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customBlockSites`,
    fetchPostConfig({ name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'add exception success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const addCustomBlackListAction = createActionAsync(
  ADD_CUSTOM_BLACK_LIST,
  addCustomBlackList,
  defaultActionOption
);

function resetAddCustomBlackList() {
  return Promise.resolve({});
}

export const resetAddCustomBlackListAction = createActionAsync(
  RESET_ADD_CUSTOM_BLACK_LIST,
  resetAddCustomBlackList,
  defaultActionOption
);

export const addCustomBlackListReducer = createReducerAsync([
  addCustomBlackListAction,
  resetAddCustomBlackListAction,
]);

function deleteCustomBlackList(uid) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customBlockSites/${uid}`,
    fetchDelConfig({})
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'delete success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const deleteCustomBlackListAction = createActionAsync(
  DELETE_CUSTOM_BLACK_LIST,
  deleteCustomBlackList,
  defaultActionOption
);

function resetDeleteCustomBlackList() {
  return Promise.resolve({});
}

export const resetDeleteCustomBlackListAction = createActionAsync(
  RESET_DELETE_CUSTOM_BLACK_LIST,
  resetDeleteCustomBlackList,
  defaultActionOption
);

export const deleteCustomBlackListReducer = createReducerAsync([
  deleteCustomBlackListAction,
  resetDeleteCustomBlackListAction,
]);

function updateCustomBlackList(uid, name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customBlockSites`,
    fetchPutConfig({ uid, name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update exception success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const updateCustomBlackListAction = createActionAsync(
  UPDATE_CUSTOM_BLACK_LIST,
  updateCustomBlackList,
  defaultActionOption
);

function resetUpdateCustomBlackList() {
  return Promise.resolve({});
}

export const resetUpdateCustomBlackListAction = createActionAsync(
  RESET_UPDATE_CUSTOM_BLACK_LIST,
  resetUpdateCustomBlackList,
  defaultActionOption
);

export const updateCustomBlackListReducer = createReducerAsync([
  updateCustomBlackListAction,
  resetUpdateCustomBlackListAction,
]);

function toggleCustomBlackList(enable, uids) {
  const data = {
    enable,
    uids,
  };
  return fetch(
    '/services/tianxun/apiv2/blockSite/customBlockSites/toggleSites',
    fetchPutConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update default websites success' };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const toggleCustomBlackListAction = createActionAsync(
  TOGGLE_CUSTOM_BLACK_LIST,
  toggleCustomBlackList,
  defaultActionOption
);

function resetToggleCustomBlackList() {
  return Promise.resolve({});
}

export const reseToggleCustomBlackListAction = createActionAsync(
  RESET_TOGGLE_CUSTOM_BLACK_LIST,
  resetToggleCustomBlackList,
  defaultActionOption
);

export const toggleCustomBlackListReducer = createReducerAsync([
  toggleCustomBlackListAction,
  reseToggleCustomBlackListAction,
]);

function getWhiteList() {
  return fetch(`/services/tianxun/apiv2/blockSite/whiteList`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { data: data.sites.reverse() };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const getWhiteListAction = createActionAsync(
  GET_WHITE_LIST,
  getWhiteList,
  defaultActionOption
);

export const getWhiteListReducer = createReducerAsync(getWhiteListAction);

function addWhiteList(name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/whiteList`,
    fetchPostConfig({ name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'add white list success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const addWhiteListAction = createActionAsync(
  ADD_WHITE_LIST,
  addWhiteList,
  defaultActionOption
);

function resetAddWhiteList() {
  return Promise.resolve({});
}

export const resetAddWhiteListAction = createActionAsync(
  RESET_ADD_WHITE_LIST,
  resetAddWhiteList,
  defaultActionOption
);

export const addWhiteListReducer = createReducerAsync([
  addWhiteListAction,
  resetAddWhiteListAction,
]);

function deleteWhiteList(uid) {
  return fetch(`/services/tianxun/apiv2/blockSite/whiteList/${uid}`, fetchDelConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'delete whiteList success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const deleteWhiteListAction = createActionAsync(
  DELETE_WHITE_LIST,
  deleteWhiteList,
  defaultActionOption
);

function resetDeleteWhiteList() {
  return Promise.resolve({});
}

export const resetDeleteWhiteListAction = createActionAsync(
  RESET_DELETE_WHITE_LIST,
  resetDeleteWhiteList,
  defaultActionOption
);

export const deleteWhiteListReducer = createReducerAsync([
  deleteWhiteListAction,
  resetDeleteWhiteListAction,
]);

function updateWhiteList(uid, name, domain) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/whiteList`,
    fetchPutConfig({ uid, name, domain })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update whiteList success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const updateWhiteListAction = createActionAsync(
  UPDATE_WHITE_LIST,
  updateWhiteList,
  defaultActionOption
);

function resetUpdateWhiteList() {
  return Promise.resolve({});
}

export const resetUpdateWhiteListAction = createActionAsync(
  RESET_UPDATE_WHITE_LIST,
  resetUpdateWhiteList,
  defaultActionOption
);

export const updateWhiteListReducer = createReducerAsync([
  updateWhiteListAction,
  resetUpdateWhiteListAction,
]);

function uploadDomainFile(mode, file) {
  const formData = new FormData();
  formData.append('file', file);
  return fetch(
    `/services/tianxun/apiv2/blockSite/uploadFile?mode=${mode}`,
    fetchPostFormDataConfig(formData)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const uploadDomainFileAction = createActionAsync(
  UPLOAD_DOMAIN_FILE,
  uploadDomainFile,
  defaultActionOption
);

export const uploadDomainFileReducer = createReducerAsync(uploadDomainFileAction);

function getCustomFile(mode) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customCategoryFiles?type=${mode}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const getCustomFileAction = createActionAsync(
  GET_CUSTOM_FILE,
  getCustomFile,
  defaultActionOption
);

export const getCustomFileReducer = createReducerAsync(getCustomFileAction);

function updateCustomFile(categoryID, forbidden) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customCategoryFiles`,
    fetchPutConfig({ categoryID, forbidden })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update custom file success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const updateCustomFileAction = createActionAsync(
  UPDATE_CUSTOM_FILE,
  updateCustomFile,
  defaultActionOption
);

export const updateCustomFileReducer = createReducerAsync(updateCustomFileAction);

function deleteCustomFile(categoryID) {
  return fetch(
    `/services/tianxun/apiv2/blockSite/customCategoryFiles/${categoryID}`,
    fetchDelConfig({})
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'delete custom file success' };
    })
    .catch(() => ({ success: false, errDesc: '' }));
}

export const deleteCustomFileAction = createActionAsync(
  DELETE_CUSTOM_FILE,
  deleteCustomFile,
  defaultActionOption
);

export const deleteCustomFileReducer = createReducerAsync(deleteCustomFileAction);

function getEffectiveTime() {
  return fetch(`/services/tianxun/apiv2/workTime`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, errDesc: 'fetch error' }));
}

export const getEffectiveTimeAction = createActionAsync(
  GET_WEB_CTRL_EFFECTIVE_TIME,
  getEffectiveTime,
  defaultActionOption
);

export const getEffectiveTimeReducer = createReducerAsync(getEffectiveTimeAction);

function updateEffectiveTime(timeList) {
  timeList.forEach((item) => {
    // 调整时间24:00至23:59
    item.endtime = item.endtime === '24:00' ? '23:59' : item.endtime;
  });
  const bodyData = {
    info: {
      type: 5,
      timePeriod: timeList,
    },
  };
  return fetch(`/services/tianxun/apiv2/workTime/update`, fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, errDesc: 'update failed' }));
}

export const updateEffectiveTimeAction = createActionAsync(
  UPDATE_WEB_CTRL_EFFECTIVE_TIME,
  updateEffectiveTime,
  defaultActionOption
);

function resetUpdateEffectiveTime() {
  return Promise.resolve({});
}

export const resetUpdateEffectiveTimeAction = createActionAsync(
  RESET_UPDATE_WEB_CTRL_EFFECTIVE_TIME,
  resetUpdateEffectiveTime,
  defaultActionOption
);

export const updateEffectiveTimeReducer = createReducerAsync([
  updateEffectiveTimeAction,
  resetUpdateEffectiveTimeAction,
]);

// 用以区分删除和更新的操作提示
function deleteEffectiveTime(timeList) {
  const bodyData = {
    info: {
      type: 5,
      timePeriod: timeList,
    },
  };
  return fetch(`/services/tianxun/apiv2/workTime/update`, fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, errDesc: 'delete failed' }));
}

export const deleteEffectiveTimeAction = createActionAsync(
  DELETE_WEB_CTRL_EFFECTIVE_TIME,
  deleteEffectiveTime,
  defaultActionOption
);

export const deleteEffectiveTimeReducer = createReducerAsync(deleteEffectiveTimeAction);
