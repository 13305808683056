import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isMobile } from '../../../common';
import FormTemplate, { IFormItem } from '../FormTemplate/FormTemplate';
import s from './LoginForm.module.scss';
import { getUrlParams, regPattern, smsCodeErrormessage } from '../../../common';
import { loginAction, resetLoginErrorAction } from '../../../redux/login';
import { resetPhoneCaptchaErrorAction } from '../../../redux/getPhoneCaptcha';
import {
  resetSearchUserByPhoneAction,
  searchUserByPhoneAction,
} from '../../../redux/user';
import { registerAction, resetRegisterErrorAction } from '../../../redux/register';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';

const smsCodeErrorMessage = smsCodeErrormessage();

const errorMessageInfo: any = {
  ...smsCodeErrorMessage,
  'User not found.': '用户不存在。',
  'login fetch error': '登录失败，请重试！',
};

interface IRegisterData {
  user: {
    userName: string;
    email: string | null;
    password: string | null;
    phoneNumber: string;
    orgName: string;
  };
  promotion: string | null;
  checked: boolean;
  smsCode: string;
}

interface IProps {
  searchUserByPhoneAction: (phone: string) => void;
  searchUserByPhoneResult: any;
  loginAction: (value: { phone: string; smsCode: string; redirect: string }) => void;
  resetLoginErrorAction: () => void;
  resetPhoneCaptchaErrorAction: () => void;
  loginResult: any;
  getPhoneCaptchaResult: any;
  isModal: boolean;
  switchToRegister?: () => void;
  redirect?: string;
  registerAction: (data: IRegisterData) => void;
  registerResult: any;
  resetRegisterErrorAction: () => void;
  resetSearchUserByPhoneAction: () => void;
}

function LoginForm(props: IProps) {
  const [disableCaptchaBtn, setDisableCaptchaBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formValue, setformValue] = useState<any>({});

  useEffect(() => {
    const { loginResult } = props;
    setErrorMessage(errorMessageInfo[loginResult.reason]);
  }, [props.loginResult]);

  useEffect(() => {
    const { getPhoneCaptchaResult } = props;
    setErrorMessage(errorMessageInfo[getPhoneCaptchaResult.message]);
  }, [props.getPhoneCaptchaResult]);

  useEffect(() => {
    const { registerResult } = props;
    setErrorMessage(errorMessageInfo[registerResult.message]);
  }, [props.registerResult]);

  useEffect(() => {
    handleLoginRegister();
  }, [props.searchUserByPhoneResult]);

  const phoneValidator = async (rule: any, value: string): Promise<any> => {
    if (!regPattern.PHONE.test(value)) {
      return Promise.reject('手机号格式错误');
    }
    setDisableCaptchaBtn(false);
    return Promise.resolve();
  };

  const handleLoginRegister = async () => {
    const promotion = getUrlParams('promotion');
    const pathname = window.location.pathname;
    const redirect = isMobile()
      ? '/?type=login'
      : getUrlParams('redirect') || props.redirect || pathname || '/';
    const { user, success } = props.searchUserByPhoneResult;
    if (!success) return;
    if (user && user.length !== 0) {
      // 手机号存在调登录接口
      await props.loginAction({ ...formValue, redirect });
    } else {
      // 调注册接口
      const registerData = {
        user: {
          userName: formValue.phone,
          email: null,
          password: null,
          phoneNumber: formValue.phone,
          orgName: formValue.phone,
        },
        promotion: !!promotion ? promotion : 'DEFAULT',
        checked: !!formValue.agreement,
        smsCode: formValue.smsCode,
      };
      await props.registerAction(registerData);
    }
  };

  const resetActionInfo = async () => {
    await props.resetLoginErrorAction();
    await props.resetPhoneCaptchaErrorAction();
    await props.resetRegisterErrorAction();
    await props.resetSearchUserByPhoneAction();
  };

  const forItems: IFormItem[] = [
    {
      name: 'phone',
      placeholder: '手机号码',
      type: 'text',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: '请输入手机号码',
        },
        {
          validator: phoneValidator,
        },
      ],
    },
    {
      name: 'smsCode',
      placeholder: '短信验证码',
      type: 'text',
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          message: '请输入短信验证码',
        },
      ],
    },
    {
      name: 'agreement',
    },
  ];

  return (
    <div>
      <FormTemplate
        formName="loginForm"
        btnText={isMobile() ? '注册' : '登录/注册'}
        forItems={forItems}
        disableCaptchaBtn={disableCaptchaBtn}
        handleSubmit={async (value: {
          phone: string;
          smsCode: string;
          agreement: any;
        }): Promise<void> => {
          setformValue(value);
          // 1. 确认手机号是否存在，存在调登录接口， 不存在调注册接口
          if (regPattern.PHONE.test(value.phone)) {
            await props.searchUserByPhoneAction(value.phone);
          }
        }}
        btnClassName={props.isModal ? s.loginBtn : ''}
      />
      <QsAlert
        visible={!!errorMessage}
        closable
        type="error"
        message={errorMessage}
        position="fixed"
        onClose={resetActionInfo}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    loginResult: state.loginResult.data,
    registerResult: state.registerResult.data,
    getPhoneCaptchaResult: state.getPhoneCaptchaResult.data,
    searchUserByPhoneResult: state.searchUserByPhoneResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    resetLoginErrorAction: bindActionCreators(resetLoginErrorAction, dispatch),
    loginAction: bindActionCreators(loginAction, dispatch),
    registerAction: bindActionCreators(registerAction, dispatch),
    resetRegisterErrorAction: bindActionCreators(resetRegisterErrorAction, dispatch),
    resetPhoneCaptchaErrorAction: bindActionCreators(
      resetPhoneCaptchaErrorAction,
      dispatch
    ),
    searchUserByPhoneAction: bindActionCreators(searchUserByPhoneAction, dispatch),
    resetSearchUserByPhoneAction: bindActionCreators(
      resetSearchUserByPhoneAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
