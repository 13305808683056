import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import s from './Home.module.scss';
import { isMobile } from '../../../common';
import MobileHome from '../../mobile/pages/MobileHome/MobileHome';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
import { checkLoginStateAction } from '../../../redux/user';
import { fetchQsPcClientUrlAction } from '../../../redux/downloadPCClient';

import bigAndroidIcon from './images/bigAndroid.svg';
import bigWindowsIcon from './images/bigWindows.svg';
import activityBanner from './images/activityBanner2.png';
import inviteActivityBanner from './images/activityBanner.png';
import homeBanner from './images/homeBanner.png';
import zgydHomeBanner from '../../../ZGYDAssets/zgydHomeBanner.png';
import activityText from './images/activityText.png';

import titleBanner from './images/titleBanner.svg';
import zgydTitleBanner from '../../../ZGYDAssets/zgydTitleBanner.svg';
import { QsHBigButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import { getActivityListAction } from '../../../redux/invitationActivity';
import { getEnvironmentsAction } from '../../../redux/environments';
import FunctionInfo from '../../components/FunctionInfo/FunctionInfo';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  checkLoginStateResult: any;
  checkLoginStateAction: () => void;
  fetchQsPcClientUrlResult: any;
  fetchQsPcClientUrlAction: () => void;
  getActivityListResult: any;
  getEnvironmentsResult: any;
  getEnvironmentsAction: () => void;
}

interface IStates {
  showModal: boolean;
  modalType: 'login' | 'register';
}

class Home extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      modalType: 'register',
    };
  }

  async componentDidMount() {
    document.title = !!isZGYD()
      ? '随时随地管理孩子电子设备,让孩子健康有度上网'
      : '青松守护-随时随地管理孩子电子设备,让孩子健康有度上网';

    await Promise.all([
      this.props.checkLoginStateAction(),
      this.props.fetchQsPcClientUrlAction(),
      this.props.getEnvironmentsAction(),
    ]);
  }

  platformInfo = () => {
    const platforms = [bigWindowsIcon, bigAndroidIcon];
    const { isLogin } = this.props.checkLoginStateResult;
    return (
      <>
        <QsHBigButton
          type="primary"
          theme="green"
          onClick={() => {
            if (!!isLogin) {
              window.location.href = '/dashboard';
            } else {
              this.setState({ showModal: true, modalType: 'register' });
            }
          }}
        >
          {!!isLogin ? '进入控制中心' : '免费试用'}
        </QsHBigButton>
        <div className={s.platformContainer}>
          {platforms.map((icon) => (
            <a href="/downloads" target="_blank" key={icon} className={s.platformIcon}>
              <img src={icon} alt="" />
            </a>
          ))}
        </div>
        <div className={s.tips}>
          <span>适用于 Windows、Android。</span>
        </div>
      </>
    );
  };

  renderBanner = () => {
    return (
      <div className={s.bannerContainer}>
        <img src={!!isZGYD() ? zgydHomeBanner : homeBanner} alt="" />
        <div className={s.infoContainer}>
          <img src={!!isZGYD() ? zgydTitleBanner : titleBanner} alt="" />
          <div className={s.desc}>随时随地管理孩子电子设备，让孩子健康有度上网！</div>
          {this.platformInfo()}
        </div>
      </div>
    );
  };

  renderCommonBanner = () => {
    const { isLogin } = this.props.checkLoginStateResult;
    return (
      <div className={s.bannerCommonContainer}>
        <img src={homeBanner} alt="" />
        <div className={s.commonContainer}>
          <QsHBigButton
            type="primary"
            theme="green"
            onClick={() => {
              if (!!isLogin) {
                window.location.href = '/dashboard';
              } else {
                this.setState({ showModal: true, modalType: 'register' });
              }
            }}
          >
            {!!isLogin ? '进入控制中心' : '免费试用'}
          </QsHBigButton>
        </div>
      </div>
    );
  };

  inviteActivity = () => {
    return (
      <div className={s.activityBanner}>
        <img src={inviteActivityBanner} alt="" />
        <a href="/invitationActivity" target="_blank">
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <QsHBigButton type="primary" theme="green" onClick={() => {}}>
            立即参与
          </QsHBigButton>
        </a>
      </div>
    );
  };

  newRegisterActivity = () => {
    return (
      <div className={s.activityBanner}>
        <img src={activityBanner} alt="" />
        <div className={s.activityInfo}>
          <img src={activityText} alt="" className={s.activityText} />
          {this.platformInfo()}
        </div>
      </div>
    );
  };

  // 暂时不用
  renderBannerCarousel = () => {
    return (
      <div className={s.carousel}>
        <Carousel
          interval={2000}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          renderIndicator={(clickHandler, isSelected) => {
            return (
              <span
                onClick={clickHandler}
                className={`${s.dot} ${isSelected ? s.selected : ''}`}
              />
            );
          }}
        >
          <div>{this.newRegisterActivity()}</div>
          <div>{this.renderBanner()}</div>
        </Carousel>
      </div>
    );
  };

  dashboardBtnClick = () => {
    const { isLogin } = this.props.checkLoginStateResult;
    if (!!isLogin) {
      window.location.href = '/dashboard';
    } else {
      this.setState({
        showModal: true,
        modalType: 'login',
      });
    }
  };

  pcDownloadBtnClick = () => {
    const url = this.props.fetchQsPcClientUrlResult.downloadUrl || '';
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    if (isMobile()) {
      return <MobileHome />;
    }

    return (
      <div id="homePage">
        <HomeLayout>
          <div>
            {!isZGYD() ? this.renderCommonBanner() : this.renderBanner()}
            <div>
              <div className={s.funcContainer}>
                <div className={s.funcTitle}>我们可以为您的孩子做什么？</div>
                <div className={s.funcSubtitle}>
                  同时管理手机和电脑，帮助您{!!isZGYD() ? '轻松' : '“青松”'}管理孩子
                </div>
                <FunctionInfo
                  handleDashboardBtnClick={this.dashboardBtnClick}
                  handlePcDownloadBtnClick={this.pcDownloadBtnClick}
                />
              </div>
            </div>
          </div>
        </HomeLayout>
        <LoginRegisterModal
          redirect="/dashboard"
          visible={this.state.showModal}
          handleClose={() => {
            this.setState({ showModal: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
    fetchQsPcClientUrlResult: state.fetchQsPcClientUrlResult.data,
    getActivityListResult: state.getActivityListResult.data,
    getEnvironmentsResult: state.getEnvironmentsResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    fetchQsPcClientUrlAction: bindActionCreators(fetchQsPcClientUrlAction, dispatch),
    getActivityListAction: bindActionCreators(getActivityListAction, dispatch),
    getEnvironmentsAction: bindActionCreators(getEnvironmentsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
