import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchGetConfig,
  fetchPostConfig,
  fetchDelConfig,
  fetchPutConfig,
} from '../common';
import {
  GET_LOCK_SCREEN_DURATIONS,
  UPDATE_LOCK_SCREEN_DURATIONS,
  DELETE_LOCK_SCREEN_DURATIONS,
  ADD_LOCK_SCREEN_DURATIONS,
  CLEAR_UPDATE_LOCK_SCREEN_DURATIONS,
} from './constants';

function getLockScreenDurations() {
  return fetch('/services/tianxun/apiv2/LockScreen/Duration', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'lock screen durations fetch error' }));
}

export const getLockScreenDurationsAction = createActionAsync(
  GET_LOCK_SCREEN_DURATIONS,
  getLockScreenDurations,
  defaultActionOption
);

export const getLockScreenDurationsReducer = createReducerAsync(
  getLockScreenDurationsAction
);

function updateLockScreen(id, beginTime, endTime, open, weekdays, isSwitch, switchValue) {
  const data = {
    id,
    beginTime,
    endTime,
    open,
    weekdays,
  };
  return fetch('/services/tianxun/apiv2/LockScreen/Duration', fetchPostConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data, isSwitch, switchValue };
    })
    .catch(() => ({ success: false, data: 'lock screen durations update error' }));
}

export const updateLockScreenAction = createActionAsync(
  UPDATE_LOCK_SCREEN_DURATIONS,
  updateLockScreen,
  defaultActionOption
);

function clearUpdateLockScreen() {
  return Promise.resolve({ success: true, isSwitch: false, switchValue: false });
}

export const clearUpdateLockScreenAction = createActionAsync(
  CLEAR_UPDATE_LOCK_SCREEN_DURATIONS,
  clearUpdateLockScreen,
  defaultActionOption
);

export const updateLockScreenReducer = createReducerAsync([
  updateLockScreenAction,
  clearUpdateLockScreenAction,
]);

function deleteLockScreen(id) {
  return fetch(`/services/tianxun/apiv2/LockScreen/Duration?id=${id}`, fetchDelConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, data: 'lock screen durations delete error' }));
}

export const deleteLockScreenAction = createActionAsync(
  DELETE_LOCK_SCREEN_DURATIONS,
  deleteLockScreen,
  defaultActionOption
);

export const deleteLockScreenReducer = createReducerAsync(deleteLockScreenAction);

function addLockScreen(beginTime, endTime, weeks) {
  return fetch(
    '/services/tianxun/apiv2/LockScreen/Duration',
    fetchPutConfig({ beginTime, endTime, weekdays: weeks })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, data: 'lock screen durations add error' }));
}

export const addLockScreenAction = createActionAsync(
  ADD_LOCK_SCREEN_DURATIONS,
  addLockScreen,
  defaultActionOption
);

export const addLockScreenReducer = createReducerAsync(addLockScreenAction);
