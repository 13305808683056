import { Input } from 'antd';
import React, { useState } from 'react';

import s from './QsInput.module.scss';
import searchIcon from './images/searchIcon.svg';

interface IProps {
  label?: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  isError?: boolean;
  errorMsg?: string;
  onChange?: (e: any) => void;
  onSearch?: (value: string) => void;
  size?: 'large' | 'middle';
  suffix?: React.ReactElement | string;
  allowClear?: boolean;
  type?: 'search' | 'default';
  width?: number;
}

function QsInput(props: IProps) {
  const [value, setValue] = useState('');

  const handleSearch = () => {
    if (props.onSearch) {
      props.onSearch(value);
    }
  };

  let suffix;
  if (props.type === 'search') {
    suffix = (
      <span className={s.searchIcon} onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </span>
    );
  } else {
    suffix = props.suffix;
  }
  return (
    <div className={s.container} style={{ width: `${props.width}px` }}>
      {props.label && <div className={`${s.label}`}>{props.label}</div>}
      <div className={`${s.input} ${props.isError ? s.inputError : ''}`}>
        <Input
          size={props.size || 'large'}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          allowClear={props.allowClear}
          onChange={(e) => {
            const v = e.target.value;
            setValue(v);
            if (props.onChange) {
              props.onChange(e);
            }

            if (v.length === 0 || !v) {
              if (props.onSearch) {
                props.onSearch(v);
              }
            }
          }}
          onPressEnter={handleSearch}
          suffix={suffix}
        />
        {props.isError && <div className={s.error}>{props.errorMsg}</div>}
      </div>
    </div>
  );
}

export default QsInput;
