import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './Header.module.scss';
import logo from '../../../commonComponents/QsIcons/logo.svg';
import zgydLogo from '../../../ZGYDAssets/zgydHLogo.png';
import { checkLoginStateAction } from '../../../redux/user';
import { logoutAction } from '../../../redux/login';
import { getUrlParams } from '../../../common';
import { getTerminalInfoAction } from '../../../redux/terminal';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
import { QsHNormalButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import { isActiveAnniversary, isZGYD } from '../../../utils/lib';
import variables from '../../../variables.module.scss';
import anniversaryBanner from './images/anniversaryBanner.png';

interface IProps {
  checkLoginStateAction: () => void;
  checkLoginStateResult: any;
  logoutAction: () => void;
  getTerminalInfoAction: () => void;
  getHeaderHeight?: (height: number) => void;
}

interface IStates {
  showModal: boolean;
  modalType: 'login' | 'register';
  currentPosition: 'top' | 'notTop';
}

class Header extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      modalType: 'login',
      currentPosition: 'top',
    };
  }

  listenScrollEvent = () => {
    if (window.scrollY > 0) {
      this.setState({ currentPosition: 'notTop' });
    } else {
      this.setState({ currentPosition: 'top' });
    }
  };

  async componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
    await this.props.checkLoginStateAction();
    const { isLogin } = this.props.checkLoginStateResult;
    if (isLogin) {
      await this.props.getTerminalInfoAction();
    }

    const height = document.getElementById('headerId')?.clientHeight;
    if (height !== undefined && this.props.getHeaderHeight !== undefined) {
      this.props.getHeaderHeight(height);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  linkTo = () => {
    const currentLink = window.location.pathname;
    if (currentLink === '/price') {
      localStorage.setItem('currentPath', 'price');
    }

    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `promotion=${promotion}` : '';
    window.location.href = `/price?${promotionQuery}`;
  };

  render() {
    const currentLink = window.location.pathname;
    const { isLogin } = this.props.checkLoginStateResult;
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `promotion=${promotion}` : '';

    return (
      <>
        <div
          id="headerId"
          className={s.header}
          style={
            this.state.currentPosition === 'top'
              ? { backgroundColor: 'transparent' }
              : {
                  backgroundColor: variables.colorWhite,
                  boxShadow: '0 2px 8px 0 rgba(0,0,0,0.20)',
                }
          }
        >
          {isActiveAnniversary() && (
            <img
              src={anniversaryBanner}
              style={{ cursor: 'pointer', width: '100%' }}
              alt=""
              onClick={() => {
                this.linkTo();
              }}
            />
          )}
          <div className={s.headerContent}>
            <a href={`${promotionQuery ? `/?${promotionQuery}` : '/'}`}>
              {isZGYD() ? (
                <img src={zgydLogo} alt="" style={{ height: '34px' }} />
              ) : (
                <img src={logo} alt="" className={s.logo} />
              )}
            </a>
            <div className={s.link}>
              <Link
                to={`/?${promotionQuery}`}
                className={`${
                  currentLink === '/' || currentLink === '' ? s.activeLink : s.normalLink
                }`}
              >
                <span>首页</span>
              </Link>
              <Link
                to={`/downloads?${promotionQuery}`}
                className={`${
                  currentLink === '/downloads' ? s.activeLink : s.normalLink
                }`}
              >
                <span>下载</span>
              </Link>
              <Link
                to={`/price?${promotionQuery}`}
                className={`${currentLink === '/price' ? s.activeLink : s.normalLink}`}
              >
                <span>价格</span>
              </Link>
              {!isZGYD() && (
                <Link
                  to={`/faq?${promotionQuery}`}
                  className={`${currentLink === '/faq' ? s.activeLink : s.normalLink}`}
                >
                  <span>帮助中心</span>
                </Link>
              )}
            </div>
            {isLogin ? (
              <div>
                <span className={s.loginBtn} onClick={this.props.logoutAction}>
                  退出登录
                </span>
                <Link to="/dashboard">
                  <QsHNormalButton type="primary" theme="green">
                    进入控制中心
                  </QsHNormalButton>
                </Link>
              </div>
            ) : (
              <div>
                <span
                  className={s.loginBtn}
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                >
                  <QsHNormalButton type="primary" theme="green">
                    登录/注册
                  </QsHNormalButton>
                </span>
              </div>
            )}
          </div>
        </div>
        <LoginRegisterModal
          visible={this.state.showModal}
          handleClose={() => {
            this.setState({ showModal: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    logoutAction: bindActionCreators(logoutAction, dispatch),
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
