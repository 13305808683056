import React, { useEffect, useState } from 'react';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import s from './InvitationActivity.module.scss';
import couponImg from './images/coupon.png';
import { QsHBigButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkLoginStateAction, getOrgInfoAction } from '../../../redux/user';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
import {
  getActivityListAction,
  getParticipateInfoAction,
} from '../../../redux/invitationActivity';

import modalBgEmpty from './images/modalBgEmpty.png';
import modalBg from './images/modalBg2.jpg';
import QrCodeWithLogo from './QrCodeWithLogo/QrCodeWithLogo';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';
import { QsModalBaisc } from '../../../commonComponents/QsModal/QsModal';

interface IProps {
  checkLoginStateResult: any;
  checkLoginStateAction: () => void;
  getActivityListAction: () => void;
  getParticipateInfoResult: any;
  getParticipateInfoAction: (activityId: string, parentId: string) => void;
  getActivityListResult: any;
  getOrgInfoResult: any;
  getOrgInfoAction: () => void;
}

function InvitationActivity(props: IProps) {
  const [showModal, setShowModal] = useState(false);

  const [showQrCode, setShowQrCode] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [alertInfo, setAlertInfo] = useState(
    (): {
      type: 'success' | 'error';
      message: string;
    } => {
      return {
        type: 'success',
        message: '复制成功。',
      };
    }
  );

  useEffect(() => {
    async function fetchData() {
      await Promise.all([props.checkLoginStateAction(), props.getActivityListAction()]);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      await props.getParticipateInfoAction(
        props.getActivityListResult?.data?.[0]?.['id'],
        props.checkLoginStateResult?.organizationId
      );
    }

    const { isLogin } = props.checkLoginStateResult;
    if (!!isLogin) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkLoginStateResult]);

  const alertError = () => {
    setShowAlert(true);
    setAlertInfo({
      type: 'error',
      message: '复制失败, 可以选择右击保存图片。',
    });
  };

  const alertSuccess = () => {
    setShowAlert(true);
    setAlertInfo({
      type: 'success',
      message: '复制成功。',
    });
  };

  const { inviteCode, invitedUsers } = props.getParticipateInfoResult?.data || {};
  const activityName = props.getActivityListResult?.data?.[0]?.['name'] || '';

  const { isLogin } = props.checkLoginStateResult;

  return (
    <HomeLayout showFooterBg={false}>
      <div className={s.container}>
        <div className={s.banner} />
        <div className={s.content}>
          <div className={s.couponContent}>
            <img className={s.coupon} src={couponImg} alt="" />
            <p>注：新老用户均可参与本次活动</p>
            <div className={s.btn}>
              <QsHBigButton
                disable={props.getActivityListResult?.data?.length < 1 && !!isLogin}
                type="primary"
                theme="green"
                onClick={async () => {
                  if (!!isLogin) {
                    setShowQrCode(true);
                  } else {
                    setShowModal(true);
                  }
                }}
              >
                立即参与
              </QsHBigButton>
            </div>
          </div>
        </div>
        <div className={s.record}>
          <div className={s.recordContent}>
            {!!isLogin ? (
              <div>
                {invitedUsers?.length > 0 ? (
                  <div className={s.recordList}>
                    {invitedUsers.map((item: any) => {
                      return (
                        <div key={item.parentId}>
                          <span className={s.phoneNumber}>{item.phone}</span>
                          <span className={s.emphasize}>邀请成功</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={s.empty}>暂无邀请记录</div>
                )}
              </div>
            ) : (
              <div className={s.noLogin}>
                <p>当前暂未登录，登录后即可查看</p>
                <QsHBigButton
                  type="primary"
                  theme="green"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  立即登录
                </QsHBigButton>
              </div>
            )}
          </div>
        </div>

        <QsModalBaisc
          closable={true}
          visible={showQrCode}
          onCancel={() => {
            setShowQrCode(false);
          }}
        >
          <div className={s.qrCodeBg}>
            <img src={modalBgEmpty} alt="" />
            <div className={s.invitationImg}>
              <QrCodeWithLogo
                url={`${window.location.origin}/invitingRegister?activity=${activityName}&inviteCode=${inviteCode}&promotion=Webyaoqing`}
                picSrc={modalBg}
                id="invitation-activity-qrCode"
              />
            </div>
            <div className={s.copyBtn}>
              <QsHBigButton
                type="primary"
                theme="green"
                onClick={async () => {
                  const testImg: any = document.getElementById(
                    'invitation-activity-qrCode'
                  );
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  const img = new Image();
                  canvas.width = testImg.width;
                  canvas.height = testImg.height;
                  img.crossOrigin = 'Anonymous';
                  img.src = testImg.src;
                  img.onload = () => {
                    ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                    ctx?.drawImage(img, 0, 0);
                    canvas.toBlob(async (blob) => {
                      const data = [
                        // @ts-ignore
                        new ClipboardItem({
                          // @ts-ignore
                          [blob.type]: blob,
                        }),
                      ];

                      try {
                        // @ts-ignore
                        await navigator.clipboard.write(data).then(
                          () => alertSuccess(),
                          () => alertError()
                        );
                      } catch (_) {
                        alertError();
                      }
                    });
                  };
                }}
              >
                立即复制
              </QsHBigButton>
            </div>
            <QsAlert
              visible={!!showAlert}
              type={alertInfo.type}
              message={alertInfo.message}
              position="fixed"
              onClose={() => {
                setShowAlert(false);
              }}
            />
          </div>
        </QsModalBaisc>

        <LoginRegisterModal
          visible={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      </div>
    </HomeLayout>
  );
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
    getActivityListResult: state.getActivityListResult.data,
    getParticipateInfoResult: state.getParticipateInfoResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    getActivityListAction: bindActionCreators(getActivityListAction, dispatch),
    getParticipateInfoAction: bindActionCreators(getParticipateInfoAction, dispatch),
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationActivity);
