import React from 'react';

import s from './Tab.module.scss';

interface IProps {
  id: string;
  label: string | React.ReactElement;
  labelIcon?: string;
  activeTab: string;
  onClick: (label: string) => void;
}

function Tab(props: IProps) {
  const isActive = props.id == props.activeTab;
  return (
    <span
      onClick={() => {
        props.onClick(props.id);
      }}
      className={isActive ? s.activeTab : ''}
    >
      {props.labelIcon && <img className={s.logo} src={props.labelIcon} alt="" />}
      {props.label}
      {isActive && <div className={s.arrow} />}
    </span>
  );
}

export default Tab;
