import React, { useState } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AiFillCloseCircle, AiOutlineZoomIn } from 'react-icons/ai';
import s from './ImagesList.module.scss';
import { QsModalBaisc } from '../../../../commonComponents/QsModal/QsModal';

interface IProps {
  id: string;
  srcUrl: string;
  onDelete: (id: string) => void;
  createTime: string;
}

function ImageCard(props: IProps) {
  const [showScaleModal, setShowScaleModal] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const handleClick = (e: any) => {
    e.stopPropagation();
    setShowScaleModal(true);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    const config = {
      title: '确认删除此截屏吗？',
      icon: <ExclamationCircleOutlined />,
      cancelText: '取消',
      centered: true,
      okText: '确认',
      onOk: () => {
        props.onDelete(props.id);
      },
    };
    modal.confirm(config);
  };

  if (!props.srcUrl) {
    return null;
  }

  const imageUrl = URL.createObjectURL(props.srcUrl as any);
  return (
    <>
      <div className={s.imageContainer}>
        <div className={s.imageCard}>
          <img src={imageUrl} alt="" />
          <div className={s.mask} onClick={handleClick}>
            <AiOutlineZoomIn className={s.zoomIcon} />
            <AiFillCloseCircle className={s.deleteIcon} onClick={handleDelete} />
          </div>
        </div>
        <div className={s.createTime}>
          <span>{props.createTime}</span>
        </div>
      </div>
      <QsModalBaisc
        closable={true}
        visible={showScaleModal}
        onCancel={() => {
          setShowScaleModal(false);
        }}
      >
        <div>
          <img src={imageUrl} alt="screenShot" className={s.scaleImg} />
        </div>
      </QsModalBaisc>
      {contextHolder}
    </>
  );
}

interface ImagesListProps {
  imagesDataList: any;
  onDelete: (data: string) => void;
}

function ImagesList(props: ImagesListProps) {
  return (
    <div className={s.container}>
      {props.imagesDataList?.map((item: any) => {
        return (
          <ImageCard
            key={item.id}
            srcUrl={item.srcUrl}
            id={item.id}
            onDelete={props.onDelete}
            createTime={item.createTime}
          />
        );
      })}
    </div>
  );
}

export default ImagesList;
