import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import s from './FormLayout.module.scss';
import logo from '../../../commonComponents/QsIcons/logo.svg';
import { getUrlParams } from '../../../common';

interface IProps {
  title: string;
  children: React.ReactNode;
}

class FormLayout extends Component<IProps, Record<string, never>> {
  render() {
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `?promotion=${promotion}` : '';

    return (
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.logoContainer}>
            <Link to={`/${promotionQuery}`}>
              <img src={logo} alt="logo" className={s.logo} />
            </Link>
          </div>
        </div>
        <div className={s.content}>
          <h1>{this.props.title}</h1>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default FormLayout;
