import React from 'react';
import s from './RoundSwitch.module.scss';

interface IProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

function RoundSwitch(props: IProps) {
  const { isChecked } = props;
  return (
    <label className={s.switch}>
      <input
        type="checkbox"
        onChange={() => {
          props.onChange(!isChecked);
        }}
        checked={isChecked}
      />
      <div className={`${s.slider} ${s.round}`}>
        <span className={!isChecked ? s.checkedText : ''}>白名单</span>
        <span className={isChecked ? s.checkedText : ''}>黑名单</span>
      </div>
    </label>
  );
}

export default RoundSwitch;
