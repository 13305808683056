import { createReducer } from 'redux-act';

const defaultsState = {
  loading: false,
  request: null,
  data: {},
  error: null,
};

function reducerFormater(actionAsync, defaultState) {
  return {
    [actionAsync.request]: (state, payload) => ({
      ...state,
      request: payload,
      loading: true,
      error: null,
    }),
    [actionAsync.ok]: (state, payload) => ({
      ...state,
      loading: false,
      data: payload.response,
    }),
    [actionAsync.error]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [actionAsync.reset]: () => defaultState,
  };
}

export default function createReducerAsync(actionAsync, defaultState = defaultsState) {
  if (typeof actionAsync === 'object') {
    const arr = actionAsync.reduce((acc, cur, index) => {
      let accReducer = acc;
      if (index === 1) {
        accReducer = reducerFormater(acc, defaultState);
      }
      const curReducer = reducerFormater(cur, defaultState);
      return Object.assign(accReducer, curReducer);
    });
    return createReducer(arr, defaultState);
  }
  return createReducer(reducerFormater(actionAsync, defaultState), defaultState);
}
