import React from 'react';
import pointerGreen from './images/pointerGreen.png';
import pointerBlue from './images/pointerBlue.png';

import s from './BindMobile.module.scss';
import QrCode from '../QrCode/QrCode';
import { isZGYD } from '../../../utils/lib';
import { QsButtonFixedSize } from '../../../commonComponents/QsButton/QsButton';
import { ChildAppDownload } from '../../../home/pages/Download/QrCodeDownload';

interface IProps {
  orgId: string;
  onClick: () => void;
}

function BindMobile(props: IProps) {
  return (
    <div className={s.bindMobileCard}>
      <div className={s.bindMobileCardTitle}>绑定「孩子端」方法</div>
      <div className={s.bindMobileContent}>
        <div className={s.bindMobileDownload}>
          <div className={s.bindMobileStep1}>第一步</div>
          <div className={s.bindMobileDownloadTitle}>
            <span>下载扫我</span> <img src={pointerBlue} alt="" />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div className={s.bindMobileCardQrCode}>
              <div>
                <ChildAppDownload qrcodeWidth={140} size="small" colorType="blue" />
              </div>
            </div>
          </div>
          <div className={s.bindMobileCardDesc}>
            用 <span>孩子手机</span> 扫码下载并安装 <span>孩子端。</span>
          </div>
        </div>

        <div className={s.bindMobileCardLine}></div>

        <div className={s.bindMobileBinding}>
          <div className={s.bindMobileStep2}>第二步</div>
          <div className={s.bindMobileBindingTitle}>
            <span>绑定扫我</span> <img src={pointerGreen} alt="" />
          </div>

          <div style={{ textAlign: 'center' }}>
            <div className={s.bindMobileCardQrCode}>
              <div className={`${s.borderRightCorner} ${s.borderCornerLeftTop}`}></div>
              <div className={`${s.borderRightCorner} ${s.borderCornerRightTop}`}></div>
              <div className={`${s.borderRightCorner} ${s.borderCornerLeftBottom}`}></div>
              <div
                className={`${s.borderRightCorner} ${s.borderCornerRightBottom}`}
              ></div>
              <div>
                <QrCode value={props.orgId} elementId="bind-device" width={140} />
              </div>
            </div>
          </div>
          <div className={s.bindMobileCardDesc}>
            打开{!!isZGYD() ? '' : '青松守护'} <span>孩子端</span>{' '}
            扫描上方二维码完成孩子设备添加。
          </div>
        </div>
      </div>

      <div style={{ width: '350px', margin: '0 auto', marginTop: '40px' }}>
        <QsButtonFixedSize
          size="middle"
          type="primary"
          handleClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
        >
          <span>我已完成绑定</span>
        </QsButtonFixedSize>
      </div>
    </div>
  );
}

export default BindMobile;
