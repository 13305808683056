import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchPostConfig,
  fetchDelConfig,
  fetchPatchConfig,
  fetchGetConfig,
} from '../common';
import {
  ADD_CHILD_INFO,
  GET_CHILD_INFO,
  DELETE_CHILD_INFO,
  UPDATE_CHILD_INFO,
  CURRENT_CHILD,
  GET_CHILDREN_AVATARS,
} from './constants';
import { getOrgInfo } from './user';

async function addChildInfo(name, gender, birthYear, avatarUrl) {
  const orgInfo = await getOrgInfo();
  const bodyData = {
    name,
    gender,
    birth_year: birthYear,
    parent_id: orgInfo.org.org_id,
    avatar_url: avatarUrl,
  };
  return fetch('/api/user/api/children', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const addChildInfoAction = createActionAsync(
  ADD_CHILD_INFO,
  addChildInfo,
  defaultActionOption
);

export const addChildInfoReducer = createReducerAsync(addChildInfoAction);

async function getChildInfo() {
  const orgInfo = await getOrgInfo();
  const bodyData = {
    parent_id: orgInfo.org.org_id,
  };
  return fetch('/api/user/api/children/find', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getChildInfoAction = createActionAsync(
  GET_CHILD_INFO,
  getChildInfo,
  defaultActionOption
);

export const getChildInfoReducer = createReducerAsync(getChildInfoAction);

async function updateChildInfo(childId, name, gender, birthYear, avatarUrl) {
  const bodyData = {
    name,
    gender,
    birth_year: birthYear,
    avatar_url: avatarUrl,
  };
  return fetch(`/api/user/api/children/${childId}`, fetchPatchConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateChildInfoAction = createActionAsync(
  UPDATE_CHILD_INFO,
  updateChildInfo,
  defaultActionOption
);

export const updateChildInfoReducer = createReducerAsync(updateChildInfoAction);

function deleteChildInfo(childId) {
  return fetch(`/api/user/api/children/${childId}`, fetchDelConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const deleteChildInfoAction = createActionAsync(
  DELETE_CHILD_INFO,
  deleteChildInfo,
  defaultActionOption
);

export const deleteChildInfoReducer = createReducerAsync(deleteChildInfoAction);

function currentChild(childInfo) {
  return Promise.resolve(childInfo);
}

export const currentChildAction = createActionAsync(
  CURRENT_CHILD,
  currentChild,
  defaultActionOption
);

export const currentChildReducer = createReducerAsync(currentChildAction);

async function getChildrenAvatars() {
  return fetch(`/api/user/api/children/default_avatars`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getChildrenAvatarsAction = createActionAsync(
  GET_CHILDREN_AVATARS,
  getChildrenAvatars,
  defaultActionOption
);

export const getChildrenAvatarsReducer = createReducerAsync(getChildrenAvatarsAction);
