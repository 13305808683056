import React, { Component } from 'react';
import { Popover } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './TopNavigation.module.scss';
import { getOrgInfoAction } from '../../../redux/user';
import { logoutAction } from '../../../redux/login';
import { getProductStateAction } from '../../../redux/order';
import SubNav from '../SubNav/SubNav';
import { getUrlQuery } from '../../../common';
import profileIcon from './images/profileIcon.png';
import logoutIcon from './images/logoutIcon.svg';
import UpOutlined from './images/UpOutlined.svg';
import DownOutlined from './images/DownOutlined.svg';
import { displayPhoneNumber } from '../../../utils/lib';

interface IProps {
  logoutAction: () => void;
  getOrgInfoAction: () => void;
  getOrgInfoResult: any;
  getProductStateAction: () => void;
  getProductStateResult: any;
  title: string;
  description: string;
  subNavInfo?: any;
  getUserInfoResult: any;
}

interface IState {
  showPopover: boolean;
}

class TopNavigation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  async componentDidMount() {
    await this.props.getOrgInfoAction();
    await this.props.getProductStateAction();
  }

  handleVisibleChange = (show: boolean) => {
    this.setState({ showPopover: show });
  };

  render() {
    const content = (
      <div className={s.popContainer}>
        <div className={s.popContent}>
          <div
            className={s.logout}
            onClick={() => {
              this.props.logoutAction();
            }}
          >
            <img src={logoutIcon} alt="" />
            <span>退出账号</span>
          </div>
        </div>
      </div>
    );

    const type = getUrlQuery('type');
    const userInfo = this.props.getUserInfoResult || {};
    return (
      <div className={s.outerContainer}>
        <div className={s.container}>
          {this.props.subNavInfo ? (
            <SubNav
              mainNavInfo={{ name: '控制中心', url: `/dashboard?type=${type}` }}
              subNavInfo={this.props.subNavInfo}
            />
          ) : (
            <div className={s.subPath}>
              <div className={s.title}>{this.props.title}</div>
              <div className={s.desc}>{this.props.description}</div>
            </div>
          )}
          <Popover
            placement="bottom"
            title={null}
            content={content}
            trigger="hover"
            overlayClassName={s.popOver}
            open={this.state.showPopover}
            onOpenChange={this.handleVisibleChange}
          >
            <div className={s.accountContent}>
              <img
                src={profileIcon}
                alt=""
                style={{ height: '40px', marginRight: '10px' }}
              />
              <div className={s.accountName}>
                <span>{`用户${displayPhoneNumber(userInfo.phone_number)}` || '--'}</span>
                {this.state.showPopover ? (
                  <img src={UpOutlined} alt="" />
                ) : (
                  <img src={DownOutlined} alt=""></img>
                )}
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getOrgInfoResult: state.getOrgInfoResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    getUserInfoResult: state.getUserInfoResult.data.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    logoutAction: bindActionCreators(logoutAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
