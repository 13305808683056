import one1 from './images/1.svg';
import two2 from './images/2.svg';
import three3 from './images/3.svg';
import four4 from './images/4.svg';

export const advantageInfo = [
  {
    img: one1,
    title: '系出名门',
    describe: '信息安全领军上市企业启明星辰打造',
  },
  {
    img: two2,
    title: '专业防护',
    describe: '24年网络安全行业沉淀，把好安全第一关',
  },
  {
    img: three3,
    title: '功能强大',
    describe: '全面管控孩子上网及终端设备使用行为',
  },
  {
    img: four4,
    title: '操作简便',
    describe: '30秒即可轻松上手，并有8小时*5天专属客服',
  },
];
