const productName = '青松守护';
const data = [
  {
    title: '1、权利声明',
    items: [
      '本软件的一切知识产权，以及与本“软件”相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等均为川陀大匠公司所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。',
    ],
  },
  {
    title: '2、许可范围',
    items: [
      '2.1 下载、安装和使用：本软件为免费软件，用户可以非商业性、无限制数量地下载、安装及使用本软件。',
      '2.2 复制、分发和传播：用户在非商业性使用的情况下，可以无限制数量地复制、分发和传播本软件产品。但必须保证每一份复制、分发和传播都是完整和真实的，包括所有有关本软件产品的软件、电子文档版权和商标，亦包括本协议。',
    ],
  },
  {
    title: '3、权利限制',
    items: [
      '3.1 禁止反向工程、反向编译和反向汇编：用户不得对本软件产品进行反向工程（Reverse Engineer）、反向编译（Decompile）或反向汇编（Disassemble），同时不得改动编译在程序文件内部的任何资源。除法律、法规明文规定允许上述活动外，用户必须遵守此协议限制。',
      '3.2 组件分割：本软件产品是作为一个单一产品而被授予许可使用，用户不得将各个部分分开用于任何目的。',
      '3.3 个别授权：如需进行商业性的销售、复制、分发，包括但不限于软件销售、预装、捆绑等，必须获得川陀大匠公司的书面授权和许可。如进行转载本应用内的文章（无论是否商业性使用），均需进行单独授权，获得川陀大匠公司的书面授权和许可后方可进行转载。',
      '3.4 保留权利：本协议未明示授权的其他一切权利仍归川陀大匠公司所有，用户使用其他权利时必须获得川陀大匠公司的书面同意。',
    ],
  },
  {
    title: '4、退款协议',
    items: [
      '4.1 如何退款：如果您需要提交退款申请，请及时添加客服微信：QingSongShouHu，同时请提供产品名称、您的账号名（手机号）、退款的理由与问题截图，我们会在收到退款申请的72小时(工作日)内处理。退款后的钱款将退还至您购买时用于支付的账户。',
      '4.2 适用于申请退款的情况：',
      '4.2.1 产品技术问题：如果购买的软件出现严重的技术问题，并且我们没有提供解决方案。如果您不愿意等待产品升级，我们将对您退款。',
      '4.2.2 退款时限：退款申请须在购买后七日内提交，超出时限则不予受理。',
      '4.2.3 权限未及时没有开通：如果您在购买产品后的72小时内都没有开通权限，并在联系客服团队后未能在72小时内及时得到回复。在这种情况下，如果您要求退款，我们会根据您的要求安排退款。',
      '4.2.4 重复支付：如果因支付平台或其他技术问题造成的对您的重复收费， 我们将退回多收取的费用。',
      '4.3 不适用于申请退款的情况：',
      '4.3.1 购买产品后，无任何合理理由取消订单。',
      '4.3.2 购买使用超过 7 日。',
      '4.3.3 购买之前，客户未能了解产品说明，导致购买不当。购买产品后发现产品并没有满足客户提出的但却从未在我方官方网站产品描述中出现的需求，请您购买产品前务必详细了解产品功能，并在购买前试用免费版本。',
      '4.3.4 由于产品技术问题导致的退款请求，客户拒绝与我们的客服团队合作尝试解决问题，拒绝提供有关问题的详细说明和信息，或者拒绝尝试使用客服团队提供的解决方案。',
      '4.3.5 由于不同地区和商家之间的价格差异而提出的退款要求。',
      '4.3.6 由于客户自己的错误而并非产品本身的问题而提出的退款要求。例如，购买错误的软件、下载错误的版本、计算机磁盘空间不足、购买后改变主意等。',
      '4.3.7 由于信用卡诈骗或未经授权的支付而提出的退款请求。',
    ],
  },
  {
    title: '5、用户使用须知',
    items: [
      `5.1.1 您理解并同意，您将按本服务之需要向${productName}提供手机号进行注册使用。若第三方对您申请控制手机号的行为提出权属异议，您同意并授权${productName}向该第三方或司法机关提供您提交的申请资料，并同意${productName}可依据本协议规定或司法机关的指令，视情节严重程度，对您做出暂时或永久冻结本服务等处理措施。情节严重的，${productName}有权移交有关行政管理机关给予行政处罚，或者追究您的刑事责任。`,
      `5.1.2 您理解并承诺，${productName}对被守护者设备的查询和控制措施均是应您申请所为，您自愿承担被守护者手机或平板设备因限制措施所遭受的任何损失（包括但不限于取证费用、诉讼费用、民事赔偿等）。`,
      `5.1.3 您理解并同意，${productName}可以受理任何用户对任何手机或平板设备加入本服务的申请。为保护您信息安全，您需要采取必要的、有效的措施，避免被他人获悉您的手机验证信息及被守护者的手机设备和使用情况等信息。您保证，只对有法定监护关系的孩子或直系亲属关系的老人有使用权的手机或平板设备提出申请，不得对其他用户的手机或平板设备进行绑定。`,
      `5.1.4 您理解并同意，注册时绑定的手机号码是您使用本服务时唯一的识别信息，以及接受提醒信息的唯一途径。如因您提供的手机号码错误，导致无法成功申请服务，或因更换手机号码导致信息无法送达，${productName}不承担任何责任。`,
      '5.1.5 本软件包含安全资讯推送功能：本软件将根据国家有关部门和新闻媒体的公开公示信息、川陀大匠公司安全实验室的研究成果，通过本软件的“资讯”界面，向您推送个人信息安全防护相关资讯。',
      '5.2 本软件仅适用于其官方网站公布支持的操作系统（即Android安卓系统）及具体的手机型号，如果用户在安装本软件后因任何原因放弃使用，可使用Android（安卓）系统相应方式删除本软件。',
      '5.3 本软件由川陀大匠公司提供产品支持。',
      '5.4 软件的修改和升级：川陀大匠公司保留随时为用户提供本软件的修改、升级版本的权利。由用户选择确定后，软件会进行升级更新，产生相应的数据流量费，由运营商收取。',
      '5.5 产品随附：为了提供更多的功能扩展，川陀大匠公司可以将川陀大匠公司的其他相关产品，随附在本软件中提供用户自主选择下载和安装。',
      '5.6 本软件不含有任何旨在破坏用户移动通讯设备数据和获取用户隐私信息的恶意代码，不含有任何跟踪、监视用户的功能代码，不会监控用户网上、网下的行为，不会收集用户使用其它软件、文档等个人信息，不会泄漏用户隐私。',
      '5.7 用户应在遵守法律及本协议的前提下使用本软件。用户无权实施包括但不限于下列行为：',
      '5.7.1 删除或者改变本软件上的所有权利管理电子信息；',
      '5.7.2 故意避开或者破坏著作权人为保护本软件著作权而采取的技术措施；',
      '5.7.3 利用本软件误导、欺骗他人;',
      '5.7.4 违反国家规定，对计算机信息系统功能进行删除、修改、增加、干扰，造成计算机信息系统不能正常运行；',
      '5.7.5 未经允许，进入计算机信息网络或者使用计算机信息网络资源；',
      '5.7.6 未经允许，对计算机信息网络功能进行删除、修改或者增加的；',
      '5.7.7 未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；',
      '5.7.8 破坏本软件系统或网站的正常运行，故意传播计算机病毒等破坏性程序；',
      '5.7.9 其他任何危害计算机信息网络安全的行为。',
      '5.8 对于从非川陀大匠公司指定站点下载的本软件产品以及从非川陀大匠公司发行的介质上获得的本软件产品，川陀大匠公司无法保证该软件是否感染病毒、是否隐藏有伪装的特洛伊木马程序或者其他黑客软件，使用此类软件，将可能导致不可预测的风险，建议用户不要轻易下载、安装、使用，川陀大匠公司不承担任何由此产生的一切法律责任。',
      '5.9 用户使用本软件发布、上传、或传送的任何内容应符合国家现行法律、法规的相关规定，不得含有以下内容：',
      '5.9.1 违反宪法所确定的基本原则的；含有法律、行政法规禁止的其他内容的；',
      '5.9.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
      '5.9.3 损害国家荣誉和利益的，攻击党和政府及其领导人的；',
      '5.9.4 煽动民族仇恨、民族歧视，破坏民族团结的；',
      '5.9.5 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的，以非法民间组织名义活动的；',
      '5.9.6 破坏国家宗教政策，宣扬邪教和封建迷信的；',
      '5.9.7 散布谣言或不实消息，扰乱社会秩序，破坏社会稳定的；',
      '5.9.8 散布反动、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
      '5.9.9 虚假、骚扰性、侮辱性、恐吓性、伤害性、挑衅性、庸俗性信息；',
      '5.9.10 违背中华民族传统美德、社会公德、伦理道德、以及社会主义精神文明的；',
      '5.9.11 宣扬种族歧视，破坏国家、民族、地区团结的言论和消息的；',
      '5.9.12 侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；',
      '5.9.13 侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的信息；',
      '5.9.14 有法律、行政法规和国家规定禁止的其他内容的。',
      '5.10 对于用户通过本软件发布、上传或传送的内容，川陀大匠公司有权根据国家相关法律法规和本协议其他准则，进行必要的监督和审查。如用户在使用本软件时违反任何上述规定或接到用户投诉，川陀大匠公司有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户上传并发布的内容、暂停或终止用户使用本软件服务的权利）以减轻用户不当行为造成的影响。',
      '5.11 用户若在本软件上传、发布、传送反动、色情、暴力等国家法律、行政法规禁止的内容及信息，本软件的系统记录有可能作为用户违反法律的证据。因用户在本软件的上传、发布、传送上述内容而导致任何第三方提出索赔要求或衍生的任何损害或损失，由用户承担全部责任。因用户行为给川陀大匠公司造成损失的，用户应负责全额赔偿(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)。',
      '5.12 隐私权保护',
      '5.12.1 川陀大匠公司承诺遵守《民法典》《个人信息保护法》等有关个人隐私保护法律的规定。其他详见《隐私政策》。',
      '5.12.2 川陀大匠公司制定了严格的用户上传信息处理规则和安全保护措施来确保不超越目的和范围收集用户信息，确保用户上传信息的安全，确保用户上传信息不被滥用。除征得用户明确同意和法律明确规定外，川陀大匠公司不会向任何第三方提供用户上传文件及信息。',
      '5.12.3 川陀大匠公司制定了严格的用户上传信息处理规则和安全保护措施来确保不超越目的和范围收集用户信息，确保用户上传信息的安全，确保用户上传信息不被滥用。除以下情形外，川陀大匠公司不会收集、或向任何第三方提供用户上传的资料及用户信息：',
      '(1)事先获得用户的授权；',
      '(2)依据法律、法规、法律程序的要求或政府主管部门的强制性要求；',
      '(3)符合相关服务条款或使用协议的约定。',
      '5.13 程序错误日志上报：当程序出现意外错误或崩溃时，会自动生成一个错误日志。本软件根据需要会向川陀大匠公司安全中心上报错误日志，以便定位程序错误或崩溃的原因，改善产品质量。在需要用户上报错误日志时，本软件会询问用户是否同意上报。如果用户同意上报，本软件会将此错误日志上报给川陀大匠公司安全中心；如果用户不同意，则不会上报。错误日志是川陀大匠公司程序在出现错误或崩溃时记录的运行信息，仅包含程序本身的出错信息，绝不涉及任何用户个人数据。',
    ],
  },
  {
    title: '6、开源许可条款',
    items: [
      '6.1本软件使用了Retrofit，OkHttp，UtilCode等开源框架，根据 Apache License 协议要求，需保留以下未经修改的版权声明、许可条件及免责条款：https://www.apache.org/licenses/LICENSE-2.0',
    ],
  },
  {
    title: '7、免责与责任限制',
    items: [
      '7.1 川陀大匠公司将不断完善产品功能，为用户提供更好的产品体验。基于用户手机的硬件及软件的差异，本功能无法保证技术上绝对的及时性、准确性、完整性。',
      '7.2 用户确认，其知悉本软件所有功能及川陀大匠公司为实现本软件各功能所进行的必要操作，其根据自身需求自愿选择使用本软件及相关服务，川陀大匠公司不对您使用本软件及服务所查询的结果的安全性、正确性、及时性、完整性、实用性等作出任何保证，您应对您使用本软件及服务中的内容或所查询的结果自行加以判断，并承担因使用内容而引起的所有风险。因使用本软件及相关服务所存在的风险和一切后果将完全由其自己承担，川陀大匠公司不承担任何责任。',
      '7.3 本软件经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误。如果出现不兼容及软件错误的情况，用户可拨打技术支持电话将情况报告川陀大匠公司，获得技术支持。如果无法解决兼容性问题，用户可以删除本软件。',
      '7.4 用户确认，其知晓并理解川陀大匠公司对本软件各项功能的及时性、准确性、完整性、能否实现预定效果、能否满足用户需求，未做出任何明示或默示的承诺或保证。如用户认为本软件存在不足，可随时关闭相关功能或卸载本软件。',
      '7.5 在适用法律允许的最大范围内，非因川陀大匠公司公司过错所产生的损害及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，由用户自行承担。',
      '7.6 对于因电信系统或互联网网络故障、计算机故障或病毒、信息损坏或丢失、计算机系统问题或其它任何不可抗力原因而产生损失，川陀大匠公司不承担任何责任。如用户遇有相关问题请及时通知川陀大匠公司，川陀大匠公司将在采取相应措施及时解决用户所通知的问题。',
      '7.7 用户违反本协议规定，川陀大匠公司有权采取包括但不限于中断使用许可、停止提供服务、限制使用、法律追究等措施。',
      '7.8 如果您从未经川陀大匠公司授权的第三方获取本软件或与本软件名称相同的安装程序，川陀大匠公司无法保证该软件能正常使用，并对因此给您造成的损失不予负责。',
      '7.9 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同)，这种使用己经获得合法授权。本软件如果使用了第三方的软件或技术，川陀大匠公司将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”“授权协议”“严源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守相关要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求川陀大匠公司给予协助，您应当自行承担法律责任。',
      `7.10 川陀大匠公司在有限时间内提供此试用版软件，以便您能够评估${productName}的功能和技术。您得到授权在指定的试用期（“试用期”）内使用${productName}，在此期间${productName}可能只提供有限或者受限的功能，因此，在试用期内使用${productName}是您自行独立审慎判断的结果，您将对此负责；且，在使用该服务过程中，您将对自行操作的行为及其产生的结果负责，请您自行把握风险谨慎操作。`,
      `7.11 您理解并同意，在免费期间，川陀大匠公司虽然对${productName}中的服务提供可用性支撑，但不对其中任何错误或漏洞提供任何担保，并不对您使用${productName}中的服务、工作或结果承担任何责任。`,
      `7.12 您理解并认可，川陀大匠公司保留随时修改、取消、增强${productName}中的服务一项或多项功能的权利，并有权要求您使用最新更新的版本；川陀大匠公司保留随时终止提供${productName}中的服务的权利；届时，川陀大匠公司将以提前通过在网站内合适版面发布公告或发送站内通知等方式通知您。如您有任何违反本服务条款的情形，或经川陀大匠公司根据自己的独立判断认为您的使用行为不符合川陀大匠公司的要求，川陀大匠公司除有权随时中断或终止您使用${productName}中的服务而无须通知您，并将限制您新订购${productName}中的服务的权限；同时，如有造成损失的，川陀大匠公司有权要求您赔偿损失。`,
    ],
  },
  {
    title: '8、法律及争议解决',
    items: [
      '8.1 本协议适用中华人民共和国法律。',
      '8.2 因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成的，任何一方均可向北京仲裁委员会提起仲裁。',
    ],
  },
  {
    title: '9、其他条款',
    items: [
      '9.1如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为无效，但本协议的其余条款仍应有效并且有约束力。',
      `9.2 川陀大匠公司有权随时根据有关法律、法规的变化以及公司经营状况和经营策略的调整等修改本协议。修改后的协议会在您使用${productName}APP时进行弹窗提示，并指引您阅读。当发生有关争议时，以最新的协议文本为准。如果您不同意改动的内容，用户可以自行删除本软件。如果用户继续使用本软件，则视为您接受本协议的变动。`,
    ],
  },
];

export default data;
