import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPaidOrdersAction, getPricesInfoAction } from '../../../redux/order';

import s from './OrderInfo.module.scss';
import emptyIcon from './images/emptyIcon.svg';
import defaultIcon from './images/defaultIcon.svg';
import tHeaderIcon from './images/tHeaderIcon.svg';
import OrderItem from './OrderItem';

interface IProps {
  getPaidOrdersAction: (priceInfo: any[]) => void;
  getPaidOrdersResult: any;
  getPricesInfoAction: () => void;
  getPricesInfoResult: any;
}

function OrderInfo(props: IProps) {
  async function fetchData() {
    await props.getPricesInfoAction();
  }

  useEffect(() => {
    document.title = '青松守护 - 订单信息';
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      await props.getPaidOrdersAction(props.getPricesInfoResult || []);
    }
    fetchData();
  }, [props.getPricesInfoResult]);

  const mapOrderInfo = (isIn7Days: boolean) => {
    let orders = props.getPaidOrdersResult || [];
    if (isIn7Days) {
      orders = orders.filter(
        (item: any) => moment().diff(moment.unix(item.order.paid_time), 'days') <= 7
      );
    }
    return orders.map((paidOrder: any) => {
      return {
        id: paidOrder.order.id,
        paidTime: moment.unix(paidOrder.order.paid_time).format('YYYY年MM月DD日 HH:mm'),
        type: paidOrder.type,
        amount: paidOrder.order.amount,
        activeCode: paidOrder.activeCode,
      };
    });
  };

  const orderAll = mapOrderInfo(false);
  const orderIn7Days = mapOrderInfo(true);

  return (
    <div className={s.container}>
      <div className={s.tableContainer}>
        <div>近7天订单</div>
        <OrderTable data={orderIn7Days} />
      </div>
      <div className={`${s.tableContainer} ${s.topSpace}`}>
        <div>全部订单</div>
        <OrderTable data={orderAll} />
      </div>
    </div>
  );
}

interface ITableData {
  id: string;
  paidTime: string;
  type: string;
  amount: number;
  activeCode: any[];
}

interface IOrderTableProps {
  data: ITableData[];
}

function OrderTable(props: IOrderTableProps) {
  return (
    <div>
      <div className={s.tableHeader}>
        <span style={{ width: '36px', display: 'flex', alignItems: 'center' }}>
          {props.data.length === 0 ? (
            <img src={defaultIcon} alt="" style={{ width: '16px', height: '16px' }} />
          ) : (
            <img src={tHeaderIcon} alt="" style={{ width: '16px', height: '16px' }} />
          )}
        </span>
        <span>订单日期</span>
        <span>会员类型</span>
        <span>优惠信息</span>
        <span>实付金额</span>
        <span>交易状态</span>
      </div>
      {props.data.length === 0 ? (
        <div className={s.dataEmpty}>
          <img src={emptyIcon} alt="emptyIcon" />
          您当前暂无订单
        </div>
      ) : (
        <div className={s.tableContent}>
          {props.data.map((item) => {
            return (
              <OrderItem
                key={item.id}
                id={item.id}
                type={item.type}
                paidTime={item.paidTime}
                amount={item.amount}
                detailInfo={item.activeCode}
              ></OrderItem>
            );
          })}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getPaidOrdersResult: state.getPaidOrdersResult.data.data,
    getPricesInfoResult: state.getPricesInfoResult.data.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPaidOrdersAction: bindActionCreators(getPaidOrdersAction, dispatch),
    getPricesInfoAction: bindActionCreators(getPricesInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);
