import React from 'react';
import { Switch } from 'react-router-dom';

import './App.module.scss';
import MobileInvitingRegister from './home/mobile/pages/MobileInvitingRegister/MobileInvitingRegister';
import Login from './home/pages/Login/Login';
import homePages from './home/routes';
import qsParentPages from './qsParent/routes';

import QsRoute, { QsRouter } from './commonComponents/Auth/QsRoute';

function App() {
  return (
    <div>
      <QsRouter>
        <Switch>
          <QsRoute path="/login" mobile={true} invisibleWhenAuthenticated={true}>
            <Login />
          </QsRoute>
          <QsRoute path="/invitingRegister" mobile={true}>
            <MobileInvitingRegister />
          </QsRoute>
          {homePages()}
          {qsParentPages()}
        </Switch>
      </QsRouter>
    </div>
  );
}

export default App;
