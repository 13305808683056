import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

import s from './OrderInfo.module.scss';
import upIcon from './images/upIcon.svg';
import copyIcon from '../../../commonComponents/QsIcons/copyIcon.svg';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';

interface IProps {
  id: string;
  type: string;
  paidTime: any;
  amount: number;
  detailInfo: any[];
}

function OrderItem(props: IProps) {
  const [show, setShow] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const codeAllUsed =
      props.detailInfo.filter((item) => item.status === 1).length ===
      props.detailInfo.length;
    if (codeAllUsed && props.detailInfo.length > 0) {
      setShow(false);
    }
  }, [props.detailInfo]);

  const copyText = (text: string) => {
    copy(text);
    setShowAlert(true);
  };

  const toggleCard = () => {
    setShow((prev) => !prev);
  };

  const showDetail = props.detailInfo.length > 0;
  const codeAllUsed =
    props.detailInfo.filter((item) => item.status === 1).length ===
    props.detailInfo.length;

  return (
    <>
      <div key={props.id} className={s.tableItem}>
        <span style={{ width: '36px', display: 'flex', alignItems: 'center' }}>
          {props.detailInfo.length > 0 && (
            <img
              onClick={toggleCard}
              src={upIcon}
              alt=""
              className={`${s.detailImg} ${show ? '' : s.detailImgDown}`}
            />
          )}
        </span>
        <span>{props.paidTime}</span>
        <span>{props.type}</span>
        <span>- -</span>
        <span>{props.amount}</span>
        <span className={s.orderState}>
          <span className={s.dot} /> 交易成功
          {showDetail ? (
            codeAllUsed ? (
              <span className={s.used}>（已使用）</span>
            ) : (
              <span className={s.waitForUse}>（待使用）</span>
            )
          ) : null}
        </span>
      </div>
      {showDetail && show && (
        <ul className={s.groupBuyDetail}>
          {props.detailInfo.map((item, index) => {
            const isUsed = item.status === 1;
            return (
              <li key={index} className={isUsed ? s.codeUsed : ''}>
                <span>激活码{index + 1}：</span>
                <span>{item.activeCode}</span>
                <span className={s.copyText}>
                  <img src={copyIcon} alt="" onClick={() => copyText(item.activeCode)} />
                </span>
                {isUsed ? (
                  <span style={{ marginRight: '40px' }} className={s.defaultTag}>
                    已使用
                  </span>
                ) : (
                  <span style={{ marginRight: '40px' }} className={s.successTag}>
                    待使用
                  </span>
                )}
                <span>（激活码可兑换 1 年会员）</span>
              </li>
            );
          })}
        </ul>
      )}
      <QsAlert
        visible={showAlert}
        type="success"
        message="复制成功"
        position="fixed"
        onClose={() => {
          setShowAlert(false);
        }}
      />
    </>
  );
}
export default OrderItem;
