import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { Tooltip } from 'antd';

import QsSwitch from '../../../commonComponents/QsSwitch/QsSwitch';
import { getOriginalBehaviorListAction } from '../../../redux/behavior';
import s from './Behavior.module.scss';
import Select from './Select/Select';
import webIcon from './images/webIcon.svg';
import appIcon from '../../../commonComponents/QsIcons/appIcon.svg';
import mobileAppIcon from './images/mobileAppIcon.svg';
import QSTable from '../../../commonComponents/QsTable/QsTable';
import expandIcon from './images/expandIcon.svg';
import putIcon from './images/putIcon.svg';
import { getUrlQuery } from '../../../common';
import variables from '../../../variables.module.scss';

enum TimeFilter {
  Today = 'today',
  SevenDays = '7Days',
}

enum DataType {
  Web = 'web',
  Application = 'application',
  MobileApp = 'mobileApp',
}

interface IProps {
  getOriginalBehaviorListAction: (time: number, refresh: boolean) => Promise<void>;
  getOriginalBehaviorListResult: any;
}

function Behavior(props: IProps) {
  const [timeFilter, setTimeFilter] = useState(TimeFilter.Today);
  const [dateCursor, setDateCursor] = useState(0);
  const [activeType, setActiveType] = useState('all');
  const [clickedItem, setClickedItem] = useState('');
  const [expand, setExpand] = useState(false);
  const FORMAT_DATE = 'YYYY-MM-DD';

  useEffect(() => {
    const type: any = getUrlQuery('type') || 'pc';
    if (type === 'mobile') {
      setActiveType(DataType.MobileApp);
    }
  }, []);

  const getDate = (day: number) => {
    return moment(
      moment()
        .subtract(dateCursor + day, 'd')
        .format(FORMAT_DATE)
    ).unix();
  };

  const getHistoryData = async () => {
    await props.getOriginalBehaviorListAction(getDate(1), false);
    setDateCursor(dateCursor + 1);
  };

  const filterData = () => {
    let dataItems = props.getOriginalBehaviorListResult.result?.data || [];
    let addedItem = props.getOriginalBehaviorListResult.result?.added || [];
    if (activeType !== 'all') {
      dataItems = dataItems.filter((item: any) => item.type === activeType);
      addedItem = addedItem.filter((item: any) => item.type === activeType);
    } else {
      dataItems = dataItems.filter((item: any) => item.type !== DataType.MobileApp);
      addedItem = addedItem.filter((item: any) => item.type !== DataType.MobileApp);
    }

    return { dataItems, addedItem };
  };

  useEffect(() => {
    async function fetchData() {
      const currentDate = moment(moment().format(FORMAT_DATE)).unix();
      await props.getOriginalBehaviorListAction(currentDate, true);
    }
    const { dataItems, addedItem } = filterData();
    if (dateCursor === 0) {
      fetchData();
    } else if ((dataItems.length < 10 || addedItem.length <= 0) && dateCursor <= 6) {
      getHistoryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateCursor, activeType]);

  const handleScroll = async (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && timeFilter === TimeFilter.SevenDays) {
      await getHistoryData();
    }
  };

  const timeSwitch = () => {
    const data = [
      {
        value: TimeFilter.Today,
        name: '今天',
      },
      {
        value: TimeFilter.SevenDays,
        name: '近七天',
      },
    ];

    return (
      <QsSwitch
        data={data}
        currentValue={timeFilter}
        handleClick={async (value: any) => {
          setTimeFilter(value);
          if (value === TimeFilter.Today) {
            const currentDate = moment(moment().format(FORMAT_DATE)).unix();
            // eslint-disable-next-line react/prop-types
            await props.getOriginalBehaviorListAction(currentDate, true);
            setDateCursor(0);
          } else {
            await getHistoryData();
          }
        }}
      />
    );
  };

  const icon: any = {
    web: webIcon,
    application: appIcon,
    mobileApp: mobileAppIcon,
  };

  const handleSelectChange = (type: string) => {
    setActiveType(type);
  };

  const setColumnWidth = () => {
    if (window.innerWidth <= 1024) {
      return '200px';
    } else {
      return '300px';
    }
  };
  const columns = [
    {
      title: '活动时间',
      dataIndex: 'time',
      key: 'time',
      width: setColumnWidth(),
    },
    {
      title: <Select defaultActive={activeType} onChange={handleSelectChange} />,
      dataIndex: 'type',
      key: 'type',
      // eslint-disable-next-line react/display-name
      render: (record: any, rowRecord: any) => {
        const iconUrl = isUndefined(record.icon) ? icon[record.type] : record.icon;
        return (
          <div className={s.name}>
            <div className={s.icon} style={{ backgroundImage: `url(${iconUrl})` }} />
            <div>
              <Tooltip
                placement="bottom"
                title={record.value}
                color={variables.colorMasterC7}
              >
                <span className={s.nameValue}>{record.value}</span>
              </Tooltip>
              {rowRecord.expand.length > 0 && (
                <div className={s.recordDetail}>
                  {rowRecord.expand.length} 条访问记录
                  {clickedItem === rowRecord.key && expand ? (
                    <span>
                      收起详情
                      <img src={putIcon} alt="icon" />
                    </span>
                  ) : (
                    <span>
                      展开详情
                      <img src={expandIcon} alt="icon" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
      // eslint-disable-next-line react/display-name
      expendRender: (record: any) => {
        return (
          <Tooltip placement="bottom" title={record} color={variables.colorMasterC7}>
            <span className={s.nameValue}>{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '时长',
      dataIndex: 'duration',
      key: 'duration',
      width: setColumnWidth(),
    },
  ];

  const formatTime = (timestamp: number) => {
    const currentTime = new Date();
    const currentM = currentTime.getMonth() + 1;
    const currentDate = currentTime.getDate();
    const currentTimeStr =
      currentM < 10 ? `0${currentM}月${currentDate}日` : `${currentM}月${currentDate}日`;
    const dateTime = moment.unix(timestamp).format('MM月DD日 HH:mm');
    const [date, time] = dateTime.split(' ');
    return date === currentTimeStr ? `今天 ${time}` : dateTime;
  };

  const secondsToHms = (d: number) => {
    const h = Math.floor(d / 3600);
    const m = Math.ceil((d % 3600) / 60);

    const hDisplay = h > 0 ? h + '小时' : '';
    const mDisplay = m > 0 ? m + '分钟' : '';
    return hDisplay + mDisplay;
  };

  const mapData = () => {
    const { dataItems } = filterData();
    return dataItems.map((item: any, index: number) => {
      const name =
        item.type === DataType.Application || item.type === DataType.MobileApp
          ? `打开了 「${item.name}」`
          : `访问了 ${item.name} ${item.url}`;
      const details = (item.subRecords || []).map((sub: any, index: number) => {
        return {
          key: index.toString(),
          time: formatTime(sub.time),
          type: `访问了 ${sub.name} ${sub.url}`,
          duration: sub.duration > 0 ? `< ${secondsToHms(sub.duration)}` : '< 1 分钟',
        };
      });

      return {
        key: index.toString(),
        time: formatTime(item.time),
        type: {
          value: name,
          icon: item.icon?.url,
          type: item.type,
        },
        duration: item.duration > 0 ? `< ${secondsToHms(item.duration)}` : '< 1 分钟',
        expand: details,
      };
    });
  };

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.switch}>{timeSwitch()}</div>
        <div className={s.table}>
          <QSTable
            columns={columns}
            dataSource={mapData()}
            handleScroll={handleScroll}
            emptyMessage="暂无行为记录"
            handleItemClick={(currentKey, expand) => {
              setClickedItem(currentKey);
              setExpand(expand);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getOriginalBehaviorListResult: state.getOriginalBehaviorListResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOriginalBehaviorListAction: bindActionCreators(
      getOriginalBehaviorListAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Behavior);
