import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import s from './TabContent.module.scss';
import NoData from '../../../../commonComponents/NoData/NoData';
import pcCloseImg from '../images/pcClose.svg';
import pcOpenImg from '../images/pcOpen.svg';
import phoneCloseImg from '../images/phoneClose.svg';
import phoneOpenImg from '../images/phoneOpen.svg';
import ImagesList from '../ImagesList/ImagesList';
import { MobileBaseCard, PcBaseCard } from '../../SoftwareMgr/BaseCard/BaseCard';
import { DeviceStatus } from '../../../pages/Dashboard/LeftContainer/LeftContainer';
import { QsButtonFixedSize } from '../../../../commonComponents/QsButton/QsButton';

interface IProps {
  theme: 'green' | 'blue' | 'yellow';
  children: React.ReactElement | string;
  desc: string;
}

function TabContent(props: IProps) {
  return (
    <>
      <div className={s.tips}>
        <span>
          <BsFillInfoCircleFill className={s[`${props.theme}Icon`]} />
        </span>
        <span className={s.desc}>{props.desc}</span>
      </div>
      <div className={s.container}>{props.children}</div>
    </>
  );
}

interface ILeftMobileCardProps {
  clickHandle: () => void;
  isProcessing: boolean;
  btnDisabled?: boolean;
  online: boolean;
}

function LeftMobileCard(props: ILeftMobileCardProps) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className={s.leftMobileCard}>
      <img src={props.online ? phoneOpenImg : phoneCloseImg} alt="" />

      {props.isProcessing && (
        <div className={s.loadingBox}>
          <Spin indicator={antIcon} />
          <span className={s.loadingText}>截屏中，请稍后…</span>
        </div>
      )}

      <div className={s.pcCardTitle}>孩子手机</div>
      <div>
        <DeviceStatus isOnline={props.online} />
      </div>
      <div className={s.screenShotBtn}>
        <QsButtonFixedSize
          size="small"
          type="primary"
          disabled={!props.online || props.isProcessing}
          handleClick={() => {
            if (props.online) {
              props.clickHandle();
            }
          }}
        >
          <span>立即截屏</span>
        </QsButtonFixedSize>
      </div>
    </div>
  );
}

interface IPcEmptyCard {
  clickHandle: () => void;
  functionName: string;
}

function PcEmptyCard(props: IPcEmptyCard) {
  return <PcBaseCard handleClick={props.clickHandle} functionName={props.functionName} />;
}

interface IPcCardProps {
  clickHandle: () => void;
  pcTerminals: any;
}

function PcCard(props: IPcCardProps) {
  const checkOnline = () => {
    if (props.pcTerminals.length > 0) {
      if (props.pcTerminals[0].online) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={s.pcCard}>
        <img src={checkOnline() ? pcOpenImg : pcCloseImg} alt="" />
        <div className={s.pcCardTitle}>孩子电脑</div>
        <div>
          <DeviceStatus isOnline={checkOnline()} />
        </div>
        <div className={s.pcCardDesc}>点击“开启远程”，即可远程至孩子电脑。</div>
        <QsButtonFixedSize
          size="normal"
          type="primary"
          disabled={!checkOnline()}
          handleClick={() => {
            if (checkOnline()) {
              props.clickHandle();
            }
          }}
        >
          <span>开启远程</span>
        </QsButtonFixedSize>
      </div>
    </>
  );
}

interface IMobileEmptyCardProps {
  clickHandle: () => void;
}

function MobileEmptyCard(props: IMobileEmptyCardProps) {
  return (
    <>
      <MobileBaseCard handleClick={props.clickHandle} functionName="远程守护" />
    </>
  );
}

interface IMobileCardProps {
  deviceList: any;
  clickHandle: () => void;
  imagesDataList: any;
  deleteImgById: (data: string) => void;
  isProcessing: boolean;
  btnDisabled?: boolean;
}

function MobileCard(props: IMobileCardProps) {
  const checkOnline = () => {
    if (props.deviceList?.length > 0) {
      if (props.deviceList?.[0].isOnline) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <LeftMobileCard
        btnDisabled={props.btnDisabled}
        online={checkOnline()}
        clickHandle={props.clickHandle}
        isProcessing={props.isProcessing}
      />
      <div className={s.cardList}>
        <div className={s.cardListTitle}>截屏记录</div>
        <div className={s.cardListContent}>
          {props.imagesDataList?.length <= 0 ? (
            <div className={s.noData}>
              <NoData message="暂无截屏记录" />
            </div>
          ) : (
            <ImagesList
              imagesDataList={props.imagesDataList}
              onDelete={props.deleteImgById}
            />
          )}
        </div>
      </div>
    </>
  );
}

export {
  TabContent,
  // LeftPcCard,
  PcEmptyCard,
  PcCard,
  LeftMobileCard,
  MobileEmptyCard,
  MobileCard,
};
