import { createActionAsync } from 'redux-act-async';
import createReducerAsync from './reducer/createReducerAsync';

import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig } from '../common';
import { GET_ALL_FAQ, GET_ALL_FAQ_CATEGORIES, SEARCH_FAQ } from './constants';

function getAllFaq() {
  return fetch('/api/ibg/faqs', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 403) return Promise.reject();
      const data = await resp.json();
      if (resp.status === 200) {
        return { success: true, data };
      }
      return { success: false, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getAllFaqAction = createActionAsync(
  GET_ALL_FAQ,
  getAllFaq,
  defaultActionOption
);

export const getAllFaqReducer = createReducerAsync(getAllFaqAction);

function getAllCategories() {
  return fetch('/api/ibg/faqs/categories', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 403) return Promise.reject();
      const data = await resp.json();
      if (resp.status === 200) {
        return { success: true, data };
      }
      return { success: false, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getAllCategoriesAction = createActionAsync(
  GET_ALL_FAQ_CATEGORIES,
  getAllCategories,
  defaultActionOption
);

export const getAllCategoriesReducer = createReducerAsync(getAllCategoriesAction);

function searchFaq(searchValue) {
  return fetch(`/api/ibg/faqs/find/?kind=search&&search=${searchValue}`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 403) return Promise.reject();
      const data = await resp.json();
      if (resp.status === 200) {
        return { success: true, data };
      }
      return { success: false, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const searchFaqAction = createActionAsync(
  SEARCH_FAQ,
  searchFaq,
  defaultActionOption
);

export const searchFaqReducer = createReducerAsync(searchFaqAction);
