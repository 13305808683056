import React from 'react';
import Register from '../../../pages/Sem/Register';
import contentImg from './images/content.png';
import content2Img from './images/content2.png';
import s from './MobileInvitingRegister.module.scss';

function MobileInvitingRegister() {
  return (
    <div className={s.container}>
      <div className={s.registerContainer}>
        <img className={s.content} src={contentImg} alt="content" />
        <div className={s.register}>
          <Register
            formName="registerForTop"
            layout="horizontal"
            shape="round"
            btnText="立即注册"
            inputType="round"
          />
        </div>
      </div>

      <img className={s.content2} src={content2Img} alt="content" />
    </div>
  );
}

export default MobileInvitingRegister;
