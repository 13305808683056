import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginForm from '../LoginForm/LoginForm';
import { QsSpecialModal } from '../../../commonComponents/QsModal/QsModal';
import s from './LoginRegisterModal.module.scss';
import { resetLoginErrorAction } from '../../../redux/login';
import { resetRegisterErrorAction } from '../../../redux/register';
import { resetPhoneCaptchaErrorAction } from '../../../redux/getPhoneCaptcha';
import { resetSearchUserByPhoneAction } from '../../../redux/user';
import activityBanner from './images/activityBanner.png';

interface IProps {
  visible: boolean;
  handleClose: () => void;
  redirect?: string;
  resetRegisterErrorAction: () => void;
  resetSearchUserByPhoneAction: () => void;
  resetLoginErrorAction: () => void;
  resetPhoneCaptchaErrorAction: () => void;
}

function LoginRegisterModal(props: IProps) {
  const resetActionInfo = async () => {
    await props.resetLoginErrorAction();
    await props.resetPhoneCaptchaErrorAction();
    await props.resetRegisterErrorAction();
    await props.resetSearchUserByPhoneAction();
  };

  return (
    <QsSpecialModal
      title="登录账号"
      visible={props.visible}
      width={430}
      customFooter={null}
      onCancel={() => {
        resetActionInfo();
        props.handleClose();
      }}
    >
      <div>
        <div className={s.loginTips}>未注册，验证后自动登录</div>
        <img
          style={{ width: '360px', marginBottom: '30px' }}
          src={activityBanner}
          alt=""
        />
        <LoginForm redirect={props.redirect} isModal={true} />
      </div>
    </QsSpecialModal>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    resetLoginErrorAction: bindActionCreators(resetLoginErrorAction, dispatch),
    resetRegisterErrorAction: bindActionCreators(resetRegisterErrorAction, dispatch),
    resetPhoneCaptchaErrorAction: bindActionCreators(
      resetPhoneCaptchaErrorAction,
      dispatch
    ),
    resetSearchUserByPhoneAction: bindActionCreators(
      resetSearchUserByPhoneAction,
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(LoginRegisterModal);
