import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig } from '../common';
import { GET_IOS_RELEASE_INFO } from './constants';

async function getIosReleaseInfo() {
  const resp = await fetch('/api/mb/v1/releases/iosRelease', fetchGetConfig());
  if (!resp.ok && resp.status !== 429 && resp.status !== 400) {
    return {};
  }
  const data = await resp.json();
  return data;
}

export const getIosReleaseInfoAction = createActionAsync(
  GET_IOS_RELEASE_INFO,
  getIosReleaseInfo,
  defaultActionOption
);

export const getIosReleaseInfoReducer = createReducerAsync(getIosReleaseInfoAction);
