import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig } from '../common';
import { GET_PHONE_CAPTCHA, RESET_PHONE_CAPTCHA_ERROR } from './constants';

function getPhoneCaptcha(phone) {
  return fetch('/services/tianxun/apiv2/user/sms', fetchPostConfig({ phone }))
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 429 && resp.status !== 400) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'sms fetch error' }));
}

export const getPhoneCaptchaAction = createActionAsync(
  GET_PHONE_CAPTCHA,
  getPhoneCaptcha,
  defaultActionOption
);

function resetPhoneCaptchaError() {
  return Promise.resolve({ reason: null });
}

export const resetPhoneCaptchaErrorAction = createActionAsync(
  RESET_PHONE_CAPTCHA_ERROR,
  resetPhoneCaptchaError,
  defaultActionOption
);

export const getPhoneCaptchaReducer = createReducerAsync([
  getPhoneCaptchaAction,
  resetPhoneCaptchaErrorAction,
]);
