/* eslint @typescript-eslint/no-var-requires: "off" */
import { createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import rootReducer from '../redux/reducer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
export default function configureStore(initialState: any = {}): any {
  const store = createStore(rootReducer, applyMiddleware(thunk));

  // @ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // @ts-ignore
    module.hot.accept('../redux/reducer/index', () => {
      const nextReducer = require('../redux/reducer/index').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
