import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { DownOutlined, UpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MdThumbUp } from 'react-icons/md';
import moment from 'moment';
import 'moment/locale/zh-cn';

import s from './FeedbackItem.module.scss';
import deleteNormal from './images/deleteNormal.svg';
import deleteHover from './images/deleteHover.svg';
import optimizationIcon from './images/optimizationIcon.svg';

moment.locale('zh-cn');

const { confirm } = Modal;

interface IStates {
  isDeleteHover: boolean;
  isOverflow: boolean;
  expand: boolean;
}

interface IProps {
  id: string;
  name: string;
  content: string;
  time: number;
  likeNumber: number;
  liked: boolean;
  isMine: boolean;
  isOptimize: boolean;
  handleLikeClick: (cancel: boolean) => void;
  handleDelete: () => void;
}

class FeedbackItem extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isDeleteHover: false,
      isOverflow: false,
      expand: false,
    };
  }

  componentDidMount() {
    const displayEle = document.getElementById(this.props.id);
    if (displayEle) {
      this.setState({
        isOverflow: displayEle.scrollHeight > displayEle.clientHeight,
      });
    }
  }

  shrinkContent = () => {
    return (
      <div className={s.info}>
        <div
          id={this.props.id}
          className={`${this.state.expand ? s.showAll : s.overflow}`}
        >
          {this.props.content}
        </div>
        {this.state.isOverflow &&
          (!this.state.expand ? (
            <div
              className={s.expend}
              onClick={() => {
                this.setState({ expand: true });
              }}
            >
              展开
              <DownOutlined />
            </div>
          ) : (
            <div
              className={s.expend}
              onClick={() => {
                this.setState({ expand: false });
              }}
            >
              收起
              <UpOutlined />
            </div>
          ))}
      </div>
    );
  };

  showConfirm = () => {
    confirm({
      title: '确认删除此条内测体验反馈吗？',
      icon: <ExclamationCircleOutlined />,
      width: 480,
      centered: true,
      okText: '确认删除',
      cancelText: '取消删除',
      onOk: this.props.handleDelete,
    });
  };

  render() {
    return (
      <div className={s.container}>
        {this.props.isOptimize && (
          <img src={optimizationIcon} alt="optimizationIcon" className={s.optimize} />
        )}
        <div className={s.name}>{this.props.name}</div>
        {this.shrinkContent()}
        <div className={s.operation}>
          <div>
            {this.props.liked ? (
              <Button type="primary" onClick={() => this.props.handleLikeClick(true)}>
                <MdThumbUp />
                已点赞 {this.props.likeNumber}
              </Button>
            ) : (
              <Button
                type="primary"
                className={s.likeBtn}
                onClick={() => this.props.handleLikeClick(false)}
              >
                <MdThumbUp />
                点赞 {this.props.likeNumber}
              </Button>
            )}
            {this.props.isMine && (
              <span
                className={s.delete}
                onMouseOver={() => {
                  this.setState({ isDeleteHover: true });
                }}
                onMouseLeave={() => {
                  this.setState({ isDeleteHover: false });
                }}
                onClick={this.showConfirm}
              >
                {this.state.isDeleteHover ? (
                  <img src={deleteHover} alt="deleteIcon" />
                ) : (
                  <img src={deleteNormal} alt="deleteIcon" />
                )}
                删除
              </span>
            )}
          </div>
          <div className={s.time}>{moment.unix(this.props.time).format('lll')}</div>
        </div>
      </div>
    );
  }
}

export default FeedbackItem;
