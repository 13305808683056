import React, { Component } from 'react';
import { Input, Button, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './Feedback.module.scss';
import logo from '../../../commonComponents/QsIcons/logo.svg';
import emptyIcon from '../../../commonComponents/QsIcons/emptyIcon.svg';
import BasicTabs from '../../../commonComponents/QsTabs/BasicTabs';
import FeedbackItem from '../../components/FeedbackItem/FeedbackItem';
import ActivityRule from '../../components/ActivityRule/ActivityRule';
import {
  publishFeedbackAction,
  getAllFeedbackAction,
  allFeedbackCountsAction,
  getMineFeedbackAction,
  mineFeedbackCountsAction,
  feedBackLikeAction,
  feedBackDeleteAction,
} from '../../../redux/feedback';
import { getOrgInfoAction } from '../../../redux/user';
import CompleteInfoModal from '../../components/CompleteInfoModal/CompleteInfoModal';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';

const { TextArea } = Input;

interface IStates {
  usedWords: number;
  showWordsTips: boolean;
  content: string;
  showAlert: boolean;
  currentType: 'all' | 'mine';
  allCurrentPage: number;
  mineCurrentPage: number;
}

interface IProps {
  publishFeedbackAction: (orgId: string, userName: string, content: string) => void;
  publishFeedbackResult: any;
  getOrgInfoAction: () => void;
  getOrgInfoResult: any;
  getAllFeedbackAction: (page: number, orgId: string) => void;
  getAllFeedbackResult: any;
  allFeedbackCountsAction: () => void;
  allFeedbackCountsResult: any;
  getMineFeedbackAction: (page: number, orgId: string) => void;
  getMineFeedbackResult: any;
  mineFeedbackCountsAction: (orgId: string) => void;
  mineFeedbackCountsResult: any;
  feedBackLikeAction: (id: string, orgId: string, cancel: boolean) => void;
  feedBackDeleteAction: (id: string, orgId: string) => void;
}

class Feedback extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      usedWords: 0,
      showWordsTips: false,
      content: '',
      showAlert: false,
      allCurrentPage: 1,
      mineCurrentPage: 1,
      currentType: 'all',
    };
  }

  async componentDidMount() {
    document.title = '青松守护';
    await this.props.getOrgInfoAction();
    const orgInfo = this.props.getOrgInfoResult.org || {};
    await Promise.all([
      this.updateData(),
      this.props.allFeedbackCountsAction(),
      this.props.mineFeedbackCountsAction(orgInfo.org_id),
    ]);
  }

  updateData = async () => {
    const orgInfo = this.props.getOrgInfoResult.org || {};
    this.props.getAllFeedbackAction(this.state.allCurrentPage - 1, orgInfo.org_id);
    this.props.getMineFeedbackAction(this.state.mineCurrentPage - 1, orgInfo.org_id);
  };

  textAreaOnChange = (e: any) => {
    const value = e.target.value;
    this.setState({ usedWords: value.length, content: value });
  };

  publish = async () => {
    const orgInfo = this.props.getOrgInfoResult.org;
    await this.props.publishFeedbackAction(
      orgInfo.org_id,
      orgInfo.team_name,
      this.state.content
    );
    await this.updateData();
    this.setState({ showAlert: true });
    if (this.props.publishFeedbackResult.success) {
      this.setState({ content: '' });
    }
  };

  publishFeedBack = () => {
    return (
      <div className={s.publishContainer} id="publish">
        <div className={s.publishTitle}>发表用户体验</div>
        <div className={s.textAreaContainer}>
          <TextArea
            value={this.state.content.length === 0 ? undefined : this.state.content}
            rows={7}
            className={s.textArea}
            placeholder="编辑您的青松守护用户体验"
            maxLength={800}
            onChange={this.textAreaOnChange}
            onFocus={() => {
              this.setState({ showWordsTips: true });
            }}
            onBlur={() => {
              this.setState({ showWordsTips: false });
            }}
          />
          {this.state.showWordsTips && (
            <span className={s.textLength}>{this.state.usedWords}/800</span>
          )}
        </div>
        <Button
          type="primary"
          onClick={this.publish}
          disabled={this.state.content.length === 0 || this.state.content.length > 800}
        >
          发表
        </Button>
      </div>
    );
  };

  handleLikeClick = async (id: string, cancel: boolean) => {
    const orgInfo = this.props.getOrgInfoResult.org || {};
    await this.props.feedBackLikeAction(id, orgInfo.org_id, cancel);
    await this.updateData();
  };

  handleDelete = async (id: string) => {
    const orgInfo = this.props.getOrgInfoResult.org || {};
    await this.props.feedBackDeleteAction(id, orgInfo.org_id);
    await this.updateData();
  };

  feedbackContent = (feedbackItems: any[], feedbackIds: string[]) => {
    if (feedbackItems.length === 0 || !feedbackItems[0].user_name) {
      return (
        <div className={s.emptyContent}>
          <img src={emptyIcon} alt="emptyIcon" />
          当前暂未发表内测体验反馈
        </div>
      );
    }

    return (
      <div>
        {feedbackItems.map((item, index) => {
          return (
            <FeedbackItem
              key={feedbackIds[index]}
              id={feedbackIds[index]}
              name={item.user_name}
              content={item.content}
              liked={item.has_liked}
              likeNumber={item.like_count}
              time={item.generation_time}
              isMine={item.is_mine}
              isOptimize={item.has_picked}
              handleLikeClick={(cancel: boolean) =>
                this.handleLikeClick(feedbackIds[index], cancel)
              }
              handleDelete={async () => {
                await this.handleDelete(feedbackIds[index]);
              }}
            />
          );
        })}
      </div>
    );
  };

  handlePageChange = (page: number) => {
    if (this.state.currentType === 'all') {
      this.setState({ allCurrentPage: page }, async () => {
        await this.updateData();
      });
    } else {
      this.setState({ mineCurrentPage: page }, async () => {
        await this.updateData();
      });
    }
  };

  showFeedBack = () => {
    const allFeedbackItems: any[] = Object.values(this.props.getAllFeedbackResult);
    const allFeedbackIds = Object.keys(this.props.getAllFeedbackResult);

    const mineFeedbackItems: any[] = Object.values(this.props.getMineFeedbackResult);
    const mineFeedbackIds = Object.keys(this.props.getMineFeedbackResult);
    const items = [
      {
        key: 'all',
        title: '全部',
        content: this.feedbackContent(allFeedbackItems, allFeedbackIds),
      },
      {
        key: 'mine',
        title: '我的',
        content: this.feedbackContent(mineFeedbackItems, mineFeedbackIds),
      },
    ];
    const total = {
      all: this.props.allFeedbackCountsResult.count || 0,
      mine: this.props.mineFeedbackCountsResult.count || 0,
    };
    return (
      <div className={s.showContainer}>
        <BasicTabs
          type="rectangle"
          position="above"
          items={items}
          size="large"
          onChange={(key: any) => {
            this.setState({ currentType: key });
          }}
        />
        <div className={s.pagination}>
          <Pagination
            defaultCurrent={1}
            current={
              this.state.currentType === 'all'
                ? this.state.allCurrentPage
                : this.state.mineCurrentPage
            }
            total={total[this.state.currentType]}
            pageSize={15}
            showSizeChanger={false}
            hideOnSinglePage={true}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  };

  render() {
    const errorInfo =
      this.props.publishFeedbackResult.data &&
      this.props.publishFeedbackResult.data.reason;
    const errMessage: any = {
      'max out': '当日最多可发表5次产品体验信息。',
      'content too long': '内容过长',
    };
    return (
      <div className={s.container}>
        <div className={s.header}>
          <div>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className={s.content}>
          <ActivityRule anchorId="publish" />
          {this.publishFeedBack()}
          {this.showFeedBack()}
        </div>
        <QsAlert
          visible={this.state.showAlert}
          type={this.props.publishFeedbackResult.success ? 'success' : 'error'}
          message={
            this.props.publishFeedbackResult.success
              ? '发表成功！'
              : errMessage[errorInfo] || '发表失败,请稍后重试。'
          }
          position="fixed"
          onClose={async () => {
            this.setState({ showAlert: false });
          }}
        />
        <CompleteInfoModal />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getOrgInfoResult: state.getOrgInfoResult.data,
    publishFeedbackResult: state.publishFeedbackResult.data,
    getAllFeedbackResult: state.getAllFeedbackResult.data,
    allFeedbackCountsResult: state.allFeedbackCountsResult.data,
    getMineFeedbackResult: state.getMineFeedbackResult.data,
    mineFeedbackCountsResult: state.mineFeedbackCountsResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    publishFeedbackAction: bindActionCreators(publishFeedbackAction, dispatch),
    getAllFeedbackAction: bindActionCreators(getAllFeedbackAction, dispatch),
    allFeedbackCountsAction: bindActionCreators(allFeedbackCountsAction, dispatch),
    getMineFeedbackAction: bindActionCreators(getMineFeedbackAction, dispatch),
    mineFeedbackCountsAction: bindActionCreators(mineFeedbackCountsAction, dispatch),
    feedBackLikeAction: bindActionCreators(feedBackLikeAction, dispatch),
    feedBackDeleteAction: bindActionCreators(feedBackDeleteAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
