import { createActionAsync } from 'redux-act-async';
import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig, fetchPatchConfig } from '../common';
import {
  GET_LOCK_SCREEN_CONTROLS,
  UPDATE_LOCK_SCREEN_CONTROLS,
  GET_PC_LOCK_SCREEN_CONTROLS,
  RESET_UPDATE_LOCK_SCREEN_CONTROLS,
} from './constants';

function getLockScreenControls(mobileId) {
  return fetch('/api/mb/v1/lockScreenControls/find', fetchPostConfig({ mobileId }))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({
      success: false,
      data: 'get mobile lock screen controls error',
    }));
}

export const getLockScreenControlsAction = createActionAsync(
  GET_LOCK_SCREEN_CONTROLS,
  getLockScreenControls,
  defaultActionOption
);

export const getLockScreenControlsReducer = createReducerAsync(
  getLockScreenControlsAction
);

function getPcLockScreenControls(orgId) {
  return fetch('/api/mb/v1/lockScreenControls/find', fetchPostConfig({ mobileId: orgId }))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({
      success: false,
      data: 'get pc lock screen controls error',
    }));
}

export const getPcLockScreenControlsAction = createActionAsync(
  GET_PC_LOCK_SCREEN_CONTROLS,
  getPcLockScreenControls,
  defaultActionOption
);

export const getPcLockScreenControlsReducer = createReducerAsync(
  getPcLockScreenControlsAction
);

function updateLockScreenControls(policyId, mode, customLockScreenRule, enable) {
  return fetch(
    `/api/mb/v1/lockScreenControls/${policyId}`,
    fetchPatchConfig({ mode, customLockScreenRule, enable })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      if (resp.status === 204) {
        return { success: true, message: 'No Content' };
      }
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({
      success: false,
      data: 'update mobile lock screen controls error',
    }));
}

export const updateLockScreenControlsAction = createActionAsync(
  UPDATE_LOCK_SCREEN_CONTROLS,
  updateLockScreenControls,
  defaultActionOption
);

function resetUpdateLockScreen() {
  return Promise.resolve({});
}

export const resetUpdateLockScreenAction = createActionAsync(
  RESET_UPDATE_LOCK_SCREEN_CONTROLS,
  resetUpdateLockScreen,
  defaultActionOption
);

export const updateLockScreenControlsReducer = createReducerAsync([
  updateLockScreenControlsAction,
  resetUpdateLockScreenAction,
]);
