import React, { Component } from 'react';

import s from '../Policy/Policy.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import agreementInfo from './agreementInfo';
import MobileHeader from '../../mobile/components/MobileHeader/MobileHeader';
import MobileFooter from '../../mobile/components/MobileFooter/MobileFooter';
import { isMobile } from '../../../common';
import CompleteInfoModal from '../../components/CompleteInfoModal/CompleteInfoModal';
import { isZGYD } from '../../../utils/lib';

class Agreement extends Component {
  componentDidMount() {
    document.title = `用户协议${!!isZGYD() ? '' : ' - 青松守护'}`;
  }

  render() {
    const productName = '青松守护';
    return (
      <div>
        {isMobile() ? <MobileHeader /> : <Header />}
        <div className={isMobile() ? s.mobileContainer : s.container}>
          <div className={`${s.header} ${isMobile() ? s.mobileHeader : ''}`}>
            用户协议
          </div>
          <div className={s.time}>更新日期：2022年6月22日</div>
          <div className={s.time}>生效日期：2022年6月22日</div>
          <div className={s.mgb20}>欢迎使用{productName}！</div>
          <div className={s.mgb20}>
            请务必认真阅读和理解本《用户使用协议》（以下简称本《协议》）各条款内容，特别是免除或者限制责任的条款、法律适用和管辖条款，并选择接受或者不接受。免除或者限制责任的条款、法律适用和管辖条款可能以黑体加粗、下划线形式提示您注意。
          </div>
          <div className={s.mgb20}>
            除非已阅读并接受本《协议》条款，否则您无权下载、安装或使用{productName}
            安卓版及其相关服务。您一旦安装、复制、下载、访问或以其它方式使用本软件产品，将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意本《协议》中的条款，请不要安装、复制或使用本软件。
          </div>
          <div className={s.mgb20}>
            本《协议》是用户与南京川陀大匠信息技术有限公司（下称“川陀大匠公司”）之间关于用户下载、安装、使用、复制
            {productName}安卓版软件（以下简称“本软件”或“{productName}”）的法律协议。
          </div>
          {agreementInfo.map((item: any, index: number) => (
            <div key={index}>
              <div className={`${s.mgb20} ${s.title}`}>{item.title}</div>
              {item.items.map((d: string, index: number) => (
                <div key={index} className={s.mgb20}>
                  {d}
                </div>
              ))}
            </div>
          ))}
        </div>
        {isMobile() ? <MobileFooter /> : <Footer showBackground={true} />}
        <CompleteInfoModal />
      </div>
    );
  }
}

export default Agreement;
