import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMobileDeviceAction } from '../../../redux/mobileAppDevice';
import { fetchQsPcClientUrlAction } from '../../../redux/downloadPCClient';
import BindMobile from './BindMobile';
import BindWindows from './BindWindows';
import { QsModalBaisc } from '../../../commonComponents/QsModal/QsModal';

interface IStates {
  deviceType: 'mobile' | 'pc';
  isLoading: boolean;
}

interface IProps {
  visible: boolean;
  handleCancel: () => void;
  defaultType?: 'mobile' | 'pc';
  hasPc?: boolean;
  hasMobile?: boolean;
  getMobileDeviceAction: () => Promise<void>;
  getMobileDeviceResult: any;
  getOrgInfoResult: any;
  fetchQsPcClientUrlAction: () => void;
  fetchQsPcClientUrlResult: any;
  mobileLoopAction?: () => void;
}

class BindFlowModal extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      deviceType: 'mobile',
      isLoading: true,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: false,
      deviceType: this.props.defaultType || 'mobile',
    });

    await this.props.fetchQsPcClientUrlAction();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.defaultType !== this.props.defaultType) {
      this.setState({
        deviceType: this.props.defaultType || 'mobile',
      });
    }
  }

  getMobileDeviceInfo = () => {
    const { data: mobileDevice, success } = this.props.getMobileDeviceResult || {};
    const mobile = success && _.isArray(mobileDevice) ? mobileDevice : [];
    return mobile;
  };

  getMobileDevice = async () => {
    await this.props.getMobileDeviceAction();
    const mobile = this.getMobileDeviceInfo();

    if (mobile.length > 0) {
      if (this.props.mobileLoopAction) {
        this.props.mobileLoopAction();
      }
    }
  };

  pcDownload = () => {
    return (
      <BindWindows
        onClick={() => {
          const url = this.props.fetchQsPcClientUrlResult.downloadUrl || '';
          const a = document.createElement('a');
          a.href = url;
          document.body.appendChild(a);
          a.click();
        }}
      ></BindWindows>
    );
  };

  mobileDownload = () => {
    const orgId = this.props.getOrgInfoResult && this.props.getOrgInfoResult.org_id;
    return (
      <BindMobile
        orgId={orgId}
        onClick={() => {
          this.getMobileDevice();
          this.props.handleCancel();
          this.setState({
            deviceType: this.props.defaultType || 'mobile',
          });
        }}
      ></BindMobile>
    );
  };

  render() {
    if (this.state.isLoading) {
      return null;
    }
    return (
      <QsModalBaisc
        closable={true}
        visible={this.props.visible}
        onCancel={() => {
          if (this.props.defaultType === 'mobile') {
            this.getMobileDevice();
          }
          this.props.handleCancel();
          this.setState({
            deviceType: this.props.defaultType || 'mobile',
          });
        }}
      >
        {this.state.deviceType === 'mobile' ? this.mobileDownload() : this.pcDownload()}
      </QsModalBaisc>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    fetchQsPcClientUrlResult: state.fetchQsPcClientUrlResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    fetchQsPcClientUrlAction: bindActionCreators(fetchQsPcClientUrlAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BindFlowModal);
