import { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.body;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  children: React.ReactNode;
}

class Modal extends Component<IProps, Record<string, never>> {
  el: HTMLElement;
  constructor(props: IProps) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot?.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Modal;
