import React, { Component } from 'react';

import s from './QsSwitch.module.scss';

interface IProps {
  data: {
    value: string | number;
    name: string;
  }[];
  currentValue: string | number;
  handleClick: (value: string | number) => void;
}

interface IStates {
  sliderPosition: 'left' | 'right' | null;
}

class QsSwitch extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      sliderPosition: null,
    };
  }

  componentWillUnmount() {
    this.setState({ sliderPosition: null });
  }

  render() {
    const sliderPositionAnimation: any = {
      left: s.leftSliderAnimation,
      right: s.rightSliderAnimation,
    };

    return (
      <div className={s.container}>
        {this.props.data.map((item, index) => {
          return (
            <div
              key={item.value}
              onClick={() => {
                this.setState({ sliderPosition: index === 0 ? 'left' : 'right' });
                this.props.handleClick(item.value);
              }}
              className={this.props.currentValue === item.value ? s.activeText : ''}
            >
              {item.name}
            </div>
          );
        })}
        <span
          className={`${s.slider} ${
            this.props.currentValue === this.props.data[0].value
              ? s.leftSlider
              : s.rightSlider
          } ${sliderPositionAnimation[this.state.sliderPosition || ''] || ''}`}
        />
      </div>
    );
  }
}

export default QsSwitch;
