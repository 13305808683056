import { createActionAsync } from 'redux-act-async';
import moment from 'moment';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig } from '../common';
import { GET_BEHAVIOR_LIST, GET_ORIGINAL_BEHAVIOR_LIST } from './constants';
import { getMobileDevice } from './mobileAppDevice';

function formatData(data, oldItems = []) {
  const currentTime = new Date();
  const currentM = currentTime.getMonth() + 1;
  const currentDate = currentTime.getDate();
  const currentTimeStr =
    currentM < 10 ? `0${currentM}月${currentDate}日` : `${currentM}月${currentDate}日`;

  const formatData = data.map((data) => {
    const dateTime = moment.unix(data.time).format('MM月DD日 HH:mm');
    const [date, time] = dateTime.split(' ');
    return {
      ...data,
      time,
      date: date === currentTimeStr ? '今天' : date,
    };
  });

  const dates = Array.from(new Set(formatData.map((data) => data.date)));
  const result = dates.map((date) => {
    const items = formatData.filter((data) => data.date === date);
    return {
      date,
      items: items,
    };
  });

  const mergedItems = oldItems.concat(result);
  const allList = [];
  mergedItems.forEach((ob) => {
    const saved = allList.filter((data) => data.date === ob.date);
    if (saved.length > 0) {
      saved[0].items = saved[0].items.concat(ob.items);
    } else {
      allList.push(ob);
    }
  });

  return allList;
}

function getPcHistory(time, needAggregate = false) {
  const bodyData = {
    time,
    needAggregate,
  };
  return fetch('/services/tianxun/apiv2/historyQueries', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data.items;
    })
    .catch(() => {
      return [];
    });
}

async function getMobileAppHistory(bodyData) {
  return fetch('/api/mb/v1/applicationUsages/find', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => {
      return [];
    });
}

async function getAllMobileAppHistory(time, needAggregate = false) {
  const mobileInfo = await getMobileDevice();
  const result = await Promise.all(
    mobileInfo.data.map((ele) => {
      const bodyData = {
        mobileId: ele.id,
        time,
        needAggregate,
      };
      const info = getMobileAppHistory(bodyData);
      return info;
    })
  );

  if (result.length === 0) {
    return result;
  }

  return result.reduce((sum, n) => {
    return sum.concat(n);
  });
}

async function getBehaviorList(time, refresh, dispatch, getState) {
  try {
    const oldHistory = refresh
      ? []
      : getState().getBehaviorListResult.data?.result?.data || [];
    const mobileAppHistory = await getAllMobileAppHistory(time);
    const pcHistory = await getPcHistory(time, true);
    const allHistory = formatData(
      mobileAppHistory.concat(pcHistory).sort((a, b) => b.time - a.time)
    );

    const data = oldHistory.concat(allHistory);

    return { success: true, result: { data, added: allHistory } };
  } catch (e) {
    return { success: false, message: 'fetch error' };
  }
}

export const getBehaviorListAction = createActionAsync(
  GET_BEHAVIOR_LIST,
  getBehaviorList,
  defaultActionOption
);

export const getBehaviorListReducer = createReducerAsync(getBehaviorListAction);

async function getOriginalBehaviorList(time, refresh, dispatch, getState) {
  try {
    const oldHistory = refresh
      ? []
      : getState().getOriginalBehaviorListResult.data?.result?.data || [];
    const mobileAppHistory = await getAllMobileAppHistory(time, true);
    const pcHistory = await getPcHistory(time, true);
    const allHistory = mobileAppHistory.concat(pcHistory).sort((a, b) => b.time - a.time);

    const data = oldHistory.concat(allHistory);

    return { success: true, result: { data, added: allHistory } };
  } catch (e) {
    return { success: false, message: 'fetch error' };
  }
}

export const getOriginalBehaviorListAction = createActionAsync(
  GET_ORIGINAL_BEHAVIOR_LIST,
  getOriginalBehaviorList,
  defaultActionOption
);

export const getOriginalBehaviorListReducer = createReducerAsync(
  getOriginalBehaviorListAction
);
