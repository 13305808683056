import React, { useEffect, useState } from 'react';

import timeIcon from '../../../commonComponents/QsIcons/timeIcon.svg';
import remoteIcon from '../../../commonComponents/QsIcons/remoteIcon.svg';
import appIcon from '../../../commonComponents/QsIcons/applicationIcon.svg';
import learningModeIcon from '../../../commonComponents/QsIcons/learningModeIcon.svg';

import s from './FunctionInfo.module.scss';
import androidIcon from './images/androidIcon.svg';
import dashboardIcon from './images/dashboardIcon.svg';
import windowsIcon from './images/windowsIcon.svg';
import mobileParentApp from './images/mobile.png';
import pcParent from './images/computer.png';
import zgydPcParent from '../../../ZGYDAssets/zgydPcParent.svg';
import { isZGYD } from '../../../utils/lib';
import variables from '../../../variables.module.scss';

interface IProps {
  hideBtn?: boolean;
  handleDashboardBtnClick?: () => void;
  handlePcDownloadBtnClick?: () => void;
}

function FunctionInfo(props: IProps): any {
  const [mobileParentAppVisible, setMobileParentAppVisible] = useState(false);
  const [pcParentVisible, setPcParentVisible] = useState(false);

  const checkEleVisible = (id: string, offset = 0): boolean => {
    const ele = document.getElementById(id);
    if (!ele) {
      return false;
    }
    const top = ele.getBoundingClientRect().top;
    const height = ele.getBoundingClientRect().height;
    const visible = top + offset + height >= 0 && top - offset <= window.innerHeight;
    return visible;
  };

  useEffect(() => {
    const scrollHandle = () => {
      const mobileParentAppVisible = checkEleVisible('mobileParentApp');
      const pcParentVisible = checkEleVisible('pcParent');
      setMobileParentAppVisible(mobileParentAppVisible);
      setPcParentVisible(pcParentVisible);
    };

    window.addEventListener('scroll', () => {
      scrollHandle();
    });

    return () =>
      window.removeEventListener('scroll', () => {
        scrollHandle();
      });
  }, [mobileParentAppVisible, pcParentVisible]);

  const funcItems = [
    {
      id: 'mobileParentApp',
      title: '手机守护',
      subTitle: '手机防沉迷，家长更放心',
      visible: mobileParentAppVisible,
      desc: [
        {
          icon: {
            url: timeIcon,
            color: variables.colorFunctionWarning,
          },
          title: '约定使用时间',
          desc: ['和孩子约定每天什么时候可以用手机，可以用多长时间。'],
        },
        {
          icon: {
            url: remoteIcon,
            color: variables.colorFunctionInfo,
          },
          title: '亲子同屏',
          desc: ['共享孩子的屏幕，和孩子同屏学习，共同成长。'],
        },
        {
          icon: {
            url: appIcon,
            color: variables.primaryColor,
          },
          title: 'App 管理',
          desc: ['设置可用 App 及其使用时长。'],
        },
      ],
      image: mobileParentApp,
      download: [
        {
          icon: androidIcon,
          title: 'Android 下载',
          onClick: () => {
            window.open('/downloads', '_black');
          },
        },
      ],
    },
    {
      id: 'pcParent',
      title: '电脑守护',
      subTitle: '上网有过滤，网课更安心',
      visible: pcParentVisible,
      desc: [
        {
          icon: {
            url: learningModeIcon,
            color: variables.colorFunctionError,
          },
          title: '学习模式',
          desc: ['在学习期间杜绝游戏、短视频对孩子的干扰。'],
        },
        {
          icon: {
            url: timeIcon,
            color: variables.colorFunctionWarning,
          },
          title: '约定使用时间',
          desc: ['和孩子约定每天什么时候可以用电脑，可以用多长时间。'],
        },
        {
          icon: {
            url: remoteIcon,
            color: variables.colorFunctionInfo,
          },
          title: '亲子同屏',
          desc: ['共享孩子的屏幕，和孩子同屏学习，共同成长。'],
        },
        {
          icon: {
            url: appIcon,
            color: variables.primaryColor,
          },
          title: '软件管理',
          desc: ['设置可用软件及其使用时长。'],
        },
      ],
      download: [
        {
          icon: dashboardIcon,
          title: '进入控制中心',
          onClick: props.handleDashboardBtnClick,
        },
        {
          icon: windowsIcon,
          title: 'Windows 下载',
          onClick: props.handlePcDownloadBtnClick,
        },
      ],
      image: !!isZGYD() ? zgydPcParent : pcParent,
    },
  ];

  return funcItems.map((item: any, index) => {
    const desc = (
      <React.Fragment>
        <div>{item.title}</div>
        <div>{item.subTitle}</div>
        {item.desc.map((desc: any) => (
          <div key={desc.title} className={s.detail}>
            <div
              className={s.icon}
              style={{
                backgroundColor: desc.icon.color,
                boxShadow: `2px 4px 8px 0 ${desc.icon.color}33`,
              }}
            >
              <img src={desc.icon.url} alt="" />
            </div>
            <div>
              <div>
                <span>{desc.title}</span>
              </div>
              <ul>
                {desc.desc.map((i: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <li>{i}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        {!props.hideBtn && (
          <div>
            {item.download.map((i: any) => (
              <div
                key={i.title}
                className={s.download}
                onClick={() => {
                  if (i.onClick) {
                    i.onClick();
                  }
                }}
              >
                <img src={i.icon} alt="" />
                <span>{i.title}</span>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );

    const image = (
      <img
        src={item.image}
        alt="funcImage"
        id={item.id}
        className={`${item.visible ? s.showFunImage : ''}`}
      />
    );
    if (index % 2 === 0) {
      return (
        <div className={s.funcItem} key={item.id}>
          <div className={s.funcDesc}>{desc}</div>
          <div className={s.funcImage}>{image}</div>
        </div>
      );
    } else {
      return (
        <div className={s.funcItem} key={item.id}>
          <div className={s.funcImage}>{image}</div>
          <div className={s.funcDesc}>{desc}</div>
        </div>
      );
    }
  });
}

export default FunctionInfo;
