import { createActionAsync } from 'redux-act-async';
import moment from 'moment';
import createReducerAsync from './reducer/createReducerAsync';
import fetch, { customFetch } from './fetch.server';
import {
  defaultActionOption,
  fetchPostConfig,
  fetchGetConfig,
  fetchDelConfig,
} from '../common';
import {
  SCREEN_SHOT_UPDATE_STATUS,
  TRIGGER_SCREEN_SHOT,
  DELETE_IMAGE_BY_ID,
  CHECK_SCREEN_SHOT_PERMISSION,
  GET_ALL_IMAGES,
} from './constants';

async function triggerScreenShot(mobileId) {
  return await customFetch(
    `/api/mb/v1/screenshotJobs`,
    fetchPostConfig({ mobileId }),
    'json'
  );
}

export const triggerScreenShotAction = createActionAsync(
  TRIGGER_SCREEN_SHOT,
  triggerScreenShot,
  defaultActionOption
);

export const triggerScreenShotReducer = createReducerAsync(triggerScreenShotAction);

async function getScreenShotToken(imagePath, method) {
  return await customFetch(
    '/api/mb/v1/imageQueryCredentials/fetch',
    fetchPostConfig({ imagePath, method }),
    'json'
  );
}

async function screenShotUpdateStatus(id) {
  return await customFetch(`/api/mb/v1/screenshotJobs/${id}`, fetchGetConfig(), 'json');
}

export const screenShotUpdateStatusAction = createActionAsync(
  SCREEN_SHOT_UPDATE_STATUS,
  screenShotUpdateStatus,
  defaultActionOption
);

export const screenShotUpdateStatusReducer = createReducerAsync(
  screenShotUpdateStatusAction
);

async function getScreenShotFromCDN(token, url) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then(async (resp) => {
      if (resp.status === 404) {
        return { success: true, data: 'not found' };
      }
      if (!resp.ok) return Promise.reject();
      const createTime = resp.headers.get('Last-Modified');
      const time = moment(createTime).format('YYYY-MM-DD HH:mm');
      const timestamp = moment(createTime).unix();

      const img = await resp.blob();
      return { success: true, data: { img, time, timestamp } };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

async function getScreenImagesInfo(mobileId) {
  return await customFetch(
    `/api/mb/v1/screenshots/find`,
    fetchPostConfig({ mobileId: mobileId }),
    'json'
  );
}

async function getData(data) {
  return Promise.all(
    data.images.map(async (item) => {
      const imagePath = item.path;
      const getScreenShotTokenResult = await getScreenShotToken(imagePath, 'GET');
      const { auth, url } = getScreenShotTokenResult.data || {};
      if (!!auth && !!url) {
        const getScreenShotFromCDNResult = await getScreenShotFromCDN(auth, url);
        const { success, data } = getScreenShotFromCDNResult;
        const imageUrl = success ? data.img : '';
        const tmp = {
          id: item.id,
          srcUrl: imageUrl,
          createTime: data.time || '--',
        };
        return tmp;
      }
    })
  );
}

async function getAllImages(mobileId) {
  const imagesMetaInfo = await getScreenImagesInfo(mobileId);
  const { success, data } = imagesMetaInfo;
  let sortResult = [];
  if (success && data.images?.length > 0) {
    const result = await getData(data);
    sortResult = result.sort((a, b) => {
      return moment(a.time).unix() - moment(b.time).unix();
    });
  }

  return Promise.resolve({
    success: true,
    data: { data: sortResult.reverse(), id: data.id },
  });
}

export const getAllImagesAction = createActionAsync(
  GET_ALL_IMAGES,
  getAllImages,
  defaultActionOption
);

export const getAllImagesReducer = createReducerAsync(getAllImagesAction);

async function deleteImageById(screenshotId, imageId) {
  return await customFetch(
    `/api/mb/v1/screenshots/${screenshotId}/${imageId}`,
    fetchDelConfig({}),
    'json'
  );
}

export const deleteImageByIdAction = createActionAsync(
  DELETE_IMAGE_BY_ID,
  deleteImageById,
  defaultActionOption
);

export const deleteImageByIdReducer = createReducerAsync(deleteImageByIdAction);

async function checkScreenShotPermission(screenshotId) {
  return await customFetch(
    `/api/mb/v1/screenshots/${screenshotId}/status`,
    fetchGetConfig(),
    'json'
  );
}

export const checkScreenShotPermissionAction = createActionAsync(
  CHECK_SCREEN_SHOT_PERMISSION,
  checkScreenShotPermission,
  defaultActionOption
);

export const checkScreenShotPermissionReducer = createReducerAsync(
  checkScreenShotPermissionAction
);
