import React, { useState } from 'react';
import {
  SwapOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

import s from './LeftContainer.module.scss';
import phoneBigEmpty from './images/phoneBigEmpty.svg';
import phoneBigOnline from './images/phoneBigOnline.svg';
import phoneBigOffline from './images/phoneBigOffline.svg';
import phoneBigLocked from './images/phoneBigLocked.svg';
import phoneSmallEmpty from './images/phoneSmallEmpty.png';
import phoneSmallOnline from './images/phoneSmallOnline.svg';
import phoneSmallOffline from './images/phoneSmallOffline.svg';
import phoneSmallLocked from './images/phoneSmallLocked.svg';
import pcBigEmpty from './images/pcBigEmpty.svg';
import pcBigOnline from './images/pcBigOnline.svg';
import pcBigOffline from './images/pcBigOffline.svg';
import pcBigLocked from './images/pcBigLocked.svg';
import pcSmallEmpty from './images/pcSmallEmpty.png';
import pcSmallOnline from './images/pcSmallOnline.svg';
import pcSmallOffline from './images/pcSmallOffline.svg';
import pcSmallLocked from './images/pcSmallLocked.svg';
import silentModeIcon from './images/silentModeIcon.svg';
import lockImage from './images/lockImage.svg';
import BindFlowModal from '../../../components/BindFlowModal/BindFlowModal';
import MemberExpireModal from '../../../components/MemberExpireModal/MemberExpireModal';
import {
  QsButton,
  QsButtonFixedSize,
} from '../../../../commonComponents/QsButton/QsButton';

export const CONSTANT = {
  textPc: '电脑',
  textMobile: '手机',
};

interface IProps {
  currentDevice: 'pc' | 'mobile';
  handleDeviceSwitch: () => void;
  pcInfo: any;
  mobileInfo: any;
  isLimitExpired: boolean;
  pcLockScreenInfo: { locked: boolean; lockedCountdown: string };
  mobileLockScreenInfo: { locked: boolean; lockedCountdown: string };
}

function LeftContainer(props: IProps) {
  const isPc = props.currentDevice === 'pc';
  const switchDeviceName = isPc ? CONSTANT.textMobile : CONSTANT.textPc;

  const currentDevice = isPc ? props.pcInfo : props.mobileInfo;
  const switchDevice = isPc ? props.mobileInfo : props.pcInfo;
  const lockScreenInfo = isPc ? props.pcLockScreenInfo : props.mobileLockScreenInfo;

  const getBigImage = () => {
    if (!currentDevice) {
      return isPc ? pcBigEmpty : phoneBigEmpty;
    } else if (currentDevice.isOnline) {
      if (isPc) {
        return props.pcLockScreenInfo.locked ? pcBigLocked : pcBigOnline;
      } else {
        return props.mobileLockScreenInfo.locked ? phoneBigLocked : phoneBigOnline;
      }
    } else {
      return isPc ? pcBigOffline : phoneBigOffline;
    }
  };

  const getSmallImage = () => {
    if (!switchDevice) {
      return !isPc ? pcSmallEmpty : phoneSmallEmpty;
    } else if (switchDevice.isOnline) {
      if (!isPc) {
        return props.pcLockScreenInfo.locked ? pcSmallLocked : pcSmallOnline;
      } else {
        return props.mobileLockScreenInfo.locked ? phoneSmallLocked : phoneSmallOnline;
      }
    } else {
      return !isPc ? pcSmallOffline : phoneSmallOffline;
    }
  };

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.deviceImg}>
          <div className={s.mobileImg}>
            <img src={getBigImage()} alt="" />
            {currentDevice?.isOnline && lockScreenInfo.locked && (
              <div className={`${s.lockScreenInfo} ${isPc ? '' : s.mobileLockScreen}`}>
                <img src={lockImage} alt="" />
                <div>正在锁屏中...</div>
              </div>
            )}
          </div>
          {isPc && currentDevice?.isOnline && props.pcInfo?.silentMode && (
            <div className={s.pcSilentMode}>
              <img src={silentModeIcon} alt="" />
              隐身模式已开启
            </div>
          )}
        </div>
        {!currentDevice ? (
          <AddDevice
            type="big"
            deviceType={props.currentDevice}
            isLimitExpired={props.isLimitExpired}
          />
        ) : (
          <div className={s.currentText}>当前设备</div>
        )}
        <div className={s.switchDevice} onClick={props.handleDeviceSwitch}>
          <SwapOutlined />
          切换至孩子{switchDeviceName}
        </div>
      </div>
      <div className={s.bottom}>
        <div className={s.bottomDevice}>
          <img src={getSmallImage()} alt="" />
          <span>孩子{switchDeviceName}</span>
        </div>
        {!switchDevice ? (
          <AddDevice
            deviceType={isPc ? 'mobile' : 'pc'}
            isLimitExpired={props.isLimitExpired}
          />
        ) : (
          <DeviceStatus isOnline={switchDevice.isOnline} />
        )}
      </div>
    </div>
  );
}

interface IAddDeviceProps {
  type?: 'big' | 'default';
  deviceType?: 'pc' | 'mobile';
  isLimitExpired: boolean;
}

export function AddDevice(props: IAddDeviceProps) {
  const [showBindModal, setShowBindModal] = useState(false);
  const [showExpireModal, setShowExpireModal] = useState(false);

  const isPc = props.deviceType === 'pc';
  const btnText = isPc ? CONSTANT.textPc : CONSTANT.textMobile;

  const handleClick = () => {
    if (props.isLimitExpired) {
      setShowExpireModal(true);
    } else {
      setShowBindModal(true);
    }
  };

  let addBtn;
  if (props.type === 'big') {
    addBtn = (
      <div>
        <QsButtonFixedSize size="small" type="primary" handleClick={handleClick}>
          <div className={s.addBtn}>
            <PlusCircleOutlined />
            <span>添加孩子{btnText}</span>
          </div>
        </QsButtonFixedSize>
      </div>
    );
  } else {
    addBtn = (
      <QsButton size="normal" type="outline" handleClick={handleClick}>
        <div className={s.addBtnSmall}>
          <PlusCircleOutlined />
          <span>添加孩子{btnText}</span>
        </div>
      </QsButton>
    );
  }

  return (
    <>
      {addBtn}
      <BindFlowModal
        defaultType={props.deviceType}
        visible={showBindModal}
        handleCancel={() => {
          setShowBindModal(false);
        }}
      />
      <MemberExpireModal
        visible={showExpireModal}
        handleClose={() => {
          setShowExpireModal(false);
        }}
      />
    </>
  );
}

interface IDeviceStatusProps {
  isOnline: boolean;
}

export function DeviceStatus(props: IDeviceStatusProps) {
  return (
    <div>
      {props.isOnline ? (
        <span className={`${s.status} ${s.powerOn}`}>已开机</span>
      ) : (
        <span className={`${s.status} ${s.powerOff}`}>
          未开机
          <Tooltip title="未开机状态可能是设备断网或关机。" placement="bottom">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      )}
    </div>
  );
}

export default LeftContainer;
