import {
  REMOTE_DESKTOP_REQUEST,
  REMOTE_DESKTOP_VERIFIED,
  REMOTE_DESKTOP_SUCCESS,
  REMOTE_DESKTOP_FAILURE,
  REMOTE_DESKTOP_CLOSED,
} from '../constants';

export default function remoteDesktop(
  state = {
    status: 'request',
    error: null,
    channel: null,
  },
  action
) {
  switch (action.type) {
    case REMOTE_DESKTOP_REQUEST:
      return {
        ...state,
        status: 'request',
      };
    case REMOTE_DESKTOP_VERIFIED:
      return {
        ...state,
        status: 'verified',
      };
    case REMOTE_DESKTOP_SUCCESS:
      return {
        ...state,
        status: 'success',
        channel: action.channel,
      };
    case REMOTE_DESKTOP_FAILURE:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    case REMOTE_DESKTOP_CLOSED:
      return {
        ...state,
        status: 'closed',
      };
    default:
      return state;
  }
}
