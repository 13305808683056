import React, { Component } from 'react';

import s from './PricePackageRadio.module.scss';
import { IPriceInfo } from '../../pages/Price/Price';
import checkIcon from './images/checkIcon.png';
import GroupPurchaseDescPopover from '../GroupPurchaseDescPopover/GroupPurchaseDescPopover';
import AnniversaryPopover from '../AnniversaryPopover/AnniversaryPopover';
import anniversary from './images/3anniversary.png';
import { isActiveAnniversary } from '../../../utils/lib';

interface IProps {
  defaultChecked: string;
  handleClick: (currentPriceInfo: any) => void;
  pricesInfo: IPriceInfo[];
}

interface IStates {
  currentChecked: string;
}

class PricePackageRadio extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentChecked: '',
    };
  }

  componentDidMount() {
    this.setState({ currentChecked: this.props.defaultChecked });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.defaultChecked !== this.props.defaultChecked) {
      this.setState({ currentChecked: this.props.defaultChecked });
    }
  }

  render() {
    const pricesInfo = this.props.pricesInfo.filter((info) => info.name !== '免费体验');
    return (
      <div className={s.container}>
        {pricesInfo.map((item: IPriceInfo) => {
          const isChecked = this.state.currentChecked === item.id;
          const isGroupPurchase = item.name === '团购套餐';
          const isYearPurchase = item.name === '年付会员';

          return (
            <div
              key={item.id}
              className={isChecked ? s.checked : s.normal}
              onClick={async () => {
                await this.props.handleClick(item);
                this.setState({ currentChecked: item.id });
              }}
            >
              {isYearPurchase && isActiveAnniversary() && (
                <>
                  <img src={anniversary} alt="" className={s.anniversaryTag} />
                  <span className={s.anniversaryTips}>
                    <AnniversaryPopover />
                  </span>
                </>
              )}
              <div className={s.name}>
                <span>{item.name}</span>
                {isGroupPurchase ? (
                  <span className={s.groupPurchaseDesc}>
                    <GroupPurchaseDescPopover />
                  </span>
                ) : (
                  <span>（{item.months}个月）</span>
                )}
              </div>
              <div>
                <span className={s.price}>
                  {item.price}
                  {isGroupPurchase && <span className={s.discountInfo}>9折</span>}
                </span>
              </div>
              <div className={s.originPriceContainer}>
                {item.originPrice && (
                  <span className={s.originPrice}>¥{item.originPrice}</span>
                )}
              </div>
              {isChecked && <img src={checkIcon} alt="" className={s.checkIcon} />}
            </div>
          );
        })}
      </div>
    );
  }
}

export default PricePackageRadio;
