import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import s from './MobileScreenShot.module.scss';
import {
  triggerScreenShotAction,
  screenShotUpdateStatusAction,
  deleteImageByIdAction,
  checkScreenShotPermissionAction,
  getAllImagesAction,
} from '../../../../redux/screenShot';
import { getMobileDeviceAction } from '../../../../redux/mobileAppDevice';
import QsAlert from '../../../../commonComponents/QsAlert/QsAlert';
import { MobileCard, MobileEmptyCard } from '../TabContent/TabContent';
import { isArray } from 'lodash';

enum ScreenShotStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  FAILED = 'failed',
  PROCESSING = 'processing',
}

enum ResponseData {
  NOT_FOUND = 'not found',
  TOO_MANY_REQUESTS = 'Too Many Requests',
  FETCH_ERROR = 'fetch error',
  NOT_PERMISSION = 'not permission',
}

enum AlertMessage {
  DEFAULT = '孩子手机网络异常，截屏失败！',
  TOO_MANY_REQUESTS = '您的操作过于频繁，请1分钟后重试！',
  NOT_PERMISSION = '抱歉，截屏数超出上限，请删除部分库存！',
  DELETE_ERROR = '删除失败',
  DELETE_SUCCESS = '删除成功',
}

interface IProps {
  triggerScreenShotAction: (mobileId: string) => void;
  triggerScreenShotResult: any;
  screenShotUpdateStatusAction: (id: string) => void;
  screenShotUpdateStatusResult: any;
  getMobileDeviceAction: () => void;
  getMobileDeviceResult: any;
  showBindModalHandle: () => void;
  deleteImageByIdResult: any;
  deleteImageByIdAction: (screenshotId: string, imageId: string) => void;
  checkPermissionAction: (screenshotId: string) => void;
  checkPermissionResult: any;
  getAllImagesAction: (mobileId: string) => void;
  getAllImagesResult: any;
  currentMobileId: string;
}

function MobileScreenShot(props: IProps) {
  const [status, setStatus] = useState<
    | ScreenShotStatus.DEFAULT
    | ScreenShotStatus.PROCESSING
    | ScreenShotStatus.FAILED
    | ScreenShotStatus.SUCCESS
  >(ScreenShotStatus.DEFAULT);
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      await displayAllImages();
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentMobileId]);

  const displayAllImages = async () => {
    await props.getMobileDeviceAction();
    await props.getAllImagesAction(props.currentMobileId);
  };

  const handleBtnClick = async () => {
    setStatus(ScreenShotStatus.PROCESSING);
    const screenshotId = props.getAllImagesResult?.data?.id || '';
    const responseResult: any = await props.checkPermissionAction(screenshotId);
    const { data: result } = responseResult.response;

    if (result?.isFull === false) {
      const result: any = await props.triggerScreenShotAction(props.currentMobileId);

      const { data, success } = result.response;
      if (data === ResponseData.TOO_MANY_REQUESTS || data === ResponseData.FETCH_ERROR) {
        const alertMessage: any = {
          [ResponseData.TOO_MANY_REQUESTS]: AlertMessage.TOO_MANY_REQUESTS,
          [ResponseData.FETCH_ERROR]: AlertMessage.DEFAULT,
        };
        setStatus(ScreenShotStatus.FAILED);
        setAlertMessage(alertMessage[data]);
        return;
      }
      const updateResult = await loopScreenShotStatus(data, success);
      if (updateResult === ScreenShotStatus.PROCESSING) {
        setStatus(ScreenShotStatus.FAILED);
        setAlertMessage(AlertMessage.DEFAULT);
      }
    } else if (result?.isFull) {
      setStatus(ScreenShotStatus.FAILED);
      setAlertMessage(AlertMessage.NOT_PERMISSION);
      return;
    }
  };

  const loopScreenShotStatus = async (data: any, success: boolean) => {
    if (!success) {
      return;
    }

    let updateResult = ScreenShotStatus.PROCESSING;
    for (let i = 1; i <= 15; i++) {
      const result: any = await props.screenShotUpdateStatusAction(data.id);
      const { status } = result.response.data;
      switch (status) {
        case ScreenShotStatus.SUCCESS:
          updateResult = ScreenShotStatus.SUCCESS;
          displayAllImages();
          setStatus(ScreenShotStatus.SUCCESS);
          break;
        case ScreenShotStatus.FAILED:
          setStatus(ScreenShotStatus.FAILED);
          setAlertMessage(AlertMessage.DEFAULT);
          updateResult = ScreenShotStatus.FAILED;
          break;
        case ScreenShotStatus.PROCESSING:
          await sleep(1000);
          break;
        default:
          break;
      }

      if (updateResult !== ScreenShotStatus.PROCESSING) {
        break;
      }
    }

    return updateResult;
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getMobileDevice = () => {
    const { data: mobileDevice, success } = props.getMobileDeviceResult || {};
    const mobile = success && isArray(mobileDevice) ? mobileDevice : [];
    return mobile;
  };

  const deleteImgById = async (data: any) => {
    const { id: screenshotId } = props.getAllImagesResult?.data;
    const result: any = await props.deleteImageByIdAction(screenshotId, data);
    const { success } = result.response;
    if (success !== undefined) {
      if (!success) {
        setStatus(ScreenShotStatus.FAILED);
        setAlertMessage(AlertMessage.DELETE_ERROR);
      } else if (!!success) {
        displayAllImages();
        setStatus(ScreenShotStatus.SUCCESS);
        setAlertMessage(AlertMessage.DELETE_SUCCESS);
      }
    }
  };

  if (getMobileDevice().length <= 0) {
    return (
      <div className={s.emptyRoot}>
        <MobileEmptyCard clickHandle={props.showBindModalHandle} />
      </div>
    );
  } else {
    const isProcessing = status === ScreenShotStatus.PROCESSING;
    const imagesData = props.getAllImagesResult?.data?.data || [];

    return (
      <>
        <div className={s.root}>
          <MobileCard
            deviceList={getMobileDevice()}
            btnDisabled={isProcessing}
            isProcessing={isProcessing}
            imagesDataList={imagesData}
            clickHandle={handleBtnClick}
            deleteImgById={deleteImgById}
          />
        </div>
        <QsAlert
          visible={!!alertMessage}
          type={alertMessage === AlertMessage.DELETE_SUCCESS ? 'success' : 'error'}
          message={alertMessage}
          position="absolute"
          onClose={() => {
            setAlertMessage('');
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    triggerScreenShotResult: state.triggerScreenShotResult.data,
    screenShotUpdateStatusResult: state.screenShotUpdateStatusResult.data,
    deleteImageByIdResult: state.deleteImageByIdResult.data,
    checkPermissionResult: state.checkPermissionResult.data,
    getAllImagesResult: state.getAllImagesResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    triggerScreenShotAction: bindActionCreators(triggerScreenShotAction, dispatch),
    screenShotUpdateStatusAction: bindActionCreators(
      screenShotUpdateStatusAction,
      dispatch
    ),
    deleteImageByIdAction: bindActionCreators(deleteImageByIdAction, dispatch),
    checkPermissionAction: bindActionCreators(checkScreenShotPermissionAction, dispatch),
    getAllImagesAction: bindActionCreators(getAllImagesAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileScreenShot);
