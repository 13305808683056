import { createActionAsync } from 'redux-act-async';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig } from '../common';
import createReducerAsync from './reducer/createReducerAsync';
import { GET_ACTIVITY_LIST, GET_PARTICIPATE_INFO } from './constants';

function getActivityList() {
  return fetch(
    '/api/promotion/v1/activity/find',
    fetchPostConfig({ isInValidPeriod: true })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getActivityListAction = createActionAsync(
  GET_ACTIVITY_LIST,
  getActivityList,
  defaultActionOption
);

export const getActivityListReducer = createReducerAsync(getActivityListAction);

function getParticipateInfo(activityId, parentId) {
  return fetch(
    '/api/promotion/v1/inviterInfo/participate',
    fetchPostConfig({ activityId: activityId, parentId: parentId })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getParticipateInfoAction = createActionAsync(
  GET_PARTICIPATE_INFO,
  getParticipateInfo,
  defaultActionOption
);

export const getParticipateInfoReducer = createReducerAsync(getParticipateInfoAction);
