import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchGetConfig,
  fetchPutConfig,
  fetchPostConfig,
} from '../common';
import {
  SEARCH_USER_BY_PHONE,
  GET_ORG_INFO,
  GET_USER_INFO,
  CHECK_LOGIN_STATE,
  UPDATE_PHONE_NUMBER,
  COMPLETE_SUMMARY,
  SET_GIFT_MODAL,
  RESET_SEARCH_USER_BY_PHONE,
} from './constants';

function logout() {
  return fetch('/user/logout', fetchGetConfig())
    .then(async (resp) => {
      if (resp.ok) {
        window.location.href = '/';
      } else {
        const data = await resp.json();
        return data;
      }
    })
    .catch(() => ({ reason: 'logout fetch error' }));
}

export function getOrgInfo() {
  return fetch(`/services/tianxun/apiv2/user/organization`, fetchGetConfig())
    .then(async (resp) => {
      const data = await resp.json();
      if (data.message === 'Get Org Info Failed.') {
        logout();
      }
      if (!resp.ok) return Promise.reject();
      return data;
    })
    .catch(() => ({ success: false, message: 'org info fetch error' }));
}

export const getOrgInfoAction = createActionAsync(
  GET_ORG_INFO,
  getOrgInfo,
  defaultActionOption
);

export const getOrgInfoReducer = createReducerAsync(getOrgInfoAction);

function searchUserByPhone(phone) {
  return fetch(`/services/tianxun/apiv2/user/search?phone=${phone}`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const searchUserByPhoneAction = createActionAsync(
  SEARCH_USER_BY_PHONE,
  searchUserByPhone,
  defaultActionOption
);

function resetSearchUserByPhone() {
  return Promise.resolve({});
}

export const resetSearchUserByPhoneAction = createActionAsync(
  RESET_SEARCH_USER_BY_PHONE,
  resetSearchUserByPhone,
  defaultActionOption
);

export const searchUserByPhoneReducer = createReducerAsync([
  searchUserByPhoneAction,
  resetSearchUserByPhoneAction,
]);

function getUserInfo(userId) {
  return fetch(`/services/tianxun/apiv2/user/${userId}`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getUserInfoAction = createActionAsync(
  GET_USER_INFO,
  getUserInfo,
  defaultActionOption
);

export const getUserInfoReducer = createReducerAsync(getUserInfoAction);

function checkLoginState() {
  return fetch('/user/me', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      if (resp.status === 200) {
        const data = await resp.json();
        return { isLogin: true, organizationId: data['organizationId'] };
      } else {
        return { isLogin: false };
      }
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const checkLoginStateAction = createActionAsync(
  CHECK_LOGIN_STATE,
  checkLoginState,
  defaultActionOption
);

export const checkLoginStateReducer = createReducerAsync(checkLoginStateAction);

async function updateIbgLimitHistoryPhone(newPhoneNumber) {
  const orgInfo = await getOrgInfo();
  fetch(
    '/api/ibg/productLimit/update',
    fetchPutConfig({ orgId: orgInfo.org.org_id, phone: newPhoneNumber })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      logout();
    })
    .catch((error) => {
      console.log('updateIbgLimitHistoryPhone error:', error);
    });
}

function updatePhoneNumber(userId, newPhoneNumber, smsCode) {
  const data = {
    userId,
    newPhoneNumber,
    smsCode,
  };
  return fetch('/services/tianxun/apiv2/user/updatePhoneNumber', fetchPutConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      if (data.success) {
        updateIbgLimitHistoryPhone(newPhoneNumber);
      }
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updatePhoneNumberAction = createActionAsync(
  UPDATE_PHONE_NUMBER,
  updatePhoneNumber,
  defaultActionOption
);

export const updatePhoneNumberReducer = createReducerAsync(updatePhoneNumberAction);

function completeSummary(province, city, grade) {
  const data = {
    province,
    city,
    grade,
  };
  return fetch('/services/tianxun/apiv2/user/completeSummary', fetchPostConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const completeSummaryAction = createActionAsync(
  COMPLETE_SUMMARY,
  completeSummary,
  defaultActionOption
);

export const completeSummaryReducer = createReducerAsync(completeSummaryAction);

function setGiftModal(userId) {
  return fetch(`/api/user/api/users/giftModal/${userId}`, fetchPutConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const setGiftModalAction = createActionAsync(
  SET_GIFT_MODAL,
  setGiftModal,
  defaultActionOption
);

export const setGiftModalReducer = createReducerAsync(setGiftModalAction);
