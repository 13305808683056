import React from 'react';
import { Steps } from 'antd';
import { stepCircleIcon } from '../../../commonComponents/QsIcons/commonIcons';

import oneStep from './images/1.svg';
import twoStep from './images/2.svg';
import threeStep from './images/3.svg';
import fourStep from './images/4.svg';
import fiveStep from './images/5.svg';
import s from './UnbindPcStepPage.module.scss';

import { ArrowLeftOutlined } from '@ant-design/icons';

const { Step } = Steps;

interface IProps {
  onClick: () => void;
}

function UnbindPcStepPage(props: IProps) {
  return (
    <div className={s.unbindPcStepPage}>
      <div className={s.unbindPcBack} onClick={props.onClick}>
        <ArrowLeftOutlined />
        <span>返回</span>
      </div>
      <div className={s.unbindPcStepList}>
        <Steps current={0} direction="vertical">
          <Step
            icon={stepCircleIcon}
            title={
              <div className={s.unbindPcStepTitle}>
                <span>Step. 1</span>
                <span>打开系统菜单，点击「设置」按钮；</span>
              </div>
            }
            description={
              <img
                className={s.unbindPcStepDesc}
                src={oneStep}
                alt="打开系统菜单，点击「设置」按钮；"
              ></img>
            }
          />
          <Step
            icon={stepCircleIcon}
            title={
              <div className={s.unbindPcStepTitle}>
                <span>Step. 2</span>
                <span>进入「应用」页；</span>
              </div>
            }
            description={
              <img
                className={s.unbindPcStepDesc}
                src={twoStep}
                alt="进入「应用」页；"
              ></img>
            }
          />
          <Step
            icon={stepCircleIcon}
            title={
              <div className={s.unbindPcStepTitle}>
                <span>Step. 3</span>
                <span>找到「青松守护」，并点击「卸载」按钮；</span>
              </div>
            }
            description={
              <img
                className={s.unbindPcStepDesc}
                src={threeStep}
                alt="找到「青松守护」，并点击「卸载」按钮；"
              ></img>
            }
          />
          <Step
            icon={stepCircleIcon}
            title={
              <div className={s.unbindPcStepTitle}>
                <span>Step. 4</span>
                <span>输入手机验证码，并点击「卸载」按钮；</span>
              </div>
            }
            description={
              <img
                className={s.unbindPcStepDesc}
                src={fourStep}
                alt="输入手机验证码，并点击「卸载」按钮；"
              ></img>
            }
          />
          <Step
            icon={stepCircleIcon}
            title={
              <div className={s.unbindPcStepTitle}>
                <span>Step. 5</span>
                <span>待卸载进度条结束后，卸载完成。</span>
              </div>
            }
            description={
              <img
                className={s.unbindPcStepDesc}
                src={fiveStep}
                alt="待卸载进度条结束后，卸载完成。"
              ></img>
            }
          />

          <Step icon={<div></div>} title="" description="" />
        </Steps>
      </div>
    </div>
  );
}

export default UnbindPcStepPage;
