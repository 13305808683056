import React, { useEffect } from 'react';
import QRCode from 'qrcode';

interface IProps {
  value: string;
  elementId: string;
  width?: number;
}

function QrCode(props: IProps) {
  const renderQrCode = () => {
    const canvas: any = document.getElementById(props.elementId);
    QRCode.toCanvas(canvas, props.value, { width: props.width }, (error: any) => {
      if (error) console.error('generate qrCode error:---->', error);
    });
  };

  useEffect(() => {
    renderQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return <canvas id={props.elementId} />;
}

export default QrCode;
