import React, { useState } from 'react';

import Tab from './Tab';
import s from './Tab.module.scss';

interface IItem {
  key: string;
  label: string | React.ReactElement;
  labelIcon?: string;
  content: string | React.ReactElement;
}

interface IProps {
  items: IItem[];
  onChange?: (key: string) => void;
  bgColor?: string;
}

function Tabs(props: IProps) {
  const [activeTab, setActiveTab] = useState(props.items[0].key);
  const onClickTabItem = (tab: string) => {
    setActiveTab(tab);
    if (props.onChange) {
      props.onChange(tab);
    }
  };

  return (
    <div className="tabs">
      <div
        className={s.tabListConatiner}
        style={{ backgroundColor: props.bgColor || '#F0FDF9' }}
      >
        <div className={s.tabList}>
          {props.items.map((item: IItem) => {
            return (
              <Tab
                activeTab={activeTab}
                key={item.key}
                id={item.key}
                label={item.label}
                labelIcon={item.labelIcon}
                onClick={onClickTabItem}
              ></Tab>
            );
          })}
        </div>
      </div>
      <div className="tab-content">
        {props.items.map((item) => {
          if (item.key === activeTab) {
            return item.content;
          }
        })}
      </div>
    </div>
  );
}

export default Tabs;
