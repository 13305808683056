import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isArray } from 'lodash';

import { getTerminalInfoAction } from '../../../redux/terminal';
import BindFlowModal from '../../components/BindFlowModal/BindFlowModal';
import { getMobileDeviceAction } from '../../../redux/mobileAppDevice';
import MobileScreenShot from './MobileScreenShot/MobileScreenShot';
import PcRemote from './PcRemote/PcRemote';
import { TabTitle } from '../../components/AppLayout/AppLayout';
import mobileIcon from '../../../commonComponents/QsIcons/mobileIconLeft.svg';
import pcIcon from '../../../commonComponents/QsIcons/pcIconLeft.svg';
import { getAllImagesAction } from '../../../redux/screenShot';
import { getUrlQuery } from '../../../common';
import s from './RemoteAssistant.module.scss';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  getTerminalInfoAction: () => void;
  getTerminalInfoResult: any;
  getOrgInfoResult: any;
  getMobileDeviceResult: any;
  getMobileDeviceAction: () => void;
  getAllImagesAction: (mobileId: string) => void;
  getProductStateResult: any;
}

function RemoteAssistant(props: IProps) {
  const [deviceType, setDeviceType] = useState<'pc' | 'mobile'>('mobile');
  const [showBindModal, setShowBindModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentMobileId, setCurrentMobileId] = useState<string>('');

  useEffect(() => {
    document.title = !!isZGYD() ? '远程守护' : '青松守护 - 远程守护';
    const type: any = getUrlQuery('type') || 'pc';

    async function fetchData() {
      await Promise.all([props.getMobileDeviceAction(), props.getTerminalInfoAction()]);
    }

    fetchData();

    setIsLoading(false);
    setDeviceType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mobileDevice = props.getMobileDeviceResult.data;
    setCurrentMobileId((mobileDevice?.[0] ? mobileDevice?.[0] : { mobileInfo: {} })?.id);
  }, [props.getMobileDeviceResult.data]);

  const getMobileDevice = () => {
    const { data: mobileDevice, success } = props.getMobileDeviceResult || {};
    const mobile = success && isArray(mobileDevice) ? mobileDevice : [];
    return mobile;
  };

  const getPcTerminals = () => {
    const onlineTerminals = props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = props.getTerminalInfoResult.offlineTerminals || [];
    const terminals = onlineTerminals.concat(offlineTerminals);
    return terminals;
  };

  const renderMobileContent = () => {
    return (
      <MobileScreenShot
        currentMobileId={currentMobileId}
        showBindModalHandle={() => {
          setShowBindModal(true);
        }}
      />
    );
  };

  const renderPcContent = () => {
    return (
      <PcRemote
        showBindModalHandle={() => {
          setShowBindModal(true);
        }}
      />
    );
  };

  if (isLoading) {
    return null;
  }
  const mobileDevice = getMobileDevice();
  const pcTerminals = getPcTerminals();
  let client = 1;
  if (props.getProductStateResult) {
    client =
      props.getProductStateResult.allLimits.find((x: any) => x.type === 'TianxunLimit')
        ?.client || 1;
  }
  const hasPc = pcTerminals.length >= client;

  const tabItems = [];
  const mobile = mobileDevice.filter((ele) => ele.name !== '苹果手机');
  const type = getUrlQuery('type') || 'pc';

  if (mobile.length > 0 && type === 'mobile') {
    mobile.forEach((ele) => {
      tabItems.push({
        key: ele.id,
        title: <TabTitle icon={mobileIcon} text={ele.name} />,
        content: <div className={s.content}>{renderMobileContent()}</div>,
        colorMode: 'yellow',
      });
    });
  } else if (type === 'mobile') {
    tabItems.push({
      key: 'mobile',
      title: <TabTitle icon={mobileIcon} text="手机" />,
      content: <div className={s.content}>{renderMobileContent()}</div>,
      colorMode: 'yellow',
    });
  }

  if (type === 'pc') {
    tabItems.push({
      key: 'pc',
      title: <TabTitle icon={pcIcon} text="电脑" />,
      content: <div className={s.content}>{renderPcContent()}</div>,
      colorMode: 'blue',
    });
  }

  return (
    <>
      <div className={s.appLayoutBg}>
        {deviceType === 'pc' ? renderPcContent() : renderMobileContent()}
      </div>
      <BindFlowModal
        defaultType={deviceType}
        hasPc={hasPc}
        hasMobile={mobileDevice.length >= 2}
        visible={showBindModal}
        handleCancel={() => {
          setShowBindModal(false);
        }}
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getTerminalInfoResult: state.getTerminalInfoResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    getAllImagesAction: bindActionCreators(getAllImagesAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteAssistant);
