import moment from 'moment';
import React, { Component } from 'react';
import s from './MobileFooter.module.scss';

class MobileFooter extends Component {
  render() {
    return (
      <div className={s.footer}>
        <div>
          <a
            href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E5%8D%97%E4%BA%AC%E5%B7%9D%E9%99%80%E5%A4%A7%E5%8C%A0%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&code=ELMJx&type=xuke"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏B2-20190842 &nbsp;
          </a>
          <a
            href="http://www.beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏 ICP 备19039170号
          </a>
        </div>
        <div>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010502010385"
            target="_blank"
            rel="noopener noreferrer"
          >
            苏公网安备 32010502010385号
          </a>
        </div>
        <div>©2016-{moment().year()}南京川陀大匠信息技术有限公司版权所有</div>
      </div>
    );
  }
}

export default MobileFooter;
