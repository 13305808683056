import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './setTime.module.scss';
import { QsNormalModal } from '../../../../commonComponents/QsModal/QsModal';
import MultiTimeSelector from '../../../components/MultiTimeSelector/MultiTimeSelector';
import {
  getEffectiveTimeAction,
  resetUpdateEffectiveTimeAction,
  updateEffectiveTimeAction,
} from '../../../../redux/webControls';
import { ITimeData } from '../../../components/MultiTimeSelector/TimeItem';
import QsAlert from '../../../../commonComponents/QsAlert/QsAlert';

export interface ITimeUpdateData {
  starttime: string;
  endtime: string;
}

interface IProps {
  getEffectiveTimeAction: () => void;
  getEffectiveTimeResult: any;
  updateEffectiveTimeAction: (timeList: ITimeUpdateData[]) => void;
  updateEffectiveTimeResult: any;
  resetUpdateEffectiveTimeAction: () => void;
}

function SetTime(props: IProps) {
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState(
    (): {
      type: 'success' | 'error';
      message: string;
    } => {
      return {
        type: 'success',
        message: '设置成功。',
      };
    }
  );
  const [selectedTime, setSelectedTime] = useState<ITimeData[]>([]);
  const [defaultTime, setDefaultTime] = useState<ITimeData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await props.getEffectiveTimeAction();
    };

    fetchData();
  }, []);

  useEffect(() => {
    setTime();
  }, [props.getEffectiveTimeResult]);

  const setTime = () => {
    const { data } = props.getEffectiveTimeResult.data || [];
    const timeList = (data || []).map((time: any) => {
      return {
        id: time.id,
        begin: time.starttime,
        end: time.endtime,
      };
    });

    setSelectedTime([...timeList]);
    setDefaultTime([...timeList]);
  };

  useEffect(() => {
    const { success, errDesc } = props.updateEffectiveTimeResult;
    if (success) {
      setShowAlert(true);
      setAlertInfo({ type: 'success', message: '设置成功。' });
      setShowModal(false);
    } else if (!!errDesc) {
      setShowAlert(true);
      setAlertInfo({ type: 'error', message: '设置失败。' });
    }
  }, [props.updateEffectiveTimeResult]);

  const handleCommit = async () => {
    const timeList = selectedTime.map((item) => ({
      starttime: item.begin,
      endtime: item.end,
    }));
    await props.updateEffectiveTimeAction(timeList);
    await props.getEffectiveTimeAction();
  };

  return (
    <div>
      <div
        className={s.addBtn}
        onClick={() => {
          setShowModal(true);
        }}
      >
        + 添加生效时间段
      </div>
      <QsNormalModal
        visible={showModal}
        title="设置生效时间段"
        width={800}
        okBtnDisable={selectedTime.length === 0}
        onCancel={async () => {
          setShowModal(false);
          setTime();
        }}
        onOk={handleCommit}
      >
        <div className={s.modalContent}>
          <MultiTimeSelector
            desc="鼠标拖动“生效时间”格子即可添加/删除生效时间段。"
            cardColorDesc={['生效', '不生效']}
            colorType="green"
            defaultTime={defaultTime}
            selectedTime={selectedTime}
            timeCardHeight={40}
            handleTimeChange={(value: ITimeData[]) => {
              setSelectedTime(value);
            }}
          />
        </div>
      </QsNormalModal>
      <QsAlert
        visible={showAlert}
        type={alertInfo.type}
        message={alertInfo.message}
        position="fixed"
        onClose={() => {
          props.resetUpdateEffectiveTimeAction();
          setShowAlert(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getEffectiveTimeResult: state.getEffectiveTimeResult.data,
    updateEffectiveTimeResult: state.updateEffectiveTimeResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getEffectiveTimeAction: bindActionCreators(getEffectiveTimeAction, dispatch),
    updateEffectiveTimeAction: bindActionCreators(updateEffectiveTimeAction, dispatch),
    resetUpdateEffectiveTimeAction: bindActionCreators(
      resetUpdateEffectiveTimeAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetTime);
