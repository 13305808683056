import moment from 'moment';

export function shouldShowMobile(mobileDevices: Array<any>) {
  const hasMobile = mobileDevices.length > 0;

  if (!hasMobile) {
    return false;
  }

  const iosMobile = mobileDevices.filter((ele) => ele.name === '苹果手机');
  const hasIosMobile =
    (mobileDevices.length === 2 && iosMobile.length === 2) ||
    (mobileDevices.length === 1 && iosMobile.length === 1);

  if (hasIosMobile) {
    return false;
  }
  return true;
}

export function isZGYD() {
  return window.location.hostname.includes('zgyd');
}

export function displayPhoneNumber(phone: string) {
  if (!phone) {
    return '';
  }
  const start = phone.slice(0, 3);
  const end = phone.slice(7, 11);
  return `${start}****${end}`;
}

export function isActiveAnniversary() {
  const endTime = '2023-09-01';
  const current = new Date();
  const isBefore = moment(current).isBefore(endTime);
  return isBefore;
}
