import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import s from './CompleteInfoModal.module.scss';
import {
  getOrgInfoAction,
  checkLoginStateAction,
  completeSummaryAction,
  getUserInfoAction,
  setGiftModalAction,
} from '../../../redux/user';

import bgImg from './images/bg.png';
import { getProductStateAction } from '../../../redux/order';
import { getAllConfigAction } from '../../../redux/config';
import { activateProductAction } from '../../../redux/product';
import { toggleWebACLAction } from '../../../redux/webControls';
import { QsModalBaisc } from '../../../commonComponents/QsModal/QsModal';

interface IProps {
  checkLoginStateAction: () => void;
  checkLoginStateResult: any;
  getOrgInfoAction: () => void;
  getOrgInfoResult: any;
  getProductStateAction: () => void;
  getProductStateResult: any;
  activateProductAction: () => void;
  activateProductResult: any;
  getAllConfigAction: () => void;
  toggleWebACLAction: () => void;
  getUserInfoAction: (userId: string) => void;
  getUserInfoResult: any;
  setGiftModalAction: (userId: string) => void;
}

function CompleteInfoModal(props: IProps) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchInitData() {
      await props.checkLoginStateAction();
    }

    fetchInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      await Promise.all([props.getOrgInfoAction(), props.getProductStateAction()]);
    }
    if (
      props.checkLoginStateResult !== undefined &&
      props.checkLoginStateResult.hasOwnProperty('isLogin') &&
      props.checkLoginStateResult.isLogin
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkLoginStateResult]);

  useEffect(() => {
    async function fetchData() {
      const { owner_id: userId } = props.getOrgInfoResult;
      await props.getUserInfoAction(userId);
    }

    if (
      props.getOrgInfoResult !== undefined &&
      props.getOrgInfoResult.hasOwnProperty('owner_id')
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getOrgInfoResult]);

  useEffect(() => {
    const giftModal = props.getUserInfoResult?.gift_modal;
    if (giftModal !== undefined && giftModal !== 1) {
      setShowModal(true);
    }
  }, [props.getUserInfoResult]);

  const fetchData = async () => {
    const { owner_id: userId } = props.getOrgInfoResult;
    await Promise.all([
      props.getProductStateAction(),
      props.getAllConfigAction(),
      props.toggleWebACLAction(),
      props.setGiftModalAction(userId),
    ]);
    await props.getUserInfoAction(userId);
  };

  const getProductInfo = async () => {
    if (props.getProductStateResult && !props.getProductStateResult.isActivate) {
      await props.activateProductAction();
      if (props.activateProductResult.success) {
        await fetchData();
      }
    } else {
      await fetchData();
    }
  };

  return (
    <QsModalBaisc
      closable={true}
      visible={showModal}
      onCancel={async () => {
        await getProductInfo();
        setShowModal(false);
      }}
    >
      <img className={s.modalContent} src={bgImg} alt="" />
    </QsModalBaisc>
  );
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    completeSummaryResult: state.completeSummaryResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    activateProductResult: state.activateProductResult.data,
    getUserInfoResult: state.getUserInfoResult.data.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    getUserInfoAction: bindActionCreators(getUserInfoAction, dispatch),
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    completeSummaryAction: bindActionCreators(completeSummaryAction, dispatch),
    activateProductAction: bindActionCreators(activateProductAction, dispatch),
    getAllConfigAction: bindActionCreators(getAllConfigAction, dispatch),
    toggleWebACLAction: bindActionCreators(toggleWebACLAction, dispatch),
    setGiftModalAction: bindActionCreators(setGiftModalAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteInfoModal);
