import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhoneCaptchaAction } from '../../../redux/getPhoneCaptcha';
import { regPattern } from '../../../common';
import { QsTextButton } from '../../../commonComponents/QsButton/QsButton';

interface IStates {
  btnText: string;
  countdown: number;
  disableBtn: boolean;
  intervalId: any;
}

interface IProps {
  getPhoneCaptcha: (phone: string) => void;
  phone: string;
  isDisable?: boolean;
  shape?: 'round' | 'square' | undefined;
}

class GetCaptchaBtn extends Component<IProps, IStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      btnText: '获取验证码',
      countdown: 60,
      disableBtn: false,
      intervalId: null,
    };
  }

  getCaptcha = async () => {
    if (!regPattern.PHONE.test(this.props.phone) || this.props.isDisable) {
      return;
    }
    await this.props.getPhoneCaptcha(this.props.phone);
    const intervalId = setInterval(this.timer, 1000);
    this.setState({
      intervalId,
      disableBtn: true,
    });
  };

  timer = () => {
    this.setState({
      countdown: this.state.countdown - 1,
      btnText: `${this.state.countdown}s后重新获取`,
    });
    if (this.state.countdown < 0) {
      clearInterval(this.state.intervalId);
      this.setState({
        btnText: '获取验证码',
        countdown: 60,
        disableBtn: false,
      });
    }
  };

  render() {
    return (
      <QsTextButton
        type={window.location.pathname === '/sem' ? 'primary-sem' : 'primary'}
        disabled={this.state.disableBtn}
        handleClick={async (e) => {
          if (this.state.disableBtn) return;
          e.preventDefault();
          await this.getCaptcha();
        }}
      >
        {this.state.btnText}
      </QsTextButton>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPhoneCaptcha: bindActionCreators(getPhoneCaptchaAction, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(GetCaptchaBtn);
