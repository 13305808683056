import React, { Component } from 'react';
import { PcCard, PcEmptyCard } from '../TabContent/TabContent';
import s from './PcRemote.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTerminalInfoAction } from '../../../../redux/terminal';

interface IProps {
  getTerminalInfoAction: () => void;
  getTerminalInfoResult: any;
  showBindModalHandle: () => void;
}

interface IState {
  pcType: 'pc' | 'laptop' | 'unknown';
  checkedTerminal: any;
}

class PcRemote extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checkedTerminal: {},
      pcType: 'pc',
    };
  }

  async componentDidMount() {
    await this.props.getTerminalInfoAction();
    const pcTerminals = this.getPcTerminals();
    this.setState({
      checkedTerminal: pcTerminals?.[0]?.online ? pcTerminals[0] : [],
      pcType: pcTerminals?.[0]?.pcType,
    });
  }

  getPcTerminals = () => {
    const onlineTerminals = this.props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = this.props.getTerminalInfoResult.offlineTerminals || [];
    const terminals = onlineTerminals.concat(offlineTerminals);
    return terminals;
  };

  findTernimalById = (id: string) => {
    const pcTerminals = this.getPcTerminals();
    for (const item of pcTerminals) {
      if (item.terminalId === id) {
        return item;
      }
    }
    return null;
  };

  startRemoteHandle = () => {
    const { ip, terminalId, pcType, clientName, groupName, city } =
      this.state.checkedTerminal;
    const remoteDesktopData = {
      ip: ip,
      terminalId: terminalId,
      pcType: pcType || '未知设备',
      clientName: clientName,
      department: groupName,
      location: city || '未知',
    };

    const queryStr = encodeURIComponent(JSON.stringify(remoteDesktopData));
    const remoteDesktopURL = `/pcRemoteConnect?config=${queryStr}`;
    window.open(remoteDesktopURL, '_blank');
  };

  render() {
    if (this.getPcTerminals().length <= 0) {
      return (
        <div className={s.root}>
          <PcEmptyCard
            clickHandle={this.props.showBindModalHandle}
            functionName="远程守护"
          />
        </div>
      );
    } else {
      const pcTerminals = this.getPcTerminals();
      return (
        <div className={s.root}>
          <PcCard clickHandle={this.startRemoteHandle} pcTerminals={pcTerminals} />
        </div>
      );
    }
  }
}

function mapStateToProps(state: any) {
  return {
    getTerminalInfoResult: state.getTerminalInfoResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PcRemote);
