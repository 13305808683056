import React, { Component } from 'react';
import { Button } from 'antd';
import { MdClose, MdErrorOutline } from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Base64 } from 'js-base64';

import s from './PurchaseModal.module.scss';
import userLogo from './images/userLogo.png';
import waiting from './images/waiting.png';
import wechatIcon from './images/wechatIcon.svg';
import alipayIcon from './images/alipayIcon.svg';
import paySuccessIcon from './images/paySuccessIcon.png';
import payFailedIcon from './images/payFailedIcon.svg';
import copyIcon from '../../../commonComponents/QsIcons/copyIcon.svg';
import PricePackageRadio from '../../../home/components/PricePackageRadio/PricePackageRadio';
import BasicTabs from '../../../commonComponents/QsTabs/BasicTabs';
import { IPriceInfo } from '../../../home/pages/Price/Price';
import {
  fetchOrderAction,
  getOnlinePaymentAction,
  getPricesInfoAction,
  getProductStateAction,
  refreshOrderAction,
  resetOnlinePaymentAction,
  updatePayMethodAction,
} from '../../../redux/order';
import { getOrgInfoAction, getUserInfoAction } from '../../../redux/user';
import {
  activateProductAction,
  createActiveCodeAction,
  queryActiveCodeAction,
  resetGetActiveCodeAction,
} from '../../../redux/product';
import { displayPhoneNumber, isZGYD } from '../../../utils/lib';
import copy from 'copy-to-clipboard';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';
import { QsModalBaisc } from '../../../commonComponents/QsModal/QsModal';

interface IProps {
  getOnlinePaymentAction: (
    paymentId: string,
    paymentMethod: 'CMB' | 'weixin' | 'ALIAPP' | 'ALIWEB'
  ) => void;
  fetchOrderResult: any;
  getOnlinePaymentResult: any;
  getOrgInfoAction: () => void;
  getUserInfoAction: (userId: string) => void;
  getOrgInfoResult: any;
  getUserInfoResult: any;
  getProductStateAction: () => void;
  getProductStateResult: any;
  resetOnlinePaymentAction: () => void;
  activateProductAction: () => void;
  fetchOrderAction: (subscriptionId: string) => void;
  updatePayMethodAction: (orderId: string, method: 'WECHAT_PAY' | 'ALI_PAY') => void;
  refreshOrderAction: (orderId: string) => void;
  getPricesInfoAction: () => void;
  getPricesInfoResult: IPriceInfo[];
  name: '年付会员' | '季付会员' | '月付会员' | '免费体验' | '团购套餐';
  btnText?: string;
  btnSize?: 'large' | 'middle' | 'small';
  customBtn?: React.ReactElement;
  // buttonType?: 'primary' | 'ghost';
  buttonType?: 'primary';
  createActiveCodeAction: (orderId: string) => void;
  getActiveCodeByOrderIdAction: (orderId: string) => void;
  queryActiveCodeResult: any;
  resetGetActiveCodeAction: () => void;
}

interface IStates {
  visible: boolean;
  timerId: any;
  currentPriceInfo: IPriceInfo;
  payMethod: 'WECHAT_PAY' | 'ALI_PAY';
  resetPage: boolean;
  showAlert: boolean;
}

class PurchaseModal extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: false,
      timerId: null,
      currentPriceInfo: {
        id: 'yearly',
        name: '年付会员',
        months: 12,
        price: 188,
      },
      // payMethod: 'WECHAT_PAY',
      payMethod: 'ALI_PAY',
      resetPage: false,
      showAlert: false,
    };
  }

  async componentDidMount() {
    await this.props.getOrgInfoAction();
    const org = this.props.getOrgInfoResult || {};
    await this.props.getUserInfoAction(org.owner_id);
    await this.props.getPricesInfoAction();
    const yearPrice = (this.props.getPricesInfoResult || []).filter(
      (item) => item.name === '年付会员'
    )[0];

    this.setState({
      currentPriceInfo: {
        ...yearPrice,
        originPrice: '360',
      },
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  loopPayment = async () => {
    const orderId =
      this.props.fetchOrderResult &&
      this.props.fetchOrderResult.order &&
      this.props.fetchOrderResult.order.id;
    // await this.props.getOnlinePaymentAction(orderId, 'CMB');
    // await this.props.getOnlinePaymentAction(orderId, 'ALIAPP');
    await this.props.getOnlinePaymentAction(orderId, 'ALIWEB');
    const orderPaidState = this.props.getOnlinePaymentResult || {};
    if (orderPaidState.pay && orderPaidState.success) {
      const currentPriceInfo: any = this.state.currentPriceInfo || {};
      const isGroupPurchase = currentPriceInfo.name === '团购套餐';
      clearInterval(this.state.timerId);
      await this.props.getProductStateAction();
      if (isGroupPurchase) {
        const order =
          (this.props.fetchOrderResult && this.props.fetchOrderResult.order) || {};
        await this.props.createActiveCodeAction(order.id);
        await this.props.getActiveCodeByOrderIdAction(order.id);
      }
    }
  };

  closeModal = async () => {
    this.setState({
      visible: false,
    });
    clearInterval(this.state.timerId);
    await this.props.resetOnlinePaymentAction();
    await this.props.resetGetActiveCodeAction();
  };

  showModal = async () => {
    const currentPriceInfo = (this.props.getPricesInfoResult || []).filter(
      (item) => item.name === this.props.name
    )[0];
    this.setState(
      // { currentPriceInfo: { ...currentPriceInfo }, payMethod: 'WECHAT_PAY' },
      { currentPriceInfo: { ...currentPriceInfo }, payMethod: 'ALI_PAY' },
      async () => {
        await this.fetchOrder();
      }
    );

    const timerId = setInterval(this.loopPayment, 3000);
    this.setState({ timerId, visible: true });
    setTimeout(this.closeModal, 1000 * 60 * 5);
  };

  fetchOrder = async () => {
    if (
      this.props.getProductStateResult &&
      !this.props.getProductStateResult.isActivate
    ) {
      await this.props.activateProductAction();
    }

    const currentPriceInfo = this.state.currentPriceInfo || {};
    const res: any = await this.props.fetchOrderAction(currentPriceInfo.id);
    console.log('res', res);
    const alipayScript = Base64.decode(res.response.data.payment.qr_url);
    console.log('alipay_script', alipayScript);
    await this.loadAlipay(alipayScript);

    const orderId =
      this.props.fetchOrderResult &&
      this.props.fetchOrderResult.order &&
      this.props.fetchOrderResult.order.id;

    await this.props.updatePayMethodAction(orderId, this.state.payMethod);
    const orderExpireTime = moment.unix(this.props.fetchOrderResult.order.expire_time);
    const currentTime = moment();
    const diff = orderExpireTime.diff(currentTime, 'minutes');
    if (diff <= 5) {
      await this.props.refreshOrderAction(orderId);
      await this.props.fetchOrderAction(currentPriceInfo.id);
    }
  };
  loadAlipay = async (html: string) => {
    console.log(html);
    html = html.replace(
      '<form',
      '<form style="display:none" rel="noreferrer" target="_blank" '
    );
    await new Promise((reslove) => {
      setTimeout(() => {
        reslove(true);
      }, 1300);
    });
    //跳转支付页面
    const div: HTMLDivElement = document.createElement('div');
    div.innerHTML = html;
    document.body.insertBefore(div, document.body.firstChild);
    document.forms[0].submit();
    div.parentNode?.removeChild(div);
    // document.body.innerHTML = html;
    // document.forms[0].submit();
    // const iframe: HTMLIFrameElement = document.createElement('iframe');
    // iframe.srcdoc = html;
    // iframe.style.display = 'none';
    // document.body.insertBefore(iframe, document.body.firstChild);
  };

  modalHeader = () => {
    const userInfo = this.props.getUserInfoResult || {};
    const orderPaidState = this.props.getOnlinePaymentResult || {};
    return (orderPaidState.pay && orderPaidState.success) ||
      (orderPaidState.pay && !orderPaidState.success) ? (
      <div className={s.modalHeader}>
        <div className={s.userInfo}>
          <img src={userLogo} alt="userLogo" />
          <div>
            <span>购买账号：</span>
            {`用户${displayPhoneNumber(userInfo.phone_number)}` || '--'}
          </div>
        </div>
        <div className={s.closeIcon} onClick={this.closeModal}>
          <MdClose />
        </div>
      </div>
    ) : (
      <div className={s.waitingUserConfirmHeader}>
        <div className={s.closeIcon} onClick={this.closeModal}>
          <MdClose />
        </div>
      </div>
    );
  };

  tabContent = () => {
    const currentPriceInfo: any = this.state.currentPriceInfo || {};
    const paymentQrCode =
      this.props.fetchOrderResult &&
      this.props.fetchOrderResult.payment &&
      this.props.fetchOrderResult.payment.qr_url;
    return (
      <div className={s.tabContent}>
        <div className={s.qrcodeContainer}>
          <div className={s.qrcode}>
            {paymentQrCode && <img src={paymentQrCode} alt="paymentQrCode" />}
          </div>
          <div>
            <div>
              共计： <span className={s.price}>¥{currentPriceInfo.price}</span>
            </div>
            <div className={s.tips}>
              <MdErrorOutline />
              请在5分钟内完成付款!
            </div>
          </div>
        </div>
        <div className={s.protocol}>
          购买即代表同意
          <a href="/agreement" target="_blank" rel="noopener noreferrer">
            {!!isZGYD() ? '《用户服务条款》' : '《青松守护用户服务条款》'}
          </a>
        </div>
      </div>
    );
  };

  payFailed = () => {
    return (
      <div className={s.modalContent}>
        <div className={s.payFailedTitle}>
          <img src={payFailedIcon} alt="payFailedIcon" />
          <div>抱歉，支付失败，请重新支付！</div>
        </div>
        <div className={s.linkBtn}>
          <Button
            size="large"
            onClick={() => {
              this.setState({ resetPage: true });
            }}
          >
            重新下单
          </Button>
        </div>
      </div>
    );
  };

  copyText = (text: string) => {
    copy(text);
    this.setState({
      showAlert: true,
    });
  };

  paySuccess = () => {
    const currentPriceInfo: any = this.state.currentPriceInfo || {};
    const order =
      (this.props.fetchOrderResult && this.props.fetchOrderResult.order) || {};

    const availableLimitArr = this.props.getProductStateResult
      ? this.props.getProductStateResult.availableLimit
      : [];
    const limit = availableLimitArr.length > 0 ? availableLimitArr[0] : null;
    const expiredTime = limit
      ? moment.unix(limit.mode.end).format('YYYY年MM月DD日')
      : '--';
    const createTime = moment(order.created_at).format('YYYY年MM月DD日 HH:mm:ss');

    const isGroupPurchase = currentPriceInfo.name === '团购套餐';
    const activeCodes = this.props.queryActiveCodeResult.data?.records || [];
    return (
      <div className={s.modalContent}>
        <div className={s.paySuccessTitle}>
          <img src={paySuccessIcon} alt="paySuccessIcon" />
          <div>支付成功</div>
        </div>
        <div className={s.paySuccessInfo}>
          {isGroupPurchase && (
            <div className={s.activeCodeContainer}>
              <div>会员激活码</div>
              {activeCodes.map((item: any, index: number) => (
                <div key={index} className={s.activeCode}>
                  {item.activeCode}
                  <img
                    className={s.copyText}
                    src={copyIcon}
                    alt=""
                    onClick={() => this.copyText(item.activeCode)}
                  />
                </div>
              ))}
              <div className={s.codeTips}>（激活码可兑换 1 年会员）</div>
              <div className={s.codeDesc}>
                使用方法：将激活码粘贴到“账号信息-会员信息-会员激活码”处兑换。
              </div>
            </div>
          )}
          <div>
            订单编号：<span>{order.id}</span>
          </div>
          <div>
            下单时间：<span>{createTime}</span>
          </div>
          {!isGroupPurchase && (
            <div>
              到期时间：<span>{expiredTime}</span>
            </div>
          )}
          <div>
            会员类型：
            <span>{isGroupPurchase ? '团购 - 年付会员' : currentPriceInfo.name}</span>
          </div>
          <div>
            优惠信息：<span>--</span>
          </div>
          <div>
            实付金额：<span>¥{order.amount || 0}</span>
          </div>
          <div>
            交易状态：<span>{isGroupPurchase ? '交易成功-待使用' : '交易成功'}</span>
          </div>
        </div>
        <div className={s.linkBtn}>
          <a href="/orderInfo">
            <Button size="large" onClick={this.closeModal}>
              查看订单
            </Button>
          </a>
        </div>
      </div>
    );
  };

  mapPriceInfo = () => {
    const priceInfo = this.props.getPricesInfoResult || [];

    return priceInfo
      .map((info: IPriceInfo) => {
        switch (info.name) {
          case '团购套餐':
            return {
              ...info,
              originPrice: '188*3',
              sortOrder: 1,
            };
          case '年付会员':
            return {
              ...info,
              originPrice: '360',
              sortOrder: 2,
            };
          case '季付会员':
            return {
              ...info,
              originPrice: '90',
              sortOrder: 3,
            };
          case '月付会员':
            return {
              ...info,
              originPrice: null,
              sortOrder: 4,
            };
          default:
            return {
              ...info,
            };
        }
      })
      .sort((a: any, b: any) => a.sortOrder - b.sortOrder);
  };

  payOrder = () => {
    const priceInfo = this.mapPriceInfo();
    const currentPriceInfo = this.state.currentPriceInfo || {};
    const items = [
      {
        key: 'WECHAT_PAY',
        title: (
          <span className={s.tabTitle}>
            <img src={wechatIcon} alt="" />
            微信支付
          </span>
        ),
        content: this.tabContent(),
      },
      {
        key: 'ALI_PAY',
        title: (
          <span className={s.tabTitle}>
            <img src={alipayIcon} alt="" />
            支付宝
          </span>
        ),
        content: this.tabContent(),
      },
    ];
    const orderId =
      this.props.fetchOrderResult &&
      this.props.fetchOrderResult.order &&
      this.props.fetchOrderResult.order.id;

    return (
      <div>
        <div className={s.title}>套餐选择</div>
        <PricePackageRadio
          pricesInfo={priceInfo}
          defaultChecked={currentPriceInfo.id}
          handleClick={async (item) => {
            this.setState({ currentPriceInfo: item }, async () => {
              await this.fetchOrder();
            });
          }}
        />
        <div className={s.payment}>
          <BasicTabs
            type="rectangleWithoutLine"
            position="above"
            items={items}
            size="large"
            onChange={async (key: any) => {
              this.setState({ payMethod: key });
              await this.props.updatePayMethodAction(orderId, key);
            }}
          />
        </div>
      </div>
    );
  };

  mapModalContent = () => {
    const orderPaidState = this.props.getOnlinePaymentResult || {};
    const currentPriceInfo: any = this.state.currentPriceInfo || {};
    const isGroupPurchase = currentPriceInfo.name === '团购套餐';
    if (isGroupPurchase && this.props.queryActiveCodeResult.success) {
      return this.paySuccess();
    } else if (!isGroupPurchase && orderPaidState.pay && orderPaidState.success) {
      return this.paySuccess();
    } else if (orderPaidState.pay && !orderPaidState.success) {
      return this.payFailed();
    } else {
      // return this.payOrder();
      return this.waitingUserConfirm();
    }
  };
  // 新的UI
  waitingUserConfirm = () => {
    return (
      <div className={s.waitingUserConfirmContent}>
        <img className={s.waitingIcon} src={waiting} alt="等待确认" />
        <div className={s.waitingTitle}>等待支付中</div>
        <div className={s.waitingBodyText}>
          请在新打开的支付宝页面完成支付，支付完成前先不要关闭该窗口。
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.customBtn ? (
          <div onClick={this.showModal}>{this.props.customBtn}</div>
        ) : (
          <Button
            type={this.props.buttonType || 'primary'}
            size={this.props.btnSize || 'large'}
            onClick={this.showModal}
            className={this.props.btnSize === 'small' ? s.smallBtn : ''}
          >
            {this.props.btnText || '立即购买'}
          </Button>
        )}
        {/* <QsModal closable={false} visible={this.state.visible}>
          {this.modalHeader()}
          <div className={s.modalContent}>
            {this.state.resetPage ? this.payOrder() : this.mapModalContent()}
            {this.mapModalContent()}
          </div>
        </QsModal> */}
        <QsModalBaisc closable={false} visible={this.state.visible}>
          <div>
            {this.modalHeader()}
            {/* <div className={s.modalContent}>
              {this.state.resetPage ? this.payOrder() : this.mapModalContent()}
            </div> */}
            {this.mapModalContent()}
          </div>
        </QsModalBaisc>
        <QsAlert
          visible={this.state.showAlert}
          type="success"
          message="复制成功"
          position="fixed"
          onClose={() => {
            this.setState({ showAlert: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    fetchOrderResult: state.fetchOrderResult.data.data,
    getOnlinePaymentResult: state.getOnlinePaymentResult.data.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getUserInfoResult: state.getUserInfoResult.data.user,
    getProductStateResult: state.getProductStateResult.data.data,
    getPricesInfoResult: state.getPricesInfoResult.data.data,
    queryActiveCodeResult: state.queryActiveCodeResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOnlinePaymentAction: bindActionCreators(getOnlinePaymentAction, dispatch),
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    getUserInfoAction: bindActionCreators(getUserInfoAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    resetOnlinePaymentAction: bindActionCreators(resetOnlinePaymentAction, dispatch),
    activateProductAction: bindActionCreators(activateProductAction, dispatch),
    fetchOrderAction: bindActionCreators(fetchOrderAction, dispatch),
    updatePayMethodAction: bindActionCreators(updatePayMethodAction, dispatch),
    refreshOrderAction: bindActionCreators(refreshOrderAction, dispatch),
    getPricesInfoAction: bindActionCreators(getPricesInfoAction, dispatch),
    createActiveCodeAction: bindActionCreators(createActiveCodeAction, dispatch),
    resetGetActiveCodeAction: bindActionCreators(resetGetActiveCodeAction, dispatch),
    getActiveCodeByOrderIdAction: bindActionCreators(queryActiveCodeAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseModal);
