import cloud from './websiteIcon/cloud.svg';
import email from './websiteIcon/email.svg';
import entertainment from './websiteIcon/entertainment.svg';
import finance from './websiteIcon/finance.svg';
import forum from './websiteIcon/forum.svg';
import game from './websiteIcon/game.svg';
import life from './websiteIcon/life.svg';
import music from './websiteIcon/music.svg';
import news from './websiteIcon/news.svg';
import novel from './websiteIcon/novel.svg';
import payment from './websiteIcon/payment.svg';
import portalwebsite from './websiteIcon/portalwebsite.svg';
import shopping from './websiteIcon/shopping.svg';
import social from './websiteIcon/social.svg';
import software from './websiteIcon/software.svg';
import sports from './websiteIcon/sports.svg';
import video from './websiteIcon/video.svg';
import custom from './websiteIcon/custom.svg';
import customblack from './websiteIcon/customblack.svg';
import comic from './websiteIcon/comic.svg';

const websiteIcons: any = {
  cloud,
  email,
  entertainment,
  finance,
  forum,
  game,
  life,
  music,
  news,
  novel,
  payment,
  portalwebsite,
  shopping,
  social,
  software,
  sports,
  video,
  custom,
  customblack,
  comic,
};

export default websiteIcons;
