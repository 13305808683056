import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchGetConfig,
  fetchPostConfig,
  fetchPutConfig,
  fetchDelConfig,
} from '../common';
import {
  GET_ONLINE_COURSE_LIST,
  SUBMIT_WORK_ORDER,
  SET_ONLINE_COURSE,
  DELETE_ONLINE_COURSE,
  CLEAR_SUBMIT_WORK_ORDER,
  GET_ONLINE_COURSE_ALL,
  CLEAR_SET_ONLINE_COURSE,
} from './constants';
import { getOrgInfo } from './user';

function getOnlineCourseList(courseName) {
  return fetch(
    `/api/ibg/qs/publicWhiteList?pageNum=-1&onlineClassName=${courseName}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getOnlineCourseListAction = createActionAsync(
  GET_ONLINE_COURSE_LIST,
  getOnlineCourseList,
  defaultActionOption
);

export const getOnlineCourseListReducer = createReducerAsync(getOnlineCourseListAction);

function getOnlineCourseAll() {
  return fetch(`/api/ibg/qs/publicWhiteList?pageNum=-1`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getOnlineCourseAllAction = createActionAsync(
  GET_ONLINE_COURSE_ALL,
  getOnlineCourseAll,
  defaultActionOption
);

export const getOnlineCourseAllReducer = createReducerAsync(getOnlineCourseAllAction);

function submitWorkOrder(phone, websiteName, websiteDomain, websiteType) {
  const data = {
    phone,
    onlineClassName: websiteName,
    onlineClassAddress: websiteDomain,
    type: websiteType,
  };
  return fetch(`/api/ibg/qs/workOrder`, fetchPostConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const submitWorkOrderAction = createActionAsync(
  SUBMIT_WORK_ORDER,
  submitWorkOrder,
  defaultActionOption
);

function clearSubmitWorkOrder() {
  return Promise.resolve({ success: '', data: { message: '' } });
}

export const clearSubmitWorkOrderAction = createActionAsync(
  CLEAR_SUBMIT_WORK_ORDER,
  clearSubmitWorkOrder,
  defaultActionOption
);

export const submitWorkOrderReducer = createReducerAsync([
  submitWorkOrderAction,
  clearSubmitWorkOrderAction,
]);

async function setOnlineCourse(courseIds) {
  const orgInfo = await getOrgInfo();
  const data = {
    orgId: orgInfo.org.org_id,
    whiteList: courseIds.length > 0 ? courseIds.join(';') + ';' : '',
  };

  return fetch(`/api/ibg/qs/publicWhiteList/updateOrg`, fetchPutConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const setOnlineCourseAction = createActionAsync(
  SET_ONLINE_COURSE,
  setOnlineCourse,
  defaultActionOption
);

function clearSetOnlineCourse() {
  return Promise.resolve({ success: '', data: { message: '' } });
}

export const clearSetOnlineCourseAction = createActionAsync(
  CLEAR_SET_ONLINE_COURSE,
  clearSetOnlineCourse,
  defaultActionOption
);

export const setOnlineCourseReducer = createReducerAsync([
  setOnlineCourseAction,
  clearSetOnlineCourseAction,
]);

async function deleteOnlineCourse(courseId) {
  const orgInfo = await getOrgInfo();
  const data = {
    orgId: orgInfo.org.org_id,
    whiteListId: courseId,
  };

  return fetch(`/api/ibg/qs/publicWhiteList/deleteOrg`, fetchDelConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const deleteOnlineCourseAction = createActionAsync(
  DELETE_ONLINE_COURSE,
  deleteOnlineCourse,
  defaultActionOption
);

export const deleteOnlineCourseReducer = createReducerAsync(deleteOnlineCourseAction);
