import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig, fetchPostConfig } from '../common';
import {
  REFRESH_ORDER,
  GET_ONLINE_PAYMENT,
  GET_PAID_ORDERS,
  GET_PRODUCT_STATE,
  GET_PRICES_INFO,
  FETCH_ORDER,
  UPDATE_PAY_METHOD,
  RESET_ONLINE_PAYMENT,
} from './constants';
import { queryActiveCode } from './product';

function refreshOrder(orderId) {
  const data = {
    orderId,
  };
  return fetch(
    '/services/tianxun/apiv2/private/organization/refreshOrder',
    fetchPostConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const refreshOrderAction = createActionAsync(
  REFRESH_ORDER,
  refreshOrder,
  defaultActionOption
);

export const refreshOrderReducer = createReducerAsync(refreshOrderAction);

function getOnlinePayment(paymentId, paymentMethod) {
  return fetch(
    `/services/tianxun/apiv2/private/organization/onlinePayment/${paymentId}?paymentMethod=${paymentMethod}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getOnlinePaymentAction = createActionAsync(
  GET_ONLINE_PAYMENT,
  getOnlinePayment,
  defaultActionOption
);

function resetOnlinePayment() {
  return Promise.resolve({});
}

export const resetOnlinePaymentAction = createActionAsync(
  RESET_ONLINE_PAYMENT,
  resetOnlinePayment,
  defaultActionOption
);

export const getOnlinePaymentReducer = createReducerAsync([
  getOnlinePaymentAction,
  resetOnlinePaymentAction,
]);

function getPaidOrders(priceInfoArr) {
  return fetch(
    '/services/tianxun/apiv2/private/organization/paidOrders',
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      const orders = data.data.sort((a, b) => b.id - a.id);
      const mapedOrders = await Promise.all(
        orders.map(async (item) => {
          const currentPriceInfo =
            priceInfoArr.filter((info) => info.id === item.order.subscription_id)[0] ||
            {};

          if (currentPriceInfo.name === '团购套餐') {
            const codeInfo = await queryActiveCode(item.order.id);
            item.activeCode = codeInfo.data.records;
          } else {
            item.activeCode = [];
          }
          item.type =
            currentPriceInfo.name === '团购套餐'
              ? '团购-年付会员'
              : currentPriceInfo.name;
          return item;
        })
      );
      const result = {
        success: data.success,
        data: mapedOrders,
      };
      return result;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getPaidOrdersAction = createActionAsync(
  GET_PAID_ORDERS,
  getPaidOrders,
  defaultActionOption
);

export const getPaidOrdersReducer = createReducerAsync(getPaidOrdersAction);

function getProductState() {
  return fetch(
    '/services/tianxun/apiv2/private/organization/productState',
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      const product = data.data.filter((item) => item.name === 'tianxun')[0];
      const limit = product ? product.limits.filter((limit) => limit.available) : [];
      const result = {
        success: data.success,
        data: {
          isActivate: !!product && product.state,
          availableLimit: limit,
          allLimits: product ? product.limits : [],
        },
      };
      return result;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getProductStateAction = createActionAsync(
  GET_PRODUCT_STATE,
  getProductState,
  defaultActionOption
);

export const getProductStateReducer = createReducerAsync(getProductStateAction);

function getPricesInfo() {
  return fetch(
    '/services/tianxun/apiv2/private/organization/pricesInfo',
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getPricesInfoAction = createActionAsync(
  GET_PRICES_INFO,
  getPricesInfo,
  defaultActionOption
);

export const getPricesInfoReducer = createReducerAsync(getPricesInfoAction);

function fetchOrder(subscriptionId) {
  return fetch(
    '/services/tianxun/apiv2/private/organization/fetchOrder',
    fetchPostConfig({ subscriptionId, way2pay: 'ALIWEB' })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const fetchOrderAction = createActionAsync(
  FETCH_ORDER,
  fetchOrder,
  defaultActionOption
);

export const fetchOrderReducer = createReducerAsync(fetchOrderAction);

function updatePayMethod(orderId, method) {
  return fetch(
    '/services/tianxun/apiv2/private/organization/updatePayMethod',
    fetchPostConfig({ orderId, method })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updatePayMethodAction = createActionAsync(
  UPDATE_PAY_METHOD,
  updatePayMethod,
  defaultActionOption
);

export const updatePayMethodReducer = createReducerAsync(updatePayMethodAction);
