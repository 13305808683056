import React, { Component } from 'react';

import s from './About.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MobileHeader from '../../mobile/components/MobileHeader/MobileHeader';
import MobileFooter from '../../mobile/components/MobileFooter/MobileFooter';
import { isMobile } from '../../../common';
import CompleteInfoModal from '../../components/CompleteInfoModal/CompleteInfoModal';
import { isZGYD } from '../../../utils/lib';

class About extends Component {
  componentDidMount() {
    document.title = `关于我们${!!isZGYD() ? '' : ' - 青松守护'}`;
  }

  render() {
    return (
      <div>
        {isMobile() ? <MobileHeader /> : <Header />}
        <div className={isMobile() ? s.mobileContainer : s.container}>
          <h1 className={`${s.title} ${isMobile() ? s.mobileTitle : ''}`}>关于我们</h1>
          <div className={isMobile() ? s.mobileContent : s.content}>
            <div>
              「青松守护」是国内知名信息安全领军企业--启明星辰信息技术集团股份有限公司（股票代码：002439）旗下品牌，由启明星辰全资子公司南京川陀大匠信息技术有限公司全权运营和维护。
            </div>
            <div>
              「青松守护」以青少年关爱为核心，以亲子间相互理解、共同进步为切入点，在妥善解决青少年上网行为管理的同时，帮助家长与孩子建立起友好的亲子关系，给予孩子充分尊重和适度自由。同时，消除家长因不了解孩子上网情况所带来的恐惧感，减少因强行管控给孩子带来的潜在伤害，最终帮助孩子养成健康的上网习惯，保护孩子身心健康发展。
            </div>
            <div>
              启明星辰信息技术集团股份有限公司成立于1996年，由留美博士严望佳女士创建，是国内极具实力的、拥有完全自主知识产权的网络安全产品、可信安全管理平台、安全服务与解决方案的综合提供商，并于2010年在深圳A股中小板上市。目前启明星辰已对网御星云、合众数据、书生电子、赛博兴安进行了全资收购，成功实现了对网络安全、数据安全、应用业务安全等多领域覆盖，形成了信息安全产业生态圈。集团总部位于北京市中关村软件园，在全国各地设有三十多个分、子公司及办事处。
            </div>
          </div>
          <div className={`${s.title} ${isMobile() ? s.mobileTitle : ''}`}>联系我们</div>
          <div className={isMobile() ? '' : s.connection}>
            <div>通讯地址：南京市建邺区创智路1号新城科技园北纬国际中心A栋15层A座</div>
            <div>{!!isZGYD() ? '' : '青松守护'}客服号：QingSongShouHu</div>
            <div>公司邮箱：qscare@qq.com</div>
          </div>
        </div>
        {isMobile() ? <MobileFooter /> : <Footer showBackground={false} />}
        <CompleteInfoModal />
      </div>
    );
  }
}

export default About;
