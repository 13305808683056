import React, { useState } from 'react';
import s from './QrCodeDownload.module.scss';
import QrCode from '../../../qsParent/components/QrCode/QrCode';
import descIcon from './images/descIcon.svg';
import xiaomiIcon from '../Price/images/xiaomiIcon.png';
import xiaomiIconActive from '../Price/images/xiaomiIconActive.png';
import huaweiIcon from '../Price/images/huaweiIcon.png';
import huaweiIconActive from '../Price/images/huaweiIconActive.png';
import oppoIcon from '../Price/images/oppoIcon.png';
import oppoIconActive from '../Price/images/oppoIconActive.png';
import vivoIcon from '../Price/images/vivoIcon.png';
import vivoIconActive from '../Price/images/vivoIconActive.png';
import childAppLogo from './images/childAppLogo.png';
import QrCodeLogo from '../../../qsParent/components/QrCode/QrCodeWithLogo';

function QrCodeChildAndroidDownload() {
  const imgWidth = window.innerWidth === 1024 ? 150 : 180;
  return (
    <div className={s.childContainer}>
      <div className={s.title}>扫描二维码立即下载</div>
      <ChildAppDownload
        colorType="green"
        qrcodeWidth={imgWidth}
        showDesc={true}
        size="normal"
      />
    </div>
  );
}

interface IProps {
  url: string;
}
function QrCodeParentAndroidDownload(props: IProps) {
  const imgWidth = window.innerWidth === 1024 ? 110 : 150;
  return (
    <div className={s.container}>
      <QrCode value={props.url} elementId="download-parent-app" width={imgWidth} />
      <div className={s.desc}>扫描二维码立即下载</div>
    </div>
  );
}

interface IChildAppDownloadProps {
  qrcodeWidth: number;
  showDesc?: boolean;
  size: 'normal' | 'small';
  colorType: 'green' | 'blue';
}

function ChildAppDownload(props: IChildAppDownloadProps) {
  const [activeTab, setActiveTab] = useState('xiaomi');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const deviceInfo = [
    {
      key: 'xiaomi',
      name: '小米',
      icon: activeTab === 'xiaomi' ? xiaomiIconActive : xiaomiIcon,
      desc: 'MIUI 10.0及以上',
    },
    {
      key: 'huawei',
      name: '华为',
      icon: activeTab === 'huawei' ? huaweiIconActive : huaweiIcon,
      desc: 'EMUI 10.0及以上、鸿蒙 2.0.0 - 3.0.0',
    },
    {
      key: 'oppo',
      name: 'OPPO',
      icon: activeTab === 'oppo' ? oppoIconActive : oppoIcon,
      desc: 'ColorOS 7.0 - 13.0',
    },
    {
      key: 'vivo',
      name: 'vivo',
      icon: activeTab === 'vivo' ? vivoIconActive : vivoIcon,
      desc: 'OriginOS 1.0 - 3.0',
    },
  ];
  return (
    <div className={s.tabContainer}>
      <div className={s.tabList}>
        {deviceInfo.map((item: any, index: number) => {
          const selectedTop = index === activeTabIndex - 1;
          const selectedBottom = index === activeTabIndex + 1;
          return (
            <div
              key={item.key}
              style={{
                borderBottomRightRadius: selectedTop ? '10px' : '0px',
                borderTopRightRadius: selectedBottom ? '10px' : '0px',
              }}
              onClick={() => {
                setActiveTab(item.key);
                setActiveTabIndex(index);
              }}
              className={`${item.key == activeTab ? s.activeTab : ''} ${
                props.size === 'small' ? s.smallTabItem : ''
              } ${s[`${props.colorType}Tab`]}`}
            >
              <img src={item.icon} alt="" />
              <span>{item.name}</span>
              {item.key == activeTab && (
                <div
                  className={`${s.activeTabLine} ${s[`${props.colorType}TabActiveLine`]}`}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className={s.tabContent} style={{ width: `${props.qrcodeWidth}px` }}>
        {props.showDesc &&
          deviceInfo.map((item) => {
            if (item.key === activeTab) {
              return (
                <div key={item.key} className={s.desc}>
                  <img src={descIcon} alt="" />
                  支持
                  <span> {item.desc}设备</span>
                  {item.key === 'xiaomi' && '（14.0及以上积极适配中）'}
                </div>
              );
            }
          })}
        <QrCodeLogo
          value={`${window.location.origin}/childAppDownload?brand=${activeTab}`}
          elementId="child-download-app"
          width={props.qrcodeWidth}
          logoSrc={childAppLogo}
        />
      </div>
    </div>
  );
}

export { QrCodeParentAndroidDownload, QrCodeChildAndroidDownload, ChildAppDownload };
