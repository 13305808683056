import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './LearningMode.module.scss';
import {
  deleteOnlineCourseAction,
  getOnlineCourseAllAction,
} from '../../../redux/onlineCourse';
import lockIcon from '../TimeMgrNew/images/lockIcon.svg';
import {
  deleteEffectiveTimeAction,
  getCustomFileAction,
  getEffectiveTimeAction,
  updateCustomFileAction,
} from '../../../redux/webControls';
import { QsButtonFixedSize } from '../../../commonComponents/QsButton/QsButton';
import AddWebsite from './SetWebsite/SetWebsite';
import SetTime, { ITimeUpdateData } from './SetTime/SetTime';
import QsTag from '../../../commonComponents/QsTag/QsTag';

interface IProps {
  handleSwitchBtnClick: (enable: boolean) => void;
  getOnlineCourseAllAction: () => void;
  getOnlineCourseAllResult: any;
  getOrgInfoResult: any;
  deleteOnlineCourseAction: (courseId: string) => void;
  toggleWebACLResult: any;
  getCustomFileAction: (mode: string) => void;
  getCustomFileResult: any;
  updateCustomFileAction: (categoryID: number, forbidden: boolean) => void;
  getEffectiveTimeResult: any;
  deleteEffectiveTimeAction: (timeList: ITimeUpdateData[]) => void;
  getEffectiveTimeAction: () => void;
}

function WhiteListMode(props: IProps) {
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        props.getOnlineCourseAllAction(),
        props.getCustomFileAction('white'),
      ]);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedItems = () => {
    const orgId = props.getOrgInfoResult?.org_id || '';
    const onlineWebsiteAll = props.getOnlineCourseAllResult.data?.records || [];
    let publicWebsites = [];
    if (orgId !== '') {
      publicWebsites = onlineWebsiteAll
        .filter((item: any) => item.orgIdList?.includes(orgId))
        .map((item: any) => ({ name: item.onlineClassName, id: item._id }));
    }

    const customWebsites = props.getCustomFileResult.data || [];
    const selectedWebsites = customWebsites
      .filter((item: any) => item.forbidden)
      .map((item: any) => ({
        name: item.name,
        id: item.id,
        type: 'custom',
      }));

    return selectedWebsites.concat(publicWebsites).map((item: any) => {
      return (
        <QsTag
          key={item.id}
          text={item.name}
          type="success"
          onClick={async () => {
            if (item.type == 'custom') {
              await props.updateCustomFileAction(item.id, false);
              await props.getCustomFileAction('white');
            } else {
              await props.deleteOnlineCourseAction(item.id);
              await props.getOnlineCourseAllAction();
            }
          }}
        />
      );
    });
  };

  const getEffectiveTimeItems = () => {
    const { data } = props.getEffectiveTimeResult.data ?? { data: [] };
    let isWholeDay = false;
    if (
      data.length === 1 &&
      data[0].starttime === '00:00' &&
      data[0].endtime === '23:59'
    ) {
      isWholeDay = true;
    }
    return data.map((item: any) => {
      const text = isWholeDay ? '00:00 - 24:00' : `${item.starttime} - ${item.endtime}`;

      return (
        <QsTag
          key={item.id}
          text={text}
          type="success"
          onClick={async () => {
            const timeList = data
              .filter((time: any) => time.id != item.id)
              .map((value: any) => ({
                starttime: value.starttime,
                endtime: value.endtime === '24:00' ? '23:59' : value.endtime,
              }));
            await props.deleteEffectiveTimeAction(timeList);
            await props.getEffectiveTimeAction();
          }}
        />
      );
    });
  };

  const selectedItems = getSelectedItems();
  const effectiveTimeItems = getEffectiveTimeItems();
  const prevEnable = props.toggleWebACLResult.enable;
  const isOpen = prevEnable === 'true';

  return (
    <div className={s.rightContent}>
      <div className={s.rightTitle}>白名单模式</div>
      <div className={s.settingContainer}>
        {prevEnable == 'true' && (
          <div className={s.disableMask}>
            <div>
              <div>
                <span className={s.lockIcon}>
                  <img src={lockIcon} alt="" />
                </span>
                白名单模式生效中，暂不支持修改。
              </div>
              <div className={s.disableTips}>
                孩子的电脑只能访问白名单中的网站，其他网站均不能访问。
              </div>
            </div>
          </div>
        )}
        <div className={`${s.greyBox} ${s.setWebsite}`}>
          <div>设置允许访问的网站</div>
          <div
            className={`${s.whiteBox} ${selectedItems.length == 0 ? s.emptyContent : ''}`}
          >
            <AddWebsite />
            {selectedItems}
          </div>
        </div>
        <div className={`${s.greyBox} ${s.setTime}`}>
          <div>设置生效时间段</div>
          <div
            className={`${s.whiteBox} ${
              effectiveTimeItems.length == 0 ? s.emptyContent : ''
            }`}
          >
            <SetTime />
            {effectiveTimeItems}
          </div>
        </div>
      </div>
      <div className={s.confirmBtn}>
        <QsButtonFixedSize
          size="normal"
          type={isOpen ? 'primaryOutline' : 'primary'}
          disabled={
            !isOpen && (selectedItems.length === 0 || effectiveTimeItems.length === 0)
          }
          handleClick={() => {
            props.handleSwitchBtnClick(!isOpen);
          }}
        >
          <span>{isOpen ? '关闭白名单模式' : '开启白名单模式'}</span>
        </QsButtonFixedSize>
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getOnlineCourseAllResult: state.getOnlineCourseAllResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    toggleWebACLResult: state.toggleWebACLResult.data,
    updateToggleWebACLResult: state.updateToggleWebACLResult.data,
    getCustomFileResult: state.getCustomFileResult.data,
    getEffectiveTimeResult: state.getEffectiveTimeResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOnlineCourseAllAction: bindActionCreators(getOnlineCourseAllAction, dispatch),
    deleteOnlineCourseAction: bindActionCreators(deleteOnlineCourseAction, dispatch),
    getCustomFileAction: bindActionCreators(getCustomFileAction, dispatch),
    updateCustomFileAction: bindActionCreators(updateCustomFileAction, dispatch),
    deleteEffectiveTimeAction: bindActionCreators(deleteEffectiveTimeAction, dispatch),
    getEffectiveTimeAction: bindActionCreators(getEffectiveTimeAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhiteListMode);
