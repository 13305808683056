export const weekEnglish = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const weeksMap: { [key: string]: { key: number; value: string } } = {
  monday: {
    key: 0,
    value: '周一',
  },
  tuesday: { key: 1, value: '周二' },
  wednesday: { key: 2, value: '周三' },
  thursday: {
    key: 3,
    value: '周四',
  },
  friday: {
    key: 4,
    value: '周五',
  },
  saturday: { key: 5, value: '周六' },
  sunday: { key: 6, value: '周日' },
};

export const weeks = ['一', '二', '三', '四', '五', '六', '日'];

export const english2Chinese: { [key: string]: string } = {
  monday: '一',
  tuesday: '二',
  wednesday: '三',
  thursday: '四',
  friday: '五',
  saturday: '六',
  sunday: '日',
};

const mapPeriod = (data: any) => {
  let period = data.split('、');
  const time = data.split('-');
  if (time.length == 2 && time.first == '00:00' && time.last == '24:00') {
    period = ['完全禁用'];
  }
  return period;
};

const mapWeeks = (weeksData: any) => {
  const dif =
    weeksMap[weeksData[weeksData.length - 1]]['key'] - weeksMap[weeksData[0]]['key'];

  let weeks = '';

  if (weeksData.length > 2 && dif == weeksData.length - 1) {
    weeks = `${weeksMap[weeksData[0]]['value']}至${
      weeksMap[weeksData[weeksData.length - 1]]['value']
    }`;
  } else {
    const weeksList: any = [];
    weeksData.forEach((element: any) => {
      weeksList.push(weeksMap[element]['value']);
    });
    weeks = weeksList.join('、');
  }

  return weeks;
};

export const mapLockScreen = (data: any) => {
  if (data.hasOwnProperty('id')) {
    delete data.id;
  }
  const result: any = {};
  for (const [key, value] of Object.entries(data)) {
    const k: any = (value as any[])
      .map((v: any) => `${v['begin']}-${v['end']}`)
      .join('、');
    if (k.length == 0) {
      continue;
    }
    if (result.hasOwnProperty(k)) {
      result[k].push(key);
    } else {
      result[k] = [key];
    }
  }

  const lastData = [];
  for (const [key, value] of Object.entries(result)) {
    lastData.push({ period: mapPeriod(key), weeks: mapWeeks(value) });
  }

  return lastData;
};
