import React from 'react';
import s from './FunctionCard.module.scss';

interface IProps {
  icon: string;
  title: string;
  functionList: Array<any>[];
  lineColor: string;
  deviceInfo: React.ReactElement;
  bgColor?: string;
}

function FunctionCard(props: IProps) {
  const { icon, title, functionList, lineColor, deviceInfo } = props;
  return (
    <div className={s.container}>
      <div
        className={s.topContainer}
        style={{ backgroundColor: props.bgColor || '#F0FDF9' }}
      >
        <div className={s.line} style={{ backgroundColor: lineColor }} />
        <img className={s.logo} src={icon} alt="" />
        <div className={s.title}>{title}</div>
      </div>
      {deviceInfo}
      <div className={s.bottomContainer}>
        {functionList.map((item: any) => {
          return (
            <div key={item.title} className={s.detailInfo}>
              <div
                style={{
                  backgroundColor: item.color,
                  boxShadow: `2px 4px 8px 0 ${item.color}33`,
                }}
              >
                <img src={item.icon} alt="" />
              </div>

              <div>
                <div>{item.title}</div>
                <div>{item.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FunctionCard;
