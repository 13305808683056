import SocketIO from 'socket.io-client';
import PeerConnection from 'rtcpeerconnection';
import {
  REMOTE_DESKTOP_REQUEST,
  REMOTE_DESKTOP_VERIFIED,
  REMOTE_DESKTOP_SUCCESS,
  REMOTE_DESKTOP_FAILURE,
  REMOTE_DESKTOP_CLOSED,
} from './constants';

const PREPARE_WEBRTC_TIMEOUT = 15 * 1000;
const CONNECT_WEBRTC_TIMEOUT = 60 * 1000;

const ICE_SERVERS = [
  { urls: 'stun:iphone-stun.strato-iphone.de:3478' },
  { urls: 'stun:stun.l.google.com:19302' },
  {
    urls: [
      'turn:turn.zd.cloudtrust.com.cn:3478?transport=tcp',
      'turn:turn.zd.cloudtrust.com.cn:3478?transport=udp',
    ],
    username: 'cloudtrust',
    credential: '296a766d',
  },
];

function rejectTimeout(time, reject) {
  return setTimeout(() => reject(new Error('Timeout Error')), time);
}

function preapreWebRTC(socket, terminalId) {
  return new Promise((resolve, reject) => {
    try {
      const timer = rejectTimeout(PREPARE_WEBRTC_TIMEOUT, reject);
      socket.emit('prepare', { terminalId });
      socket.on('prepare', (data) => {
        if (!data.success) {
          reject(new Error('Verify'));
        } else {
          clearTimeout(timer);
          resolve(data.token);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}

function getBrowserPeer() {
  if (!window.RTCPeerConnection) {
    window.RTCPeerConnection =
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.RTCIceGatherer;
  }
}

function connectWebRTC(socket, token) {
  return new Promise((resolve, reject) => {
    const timer = rejectTimeout(CONNECT_WEBRTC_TIMEOUT, () => {
      socket.emit('p2p-fail', { token });
      reject();
    });

    getBrowserPeer();

    const peer = new PeerConnection({
      iceServers: ICE_SERVERS,
    });

    const channel = peer.createDataChannel('vnc-channel');

    channel.onopen = () => {
      window.localStorage.setItem('closeOtherConnection', new Date());
      socket.emit('p2p-success', { token });
      clearTimeout(timer);
      resolve(channel);
    };

    peer.offer((err, offer) => {
      if (err) {
        socket.emit('p2p-fail', { token });
        reject(err);
      } else {
        socket.emit('offer', { token, offer });
      }
    });

    socket.on('answer', ({ answer }) => {
      peer.handleAnswer(answer);
    });

    peer.on('ice', (data) => {
      socket.emit('candidate', { token, candidate: data.candidate });
    });

    socket.on('candidate', (data) => {
      peer.processIce(data);
    });
  });
}

export default function connectRemoteDesktop(terminalId) {
  return async (dispatch) => {
    try {
      const socket = SocketIO('/vnc', {
        transports: ['websocket'],
        path: '/socket.io',
      });

      socket.on('exception', (data) => {
        dispatch({
          type: REMOTE_DESKTOP_FAILURE,
          error: data.error,
        });
      });

      dispatch({
        type: REMOTE_DESKTOP_REQUEST,
      });
      const token = await preapreWebRTC(socket, terminalId);
      dispatch({
        type: REMOTE_DESKTOP_VERIFIED,
        token,
      });
      const channel = await connectWebRTC(socket, token);

      window.addEventListener('storage', (e) => {
        if (e.key === 'closeOtherConnection') {
          channel.close();
          dispatch({
            type: REMOTE_DESKTOP_CLOSED,
          });
        }
      });
      window.addEventListener('novnc_error', (e) => {
        socket.emit('vnc-error', { token, error: e.detail.err_msg });
      });
      window.addEventListener('novnc_log', (e) => {
        socket.emit('vnc-log', { token, log: e.detail.log });
      });
      dispatch({
        type: REMOTE_DESKTOP_SUCCESS,
        channel,
      });
    } catch (error) {
      dispatch({
        type: REMOTE_DESKTOP_FAILURE,
        error,
      });
    }
  };
}
