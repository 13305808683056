import React, { Component } from 'react';
import FormTemplate, { IFormItem } from '../../components/FormTemplate/FormTemplate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { regPattern, getUrlParams } from '../../../common';
import { searchUserByPhoneAction } from '../../../redux/user';

import { registerAction, resetRegisterErrorAction } from '../../../redux/register';
import { resetPhoneCaptchaErrorAction } from '../../../redux/getPhoneCaptcha';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';

const errorMessage: any = {
  'Non-existent verification code': '手机验证码错误。',
  'invalid verifyCode': '手机验证码错误。',
  locked: '手机验证码错误次数太多, 请1小时后再进行尝试。',
  'wait a moment': '获取手机验证码太频繁，请60s后重新获取。',
  'sms fetch error': '验证码发送失败，请重试！',
  'register fetch error': '注册失败，请重试！',
  'Register Failed': '注册失败，请重试！',
};

interface IRegisterData {
  user: {
    userName: string;
    email: string | null;
    password: string | null;
    phoneNumber: string;
    orgName: string;
  };
  promotion: string | null;
  checked: boolean;
  smsCode: string;
}

interface IProps {
  registerAction: (data: IRegisterData) => void;
  registerResult: any;
  getPhoneCaptchaResult: any;
  searchUserByPhoneAction: (phone: string) => void;
  searchUserByPhoneResult: any;
  resetPhoneCaptchaErrorAction: () => void;
  resetRegisterErrorAction: () => void;
  formName: string;
  layout?: 'horizontal' | 'vertical' | 'inline';
  shape?: 'circle' | 'default' | 'round' | undefined;
  btnClassName?: any;
  btnText?: string;
  inputType?: 'round' | 'square' | undefined;
}

interface IStates {
  disableCaptchaBtn: boolean;
  errorMessage: string;
}

class Register extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      disableCaptchaBtn: false,
      errorMessage: '',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromProps(props: IProps, state: any): any {
    const { registerResult, getPhoneCaptchaResult } = props;
    return {
      errorMessage: errorMessage[registerResult.message || getPhoneCaptchaResult.message],
    };
  }

  phoneValidator = async (rule: any, value: string): Promise<any> => {
    if (regPattern.PHONE.test(value)) {
      await this.props.searchUserByPhoneAction(value);
      const { user } = this.props.searchUserByPhoneResult;
      if (user && user.length !== 0) {
        this.setState({ disableCaptchaBtn: true });
        return Promise.reject('手机号已被注册');
      }
      this.setState({ disableCaptchaBtn: false });
      return Promise.resolve();
    } else {
      this.setState({ disableCaptchaBtn: true });
      return Promise.reject('手机号格式错误');
    }
  };

  registerForm = () => {
    const forItems: IFormItem[] = [
      {
        name: 'phone',
        placeholder: '手机号码',
        type: 'text',
        validateTrigger: 'onBlur',
        rules: [
          {
            required: true,
            message: '请输入手机号码',
          },
          {
            validator: this.phoneValidator,
          },
        ],
      },
      {
        name: 'smsCode',
        placeholder: '短信验证码',
        type: 'text',
        rules: [
          {
            required: true,
            message: '请输入短信验证码',
          },
        ],
      },
    ];

    const promotion = getUrlParams('promotion');
    const activity = getUrlParams('activity') || '';
    const inviteCode = getUrlParams('inviteCode') || '';

    return (
      <>
        <FormTemplate
          formName={this.props.formName}
          forItems={forItems}
          btnText={this.props.btnText || '立即体验'}
          layout={this.props.layout}
          disableCaptchaBtn={this.state.disableCaptchaBtn}
          shape={this.props.shape}
          inputType={this.props.inputType}
          btnClassName={this.props.btnClassName}
          handleSubmit={async (data: any): Promise<void> => {
            const registerData = {
              user: {
                userName: data.phone,
                email: null,
                password: null,
                phoneNumber: data.phone,
                orgName: data.phone,
              },
              promotion: !!promotion ? promotion : 'DEFAULT',
              checked: true,
              smsCode: data.smsCode,
              activity,
              inviteCode,
            };

            await this.props.registerAction(registerData);
          }}
        />
        <QsAlert
          visible={!!this.state.errorMessage}
          closable
          type="error"
          message={this.state.errorMessage}
          position="fixed"
          onClose={async () => {
            await this.props.resetRegisterErrorAction();
            await this.props.resetPhoneCaptchaErrorAction();
          }}
        />
      </>
    );
  };

  render() {
    return <div>{this.registerForm()}</div>;
  }
}

function mapStateToProps(state: any) {
  return {
    registerResult: state.registerResult.data,
    getPhoneCaptchaResult: state.getPhoneCaptchaResult.data,
    searchUserByPhoneResult: state.searchUserByPhoneResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    registerAction: bindActionCreators(registerAction, dispatch),
    searchUserByPhoneAction: bindActionCreators(searchUserByPhoneAction, dispatch),
    resetPhoneCaptchaErrorAction: bindActionCreators(
      resetPhoneCaptchaErrorAction,
      dispatch
    ),
    resetRegisterErrorAction: bindActionCreators(resetRegisterErrorAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
