import React, { Component } from 'react';
import { isArray } from 'lodash';
import s from './SoftwareMgr.module.scss';
import { connect } from 'react-redux';
import {
  PcBaseCard,
  MobileBaseCard,
  MobileErrorBaseCard,
  PcErrorBaseCard,
} from './BaseCard/BaseCard';
import { bindActionCreators } from 'redux';
import { getTerminalInfoAction } from '../../../redux/terminal';
import {
  getMobileAppListAction,
  getMobileDeviceAction,
  updateAppTimePolicyAction,
  updateMobileAppSwitchAction,
} from '../../../redux/mobileAppDevice';
import BindFlowModal from '../../components/BindFlowModal/BindFlowModal';
import {
  getSoftwareListAction,
  postSoftwareSettingAction,
} from '../../../redux/software';
import defaultAppLogo from './images/defaultAppLogo.svg';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';
import appDefaultIcon from '../../../commonComponents/QsIcons/appDefaultIcon.svg';
import SoftwareContent from './SoftwareContent/SoftwareContent';
import { isZGYD, shouldShowMobile } from '../../../utils/lib';
import { getUrlQuery } from '../../../common';
import { getAllConfigAction, updatePolicySwitchesAction } from '../../../redux/config';

interface IUpdateData {
  softwarename: string;
  timeControlType: string;
  begin?: string;
  end?: string;
  timeRange?: {
    begin: string;
    end: string;
  }[];
}

interface IProps {
  getOrgInfoResult: any;
  getTerminalInfoResult: any;
  getMobileDeviceResult: any;
  getMobileAppListResult: any;
  getTerminalInfoAction: () => void;
  getMobileDeviceAction: () => Promise<void>;
  getMobileAppListAction: (mobileId: string) => void;
  getSoftwareListAction: (forbid: string, classification: string) => any;
  getSoftwareListResult: { softwares: any[]; policySoftwares: any[] };
  updateMobileAppSwitchAction: (mobileId: string, enable: boolean) => void;
  updateMobileAppSwitchResult: any;
  postSoftwareSettingAction: (orgId: string, softwarePolicies: IUpdateData[]) => void;
  postSoftwareSettingResult: any;
  updateAppTimePolicyAction: (id: string, regularAppControls: IUpdateData[]) => void;
  updateAppTimePolicyResult: any;
  getProductStateResult: any;
  getAllConfigAction: () => void;
  getAllConfigResult: any;
  updatePolicySwitchesAction: (data: { software_mgmt: 'true' | 'false' }) => void;
  updatePolicySwitchesResult: any;
}

interface IState {
  showBindModal: boolean;
  deviceType: 'mobile' | 'pc';
  showAlert: boolean;
  alertType: 'success' | 'error';
  alertMessage: string;
  isLoading: boolean;
  currentMobileId: string;
}

const status: any = {
  FullDisable: 1,
  PartialEnable: 2,
  FullEnable: 3,
};

class SoftwareMgr extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showBindModal: false,
      deviceType: 'mobile',
      showAlert: false,
      alertType: 'success',
      alertMessage: '',
      isLoading: true,
      currentMobileId: '',
    };
  }

  async componentDidMount() {
    document.title = !!isZGYD() ? '应用守护' : '青松守护 - 应用守护';

    await Promise.all([
      this.props.getTerminalInfoAction(),
      this.props.getMobileDeviceAction(),
      this.props.getSoftwareListAction('all', 'all'),
      this.props.getAllConfigAction(),
    ]);

    setTimeout(async () => {
      await this.props.getMobileAppListAction(this.getMobileId());
    }, 500);

    const type: any = getUrlQuery('type') || 'pc';
    this.setState({ deviceType: type });

    this.setState({
      isLoading: false,
      currentMobileId: this.getMobileId(),
    });
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      (prevProps.getMobileDeviceResult !== this.props.getMobileDeviceResult &&
        this.props.getMobileDeviceResult) ||
      (prevProps.getMobileAppListResult !== this.props.getMobileAppListResult &&
        this.props.getMobileAppListResult)
    ) {
      this.setState({
        currentMobileId: this.props.getMobileDeviceResult?.data?.[0]?.id,
      });
    }
  }

  getMobileId = () => {
    const device = this.props.getMobileDeviceResult?.data?.[0] || {};
    return device.id;
  };

  getMobileDevice = () => {
    const { data: mobileDevice, success } = this.props.getMobileDeviceResult || {};
    const mobile = success && isArray(mobileDevice) ? mobileDevice : [];
    return mobile;
  };

  getPcTerminals = () => {
    const onlineTerminals = this.props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = this.props.getTerminalInfoResult.offlineTerminals || [];
    const terminals = onlineTerminals.concat(offlineTerminals);
    return terminals;
  };

  shouldShowMobile = () => {
    const mobileDevice = this.getMobileDevice();
    return shouldShowMobile(mobileDevice);
  };

  showBindingModal = (type: 'mobile' | 'pc') => {
    this.setState({
      showBindModal: true,
      deviceType: type,
    });
  };

  mobileData = () => {
    const { getMobileAppListResult: mAppResult } = this.props;
    const regularAppControls = mAppResult?.data?.regularAppControls || [];
    return regularAppControls
      .map((app: any) => {
        const timeRangeArr = isArray(app.timeControl.timeRange)
          ? app.timeControl.timeRange.map((item: any) => {
              return `${item.begin} - ${item.end}`;
            })
          : [];
        const timeRange = timeRangeArr.join('、');

        return {
          id: app.applicationId,
          name: app.name,
          icon: app.icon?.url || defaultAppLogo,
          status: app.timeControl.type,
          timeRange,
        };
      })
      .sort((a: any, b: any) => status[a.status] - status[b.status]);
  };

  renderMobileContent = () => {
    if (this.state.isLoading) {
      return null;
    }
    const mobileAppData = this.props.getMobileAppListResult?.data?.regularAppControls;
    if (this.getMobileDevice().length <= 0) {
      return (
        <MobileBaseCard
          handleClick={() => this.showBindingModal('mobile')}
          functionName="手机 App 管理"
        />
      );
    }

    if (!!mobileAppData && mobileAppData.length <= 0) {
      return (
        <MobileErrorBaseCard
          handleClick={() => {
            window.location.reload();
          }}
        />
      );
    }

    if (!!mobileAppData && mobileAppData.length > 0) {
      const mobile = this.getMobileDevice();
      const isHuawei = mobile[0].mobileInfo.manufacture === 'huawei';
      return (
        <SoftwareContent
          data={this.mobileData()}
          handleOperation={this.handleMobileOperation}
          type="mobile"
          isHuawei={isHuawei}
          switchName="启用 APP 管理功能"
          switchDefaultValue={this.getMobileSwitchStatus()}
          onSwitchChange={async (checked) => {
            await this.handleMobileSwitchChange(checked);
          }}
        />
      );
    }
  };

  handleMobileOperation = async (data: any) => {
    const { getMobileAppListResult: mAppResult } = this.props;
    const id = mAppResult.data.id;
    await this.props.updateAppTimePolicyAction(id, data);
    await this.props.getMobileAppListAction(this.state.currentMobileId);
    const { success } = this.props.updateAppTimePolicyResult;
    this.showAlert(success, '保存成功', '保存失败');
  };

  mapPcData = () => {
    const { softwares } = this.props.getSoftwareListResult;
    return (softwares || [])
      .filter((app) => app.installCount > 0)
      .map((app: any) => {
        const timeRangeArr = isArray(app.timeRange)
          ? app.timeRange.map((item: any) => {
              return `${item.begin} - ${item.end}`;
            })
          : [];
        const timeRange = timeRangeArr.join('、');
        return {
          id: app.baseName === '' ? app.name : app.baseName,
          name: app.baseName === '' ? app.name : app.baseName,
          icon: appDefaultIcon,
          status: app.timeControlType,
          timeRange,
        };
      })
      .sort((a: any, b: any) => status[a.status] - status[b.status]);
  };

  renderPcContent = () => {
    if (this.state.isLoading) {
      return null;
    }
    if (this.getPcTerminals().length <= 0) {
      return (
        <PcBaseCard
          handleClick={() => this.showBindingModal('pc')}
          functionName="电脑软件管理"
        />
      );
    }

    if (this.mapPcData().length <= 0) {
      return (
        <PcErrorBaseCard
          handleClick={() => {
            window.location.reload();
          }}
        />
      );
    }

    if (this.mapPcData().length > 0) {
      const pcSwitchValue = this.props.getAllConfigResult.software_mgmt;
      return (
        <SoftwareContent
          data={this.mapPcData()}
          handleOperation={this.handlePcOperation}
          type="pc"
          switchName="启用软件管理功能"
          switchDefaultValue={pcSwitchValue === 'true'}
          onSwitchChange={async (checked) => {
            await this.handlePcSwitchChange(checked);
          }}
        />
      );
    }
  };

  handlePcOperation = async (data: any) => {
    const orgInfo = this.props.getOrgInfoResult || {};
    await this.props.postSoftwareSettingAction(orgInfo.org_id, data);
    await this.props.getSoftwareListAction('all', 'all');
    const { success } = this.props.postSoftwareSettingResult;
    this.showAlert(success, '保存成功', '保存失败');
  };

  showAlert = (success: boolean, successMessage: string, failedMessage: string) => {
    this.setState({
      showAlert: true,
      alertMessage: success ? successMessage : failedMessage,
      alertType: success ? 'success' : 'error',
    });
  };

  handleMobileSwitchChange = async (checked: any) => {
    if (!this.props.getMobileAppListResult.data) {
      return;
    }
    await this.props.updateMobileAppSwitchAction(
      this.props.getMobileAppListResult.data.id,
      checked
    );
    await this.props.getMobileAppListAction(this.state.currentMobileId);

    const { success } = this.props.updateMobileAppSwitchResult;
    const successMessage = checked ? 'APP 管理功能已开启' : 'APP 管理功能已关闭';
    this.showAlert(success, successMessage, '操作失败');
  };

  handlePcSwitchChange = async (checked: any) => {
    const value = checked ? 'true' : 'false';
    await this.props.updatePolicySwitchesAction({ software_mgmt: value });
    await this.props.getAllConfigAction();

    const { success } = this.props.updatePolicySwitchesResult;
    const successMessage = checked ? '软件管理功能已开启' : '软件管理功能已关闭';
    this.showAlert(success, successMessage, '操作失败');
  };

  getMobileSwitchStatus = () => {
    return this.props.getMobileAppListResult?.data?.enable === 'undefined'
      ? false
      : this.props.getMobileAppListResult?.data?.enable;
  };

  render() {
    const mobileDevice = this.getMobileDevice();
    const pcTerminals = this.getPcTerminals();
    let client = 1;
    if (this.props.getProductStateResult) {
      client =
        this.props.getProductStateResult.allLimits.find(
          (x: any) => x.type === 'TianxunLimit'
        )?.client || 1;
    }
    const hasPc = pcTerminals.length >= client;

    if (this.state.isLoading) {
      return null;
    }

    return (
      <>
        <div className={s.appLayoutBg}>
          <div className={s.content}>
            <div className={s.tabContent}>
              {this.state.deviceType === 'pc'
                ? this.renderPcContent()
                : this.renderMobileContent()}
            </div>
          </div>
        </div>
        <BindFlowModal
          defaultType={this.state.deviceType}
          hasPc={hasPc}
          hasMobile={mobileDevice.length > 0}
          visible={this.state.showBindModal}
          handleCancel={() => {
            this.setState({ showBindModal: false });
          }}
          mobileLoopAction={async () => {
            console.log();
            await this.props.getMobileAppListAction(this.getMobileId());
          }}
        />
        <QsAlert
          visible={this.state.showAlert}
          type={this.state.alertType}
          message={this.state.alertMessage}
          position="absolute"
          onClose={() => {
            this.setState({ showAlert: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getTerminalInfoResult: state.getTerminalInfoResult.data,
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    getMobileAppListResult: state.getMobileAppListResult.data,
    getSoftwareListResult: state.getSoftwareListResult?.data,
    updateMobileAppSwitchResult: state.updateMobileAppSwitchResult.data,
    postSoftwareSettingResult: state.postSoftwareSettingResult?.data,
    updateAppTimePolicyResult: state.updateAppTimePolicyResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    getAllConfigResult: state.getAllConfigResult.data,
    updatePolicySwitchesResult: state.updatePolicySwitchesResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    getMobileAppListAction: bindActionCreators(getMobileAppListAction, dispatch),
    getSoftwareListAction: bindActionCreators(getSoftwareListAction, dispatch),
    updateMobileAppSwitchAction: bindActionCreators(
      updateMobileAppSwitchAction,
      dispatch
    ),
    postSoftwareSettingAction: bindActionCreators(postSoftwareSettingAction, dispatch),
    updateAppTimePolicyAction: bindActionCreators(updateAppTimePolicyAction, dispatch),
    getAllConfigAction: bindActionCreators(getAllConfigAction, dispatch),
    updatePolicySwitchesAction: bindActionCreators(updatePolicySwitchesAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareMgr);
