import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';

import s from './WebsiteModal.module.scss';
import bulbIcon from '../images/bulbIcon.svg';
import selectedIcon from '../images/selectedIcon.svg';
import uploadIcon from '../images/uploadIcon.svg';
import lookOverIcon from '../images/lookOverIcon.svg';
import downloadIcon from '../images/downloadIcon.svg';
import QsAlert from '../../../../commonComponents/QsAlert/QsAlert';
import { regPattern } from '../../../../common';
import { QsNormalModal } from '../../../../commonComponents/QsModal/QsModal';
import QsInput from '../../../../commonComponents/QsInput/QsInput';

const { Dragger } = Upload;

interface FormItemType {
  title: string;
  defaultValue?: string;
  placeholder: string;
  isError: boolean;
  errorMsg: string;
  handleChange: (value: string) => void;
}

interface IProps {
  visible: boolean;
  title: string;
  tips?: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  formList?: FormItemType[];
  customItem?: React.ReactElement;
  disabled: boolean;
  hasError?: boolean;
  errorMsg?: string;
  onAlertClose?: () => void;
}

function WebsiteModal(props: IProps) {
  return (
    <QsNormalModal
      title={props.title}
      visible={props.visible}
      onCancel={props.handleCancel}
      onOk={props.handleConfirm}
      okBtnDisable={props.disabled}
    >
      <div>
        {props.tips && (
          <div className={s.tips}>
            <img src={bulbIcon} alt="" /> {props.tips}
          </div>
        )}
        <div className={s.workOrderForm}>
          {props.customItem}
          {props.formList &&
            props.formList.map((item, index) => (
              <QsInput
                key={index}
                label={item.title}
                defaultValue={item.defaultValue}
                placeholder={item.placeholder}
                isError={item.isError}
                errorMsg={item.errorMsg}
                onChange={(e) => {
                  item.handleChange(e.target.value);
                }}
              />
            ))}
        </div>
        <QsAlert
          visible={props.hasError || false}
          type="error"
          message={props.errorMsg || ''}
          position="fixed"
          onClose={() => {
            if (props.onAlertClose) {
              props.onAlertClose();
            }
          }}
        />
      </div>
    </QsNormalModal>
  );
}

export default WebsiteModal;

interface ICustomModalProps {
  visible: boolean;
  type?: 'modify' | 'add';
  defaultName?: string;
  defaultDomain?: string;
  handleCancel: () => void;
  handleConfirm: (values: any) => void;
  hasError?: boolean;
  errorMsg?: string;
  onAlertClose?: () => void;
}

function BlackListExceptionModal(props: ICustomModalProps) {
  const [websiteName, setWebsiteName] = useState('');
  const [websiteDomain, setWebsiteDomain] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [isDomainError, setIsDomainError] = useState(false);

  useEffect(() => {
    setWebsiteName(props.defaultName || '');
    setWebsiteDomain(props.defaultDomain || '');
  }, [props.defaultName, props.defaultDomain]);

  const formList = [
    {
      title: '网站名称：',
      defaultValue: props.defaultName,
      placeholder: '例：百度',
      isError: isNameError,
      errorMsg: '网站名称不得超过30个字符',
      handleChange: (value: string) => {
        setWebsiteName(value);
        setIsNameError(value.length > 30);
      },
    },
    {
      title: '网站网址：',
      defaultValue: props.defaultDomain,
      placeholder: '例：baidu.com',
      isError: isDomainError,
      errorMsg: '非法域名',
      handleChange: (value: string) => {
        setWebsiteDomain(value);
        if (
          !regPattern.DOMAIN_REGEX.test(value) ||
          regPattern.ILLEGAL_DOMAIN_REGEX.test(value)
        ) {
          setIsDomainError(true);
        } else {
          setIsDomainError(false);
        }
      },
    },
  ];

  const disabled =
    websiteName == '' ||
    websiteDomain == '' ||
    isNameError ||
    isDomainError ||
    (websiteName == props.defaultName && websiteDomain == props.defaultDomain);
  return (
    <WebsiteModal
      visible={props.visible}
      disabled={disabled}
      title={props.type == 'add' ? '添加例外网站' : '修改例外网站'}
      tips="黑名单模式下，例外网站可被正常访问。"
      handleCancel={() => {
        props.handleCancel();
        setIsNameError(false);
        setIsDomainError(false);
        setWebsiteName('');
        setWebsiteName('');
      }}
      handleConfirm={() => {
        props.handleConfirm({ name: websiteName, domain: websiteDomain });
      }}
      formList={formList}
    />
  );
}

function BlackListCustomAddModal(props: ICustomModalProps) {
  const [websiteName, setWebsiteName] = useState('');
  const [websiteDomain, setWebsiteDomain] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [isDomainError, setIsDomainError] = useState(false);

  useEffect(() => {
    setWebsiteName(props.defaultName || '');
    setWebsiteDomain(props.defaultDomain || '');
  }, [props.defaultName, props.defaultDomain]);

  const formList = [
    {
      title: '网站名称：',
      defaultValue: props.defaultName,
      placeholder: '例：百度',
      isError: isNameError,
      errorMsg: '网站名称不得超过30个字符',
      handleChange: (value: string) => {
        setWebsiteName(value);
        setIsNameError(value.length > 30);
      },
    },
    {
      title: '网站网址：',
      defaultValue: props.defaultDomain,
      placeholder: '例：baidu.com',
      isError: isDomainError,
      errorMsg: '非法域名',
      handleChange: (value: string) => {
        setWebsiteDomain(value);
        if (
          !regPattern.DOMAIN_REGEX.test(value) ||
          regPattern.ILLEGAL_DOMAIN_REGEX.test(value)
        ) {
          setIsDomainError(true);
        } else {
          setIsDomainError(false);
        }
      },
    },
  ];

  const disabled =
    websiteName == '' ||
    websiteDomain == '' ||
    isNameError ||
    isDomainError ||
    (websiteName == props.defaultName && websiteDomain == props.defaultDomain);

  return (
    <WebsiteModal
      visible={props.visible}
      disabled={disabled}
      title={props.type == 'add' ? '添加自定义黑名单网站' : '修改自定义黑名单网站'}
      tips="黑名单模式下，为孩子设置禁止访问「网站」。"
      handleCancel={() => {
        props.handleCancel();
        setIsNameError(false);
        setIsDomainError(false);
        setWebsiteName('');
        setWebsiteName('');
      }}
      handleConfirm={() => {
        props.handleConfirm({ name: websiteName, domain: websiteDomain });
      }}
      formList={formList}
    />
  );
}

function SubmitWorkOrderModal(props: ICustomModalProps) {
  const [websiteName, setWebsiteName] = useState('');
  const [websiteDomain, setWebsiteDomain] = useState('');
  const [websiteType, setWebsiteType] = useState(0);
  const [isNameError, setIsNameError] = useState(false);
  const [isDomainError, setIsDomainError] = useState(false);

  const formList = [
    {
      title: '网站名称：',
      placeholder: '例：百度',
      isError: isNameError,
      errorMsg: '网站名称不得超过30个字符',
      handleChange: (value: string) => {
        setWebsiteName(value);
        setIsNameError(value.length > 30);
      },
    },
    {
      title: '网站网址：',
      placeholder: '例：baidu.com',
      isError: isDomainError,
      errorMsg: '非法域名',
      handleChange: (value: string) => {
        setWebsiteDomain(value);
        if (
          !regPattern.DOMAIN_REGEX.test(value) ||
          regPattern.ILLEGAL_DOMAIN_REGEX.test(value)
        ) {
          setIsDomainError(true);
        } else {
          setIsDomainError(false);
        }
      },
    },
  ];

  const disabled =
    websiteName === '' || websiteDomain === '' || isNameError || isDomainError;

  const websiteTypeItem = (
    <div className={s.formItem}>
      <div className={s.formTitle}>网站类型：</div>
      <div className={s.websiteType}>
        <span
          className={websiteType === 0 ? s.typeSelected : ''}
          onClick={() => {
            setWebsiteType(0);
          }}
        >
          网课类网站
          {websiteType === 0 && <img src={selectedIcon} alt="" />}
        </span>
        <span
          className={websiteType === 1 ? s.typeSelected : ''}
          onClick={() => {
            setWebsiteType(1);
          }}
        >
          工具类网站
          {websiteType === 1 && <img src={selectedIcon} alt="" />}
        </span>
      </div>
    </div>
  );
  return (
    <WebsiteModal
      visible={props.visible}
      disabled={disabled}
      title="填写网站工单信息"
      tips="填写您需要的网站，我们将在 1 个工作日内帮您添加。"
      handleCancel={() => {
        props.handleCancel();
        setIsNameError(false);
        setIsDomainError(false);
        setWebsiteName('');
        setWebsiteName('');
        setWebsiteType(0);
      }}
      handleConfirm={() => {
        props.handleConfirm({
          name: websiteName,
          domain: websiteDomain,
          type: websiteType,
        });
      }}
      customItem={websiteTypeItem}
      formList={formList}
    />
  );
}

function WhiteListCustomAddModal(props: ICustomModalProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadProps = {
    name: 'file',
    accept: '.xlsx',
    multiple: false,
    beforeUpload: (file: any) => {
      setFileList([file]);

      return false;
    },
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    fileList,
  };

  const uploadFileItem = (
    <div>
      <div className={`${s.formItem} ${s.textTop}`}>
        <div className={`${s.formTitle} ${s.setTitleWidth}`}>网站域名文件：</div>
        <div className={`${s.courseInput}`}>
          <Dragger {...uploadProps}>
            <div className={s.uploadText}>
              <img src={uploadIcon} alt="" />
              点击或将文件拖拽到这里上传
            </div>
            <div className={s.uploadTips}>（上传 xlsx 格式文件）</div>
          </Dragger>
        </div>
      </div>
      <div className={s.websiteTips}>
        <div className={s.downloadTemplate}>
          <img src={downloadIcon} alt="" />
          <a href="./templateFiles/白名单模板文件.xlsx">下载模版文件</a>
        </div>
        <div className={s.methodTips}>
          <img src={lookOverIcon} alt="" />
          点击
          <a
            href="https://zhuanlan.zhihu.com/p/590472843"
            target="_blank"
            rel="noreferrer"
          >
            查看域名文件创建方法
          </a>
        </div>
      </div>
    </div>
  );

  const disabled = fileList.length == 0;
  return (
    <div>
      <WebsiteModal
        visible={props.visible}
        disabled={disabled}
        hasError={props.hasError}
        errorMsg={props.errorMsg}
        onAlertClose={props.onAlertClose}
        title="添加需要的网站信息"
        handleCancel={props.handleCancel}
        handleConfirm={() => {
          props.handleConfirm({ file: fileList[0] });
        }}
        customItem={uploadFileItem}
      />
    </div>
  );
}

export {
  BlackListExceptionModal,
  BlackListCustomAddModal,
  SubmitWorkOrderModal,
  WhiteListCustomAddModal,
};
