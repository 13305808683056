import React from 'react';

import s from './QsButton.module.scss';

interface IQsQsButtonFixedSizeProps {
  size: 'normal' | 'middle' | 'small';
  type: 'primary' | 'primaryOutline';
  children: React.ReactElement;
  disabled?: boolean;
  handleClick?: () => void;
  htmlType?: 'submit' | 'button' | 'reset';
}

/*
组件说明：
1. 此按钮定宽定高，高度固定值：48px，宽度 3 种大小。
2. 3种宽度，normal：宽 450px，middle：350px，small: 200px。
3. 2种外形，primary：主题色背景，primaryOutline：主题色边框。
*/
function QsButtonFixedSize(props: IQsQsButtonFixedSizeProps) {
  return (
    <button
      type={props.htmlType || 'button'}
      className={`${s[window.location.pathname.replace('/', '')]} ${
        s[`fixedSize-${props.size}`]
      } ${
        props.disabled
          ? s[`fixedSize-${props.type}-disable`]
          : s[`fixedSize-${props.type}`]
      }`}
      onClick={() => {
        if (props.handleClick && !props.disabled) {
          props.handleClick();
        }
      }}
    >
      {props.children}
    </button>
  );
}

interface IQsMobileLoginButtonProps {
  type: 'primary' | 'primaryOutline';
  children: React.ReactElement;
  disabled?: boolean;
  handleClick?: () => void;
  htmlType?: 'submit' | 'button' | 'reset';
}

/*
组件说明：
1. 此按钮定宽定高，高度固定值：40px
*/
function QsButtonMobileLogin(props: IQsMobileLoginButtonProps) {
  return (
    <button
      type={props.htmlType || 'button'}
      className={`${s[`mobile-login-normal`]} ${
        props.disabled
          ? s[`mobile-login-${props.type}-disable`]
          : s[`mobile-login-${props.type}`]
      }`}
      onClick={() => {
        if (props.handleClick) {
          props.handleClick();
        }
      }}
    >
      {props.children}
    </button>
  );
}

interface IQsButtonProps {
  size: 'normal' | 'small';
  type: 'default' | 'primary' | 'outline' | 'primaryOutline' | 'outlinePrimary';
  children: React.ReactElement;
  disabled?: boolean;
  handleClick?: () => void;
  htmlType?: 'submit' | 'button' | 'reset';
}

/*
组件说明：
1. 此按钮固定内部文字上下左右间距。
2. 2种尺寸大小，normal，small。
3. 5种外形， default：灰色背景，primary：主题色背景，outline：灰色边框，primaryOutline：主题色边框，outlinePrimary：灰色边框，hover呈主题色边框
*/
function QsButton(props: IQsButtonProps) {
  return (
    <button
      type={props.htmlType || 'button'}
      className={`${props.disabled ? s[`${props.type}-disable`] : s[props.type]} ${
        s[`size-${props.size}`]
      }`}
      onClick={() => {
        if (props.handleClick) {
          props.handleClick();
        }
      }}
    >
      {props.children}
    </button>
  );
}

interface IQsButtonFixedHeightProps {
  children: React.ReactElement;
  type: 'outline' | 'primaryOutline';
  disabled?: boolean;
  handleClick?: () => void;
  htmlType?: 'submit' | 'button' | 'reset';
}

/*
组件说明：
1. 此按钮高度固定50px 宽度大小由外面的容器决定
3. 2种外形， outline：灰色边框，primaryOutline：主题色边框。
*/
function QsButtonFixedHeight(props: IQsButtonFixedHeightProps) {
  return (
    <button
      type={props.htmlType || 'button'}
      className={`${
        props.disabled
          ? s[`fixedHeight-${props.type}-disable`]
          : s[`fixedHeight-${props.type}`]
      }`}
      onClick={() => {
        if (props.handleClick) {
          props.handleClick();
        }
      }}
    >
      {props.children}
    </button>
  );
}

interface IQsTextButtonProps {
  children: React.ReactElement | string;
  type: 'primary' | 'secondary' | 'primary-sem';
  disabled?: boolean;
  handleClick?: (e: any) => void;
}

function QsTextButton(props: IQsTextButtonProps) {
  return (
    <div
      className={`${
        props.disabled ? s[`text-${props.type}-disable`] : s[`text-${props.type}`]
      }`}
      onClick={(e) => {
        if (props.handleClick && !props.disabled) {
          props.handleClick(e);
        }
      }}
    >
      {props.children}
    </div>
  );
}

export {
  QsButtonFixedSize,
  QsButton,
  QsButtonFixedHeight,
  QsTextButton,
  QsButtonMobileLogin,
};
