import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig, fetchPutConfig } from '../common';
import { GET_CLIENT_CONFIG, UPDATE_UNINSTALL_PWD } from './constants';

function getClientConfig() {
  return fetch('/services/tianxun/apiv2/clientConfig', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'client config fetch error' }));
}

export const getClientConfigAction = createActionAsync(
  GET_CLIENT_CONFIG,
  getClientConfig,
  defaultActionOption
);

export const getClientConfigReducer = createReducerAsync(getClientConfigAction);

function updateUninstallPwd(clientUninstallPasswd) {
  return fetch(
    '/services/tianxun/apiv2/clientConfig/clientUninstallPasswd',
    fetchPutConfig({ clientUninstallPasswd })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'update uninstall pwd error' }));
}

export const updateUninstallPwdAction = createActionAsync(
  UPDATE_UNINSTALL_PWD,
  updateUninstallPwd,
  defaultActionOption
);

export const updateUninstallPwdReducer = createReducerAsync(updateUninstallPwdAction);
