import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import { defaultActionOption, fetchGetConfig } from '../common';
import { PARENT_RELEASE_INFO } from './constants';

async function getParentReleaseInfo() {
  return fetch(`/api/mb/v1/releases/parentReleases`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getParentReleaseInfoAction = createActionAsync(
  PARENT_RELEASE_INFO,
  getParentReleaseInfo,
  defaultActionOption
);

export const getParentReleaseInfoReducer = createReducerAsync(getParentReleaseInfoAction);
