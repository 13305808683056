import React, { Component } from 'react';

import tipsImage from './images/tipsImage.svg';
import s from './MobileTips.module.scss';
import mobileLogo from '../../../../commonComponents/QsIcons/mobileLogo.svg';

class MobileTips extends Component {
  render() {
    return (
      <div>
        <div className={s.header}>
          <img src={mobileLogo} alt="mobileLogo" />
        </div>
        <div className={s.container}>
          <img src={tipsImage} alt="tipsImage" />
          <div className={s.content}>
            <div>目前 手机浏览器 暂不支持产品使用；</div>
            <div>
              请使用
              <span className={s.title}>电脑浏览器</span>
              访问青松守护官网并使用产品！
            </div>
          </div>
          <div>青松守护官网地址</div>
          <div className={s.address}>https://www.qscare.cn</div>
        </div>
      </div>
    );
  }
}

export default MobileTips;
