import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { Switch } from 'antd';

import QsTable from '../../../../commonComponents/QsTable/QsTable';
import s from './SoftwareContent.module.scss';
import SoftwareOperation from '../SoftwareOperation/SoftwareOperation';
import QsTag from '../../../../commonComponents/QsTag/QsTag';
import QsInput from '../../../../commonComponents/QsInput/QsInput';

export type TimeState = 'FullDisable' | 'PartialEnable' | 'FullEnable';

const columns = (type: 'pc' | 'mobile') => {
  return [
    {
      title: type === 'pc' ? '软件名称' : 'App名称',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      // eslint-disable-next-line react/display-name
      render: (record: any) => {
        return (
          <span className={s.name}>
            <div
              className={s.icon}
              style={{
                backgroundImage: `url(${record.icon})`,
              }}
            />
            <span>{record.value}</span>
          </span>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: '55%',
      render: (record: any) => {
        const timeRange =
          record.timeRange === '00:00 - 23:59' ? '00:00 - 24:00' : record.timeRange;
        const status: any = {
          FullEnable: <QsTag type="success" text="自由可用"></QsTag>,
          FullDisable: <QsTag type="error" text="完全禁用"></QsTag>,
          PartialEnable: <QsTag type="warn" text={`${timeRange} 可用`}></QsTag>,
        };

        return status[record.value];
      },
    },
  ];
};

interface IData {
  id: string;
  icon: string;
  name: string;
  status: 'FullDisable' | 'FullEnable' | 'PartialEnable' | string;
  timeRange: string;
}

interface IProps {
  data: IData[];
  handleOperation: (data: any) => Promise<void>;
  type: 'pc' | 'mobile';
  isHuawei?: boolean;
  switchName: string;
  switchDefaultValue: boolean;
  onSwitchChange: (checked: boolean) => void;
}

interface IStates {
  checkedItems: string[];
  showOperation: boolean;
  alertType: 'success' | 'error';
  alertMessage: string;
  status: TimeState | null;
  timeRange: string;
  data: IData[];
  searchValue: string;
}

class SoftwareContent extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checkedItems: [],
      showOperation: false,
      alertType: 'success',
      alertMessage: '',
      status: null,
      timeRange: '',
      data: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    this.setState({ data: [...this.props.data] });
  }

  componentDidUpdate(prevProps: IProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: [...this.props.data] });
    }
  }

  checkStatus = (checkedIds: string[]) => {
    if (checkedIds.length === 0) {
      this.setState({ status: null, timeRange: '00:00 - 23:59' });
      return;
    }
    const checkedData = this.props.data.filter((app: any) => checkedIds.includes(app.id));

    const fullEnable = checkedData.filter((app: any) => app.status === 'FullEnable');
    const fullDisable = checkedData.filter((app: any) => app.status === 'FullDisable');
    const partialEnable = checkedData.filter(
      (app: any) => app.status === 'PartialEnable'
    );

    if (fullEnable.length === checkedData.length) {
      this.setState({ status: 'FullEnable' });
    } else if (fullDisable.length === checkedData.length) {
      this.setState({ status: 'FullDisable' });
    } else if (partialEnable.length === checkedIds.length) {
      const timeRange: string[] = partialEnable.map((app: any) => {
        return app.timeRange;
      });
      // @ts-ignore
      const time = [...new Set(timeRange)];
      if (time.length === 1) {
        this.setState({ status: 'PartialEnable', timeRange: partialEnable[0].timeRange });
      } else {
        this.setState({ status: null, timeRange: '00:00 - 23:59' });
      }
    } else {
      this.setState({ status: null, timeRange: '00:00 - 23:59' });
    }
  };

  handleTableChange = (items: string[]) => {
    this.setState({ checkedItems: [...items], showOperation: items.length > 0 });
    this.checkStatus(items);
  };

  handlePcOperation = async (status: any, timeRange: Array<any>) => {
    const data = this.state.checkedItems.map((item) => {
      return {
        softwarename: item,
        applicationId: item,
        timeControlType: status,
        begin: '00:00',
        end: '23:59',
        timeRange,
      };
    });

    await this.props.handleOperation(data);
    this.setState({ timeRange: '00:00 - 23:59' });
    this.closeOperation();
  };

  handleMobileOperation = async (status: any, timeRange: Array<any>) => {
    const data = this.state.checkedItems.map((item) => {
      const itemResult: { [k: string]: any } = {
        applicationId: item,
        timeControlType: status,
      };

      if (timeRange.length > 0) {
        itemResult['timeRange'] = timeRange;
      }

      return itemResult;
    });
    await this.props.handleOperation(data);
    this.setState({ timeRange: '' });
    this.closeOperation();
  };

  closeOperation = () => {
    this.setState({
      showOperation: false,
      checkedItems: [],
      status: null,
    });
  };

  handleSearch = (value: string) => {
    let data;
    if (!!value) {
      data = this.props.data.filter((item) =>
        item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    } else {
      data = [...this.props.data];
    }
    this.setState({ data: [...data], searchValue: value });
  };

  mapData = (data: any[]) => {
    return data.map((item) => {
      return {
        key: item.id,
        checkbox: true,
        name: {
          value: item.name,
          icon: item.icon,
        },
        status: {
          value: item.status,
          timeRange: item.timeRange,
        },
      };
    });
  };

  SoftwareTable = () => {
    return (
      <div className={s.tableContent}>
        <div className={s.topContainer}>
          <div className={s.searchInput}>
            <QsInput
              type="search"
              placeholder={this.props.type === 'pc' ? '搜索软件名称' : '搜索App名称'}
              allowClear
              onSearch={this.handleSearch}
            />
          </div>
          <div className={s.switchContainer}>
            <span>{this.props.switchName}</span>
            <Switch
              size="small"
              className={s.switch}
              defaultChecked={this.props.switchDefaultValue}
              onChange={async (checked, e) => {
                e.preventDefault();
                this.props.onSwitchChange(checked);
              }}
            />
          </div>
        </div>
        <div className={s.tableContent}>
          {!this.props.switchDefaultValue && <div className={s.mask} />}
          <QsTable
            columns={columns(this.props.type)}
            dataSource={this.mapData(this.state.data)}
            checkbox
            handleChange={(checkedItems) => {
              this.setState({ checkedItems: [...checkedItems] });
              this.checkStatus(checkedItems);
            }}
            defaultCheckedItems={this.state.checkedItems}
            emptyMessage="搜索结果为空"
            searchValue={this.state.searchValue}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.SoftwareTable()}
        <SoftwareOperation
          timeRange={this.state.status !== 'PartialEnable' ? '' : this.state.timeRange}
          status={this.state.status}
          checkedCount={this.state.checkedItems.length}
          visible={this.state.checkedItems.length > 0}
          handleClose={this.closeOperation}
          handleChange={
            this.props.type === 'pc' ? this.handlePcOperation : this.handleMobileOperation
          }
        />
      </>
    );
  }
}

export default SoftwareContent;
