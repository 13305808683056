import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined, PlusOutlined, CaretUpOutlined } from '@ant-design/icons';

import s from './LearningMode.module.scss';
import websiteIcons from './websiteIcons/websiteIcons';
import blackListExceptionIcon from './images/blackListExceptionIcon.svg';
import allowIcon from './images/allowIcon.svg';
import forbiddenIcon from './images/forbiddenIcon.svg';
import editIcon from './images/editIcon.svg';
import deleteIcon from './images/deleteIcon.svg';
import lockIcon from '../TimeMgrNew/images/lockIcon.svg';
import {
  addBlackExceptionAction,
  addCustomBlackListAction,
  deleteBlackExceptionAction,
  deleteCustomBlackListAction,
  getBlackExceptionAction,
  getBlockSitesDetailsAction,
  getCustomBlackListAction,
  toggleCustomBlackListAction,
  toggleWebACLAction,
  updateBlackExceptionAction,
  updateCustomBlackListAction,
  updateDefaultWebsitesAction,
} from '../../../redux/webControls';
import {
  BlackListCustomAddModal,
  BlackListExceptionModal,
} from './WebsiteModal/WebsiteModal';
import {
  QsButtonFixedHeight,
  QsButtonFixedSize,
} from '../../../commonComponents/QsButton/QsButton';
import { QsConfirmModal } from '../../../commonComponents/QsModal/QsModal';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';

function computeSitesList(exceptions: any[], customs: any[], defaults: any[]) {
  const exceptionData = exceptions.map((item: any) => {
    return {
      key: item.uid,
      checkbox: false,
      name: {
        value: `${item.name} ${item.domain}`,
        icon: websiteIcons['custom'],
      },
      status: {
        key: item.uid,
        type: 'exception',
        forbidden: null,
        name: item.name || '',
        domain: item.domain || '',
      },
    };
  });

  const customData = customs.map((item: any) => {
    return {
      key: item.uid,
      checkbox: true,
      name: {
        value: `${item.name} ${item.domain}`,
        icon: websiteIcons['customblack'],
      },
      status: {
        key: item.uid,
        type: 'custom',
        forbidden: item.enable,
        name: item.name || '',
        domain: item.domain || '',
      },
    };
  });

  const defaultData = defaults.map((item: any) => {
    return {
      key: item.catagory,
      checkbox: true,
      name: {
        value: item.name,
        icon: websiteIcons[item.catagory],
      },
      status: {
        key: item.uid,
        type: 'default',
        forbidden: item.forbidden,
        name: item.name || '',
        domain: item.domain || '',
      },
    };
  });

  return [...exceptionData, ...customData, ...defaultData];
}

interface IProps {
  handleSwitchBtnClick: (enable: boolean) => void;
  getBlockSitesDetailsAction: () => void;
  getBlockSitesDetailsResult: any;
  getBlackExceptionAction: () => void;
  getBlackExceptionResult: any;
  getCustomBlackListAction: () => void;
  getCustomBlackListResult: any;
  addBlackExceptionAction: (name: string, domain: string) => void;
  addCustomBlackListAction: (name: string, domain: string) => void;
  updateDefaultWebsitesAction: (toggle: boolean, categories: string[]) => void;
  toggleCustomBlackListAction: (enable: boolean, uids: string[]) => void;
  updateBlackExceptionAction: (uid: string, name: string, domain: string) => void;
  updateCustomBlackListAction: (uid: string, name: string, domain: string) => void;
  deleteBlackExceptionAction: (uid: string) => void;
  deleteCustomBlackListAction: (uid: string) => void;
  toggleWebACLAction: () => any;
  toggleWebACLResult: any;
}

function BlackListMode(props: IProps) {
  const [showExceptionModal, setShowExceptionModal] = useState(false);
  const [showCustomAddModal, setShowCustomAddModal] = useState(false);
  const [exceptionModalType, setExceptionModalType] = useState<'add' | 'modify'>('add');
  const [customAddModalType, setCustomAddModalType] = useState<'add' | 'modify'>('add');
  const [currentItem, setCurrentItem] = useState<any>({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('添加成功');

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        props.getBlockSitesDetailsAction(),
        props.getBlackExceptionAction(),
        props.getCustomBlackListAction(),
        props.toggleWebACLAction(),
      ]);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWebsites = async (toggle: boolean, item: any) => {
    if (item.status.type == 'default') {
      await props.updateDefaultWebsitesAction(toggle, [item.key]);
      await props.getBlockSitesDetailsAction();
    } else {
      await props.toggleCustomBlackListAction(toggle, [item.key]);
      await props.getCustomBlackListAction();
    }
  };

  const handleExceptionModalConfirm = async (values: any) => {
    const { name, domain } = values;
    const newDomain = domain.replace(/^(?:https?:\/\/)?/i, '');
    if (exceptionModalType == 'add') {
      await props.addBlackExceptionAction(name, newDomain);
      setAlertMessage('添加成功');
    } else {
      await props.updateBlackExceptionAction(currentItem.key, name, domain);
      setAlertMessage('修改成功');
    }
    await props.getBlackExceptionAction();
    setShowAlert(true);
    setShowExceptionModal(false);
  };

  const handleCustomAddModalConfirm = async (values: any) => {
    const { name, domain } = values;
    const newDomain = domain.replace(/^(?:https?:\/\/)?/i, '');
    if (customAddModalType == 'add') {
      await props.addCustomBlackListAction(name, newDomain);
      setAlertMessage('添加成功');
    } else {
      await props.updateCustomBlackListAction(currentItem.key, name, domain);
      setAlertMessage('修改成功');
    }
    await props.getCustomBlackListAction();
    setShowAlert(true);
    setShowCustomAddModal(false);
  };

  const handleModify = (item: any) => {
    setCurrentItem(item);
    if (item.status.type == 'exception') {
      setExceptionModalType('modify');
      setShowExceptionModal(true);
    } else {
      setCustomAddModalType('modify');
      setShowCustomAddModal(true);
    }
  };

  const handleDelete = async (item: any) => {
    if (item.status.type == 'exception') {
      await props.deleteBlackExceptionAction(item.key);
      await props.getBlackExceptionAction();
    } else {
      await props.deleteCustomBlackListAction(item.key);
      await props.getCustomBlackListAction();
    }
  };

  const result = computeSitesList(
    props.getBlackExceptionResult?.exceptions || [],
    props.getCustomBlackListResult?.sites || [],
    props.getBlockSitesDetailsResult?.websites || []
  );
  const prevEnable = props.toggleWebACLResult.enable;
  const isOpen = prevEnable === 'true';

  return (
    <div className={s.rightContent}>
      <div className={s.rightTitle}>黑名单模式</div>
      <div className={`${s.greyBox} ${s.balckListContainer}`}>
        {prevEnable == 'true' && (
          <div className={s.disableMask}>
            <div>
              <div>
                <span className={s.lockIcon}>
                  <img src={lockIcon} alt="" />
                </span>
                黑名单模式生效中，暂不支持修改。
              </div>
              <div className={s.disableTips}>孩子的电脑不能访问黑名单中的网站。</div>
            </div>
          </div>
        )}
        <div className={s.operationContainer}>
          <QsButtonFixedHeight
            type="primaryOutline"
            handleClick={() => {
              setExceptionModalType('add');
              setCurrentItem({});
              setShowExceptionModal(true);
            }}
          >
            <>
              <PlusOutlined />
              添加黑名单例外网站
            </>
          </QsButtonFixedHeight>
          <QsButtonFixedHeight
            type="outline"
            handleClick={() => {
              setCustomAddModalType('add');
              setCurrentItem({});
              setShowCustomAddModal(true);
            }}
          >
            <>
              <PlusOutlined />
              添加自定义黑名单网站
            </>
          </QsButtonFixedHeight>
        </div>
        <div className={s.blackListTitle}>
          <div>网站类别</div>
          <div>状态</div>
        </div>
        <div className={s.blackListItems}>
          {result.map((item) => (
            <BlackListItem
              key={item.key}
              icon={item.name.icon}
              name={item.name.value}
              type={item.status.type}
              forbidden={item.status.forbidden}
              updateWebsites={async (toggle: boolean) => {
                updateWebsites(toggle, item);
              }}
              handleModify={() => {
                handleModify(item);
              }}
              handleDelete={() => {
                handleDelete(item);
              }}
            />
          ))}
        </div>
      </div>
      <div className={s.confirmBtn}>
        <QsButtonFixedSize
          size="normal"
          type={isOpen ? 'primaryOutline' : 'primary'}
          handleClick={() => {
            props.handleSwitchBtnClick(!isOpen);
          }}
        >
          <span>{isOpen ? '关闭黑名单模式' : '开启黑名单模式'}</span>
        </QsButtonFixedSize>
      </div>
      <QsAlert
        visible={showAlert}
        type="success"
        message={alertMessage}
        position="absolute"
        onClose={() => {
          // props.resetUpdateLockScreenAction();
          setShowAlert(false);
        }}
      />
      <BlackListExceptionModal
        visible={showExceptionModal}
        type={exceptionModalType}
        defaultName={currentItem.status?.name}
        defaultDomain={currentItem.status?.domain}
        handleCancel={() => {
          setShowExceptionModal(false);
        }}
        handleConfirm={handleExceptionModalConfirm}
      />
      <BlackListCustomAddModal
        visible={showCustomAddModal}
        type={customAddModalType}
        defaultName={currentItem.status?.name}
        defaultDomain={currentItem.status?.domain}
        handleCancel={() => {
          setShowCustomAddModal(false);
        }}
        handleConfirm={handleCustomAddModalConfirm}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getBlockSitesDetailsResult: state.getBlockSitesDetailsResult.data,
    getBlackExceptionResult: state.getBlackExceptionResult.data,
    getCustomBlackListResult: state.getCustomBlackListResult.data,
    toggleWebACLResult: state.toggleWebACLResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    toggleWebACLAction: bindActionCreators(toggleWebACLAction, dispatch),
    getBlockSitesDetailsAction: bindActionCreators(getBlockSitesDetailsAction, dispatch),
    getBlackExceptionAction: bindActionCreators(getBlackExceptionAction, dispatch),
    getCustomBlackListAction: bindActionCreators(getCustomBlackListAction, dispatch),
    addBlackExceptionAction: bindActionCreators(addBlackExceptionAction, dispatch),
    addCustomBlackListAction: bindActionCreators(addCustomBlackListAction, dispatch),
    toggleCustomBlackListAction: bindActionCreators(
      toggleCustomBlackListAction,
      dispatch
    ),
    updateDefaultWebsitesAction: bindActionCreators(
      updateDefaultWebsitesAction,
      dispatch
    ),
    updateBlackExceptionAction: bindActionCreators(updateBlackExceptionAction, dispatch),
    updateCustomBlackListAction: bindActionCreators(
      updateCustomBlackListAction,
      dispatch
    ),
    deleteBlackExceptionAction: bindActionCreators(deleteBlackExceptionAction, dispatch),
    deleteCustomBlackListAction: bindActionCreators(
      deleteCustomBlackListAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlackListMode);

interface IStatusPopoverProps {
  defaultValue: string;
  handleChange: (toggle: boolean) => void;
}

function StatusPopover(props: IStatusPopoverProps) {
  const [selected, setSelected] = useState(props.defaultValue);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setSelected('自由访问');
          setDropdownVisible(false);
          props.handleChange(false);
        }}
        className={s.allowItem}
      >
        <span className={s.menuItem}>
          <img src={allowIcon} alt="" />
          自由访问
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setSelected('禁止访问');
          setDropdownVisible(false);
          props.handleChange(true);
        }}
        className={s.forbiddenItem}
      >
        <span className={s.menuItem}>
          <img src={forbiddenIcon} alt="" />
          禁止访问
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      onOpenChange={(visible: boolean) => {
        setDropdownVisible(visible);
      }}
    >
      <span className={selected == '自由访问' ? s.allow : s.forbidden}>
        <img src={selected == '自由访问' ? allowIcon : forbiddenIcon} alt="" />
        {selected}
        {dropdownVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </span>
    </Dropdown>
  );
}

interface IBlackListItemProps {
  icon: string;
  name: string;
  type: string;
  forbidden: boolean;
  updateWebsites: (toggle: boolean) => void;
  handleModify: () => void;
  handleDelete: () => void;
}

function BlackListItem(props: IBlackListItemProps) {
  const [isHover, setIsHover] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const mapStatus = (type: string, forbidden: boolean) => {
    if (type == 'exception') {
      return (
        <span className={s.exception}>
          <img src={blackListExceptionIcon} alt="" /> 例外
        </span>
      );
    }

    if (forbidden) {
      return (
        <StatusPopover
          defaultValue="禁止访问"
          handleChange={(toggle: boolean) => {
            props.updateWebsites(toggle);
          }}
        />
      );
    } else {
      return (
        <StatusPopover
          defaultValue="自由访问"
          handleChange={(toggle: boolean) => {
            props.updateWebsites(toggle);
          }}
        />
      );
    }
  };

  return (
    <div
      className={s.blackList}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <div>
        <span className={s.iconContainer}>
          <img src={props.icon} alt="" />
        </span>
        <span className={s.blackListName}>{props.name}</span>
      </div>
      <div>
        {mapStatus(props.type, props.forbidden)}
        {props.type !== 'default' && isHover && (
          <span>
            <img src={editIcon} alt="" onClick={props.handleModify} />
            <img
              src={deleteIcon}
              alt=""
              className={s.deleteIcon}
              onClick={() => {
                setShowDeleteModal(true);
              }}
            />
          </span>
        )}
      </div>
      <QsConfirmModal
        visible={showDeleteModal}
        title={
          props.type == 'exception'
            ? '是否删除该黑名单例外网站？'
            : '是否删除该自定义黑名单网站？'
        }
        onCancel={() => {
          setShowDeleteModal(false);
          setIsHover(false);
        }}
        onOk={() => {
          props.handleDelete();
          setShowDeleteModal(false);
          setIsHover(false);
        }}
      />
    </div>
  );
}
