import React, { useEffect } from 'react';

import FormLayout from '../../components/FormLayout/FormLayout';
import { isMobile } from '../../../common';
import MobileFormLayout from '../../mobile/components/MobileFormLayout/MobileFormLayout';
import LoginForm from '../../components/LoginForm/LoginForm';
import { isZGYD } from '../../../utils/lib';

function Login() {
  useEffect(() => {
    document.title = !!isZGYD()
      ? '登录 - 使用手机号登录 - 使用手机'
      : '登录 - 使用手机号登录 - 使用手机 - 青松守护';
  });

  if (isMobile()) {
    return (
      <MobileFormLayout title="注册账号">
        <LoginForm isModal={false} />
      </MobileFormLayout>
    );
  }
  return (
    <FormLayout title="登录账号">
      <LoginForm isModal={false} />
    </FormLayout>
  );
}

export default Login;
