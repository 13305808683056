import React, { Component } from 'react';

import s from './QsCheckbox.module.scss';

interface IProps {
  children?: any;
  theme: 'yellow' | 'blue' | 'green';
  onChange: (checked: boolean) => void;
  checked?: boolean;
  size?: 'default' | 'large' | 'small';
}

interface IStates {
  checked: boolean | undefined;
}

class QsCheckbox extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: this.props.checked,
    };
  }

  componentDidUpdate(prevProps: IProps): void {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleClick = (e: any) => {
    this.setState({ checked: e.target.checked });
    this.props.onChange(e.target.checked);
  };

  handleChange = (e: any) => {
    console.log('---on change', e);
  };

  render() {
    return (
      <label className={`${s.container} ${s[`${this.props.size}-container`]}`}>
        {this.props.children}
        <input
          type="checkbox"
          onClick={this.handleClick}
          checked={this.state.checked}
          onChange={this.handleChange}
        />
        <span className={`${s[this.props.theme]} ${s[`${this.props.size}-box`]}`} />
      </label>
    );
  }
}

export default QsCheckbox;
