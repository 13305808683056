import React from 'react';
import { Popover } from 'antd';
import descIcon from './images/descIcon.svg';
import s from './GroupPurchaseDescPopover.module.scss';

function GroupPurchaseDescPopover() {
  const content = (
    <div className={s.content}>
      购买完成后，您将获得 3
      份年付会员激活码，您可在“账号信息-会员信息-会员激活码”处兑换，激活码长期有效。
    </div>
  );
  return (
    <Popover
      content={content}
      title="使用说明"
      trigger="hover"
      placement="top"
      overlayClassName={s.popover}
      overlayInnerStyle={{ padding: 0 }}
    >
      <img src={descIcon} alt="" className={s.descIcon} />
    </Popover>
  );
}

export default GroupPurchaseDescPopover;
