import React from 'react';
import s from './QsTooltip.module.scss';

interface IProps {
  message: string;
}

export default function QsTooltip(props: IProps) {
  return (
    <>
      <div id="tooltip" className={`${s.tooltip} ${s.top}`}>
        <div className={s.tooltipArrow} />
        <div className={s.tooltipLabel}>{props.message}</div>
      </div>
    </>
  );
}
