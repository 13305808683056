import React, { useState } from 'react';
import { MdInfo, MdHelp, MdClose } from 'react-icons/md';
import { QsButton, QsButtonFixedSize } from '../QsButton/QsButton';
import Modal from './Modal';
import closeIcon from './image/closeIcon.svg';

import s from './QsModal.module.scss';

const AIMATION_DELAY_TIME = 400;

interface IQsModalBaiscProps {
  visible: boolean;
  closable?: boolean;
  onCancel?: () => void;
  children: React.ReactElement;
  triggerClose?: boolean;
}

function QsModalBaisc(props: IQsModalBaiscProps) {
  const [triggerClose, setTriggerClose] = useState(false);

  if (props.visible || triggerClose || props.triggerClose) {
    return (
      <Modal>
        <div className={s.container}>
          <div className={props.visible ? s.showContent : s.hideContent}>
            <div className={s.modalBasiccontent}>
              {props.closable && (
                <div
                  className={s.closeIconContainer}
                  onClick={() => {
                    props.onCancel?.();
                    // 关闭时的延时动画
                    setTriggerClose(true);
                    setTimeout(() => {
                      setTriggerClose(false);
                    }, AIMATION_DELAY_TIME);
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
              )}
              {props.children}
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}

interface IQsInfoModalProps {
  visible: boolean;
  text: string;
  onOk?: () => void;
  btnText?: string;
}

function QsInfoModal(props: IQsInfoModalProps) {
  const [triggerClose, setTriggerClose] = useState(false);
  const delayClose = () => {
    // 关闭时的延时动画
    setTriggerClose(true);
    setTimeout(() => {
      setTriggerClose(false);
    }, AIMATION_DELAY_TIME);
  };
  return (
    <QsModalBaisc visible={props.visible} closable={false} triggerClose={triggerClose}>
      <div className={s.infoModalContent}>
        <div className={s.infoModalText}>
          <MdInfo />
          {props.text}
        </div>
        <div className={s.btnContainer}>
          <QsButton
            type="primary"
            size="normal"
            handleClick={() => {
              props.onOk?.();
              delayClose();
            }}
          >
            <span>{props.btnText ?? '我知道了'}</span>
          </QsButton>
        </div>
      </div>
    </QsModalBaisc>
  );
}

interface IQsConfirmModalProps {
  visible: boolean;
  title: string;
  desc?: string;
  onOk?: () => void;
  onCancel?: () => void;
  okBtnText?: string;
  cancelBtnText?: string;
}

function QsConfirmModal(props: IQsConfirmModalProps) {
  const [triggerClose, setTriggerClose] = useState(false);
  const delayClose = () => {
    // 关闭时的延时动画
    setTriggerClose(true);
    setTimeout(() => {
      setTriggerClose(false);
    }, AIMATION_DELAY_TIME);
  };
  return (
    <QsModalBaisc visible={props.visible} closable={false} triggerClose={triggerClose}>
      <div className={s.infoModalContent}>
        <div className={s.confirmModalTitle}>
          <MdHelp />
          {props.title}
        </div>
        {props.desc && <div className={s.desc}>{props.desc}</div>}
        <div className={s.btnContainer}>
          <QsButton
            type="primary"
            size="normal"
            handleClick={() => {
              props.onOk?.();
              delayClose();
            }}
          >
            <span>{props.okBtnText ?? '确定'}</span>
          </QsButton>
          <QsButton
            type="outline"
            size="normal"
            handleClick={() => {
              props.onCancel?.();
            }}
          >
            <span>{props.cancelBtnText ?? '取消'}</span>
          </QsButton>
        </div>
      </div>
    </QsModalBaisc>
  );
}

interface IQsNormalModalProps {
  visible: boolean;
  title: string;
  children: React.ReactElement;
  onOk?: () => void;
  onCancel?: () => void;
  okBtnText?: string;
  cancelBtnText?: string;
  customFooter?: React.ReactElement | null;
  okBtnDisable?: boolean;
  width?: number;
  okBtnHtmlType?: 'submit' | 'button' | 'reset';
  conentStyle?: any;
}

function QsNormalModal(props: IQsNormalModalProps) {
  const [triggerClose, setTriggerClose] = useState(false);
  const delayClose = () => {
    // 关闭时的延时动画
    setTriggerClose(true);
    setTimeout(() => {
      setTriggerClose(false);
    }, AIMATION_DELAY_TIME);
  };
  return (
    <QsModalBaisc visible={props.visible} closable={false} triggerClose={triggerClose}>
      <div className={s.normalContent} style={{ width: `${props.width}px` }}>
        <div className={s.normalTitle}>
          <span>{props.title}</span>
          <MdClose
            onClick={() => {
              props.onCancel?.();
              delayClose();
            }}
          />
        </div>
        <div className={s.normalInfo} style={props.conentStyle}>
          {props.children}
        </div>
        {props.customFooter || props.customFooter === null ? (
          props.customFooter
        ) : (
          <div className={s.normalBtnContainer}>
            <QsButton
              type="primary"
              size="normal"
              htmlType={props.okBtnHtmlType}
              disabled={props.okBtnDisable}
              handleClick={() => {
                if (props.okBtnDisable) return;
                props.onOk?.();
                delayClose();
              }}
            >
              <span>{props.okBtnText ?? '确定'}</span>
            </QsButton>
            <QsButton
              type="outline"
              size="normal"
              handleClick={() => {
                props.onCancel?.();
                delayClose();
              }}
            >
              <span>{props.cancelBtnText ?? '取消'}</span>
            </QsButton>
          </div>
        )}
      </div>
    </QsModalBaisc>
  );
}

interface IQsSpecialModalProps {
  visible: boolean;
  title: string;
  children: React.ReactElement;
  onOk?: () => void;
  onCancel?: () => void;
  okBtnText?: string;
  cancelBtnText?: string;
  width?: number;
  customFooter?: React.ReactElement | null;
  okBtnDisable?: boolean;
}

function QsSpecialModal(props: IQsSpecialModalProps) {
  const [triggerClose, setTriggerClose] = useState(false);
  const delayClose = () => {
    // 关闭时的延时动画
    setTriggerClose(true);
    setTimeout(() => {
      setTriggerClose(false);
    }, AIMATION_DELAY_TIME);
  };
  return (
    <QsModalBaisc
      visible={props.visible}
      onCancel={props.onCancel}
      closable={true}
      triggerClose={triggerClose}
    >
      <div
        className={s.specialContent}
        style={{ width: props.width ? `${props.width}px` : '620px' }}
      >
        <div className={s.specialTitle}>{props.title}</div>
        <div className={s.specialInfo}>{props.children}</div>
        {props.customFooter || props.customFooter === null ? (
          props.customFooter
        ) : (
          <div className={s.specialBtnContainer}>
            <QsButtonFixedSize
              type="primary"
              size="middle"
              disabled={props.okBtnDisable}
              handleClick={() => {
                if (props.okBtnDisable) return;
                props.onOk?.();
                delayClose();
              }}
            >
              <span>{props.okBtnText ?? '确定'}</span>
            </QsButtonFixedSize>
          </div>
        )}
      </div>
    </QsModalBaisc>
  );
}

export { QsModalBaisc, QsInfoModal, QsConfirmModal, QsNormalModal, QsSpecialModal };
