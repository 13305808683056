import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import { defaultActionOption, fetchGetConfig } from '../common';
import { GET_PC_REMOTE_INFO } from './constants';
import { getOrgInfo } from './user';

async function getPcRemoteInfo() {
  const orgInfo = await getOrgInfo();
  return fetch(
    '/services/tianxun/apiv2/RemoteDesktop/ConnectionInfo',
    fetchGetConfig(orgInfo.org.org_id)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getPcRemoteInfoAction = createActionAsync(
  GET_PC_REMOTE_INFO,
  getPcRemoteInfo,
  defaultActionOption
);

export const getPcRemoteInfoReducer = createReducerAsync(getPcRemoteInfoAction);
