import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchPostConfig,
  fetchPatchConfig,
  fetchDelConfig,
} from '../common';
import {
  COMMUNICATION_INFO,
  UPDATE_COMMUNICATION_POLICY_SWITCH,
  UPDATE_PHONE_CALL_SWITCH,
  ADD_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  ONE_KEY_BLOCK_SWITCH,
} from './constants';

async function communicationInfo(mobileId) {
  return fetch(`/api/mb/v1/communicationControls/find`, fetchPostConfig({ mobileId }))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const communicationInfoAction = createActionAsync(
  COMMUNICATION_INFO,
  communicationInfo,
  defaultActionOption
);

export const communicationInfoReducer = createReducerAsync(communicationInfoAction);

async function updateCommunicationPolicySwitch(enable, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}`,
    fetchPatchConfig({ enable })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateCommunicationPolicySwitchAction = createActionAsync(
  UPDATE_COMMUNICATION_POLICY_SWITCH,
  updateCommunicationPolicySwitch,
  defaultActionOption
);

export const updateCommunicationPolicySwitchReducer = createReducerAsync(
  updateCommunicationPolicySwitchAction
);

async function updatePhoneCallSwitch(phoneCallControlEnable, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}`,
    fetchPatchConfig({ phoneCallControlEnable })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updatePhoneCallSwitchAction = createActionAsync(
  UPDATE_PHONE_CALL_SWITCH,
  updatePhoneCallSwitch,
  defaultActionOption
);

export const updatePhoneCallSwitchReducer = createReducerAsync(
  updatePhoneCallSwitchAction
);

async function addContact(data, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}/contacts`,
    fetchPostConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const addContactAction = createActionAsync(
  ADD_CONTACT,
  addContact,
  defaultActionOption
);

export const addContactReducer = createReducerAsync(addContactAction);

async function deleteContact(contactId, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}/contacts/${contactId}`,
    fetchDelConfig({})
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const deleteContactAction = createActionAsync(
  DELETE_CONTACT,
  deleteContact,
  defaultActionOption
);

export const deleteContactReducer = createReducerAsync(deleteContactAction);

async function updateContact(data, contactId, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}/contacts/${contactId}`,
    fetchPatchConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateContactAction = createActionAsync(
  UPDATE_CONTACT,
  updateContact,
  defaultActionOption
);

export const updateContactReducer = createReducerAsync(updateContactAction);

async function oneKeyBlockSwitch(oneKeyBlockEnable, communicationControlId) {
  return fetch(
    `/api/mb/v1/communicationControls/${communicationControlId}`,
    fetchPatchConfig({ oneKeyBlockEnable })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const oneKeyBlockSwitchAction = createActionAsync(
  ONE_KEY_BLOCK_SWITCH,
  oneKeyBlockSwitch,
  defaultActionOption
);

export const oneKeyBlockSwitchReducer = createReducerAsync(oneKeyBlockSwitchAction);
