import React, { Component } from 'react';
import s from './Sem.module.scss';
import Register from './Register';
import logo from './images/logo.svg';
import qrcode from './images/qrcode.jpg';
import one from './images/one.svg';
import two from './images/two.svg';
import three from './images/three.svg';
import four from './images/four.svg';
import { advantageInfo } from './constant';
import FunctionInfo from '../../components/FunctionInfo/FunctionInfo';
import androidIcon from './images/androidIcon.svg';
import pcIcon from './images/pcIcon.svg';

interface IAdvantageInfo {
  img: string;
  title: string;
  describe: string;
}

interface IState {
  showRegisterBottom: boolean;
}

const featureList = [
  {
    name: '多设备管理难，孩子选择“多样化”',
    title: '多端管控，家长“青松”无忧',
    src: one,
    theme: 'blue',
  },
  {
    name: '使用电子设备学习易受干扰',
    title: '一键禁用娱乐软件，净化学习环境',
    src: two,
    theme: 'yellow',
  },
  {
    name: '孩子沉迷娱乐软件，身心受损',
    title: '约定设备使用时长，科学防沉迷',
    src: three,
    theme: 'green',
  },
  {
    name: '孩子遇难题，不在身边难解决',
    title: '家长远程解题，与孩子共同成长',
    src: four,
    theme: 'blue',
  },
];

export class Sem extends Component<Record<string, never>, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showRegisterBottom: false,
    };
  }
  componentDidMount() {
    document.title = '青松守护 - 让孩子更健康地使用网络 - 培养孩子健康上网习惯';
  }

  render() {
    return (
      <div>
        <div className={s.banner}>
          <header>
            <p>
              <span>启明星辰</span>
              <span>（股票代码：002439）</span>旗下品牌
            </p>
          </header>
          <div className={s.context}>
            <div className={s.register}>
              <div className={s.header}>
                <span>
                  注册即送<span className={s.free}>3</span>天会员
                </span>
              </div>
              <Register
                formName="registerForTop"
                layout="horizontal"
                shape="round"
                btnText="立即注册"
              />
            </div>
            <div className={s.platformBox}>
              <span>多平台支持：</span>
              <img src={pcIcon} alt="" /> <span className={s.textStyle}>电脑端</span>
              <img src={androidIcon} alt="" /> <span>移动端（手机、Pad）</span>
            </div>
          </div>
        </div>

        <div>
          <h3>我们可以为您的孩子做什么？</h3>
          <div className={s.featureContainer}>
            {featureList.map((item: any) => {
              return (
                <div key={item.name} className={`${s.feature} ${s[`${item.theme}`]} `}>
                  <img src={item.src} alt="" />
                  <div>
                    <p className={s.name}>{item.name}</p>
                    <p className={s.border}></p>
                    <p className={s.title}>{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.sem}>
          <div className={s.functions}>
            <h3>同时管理手机和电脑，提高学习效率</h3>
            <FunctionInfo hideBtn={true} />
          </div>
          <div>
            <h3>我们的优势</h3>
            <div className={s.advantage}>
              {advantageInfo.map((item: IAdvantageInfo, index: number) => (
                <div key={index}>
                  <img src={item.img} alt={item.title} />
                  <div>
                    <p className={s.title}>{item.title}</p>
                    <p>{item.describe}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer>
          <div className={s.footer}>
            <div>
              <img src={logo} alt="青松守护" />
              <p>南京川陀大匠信息技术有限公司</p>
            </div>
            <div>
              <ul>
                <li>电话：173-6872-8379</li>
                <li>微信：QingSongShouHu</li>
                <li>地址：南京建邺区新城科技园创智路1号北纬国际中心A栋15层A座</li>
              </ul>
            </div>

            <div>
              <img src={qrcode} alt="二维码" />
              <p>微信扫码关注公众号</p>
              <p>获取更多福利资讯</p>
            </div>
          </div>
        </footer>
        {this.state.showRegisterBottom ? (
          <div className={s.fixedToBottom}>
            <p>
              限时<span className={s.bottomFree}>免费</span>
              ，立即注册,「青松」管理孩子网络！
            </p>
            <Register
              formName="registerForBottom"
              layout="inline"
              btnClassName={s.submit}
              btnText="立即注册"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Sem;
