import React, { Component } from 'react';
import { isUndefined } from 'lodash';

import s from './BehaviorItem.module.scss';
import webIcon from './images/webIcon.svg';
import appIcon from '../../../commonComponents/QsIcons/appIcon.svg';
import mobileAppIcon from './images/mobileAppIcon.svg';
import { Tooltip } from 'antd';
import variables from '../../../variables.module.scss';

enum DataType {
  Web = 'web',
  Application = 'application',
  MobileApp = 'mobileApp',
}

interface IProps {
  time: string;
  name: string;
  url?: string;
  showLine: boolean;
  type: DataType.Web | DataType.Application | DataType.MobileApp;
  iconSrc: undefined | string;
}

interface IStates {
  showFullUrl: boolean;
}

class BehaviorItem extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showFullUrl: false,
    };
  }

  render() {
    const icon = {
      web: webIcon,
      application: appIcon,
      mobileApp: mobileAppIcon,
    };
    const name =
      this.props.type === DataType.Application || this.props.type === DataType.MobileApp
        ? `打开了 ${this.props.name}`
        : this.props.name;

    const { iconSrc } = this.props;

    const iconUrl = isUndefined(iconSrc) ? icon[this.props.type] : iconSrc;
    return (
      <div className={s.container}>
        <div className={s.flexBox}>
          <div className={s.line} />
          <div className={s.icon} style={{ backgroundImage: `url(${iconUrl})` }}></div>
          <div className={s.info}>
            <div className={s.time}>{this.props.time}</div>
            <div className={s.name}>{name}</div>
          </div>
        </div>
        {this.props.type === DataType.Web && (
          <div className={s.urlContainer}>
            <div className={s.url} color={variables.colorMasterC7}>
              <Tooltip placement="bottom" title={this.props.url}>
                <span>{this.props.url}</span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BehaviorItem;
