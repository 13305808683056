import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import s from './OperationToolbar.module.scss';

interface IOperationItemProps {
  theme: 'green' | 'red' | 'yellow';
  content: string | React.ReactElement;
  handleClick: () => void;
  id: string;
  currentId: any;
}

export function OperationItem(props: IOperationItemProps) {
  const theme = {
    green: s.greenChecked,
    red: s.redChecked,
    yellow: s.yellowChecked,
  };

  return (
    <div
      className={`${s.operation} ${
        props.id === props.currentId ? theme[props.theme] : ''
      }`}
      onClick={props.handleClick}
    >
      {props.content}
    </div>
  );
}

interface IOperationToolbarProps {
  children: React.ReactElement;
  checkedCount: number;
  handleClose: () => void;
}

function OperationToolbar(props: IOperationToolbarProps) {
  return (
    <div className={s.outer}>
      <div className={s.container}>
        <AiOutlineClose className={s.closeIcon} onClick={props.handleClose} />
        <div className={s.checked}>
          <span>{props.checkedCount}</span>
          条已选中
        </div>
        <div className={s.operations}>{props.children}</div>
      </div>
    </div>
  );
}

export default OperationToolbar;
