import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BehaviorItem from '../../../components/BehaviorItem/BehaviorItem';
import { getBehaviorListAction } from '../../../../redux/behavior';
import s from './BehaviorOverview.module.scss';
import behaviorEmptyIcon from '../images/behaviorEmptyIcon.svg';
import expandIcon from '../../../apps/Behavior/images/expandIcon.svg';
import { Link } from 'react-router-dom';

interface IProps {
  currentDevice?: 'pc' | 'mobile';
  getBehaviorListAction: (time: number, refresh: boolean) => void;
  getBehaviorListResult: any;
}

interface IState {
  behaviorList: any;
  showMask: boolean;
}

class BehaviorOverview extends Component<IProps, IState> {
  static readonly FORMAT_DATE = 'YYYY-MM-DD';
  behaviorDomRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      behaviorList: [],
      showMask: false,
    };
    this.behaviorDomRef = React.createRef();
  }

  async componentDidMount() {
    await this.props.getBehaviorListAction(this.getDate(0), true);
    const todayDataItem = this.props.getBehaviorListResult.result?.data || [];

    if (
      !!todayDataItem &&
      todayDataItem.length > 0 &&
      todayDataItem[0].items.length >= 8
    ) {
      this.setState({
        behaviorList: todayDataItem,
      });
    } else {
      for (let i = 1; i < 7; i++) {
        let recordLength = 0;
        await this.props.getBehaviorListAction(this.getDate(i), false);

        const addedItems = this.props.getBehaviorListResult.result?.data || [];
        if (addedItems.length > 0) {
          addedItems.map((item: any) => {
            recordLength = recordLength + item.items.length;
            return recordLength;
          });
          if (recordLength >= 10) {
            this.setState({
              behaviorList: addedItems,
            });
            break;
          }
        }

        if (i === 6) {
          this.setState({
            behaviorList: addedItems,
          });
        }
      }
    }

    const visibleContent = this.behaviorDomRef?.current?.clientHeight;
    if (visibleContent && visibleContent > window.innerHeight - 309) {
      this.setState({
        showMask: true,
      });
    }
  }

  getDate = (day: number) => {
    return moment(
      moment().subtract(day, 'd').format(BehaviorOverview.FORMAT_DATE)
    ).unix();
  };

  getData = () => {
    const pcData = this.state.behaviorList
      .map((item: any) => {
        return { ...item, items: item.items.filter((i: any) => i.type !== 'mobileApp') };
      })
      .filter((item: any) => item.items.length > 0);
    const mobileData = this.state.behaviorList
      .map((item: any) => {
        return { ...item, items: item.items.filter((i: any) => i.type === 'mobileApp') };
      })
      .filter((item: any) => item.items.length > 0);
    return this.props.currentDevice === 'pc' ? pcData : mobileData;
  };

  renderRecord = () => {
    return this.getData().map((data: any) => {
      if (data.items.length === 0) {
        return;
      }
      return (
        <div key={data.date}>
          <div className={s.dataPanel}>
            <div className={s.date}>{data.date}</div>
            {data.items.map((item: any, index: number) => {
              return (
                <BehaviorItem
                  key={index + 1}
                  time={item.time}
                  name={item.name}
                  url={item.url}
                  type={item.type}
                  showLine={index !== data.items.length - 1}
                  iconSrc={item.icon?.url}
                />
              );
            })}
          </div>
        </div>
      );
    });
  };

  behaviorHistory = () => {
    const data = this.getData();
    return (
      <div className={s.behaviorContainer}>
        <div className={s.behaviorTitle}>行为记录</div>
        {data.length <= 0 ? (
          <div className={s.behaviorEmpty}>
            <img src={behaviorEmptyIcon} alt="" />
            <span>暂无行为记录</span>
          </div>
        ) : (
          <div className={s.behaviorContent}>{this.renderRecord()}</div>
        )}
        {data.length > 0 && (
          <div className={s.behaviorMask}>
            <Link
              to={`/behavior?type=${this.props.currentDevice}`}
              className={s.behaviorLink}
            >
              查看更多详情
              <img src={expandIcon} alt="" />
            </Link>
          </div>
        )}
      </div>
    );
  };

  render() {
    return this.behaviorHistory();
  }
}

function mapStateToProps(state: any) {
  return {
    getBehaviorListResult: state.getBehaviorListResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getBehaviorListAction: bindActionCreators(getBehaviorListAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BehaviorOverview);
