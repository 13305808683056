import React from 'react';
import s from './OnlineService.module.scss';
import qrCode from './qrCode.jpg';

function OnlineServiceMgr() {
  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        <div className={s.title}>青松守护客服</div>
        <div className={s.time}>（服务时间：工作日 10:00 - 17:30）</div>
      </div>
      <div className={s.qrCodeContainer}>
        <img src={qrCode} alt="" />
        <div>扫描该二维码</div>
        <div>添加「青松守护客服」微信</div>
      </div>

      <div className={s.contactContainer}>
        <div className={s.title}>官方客服微信号：</div>
        <div className={s.phone}>QingSongShouHu</div>
      </div>
    </div>
  );
}

export default OnlineServiceMgr;
