import React, { Component } from 'react';

import s from './Policy.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import policyInfo from './policyInfo';
import MobileHeader from '../../mobile/components/MobileHeader/MobileHeader';
import MobileFooter from '../../mobile/components/MobileFooter/MobileFooter';
import { isMobile } from '../../../common';
import CompleteInfoModal from '../../components/CompleteInfoModal/CompleteInfoModal';
import { isZGYD } from '../../../utils/lib';

class Policy extends Component {
  componentDidMount() {
    document.title = !!isZGYD() ? '隐私政策' : '隐私政策 - 青松守护';
  }

  render() {
    const productName = '青松守护';

    return (
      <div>
        {isMobile() ? <MobileHeader /> : <Header />}
        <div className={isMobile() ? s.mobileContainer : s.container}>
          <div className={`${s.header} ${isMobile() ? s.mobileHeader : ''}`}>
            隐私政策
          </div>
          <div className={s.time}>更新日期：2023年3月16日</div>
          <div className={s.time}>生效日期：2023年3月16日</div>
          <div className={s.mgb20}>序言</div>
          <div className={s.mgb20}>
            {productName}（包含{productName}家长端，{productName}孩子端，以下简称“
            {productName}
            ”或“本款产品”）是一款基于安卓系统开发的软件。
            {productName}
            由南京川陀大匠信息技术有限公司（以下简称“我们”、“川陀大匠”或“公司”）提供。感谢您对川陀大匠产品和服务的使用和信任！我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
          </div>
          <div className={s.mgb20}>本隐私政策将帮助您了解以下内容：</div>
          <div>一、我们如何收集和存储您的个人信息</div>
          <div>二、我们如何使用您的个人信息</div>
          <div>三、我们如何共享、转让、公开披露您的个人信息</div>
          <div>四、我们如何保护您的个人信息</div>
          <div>五、您管理个人信息的权利</div>
          <div>六、未成年人个人信息保护</div>
          <div>七、隐私政策的适用范围</div>
          <div>八、隐私政策的变更和修订</div>
          <div className={s.mgb20}>九、如何联系我们</div>
          <div className={s.mgb20}>
            我们希望通过本隐私政策向您说明，在使用{productName}
            时，我们如何收集、使用、存储、分享和转让您的个人信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。请在使用
            {productName}
            前，务必仔细阅读并了解本隐私政策，在确认充分理解并同意后使用我们的产品或服务。一旦您开始使用
            {productName}
            ，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、储存和分享您的相关信息。如对本政策内容有任何疑问、意见或建议，您可通过本隐私政策提供的各种联系方式与我们联系。
          </div>
          {policyInfo.map((item: any, index: number) => (
            <div key={index}>
              <div className={`${s.mgb20} ${s.title}`}>{item.name}</div>
              {item.items.map((d: string, index: number) => {
                if (d.includes('https://')) {
                  const url = d.split('https://')[1];
                  console.log('url----', url);
                  return (
                    <a
                      key={index}
                      href={`https://${url}`}
                      target="_blank"
                      rel="noreferrer"
                      className={s.link}
                    >
                      {d}
                    </a>
                  );
                }
                return (
                  <div key={index} className={s.mgb20}>
                    {d}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {isMobile() ? <MobileFooter /> : <Footer showBackground={true} />}
        <CompleteInfoModal />
      </div>
    );
  }
}

export default Policy;
