import React, { Component } from 'react';

import s from './Layout.module.scss';
import LeftNavigation from '../LeftNavigation/LeftNavigation';
import TopNavigation from '../TopNavigation/TopNavigation';
import CompleteInfoModal from '../../../home/components/CompleteInfoModal/CompleteInfoModal';
import { getUrlQuery } from '../../../common';

interface IProps {
  children: React.ReactElement;
  title: string;
  subTitle?: string;
  link: string;
}

class Layout extends Component<IProps, Record<string, never>> {
  renderSubInfo = () => {
    if (this.props.link.includes('softwareMgr')) {
      const type = getUrlQuery('type') || 'pc';
      if (type === 'mobile') {
        return { name: 'App管理', url: this.props.link };
      } else {
        return { name: '软件管理', url: this.props.link };
      }
    } else {
      return !!this.props.subTitle
        ? { name: this.props.subTitle, url: this.props.link }
        : null;
    }
  };

  render() {
    return (
      <>
        <div className={s.container}>
          <LeftNavigation />
          <div className={s.content}>
            <TopNavigation
              title={this.props.title}
              description=""
              subNavInfo={this.renderSubInfo()}
            />
            <div className={s.info}>{this.props.children}</div>
          </div>
        </div>
        <CompleteInfoModal />
      </>
    );
  }
}

export default Layout;
