import React from 'react';

import s from './QsHomeButton.module.scss';

interface IProps {
  children: React.ReactElement | string;
  theme: 'green' | 'yellow' | 'blue';
  type: 'primary' | 'outline';
  disable?: boolean;
  href?: string;
  onClick?: () => void;
}

function QsHBigButton(props: IProps) {
  const { type, children, theme, disable, href } = props;

  if (href !== undefined) {
    return (
      <a
        href={href}
        className={`${s[`${type}-${theme}`]} ${disable ? s.disableBtn : ''}`}
      >
        {children}
      </a>
    );
  }
  return (
    <div
      className={`${s[`${type}-${theme}`]} ${disable ? s.disableBtn : ''}`}
      onClick={() => {
        if (!props.disable && props.onClick) {
          props.onClick();
        }
      }}
    >
      {children}
    </div>
  );
}

function QsHNormalButton(props: IProps) {
  const { type, children, theme, disable, href } = props;

  if (href !== undefined) {
    return (
      <a
        href={href}
        style={{ padding: '0 36px', height: '40px', fontSize: '16px' }}
        className={`${s[`${type}-${theme}`]} ${disable ? s.disableBtn : ''}`}
      >
        {children}
      </a>
    );
  }
  return (
    <div
      style={{ padding: '0 36px', height: '40px', fontSize: '16px' }}
      className={`${s[`${type}-${theme}`]} ${disable ? s.disableBtn : ''}`}
      onClick={() => {
        if (!props.disable && props.onClick) {
          props.onClick();
        }
      }}
    >
      {children}
    </div>
  );
}

export { QsHBigButton, QsHNormalButton };
