import React, { useState } from 'react';
import { QsHBigButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import PurchaseModal from '../../../qsParent/components/PurchaseModal/PurchaseModal';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
import s from './PriceCard.module.scss';
import recommendIcon from './images/recommend.svg';
import anniversary from './images/3anniversary.png';
import AnniversaryPopover from '../../components/AnniversaryPopover/AnniversaryPopover';
import { isActiveAnniversary } from '../../../utils/lib';

interface IProps {
  name: '年付会员' | '季付会员' | '月付会员' | '免费体验';
  desc: string;
  currentPrice: string;
  prePrice: string;
  isLogin: boolean;
  recommend: boolean;
}

function PriceCard(props: IProps) {
  const [showModal, setShowModal] = useState(false);

  const { name, desc, currentPrice, prePrice, recommend } = props;

  return (
    <div className={s.container}>
      <div>
        <div className={s.title}>{name}</div>
        <div className={s.horizontalDividingLine} />
        <div className={s.desc}>{desc}</div>
        <div className={s.priceGroup}>
          <div className={s.currentPrice}>{`￥${currentPrice}`}</div>
          <div className={s.prePrice}>{prePrice !== '' ? `￥${prePrice}` : '\n'}</div>
        </div>
      </div>
      {!!recommend && <img className={s.recommendTag} src={recommendIcon} alt="" />}

      {!!recommend && isActiveAnniversary() && (
        <>
          <img className={s.anniversaryTag} src={anniversary} alt="" />
          <span className={s.anniverasyTips}>
            <AnniversaryPopover />
          </span>
        </>
      )}

      <div className={s.btn}>
        {!!props.isLogin ? (
          <PurchaseModal
            name={props.name}
            customBtn={
              <QsHBigButton type="primary" theme="green">
                立即购买
              </QsHBigButton>
            }
          />
        ) : (
          <QsHBigButton
            type="primary"
            theme="green"
            onClick={() => {
              setShowModal(true);
            }}
          >
            立即购买
          </QsHBigButton>
        )}
      </div>
      <LoginRegisterModal
        visible={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
}

export default PriceCard;
