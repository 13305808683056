import { createActionAsync } from 'redux-act-async';
import moment from 'moment';
import 'moment/locale/zh-cn';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import {
  defaultActionOption,
  fetchPostConfig,
  fetchPutConfig,
  fetchDelConfig,
} from '../common';
import { GET_TERMINAL_INFO, UPDATE_CLIENT_NAME, DELETE_TERMINAL } from './constants';

moment.locale('zh-cn');

export async function getTerminalIds(filter = [], sort = []) {
  return fetch(
    '/services/tianxun/apiv2/terminal/filterAndSort',
    fetchPostConfig({ filter, sort })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'terminal ids fetch error' }));
}

export function getTerminalById(terminalIds) {
  return fetch(
    '/services/tianxun/apiv2/terminal/detailsByTerminalId',
    fetchPostConfig({ terminalIds })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'terminal info fetch error' }));
}

function formatTerminalInfo(terminals, online) {
  return terminals.map((terminal) => {
    let durationTime = null;
    if (!!terminal.online_duration) {
      const time = moment.duration(terminal.online_duration * 1000);
      const days = !!time.days() ? `${time.days()}天` : '';
      const hours = !!time.hours() ? `${time.hours()}小时` : '';
      const minutes = !!time.minutes() ? `${time.minutes()}分钟` : '';
      durationTime = `${days}${hours}${minutes}`;
    }

    const shutdownTime = terminal.shutdowntime
      ? moment(terminal.shutdowntime).format('YYYY/MM/DD HH:mm')
      : '--';
    return {
      hostName: terminal.hostname,
      clientName: !!terminal.clientname ? terminal.clientname : '--',
      onlineTime: durationTime,
      shutdownTime,
      ip: terminal.ip,
      terminalId: terminal.terminal_id,
      pcType: terminal.pcType,
      groupName: terminal.groupname,
      city: terminal.city,
      online,
    };
  });
}

async function getTerminalInfo() {
  const ids = await getTerminalIds();
  const terminalInfo = await getTerminalById(ids.terminalIds);
  const onlineTerminals = terminalInfo.filter(
    (terminal) => terminal.online && terminal.clientstatus !== 2 && !terminal.over_limit
  );
  const offlineTerminals = terminalInfo.filter(
    (terminal) => !terminal.online && terminal.clientstatus !== 2 && !terminal.over_limit
  );
  return Promise.resolve({
    onlineTerminals: formatTerminalInfo(onlineTerminals, true),
    offlineTerminals: formatTerminalInfo(offlineTerminals, false),
  });
}

export const getTerminalInfoAction = createActionAsync(
  GET_TERMINAL_INFO,
  getTerminalInfo,
  defaultActionOption
);

export const getTerminalInfoReducer = createReducerAsync(getTerminalInfoAction);

function updateClientName(terminalId, clientName) {
  return fetch(
    '/services/tianxun/apiv2/terminal/clientName',
    fetchPutConfig({ terminalId, clientName })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'update client name error' }));
}

export const updateClientNameAction = createActionAsync(
  UPDATE_CLIENT_NAME,
  updateClientName,
  defaultActionOption
);

export const updateClientNameReducer = createReducerAsync(updateClientNameAction);

function deleteTerminal(terminalId) {
  return fetch(`/services/tianxun/apiv2/terminal/${terminalId}`, fetchDelConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'delete terminal error' }));
}

export const deleteTerminalAction = createActionAsync(
  DELETE_TERMINAL,
  deleteTerminal,
  defaultActionOption
);

export const deleteTerminalReducer = createReducerAsync(deleteTerminalAction);
