import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CompleteInfoModal from '../CompleteInfoModal/CompleteInfoModal';
// import activityEnter from './images/activityEnter.gif';
import registerActivityEnter from './images/registerActivityEnter.png';

import s from './HomeLayout.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActivityListAction } from '../../../redux/invitationActivity';
import LoginRegisterModal from '../LoginRegisterModal/LoginRegisterModal';
import { checkLoginStateAction } from '../../../redux/user';
import { logoutAction } from '../../../redux/login';

interface IProps {
  children: React.ReactElement;
  showFooterBg?: boolean;
  getActivityListAction: () => void;
  getActivityListResult: any;
  checkLoginStateAction: () => void;
  checkLoginStateResult: any;
  logoutAction: () => void;
  getHeaderHeight?: (height: number) => void;
}

interface IStates {
  showModal: boolean;
}

class HomeLayout extends Component<IProps, IStates> {
  async componentDidMount() {
    await Promise.all([
      this.props.getActivityListAction(),
      this.props.checkLoginStateAction(),
    ]);
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  getHeaderHeight = (value: number) => {
    if (this.props.getHeaderHeight !== undefined) {
      this.props.getHeaderHeight(value);
    }
  };

  render() {
    const { isLogin } = this.props.checkLoginStateResult;
    return (
      <div className={s.container}>
        <Header getHeaderHeight={this.getHeaderHeight} />
        {this.props.children}
        <Footer showBackground={this.props.showFooterBg} />
        <CompleteInfoModal />
        {!isLogin && (
          <a onClick={() => this.setState({ showModal: true })} target="_blank">
            <img
              src={registerActivityEnter}
              style={{
                position: 'fixed',
                width: '150px',
                right: '10px',
                bottom: '8vh',
                cursor: 'pointer',
              }}
              alt="register activity enter"
            />
          </a>
        )}
        {/* {this.props.getActivityListResult?.data?.length > 0 &&
          !window.location.href.includes('/invitationActivity') && (
            <a href="/invitationActivity" target="_blank">
              <img
                src={activityEnter}
                style={{
                  position: 'fixed',
                  width: '150px',
                  right: '10px',
                  bottom: '8vh',
                  cursor: 'pointer',
                }}
                alt="activity enter"
              />
            </a>
          )} */}
        <LoginRegisterModal
          visible={this.state.showModal}
          handleClose={() => {
            this.setState({ showModal: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getActivityListResult: state.getActivityListResult.data,
    checkLoginStateResult: state.checkLoginStateResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getActivityListAction: bindActionCreators(getActivityListAction, dispatch),
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    logoutAction: bindActionCreators(logoutAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
