import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import bannerText from './images/bannerText.png';
import s from './Price.module.scss';
import { getPricesInfoAction } from '../../../redux/order';
import { checkLoginStateAction } from '../../../redux/user';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import { QsHBigButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import PriceCard from './PriceCard';
import serviceInfo from './images/serviceInfo.png';

import mobileDevice from './images/mobileDevice.png';
import pcDevice from './images/pcDevice.png';
import groupByImage from './images/groupByImage.png';
import agentBanner from './images/agentBanner.png';
// import agencyLeft from './images/agencyLeft.svg';
// import agencyRight from './images/agencyRight.svg';

import timeIcon from '../../../commonComponents/QsIcons/timeIcon.svg';
import applicationIcon from '../../../commonComponents/QsIcons/applicationIcon.svg';
import multiDeviceIcon from '../../../commonComponents/QsIcons/multiDeviceIcon.svg';
import remoteIcon from '../../../commonComponents/QsIcons/remoteIcon.svg';
import learningModeIcon from '../../../commonComponents/QsIcons/learningModeIcon.svg';
import FunctionCard from './FunctionCard';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
// import serviceInfo from './images/serviceInfo.svg';
import { isZGYD } from '../../../utils/lib';
import PurchaseModal from '../../../qsParent/components/PurchaseModal/PurchaseModal';
import GroupPurchaseDescPopover from '../../components/GroupPurchaseDescPopover/GroupPurchaseDescPopover';
import variables from '../../../variables.module.scss';
import Tabs from './Tab/Tabs';
import xiaomiIcon from './images/xiaomiIcon.png';
import xiaomiIconActive from './images/xiaomiIconActive.png';
import huaweiIcon from './images/huaweiIcon.png';
import huaweiIconActive from './images/huaweiIconActive.png';
import oppoIcon from './images/oppoIcon.png';
import oppoIconActive from './images/oppoIconActive.png';
import vivoIcon from './images/vivoIcon.png';
import vivoIconActive from './images/vivoIconActive.png';
import windowsIcon from './images/windowsIcon.png';

interface IProps {
  checkLoginStateResult: any;
  getPricesInfoAction: () => void;
  getPricesInfoResult: any;
  checkLoginStateAction: () => void;
}

interface IState {
  showModal: boolean;
  selectedMobileType: 'xiaomi' | 'huawei' | 'oppo' | 'vivo';
}

export interface IPriceInfo {
  id: string;
  name: '年付会员' | '季付会员' | '月付会员' | '免费体验' | '团购套餐';
  months: number;
  price: number;
  originPrice?: string | null;
  icon?: string;
  desc?: string;
  recommend?: boolean;
}

class Price extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      selectedMobileType: 'xiaomi',
    };
  }

  async componentDidMount() {
    document.title = !!isZGYD()
      ? '价格_多少钱_家长管控软件购买'
      : '青松守护_价格_多少钱_家长管控软件购买 - 青松守护';
    await this.props.checkLoginStateAction();
    await this.props.getPricesInfoAction();

    if (localStorage.getItem('currentPath') === 'price') {
      const anchorElement = document.getElementById('priceCard');
      if (anchorElement) {
        window.scrollTo(0, anchorElement.offsetTop - window.innerHeight / 2);
      }

      localStorage.setItem('currentPath', '');
    }
  }

  getPricesList = () => {
    let data: {
      title: any;
      currentPrice: any;
      prePrice: string;
      desc: string;
      recommend: boolean;
    }[] = [];
    this.props.getPricesInfoResult?.map((item: any) => {
      let prePrice = '';
      let recommend = false;
      if (item.name.includes('年付')) {
        prePrice = '360';
        recommend = true;
      } else if (item.name.includes('季付')) {
        prePrice = '90';
      } else {
        prePrice = '';
      }

      data.push({
        title: item.name,
        currentPrice: item.price,
        prePrice: prePrice,
        desc: !!isZGYD() ? '全功能无限制使用' : '青松守护全功能无限制使用',
        recommend: recommend,
      });
    });

    data = data.filter((item) => item.title !== '团购套餐');

    return data;
  };

  getFunctionInfo = () => {
    return [
      {
        icon: mobileDevice,
        lineColor: '#59E9C4',
        bgColor: '#F0FDF9',
        title: '管理移动设备',
        deviceInfo: this.mobileDeviceInfo(),
        functionList: [
          {
            icon: timeIcon,
            color: variables.colorFunctionWarning,
            title: '约定使用时间',
            desc: '和孩子约定每天什么时候可以用手机，可以用多长时间。',
          },
          {
            icon: remoteIcon,
            color: variables.colorFunctionInfo,
            title: '亲子同屏',
            desc: '共享孩子的屏幕，和孩子同屏学习，共同成长。',
          },
          {
            icon: applicationIcon,
            color: variables.primaryColor,
            title: 'App 管理',
            desc: '设置可用 App 及其使用时长。',
          },
          {
            icon: multiDeviceIcon,
            color: variables.colorFunctionWarning,
            title: '多端共通',
            desc: `您可使用多个平台（Web 控制中心/${
              !!isZGYD() ? '' : '青松守护'
            }家长端）管理孩子手机。`,
          },
        ],
      },
      {
        icon: pcDevice,
        lineColor: variables.colorFunctionInfo,
        bgColor: '#EEF4FF',
        title: '管理电脑设备',
        deviceInfo: this.pcDeviceInfo(),
        functionList: [
          {
            icon: learningModeIcon,
            color: variables.colorFunctionError,
            title: '学习模式',
            desc: '在学习期间屏蔽游戏、短视频对孩子的干扰。',
          },
          {
            icon: timeIcon,
            color: variables.colorFunctionWarning,
            title: '约定使用时间',
            desc: '和孩子约定每天什么时候可以用电脑，可以用多长时间。',
          },
          {
            icon: remoteIcon,
            color: variables.colorFunctionInfo,
            title: '亲子同屏',
            desc: '共享孩子的屏幕，和孩子同屏学习，共同成长。',
          },
          {
            icon: applicationIcon,
            color: variables.primaryColor,
            title: '软件管理',
            desc: '设置软件可用/禁用。',
          },
          {
            icon: multiDeviceIcon,
            color: variables.colorFunctionWarning,
            title: '多端共通',
            desc: `您可使用多个平台（Web 控制中心/${
              !!isZGYD() ? '' : '青松守护'
            }家长端）管理孩子电脑。`,
          },
        ],
      },
    ];
  };

  render() {
    const { isLogin } = this.props.checkLoginStateResult;
    return (
      <HomeLayout showFooterBg={false}>
        <div>
          {!!isZGYD() ? (
            <div style={{ height: '140px' }} />
          ) : (
            <div className={s.bannerTextContainer}>
              <img src={bannerText} alt="" />
              <div className={s.horizontalDividingLine}></div>
              {!isLogin ? (
                <QsHBigButton
                  theme="green"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      showModal: true,
                    });
                  }}
                >
                  立即注册
                </QsHBigButton>
              ) : (
                <div className={s.received}>已领取</div>
              )}
              <div className={s.serviceInfo}>
                <img style={{ height: '9vw' }} src={serviceInfo} alt="" />
              </div>
            </div>
          )}
          <div className={s.contentContainer}>
            <div className={s.title}>
              <span>{!!isZGYD() ? `会员选择` : `青松守护`}</span>
            </div>
            <div className={s.desc}>
              <span>培养亲子健康上网习惯</span>
            </div>
            <div className={s.agencyContainer}>
              <img src={agentBanner} alt="" />
            </div>
            <div className={s.priceContainer} id="priceCard">
              {this.getPricesList().map((item: any) => {
                return (
                  <PriceCard
                    isLogin={isLogin}
                    key={item.title}
                    name={item.title}
                    desc={item.desc}
                    currentPrice={item.currentPrice}
                    prePrice={item.prePrice}
                    recommend={item.recommend}
                  />
                );
              })}
            </div>
            <div className={s.groupByContainer}>
              <div className={s.groupByLeft}>
                <img style={{ height: '130px' }} src={groupByImage} alt="" />
                <div>
                  <div className={s.groupByTitle}>
                    <span>团购套餐</span>
                    <GroupPurchaseDescPopover />
                  </div>
                  <div className={s.groupByLine} />
                  <div className={s.groupByDesc}>
                    一次购买 3 份享 <span>9</span>折优惠
                  </div>
                </div>
              </div>
              <div className={s.groupByRight}>
                <div className={s.groupByPrice}>
                  低至
                  <span>¥168</span>
                  <span>*3</span>
                </div>
                {isLogin ? (
                  <PurchaseModal
                    name="团购套餐"
                    customBtn={
                      <QsHBigButton type="primary" theme="green">
                        立即购买
                      </QsHBigButton>
                    }
                  />
                ) : (
                  <QsHBigButton
                    type="primary"
                    theme="green"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                      });
                    }}
                  >
                    立即购买
                  </QsHBigButton>
                )}
              </div>
            </div>
            <div className={s.title}>
              <span>{!!isZGYD() ? '包含功能' : '青松守护包含功能'}</span>
            </div>
            <div className={s.functionContainer}>
              {this.getFunctionInfo().map((item: any) => {
                return (
                  <FunctionCard
                    lineColor={item.lineColor}
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    deviceInfo={item.deviceInfo}
                    functionList={item.functionList}
                    bgColor={item.bgColor}
                  />
                );
              })}
            </div>
          </div>
          <LoginRegisterModal
            redirect="/dashboard"
            visible={this.state.showModal}
            handleClose={() => {
              this.setState({ showModal: false });
            }}
          />
        </div>
      </HomeLayout>
    );
  }

  mobileDeviceInfo() {
    const items = [
      {
        key: 'xiaomi',
        label: '小米',
        labelIcon:
          this.state.selectedMobileType == 'xiaomi' ? xiaomiIconActive : xiaomiIcon,
        content: this.tabContent('支持 MIUI 10.0及以上平板/手机（14.0及以上积极适配中）'),
      },
      {
        key: 'huawei',
        label: '华为',
        labelIcon:
          this.state.selectedMobileType == 'huawei' ? huaweiIconActive : huaweiIcon,
        content: this.tabContent(
          '支持 EMUI 10.0及以上、HarmonyOS 2.0.0 - 3.0.0平板/手机'
        ),
      },
      {
        key: 'oppo',
        label: 'OPPO',
        labelIcon: this.state.selectedMobileType == 'oppo' ? oppoIconActive : oppoIcon,
        content: this.tabContent('支持 ColorOS 7.0 - 13.0平板/手机'),
      },
      {
        key: 'vivo',
        label: 'vivo',
        labelIcon: this.state.selectedMobileType == 'vivo' ? vivoIconActive : vivoIcon,
        content: this.tabContent('支持 OriginOS 1.0 - 3.0平板/手机'),
      },
    ];
    return (
      <Tabs
        items={items}
        onChange={(tab: any) => {
          this.setState({ selectedMobileType: tab });
        }}
        bgColor="#F0FDF9"
      />
    );
  }

  pcDeviceInfo() {
    const items = [
      {
        key: 'windows',
        label: 'Windows',
        labelIcon: windowsIcon,
        content: (
          <div
            className={s.tabContent}
            style={{ borderColor: '#009DFF', color: '#009DFF' }}
          >
            支持 Windows 7、Windows 10、Windows 11 系统电脑
          </div>
        ),
      },
    ];
    return (
      <Tabs
        items={items}
        onChange={(tab: any) => {
          this.setState({ selectedMobileType: tab });
        }}
        bgColor="#EEF4FF"
      />
    );
  }

  tabContent(text: string, color?: string) {
    return (
      <div className={s.tabContent} style={{ borderColor: color, color: color }}>
        {text}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
    getPricesInfoResult: state.getPricesInfoResult.data.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPricesInfoAction: bindActionCreators(getPricesInfoAction, dispatch),
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Price);
