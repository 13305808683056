import React, { useEffect } from 'react';
import { MdCancel, MdCheckCircle, MdError, MdInfo, MdClose } from 'react-icons/md';

import s from './QsAlert.module.scss';

interface IProps {
  offset?: number;
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  position?: 'fixed' | 'absolute';
  visible: boolean;
  onClose: () => void;
  closable?: boolean;
  timeout?: number;
}

function QsAlert(props: IProps) {
  useEffect(() => {
    if (!props.closable && props.visible) {
      setTimeout(() => {
        if (props.onClose) {
          props.onClose();
        }
      }, props.timeout || 3000);
    }
  }, [props.visible]);

  const icon = (type: 'success' | 'info' | 'warning' | 'error') => {
    switch (type) {
      case 'success':
        return <MdCheckCircle />;
      case 'info':
        return <MdInfo />;
      case 'warning':
        return <MdError />;
      case 'error':
        return <MdCancel />;
    }
  };

  const transform = props.offset ? `calc(-50% + ${props.offset}px)` : 'calc(-50%)';
  if (!props.visible) {
    return <div></div>;
  }
  return (
    <div
      className={`${s.container} ${s[`${props.type}`]} ${
        props.closable ? s.closableContent : ''
      }`}
      style={{ transform: `translateX(${transform})`, position: props.position }}
    >
      <span>
        {icon(props.type)}
        <span className={props.closable ? s.message : ''}>{props.message}</span>
      </span>
      {props.closable && (
        <MdClose
          className={s.closeBtn}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      )}
    </div>
  );
}

export default QsAlert;
