import React from 'react';

import s from './Download.module.scss';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import FunctionTab from './FunctionTab';

function Download() {
  return (
    <HomeLayout showFooterBg={false}>
      <div className={s.container}>
        <FunctionTab defaultActive="p_web" defaultType="parent"></FunctionTab>
      </div>
    </HomeLayout>
  );
}

export default Download;
