import React, { Component } from 'react';

import s from './QsTag.module.scss';

import error from './error.svg';
import success from './success.svg';
import warn from './warn.svg';
import { CloseOutlined } from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  type: 'success' | 'error' | 'warn';
  text: string;
  hideIcon?: boolean;
  onClick?: () => void;
}

class QsTag extends Component<IProps, Record<string, never>> {
  constructor(props: IProps) {
    super(props);
  }

  renderImg = () => {
    if (this.props.type === 'success') {
      return <img src={success} alt="" />;
    } else if (this.props.type === 'error') {
      return <img src={error} alt="" />;
    } else {
      return <img src={warn} alt="" />;
    }
  };

  render() {
    if (this.props.onClick) {
      return (
        <span className={s.qsTagWithClick}>
          {this.props.text}
          <span onClick={this.props.onClick}>
            <CloseOutlined />
          </span>
        </span>
      );
    }
    return (
      <div
        className={`${s.qsTag} ${
          this.props.type === 'error'
            ? s.qsTagError
            : this.props.type === 'success'
            ? s.qsTagSuccess
            : s.qsTagWarn
        }`}
      >
        {!this.props.hideIcon && this.renderImg()}
        <span>{this.props.text}</span>
      </div>
    );
  }
}

export default QsTag;
