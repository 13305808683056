import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { getPcRemoteInfoAction } from '../../../../redux/pcRemote';
import connectRemoteDesktop from '../../../../redux/connectRemoteDesktop';
import s from './PcRemoteConnect.module.scss';
import VNCLoader from './VNCLoader';
import RFB from './novnc';

interface IProps {
  getPcRemoteInfoAction: () => void;
  getPcRemoteInfoResult: any;
  connectRemoteDesktop: (terminalId: string) => void;
  remoteDesktop: any;
}

function parseConfigFromUrl() {
  if (window.location.search) {
    const query = queryString.parse(window.location.search);
    if (query.config) {
      const config = JSON.parse(decodeURIComponent(query.config.toString()));
      return config;
    }
  }
  return { terminalId: '' };
}

class PcRemoteConnect extends Component<IProps, Record<string, never>> {
  async componentDidMount() {
    document.title = '远程守护';

    await this.props.getPcRemoteInfoAction();
    const config = parseConfigFromUrl();
    this.props.connectRemoteDesktop(config.terminalId);
  }

  componentDidUpdate() {
    const { password } = this.props.getPcRemoteInfoResult.data;
    if (this.props.remoteDesktop.channel) {
      const element = document.getElementById('vnc-element');
      // @ts-ignore
      const rfb = new RFB(element, this.props.remoteDesktop.channel, {
        shared: false,
        credentials: { password },
      });
      rfb.scaleViewport = false;
      rfb.resizeSession = true;
      rfb.scaleViewport = true;
    }
  }

  getVncViewer() {
    const name =
      this.props.remoteDesktop.status === 'success' ? s.vncViewerShow : s.vncViewerHide;
    return (
      <div className={name}>
        <div id="vnc-element" className={s.vncElement} />
      </div>
    );
  }

  render() {
    if (this.props.remoteDesktop.status === 'error') {
      console.error(this.props.remoteDesktop.error);
    }

    const vncloaderConfig = parseConfigFromUrl();
    return (
      <div className={s.vncContainer}>
        <VNCLoader
          className={s.vncLoader}
          status={this.props.remoteDesktop.status}
          config={vncloaderConfig}
        />
        {this.getVncViewer()}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getPcRemoteInfoResult: state.getPcRemoteInfoResult.data,
    remoteDesktop: state.remoteDesktop,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPcRemoteInfoAction: bindActionCreators(getPcRemoteInfoAction, dispatch),
    connectRemoteDesktop: bindActionCreators(connectRemoteDesktop, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PcRemoteConnect);
