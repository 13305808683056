import React, { useEffect, useRef } from 'react';
import Selecto from 'react-selecto';

import s from './MultiTimeSelector.module.scss';
import TimeItem, { ITimeData, amTime, pmTime } from './TimeItem';
import QsTag from '../../../commonComponents/QsTag/QsTag';
import QsCheckbox from '../../../commonComponents/QsCheckbox/QsCheckbox';
import './selectoStyle.scss';

interface IProps {
  desc: string;
  cardColorDesc: string[];
  colorType?: 'yellow' | 'green';
  defaultTime: ITimeData[];
  selectedTime: ITimeData[];
  timeCardHeight?: number;
  selectedTimeContainerHeight?: number;
  handleTimeChange: (value: ITimeData[]) => void;
}

function MultiTimeSelector(props: IProps) {
  const selectRef: any = useRef();

  useEffect(() => {
    setSeletorDefaultSelectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultTime]);

  const getTimeNum = (item: any) => {
    const [beginH, beginM] = item.begin.split(':');
    const endStr = item.end === '23:59' ? '24:00' : item.end;
    const [endH, endM] = endStr.split(':');
    const startNum = beginH * 60 + parseInt(beginM);
    const endNum = endH * 60 + parseInt(endM);

    return { start: startNum, end: endNum };
  };

  const getSelectoIds = () => {
    const timeList = ['00:00']
      .concat(amTime.timeList[0])
      .concat(amTime.timeList[1])
      .concat(pmTime.timeList[0])
      .concat(pmTime.timeList[1]);

    const idList: any[] = [];
    timeList.forEach((item: any) => {
      const hour = item.split(':')[0];
      if (hour === '24') return;
      idList.push(`${hour}-00-15`);
      idList.push(`${hour}-15-30`);
      idList.push(`${hour}-30-45`);
      idList.push(`${hour}-45-60`);
    });

    return idList;
  };

  const setSeletorDefaultSelectList = () => {
    if (selectRef) {
      const electo = selectRef.current?.selecto;
      electo.selectedTargets = [];
      const idList = getSelectoIds();
      idList.forEach((id) => {
        const [hStr, startM, endM] = id.split('-');
        const startNum = parseInt(hStr) * 60 + parseInt(startM);
        const endNum = parseInt(hStr) * 60 + parseInt(endM);

        const include = props.selectedTime.filter((v) => {
          const timeNum = getTimeNum(v);
          return startNum >= timeNum.start && endNum <= timeNum.end;
        });

        if (include.length > 0) {
          const el = document.getElementById(id);
          const exist = electo.selectedTargets.filter((item: any) => item === el);
          if (exist.length === 0) {
            electo.selectedTargets.push(el);
          }
        }
      });
    }
  };

  const setSelectoAllSelected = () => {
    if (selectRef) {
      const electo = selectRef.current?.selecto;
      electo.selectedTargets = [];
      const idList = getSelectoIds();
      idList.forEach((id) => {
        const el = document.getElementById(id);
        const exist = electo.selectedTargets.filter((item: any) => item === el);
        if (el && exist.length === 0) {
          electo.selectedTargets.push(el);
        }
      });
    }
  };

  const setSelectoSelectedEmpty = () => {
    if (selectRef) {
      const electo = selectRef.current?.selecto;
      electo.selectedTargets = [];
    }
  };

  const mapTagType = () => {
    switch (props.colorType) {
      case 'green':
        return 'success';
      case 'yellow':
        return 'warn';
      default:
        return 'warn';
    }
  };

  const isWholeDay = () => {
    let isWholeDay = false;
    if (
      props.selectedTime.length === 1 &&
      props.selectedTime[0].begin === '00:00' &&
      (props.selectedTime[0].end === '23:59' || props.selectedTime[0].end === '24:00')
    ) {
      isWholeDay = true;
    }

    return isWholeDay;
  };

  const calcTime = (selectedItem: any[]) => {
    const timeArr: any = [];
    selectedItem.forEach((item: any) => {
      const [beginH, beginM] = item.begin.split(':');
      const endStr = item.end === '23:59' ? '24:00' : item.end;
      const [endH, endM] = endStr.split(':');
      const startNum = beginH * 60 + parseInt(beginM);
      const endNum = endH * 60 + parseInt(endM);

      const addedStartHour =
        parseInt(beginH) + 1 > 10 ? parseInt(beginH) + 1 : `0${parseInt(beginH) + 1}`;
      const startTime =
        parseInt(beginM) === 60 ? `${addedStartHour}:00` : `${beginH}:${beginM}`;
      const addedEndHour =
        parseInt(endH) + 1 >= 10 ? parseInt(endH) + 1 : `0${parseInt(endH) + 1}`;
      const endTime = parseInt(endM) === 60 ? `${addedEndHour}:00` : `${endH}:${endM}`;

      let merged = false;
      timeArr.forEach((item: any) => {
        const [beginH, beginM] = item.begin?.split(':');
        const endStr = item.end === '23:59' ? '24:00' : item.end;
        const [endH, endM] = endStr?.split(':');
        const begin = beginH * 60 + parseInt(beginM);
        const end = endH * 60 + parseInt(endM);

        if (endNum == begin) {
          merged = true;
          item.begin = startTime;
        } else if (startNum === end) {
          merged = true;
          item.end = endTime;
        } else if (begin <= startNum && end >= endNum) {
          merged = true;
        } else if (begin >= startNum && end <= endNum) {
          merged = true;
          item.begin = startTime;
          item.end = endTime;
        }
      });

      if (!merged && startNum != endNum) {
        timeArr.push({ begin: startTime, end: endTime });
      }
    });
    return timeArr;
  };

  const handleSelect = (e: any) => {
    e.added.forEach((el: any) => {
      el.classList.add('selected');
    });
    e.removed.forEach((el: any) => {
      el.classList.remove('selected');
    });

    const selectedItem = e.selected
      .map((item: any) => {
        const [hour, startMinu, endMinu] = item.id.split('-');
        return { begin: `${hour}:${startMinu}`, end: `${hour}:${endMinu}` };
      })
      .sort((a: any, b: any) => {
        const [aHour, aStartMinu] = a.begin.split(':');
        const [bHour, bStartMinu] = b.begin.split(':');
        const aNum = aHour * 60 + parseInt(aStartMinu);
        const bNum = bHour * 60 + parseInt(bStartMinu);
        return aNum - bNum;
      });

    const timeList = calcTime(selectedItem);
    props.handleTimeChange(timeList);
  };

  return (
    <div>
      <div className={s.title}>{props.desc}</div>
      <div className={s.tips}>
        <div className={s.desc}>
          <span>
            <span
              className={
                props.colorType ? s[`desc-${props.colorType}`] : s['desc-yellow']
              }
            ></span>
            {props.cardColorDesc[0]}
          </span>
          <span>
            <span></span>
            {props.cardColorDesc[1]}
          </span>
        </div>
        <div className={s.checkbox}>
          <QsCheckbox
            theme="green"
            onChange={(checked) => {
              console.log(checked);
              if (checked) {
                props.handleTimeChange([{ id: '1', begin: '00:00', end: '23:59' }]);
                setSelectoAllSelected();
              } else {
                props.handleTimeChange([]);
                setSelectoSelectedEmpty();
              }
            }}
            size="small"
            checked={isWholeDay()}
          />
          全天{props.cardColorDesc[0]}
        </div>
      </div>
      <div>
        <Selecto
          ref={selectRef}
          dragContainer={window}
          selectableTargets={[`.selecto-target .cube`]}
          hitRate={1}
          selectByClick={true}
          selectFromInside={true}
          continueSelect={true}
          ratio={0}
          onSelect={handleSelect}
        />
        <div
          className={`elements selecto-target ${
            props.colorType ? `selecto-area-${props.colorType}` : 'selecto-area-yellow'
          }`}
        >
          <div className={s.amContainer}>
            <TimeItem
              type="am"
              timeCardHeight={props.timeCardHeight}
              colorType={props.colorType}
              selectedTime={props.selectedTime}
            />
          </div>
          <div className={s.pmContainer}>
            <TimeItem
              type="pm"
              timeCardHeight={props.timeCardHeight}
              colorType={props.colorType}
              selectedTime={props.selectedTime}
            />
          </div>
        </div>
      </div>

      {props.selectedTime.length > 0 && (
        <div className={s.selectedTime}>
          {props.cardColorDesc[0]}时间段：
          <div
            style={{
              maxHeight: props.selectedTimeContainerHeight
                ? `${props.selectedTimeContainerHeight}px`
                : '48px',
            }}
          >
            {props.selectedTime.map((item, index) => {
              const text = `${item.begin} - ${item.end === '23:59' ? '24:00' : item.end}`;
              return (
                <QsTag key={index} text={text} type={mapTagType()} hideIcon={true} />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default MultiTimeSelector;
