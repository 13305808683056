import React, { useState } from 'react';
import { Form, Input, Checkbox } from 'antd';

import GetCaptchaBtn from '../GetCaptchaBtn/GetCaptchaBtn';
import s from './FormTemplate.module.scss';
import { getUrlParams, isMobile } from '../../../common';
import { isZGYD } from '../../../utils/lib';
import variables from '../../../variables.module.scss';
import { QsButtonFixedSize } from '../../../commonComponents/QsButton/QsButton';
import QsTooltip from '../../../commonComponents/QsTooltip/QsTooltip';
import QsInput from '../../../commonComponents/QsInput/QsInput';

interface IProps {
  handleSubmit: (value: any) => void;
  forItems: IFormItem[];
  formName: string;
  btnText: string;
  disableCaptchaBtn?: boolean;
  customFooter?: React.ReactElement;
  handleCaptchaBtnClick?: () => void;
  layout?: 'horizontal' | 'vertical' | 'inline';
  shape?: 'circle' | 'default' | 'round' | undefined;
  btnClassName?: any;
  inputType?: 'round' | 'square' | undefined;
}

export interface IFormItem {
  name: string;
  placeholder?: string;
  type?: 'text' | 'password';
  rules?: any[];
  validateTrigger?: string;
  label?: string;
}

function FormTemplate(props: IProps) {
  const [phone, setPhone] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Form
      name={props.formName}
      onFinish={(values) => {
        props.handleSubmit(values);
      }}
      layout={props.layout ? props.layout : 'horizontal'}
      initialValues={{ agreement: false }}
      labelCol={{ span: 4 }}
      labelAlign="left"
    >
      <div style={{ padding: '0 10px' }}>
        {props.forItems.map((item: IFormItem) => {
          if (item.name === 'smsCode') {
            return (
              <Form.Item
                key={item.name}
                name={item.name}
                validateTrigger={item.validateTrigger}
                validateFirst={true}
                rules={item.rules}
                style={{
                  marginBottom: window.location.pathname === '/sem' ? '15px' : '32px',
                }}
                label={item.label}
              >
                {window.location.pathname !== '/sem' ? (
                  <div className={s.captchaContainer}>
                    <QsInput
                      placeholder={item.placeholder}
                      size="large"
                      suffix={
                        <GetCaptchaBtn
                          phone={phone}
                          isDisable={props.disableCaptchaBtn}
                          shape="round"
                        />
                      }
                    />
                  </div>
                ) : (
                  <div className={s.captchaContainerSem}>
                    <QsInput placeholder={item.placeholder} size="large" />
                    <GetCaptchaBtn
                      phone={phone}
                      isDisable={props.disableCaptchaBtn}
                      shape="round"
                    />
                  </div>
                )}
              </Form.Item>
            );
          }
          if (item.name === 'agreement') {
            return (
              <Form.Item
                style={{ textAlign: 'center' }}
                key={item.name}
                name={item.name}
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value) {
                        setIsOpen(false);
                        return Promise.resolve();
                      } else {
                        setIsOpen(true);
                        return Promise.reject('');
                      }
                    },
                  },
                ]}
                className={s.agreement}
              >
                <Checkbox className="radius-check">
                  <span>
                    {isOpen && <QsTooltip message="请勾选同意协议内容" />}
                    我已阅读并同意
                    <a
                      href={`/agreement${
                        getUrlParams('promotion')
                          ? `?promotion=${getUrlParams('promotion')}`
                          : ''
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {!!isZGYD() ? '《用户服务条款》' : '《青松守护用户服务条款》'}
                    </a>
                  </span>
                </Checkbox>
              </Form.Item>
            );
          }
          return (
            <Form.Item
              key={item.name}
              name={item.name}
              validateTrigger={item.validateTrigger}
              validateFirst={true}
              rules={item.rules}
              style={{
                marginBottom: window.location.pathname === '/sem' ? '15px' : '32px',
              }}
              label={item.label}
            >
              {item.type === 'password' ? (
                <Input.Password placeholder={item.placeholder} />
              ) : (
                <Input
                  placeholder={item.placeholder}
                  size="large"
                  onChange={(e) => {
                    if (item.name === 'phone') {
                      setPhone(e.target.value);
                    }
                  }}
                  style={
                    props?.inputType === 'round'
                      ? {
                          borderRadius: '50px',
                          border: `1px solid ${variables.colorMasterC3}`,
                        }
                      : undefined
                  }
                />
              )}
            </Form.Item>
          );
        })}
      </div>
      <Form.Item className={s.submitBtn}>
        {props.customFooter ? (
          props.customFooter
        ) : (
          <div className={(s.loginBtn, isMobile() ? s.radiuBtn : '')}>
            <QsButtonFixedSize type="primary" size="middle" htmlType="submit">
              <span>{props.btnText}</span>
            </QsButtonFixedSize>
          </div>
        )}
      </Form.Item>
    </Form>
  );
}

export default FormTemplate;
