import React, { Component } from 'react';
import { getUrlParams } from '../../../common';
import s from './ArticleLabels.module.scss';

interface ILabelsProps {
  labels: any[];
}

class ArticleLabels extends Component<ILabelsProps, Record<string, never>> {
  render() {
    const queryLabelId = getUrlParams('labelId');
    return (
      <span className={s.label}>
        {this.props.labels.map((label: any) => (
          <a
            href={`/news?labelId=${label.id}`}
            key={label.id}
            className={`${queryLabelId === label.id ? s.checked : ''}`}
          >
            {label.name}
          </a>
        ))}
      </span>
    );
  }
}

export default ArticleLabels;
