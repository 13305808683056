import React, { Component } from 'react';
import moment from 'moment';
import { ArrowRightOutlined } from '@ant-design/icons';
import s from './MemberCard.module.scss';
import expiredCardIcon from './images/expiredCardIcon.svg';
import memberCardIcon from './images/memberCardIcon.svg';
// import PurchaseModal from '../PurchaseModal/PurchaseModal';
import { isZGYD } from '../../../utils/lib';
import { Link } from 'react-router-dom';

interface IProps {
  availableLimit: any;
}

interface IStates {
  visible: boolean;
}

class MemberCard extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    const expireTime = this.props.availableLimit
      ? moment.unix(this.props.availableLimit.mode.end).format('YYYY年MM月DD日')
      : '--';
    return (
      <div
        className={`${s.memberCard} ${
          this.props.availableLimit ? s.unexpired : s.expired
        }`}
      >
        <img
          className={s.memberCardIcon}
          src={this.props.availableLimit ? memberCardIcon : expiredCardIcon}
          alt="icon"
        />
        <div className={s.memberCardTitle}>{!!isZGYD() ? '' : '青松守护'}会员</div>
        <div className={s.memberTime}>
          {this.props.availableLimit ? (
            <span>
              <span className={s.memberTime}>{expireTime}</span>到期
            </span>
          ) : (
            '您的会员已到期'
          )}
        </div>

        <Link to={`/price`}>
          <div
            className={`${s.purchaseBtn} ${
              this.props.availableLimit ? s.unexpiredBtn : s.expiredBtn
            }`}
          >
            <span>{this.props.availableLimit ? '立即续费' : '立即购买'}</span>
            <ArrowRightOutlined />
          </div>
        </Link>
        {/* <PurchaseModal
          name="年付会员"
          customBtn={
            <div
              className={`${s.purchaseBtn} ${
                this.props.availableLimit ? s.unexpiredBtn : s.expiredBtn
              }`}
            >
              <span>{this.props.availableLimit ? '立即续费' : '立即购买'}</span>
              <ArrowRightOutlined />
            </div>
          }
        /> */}
      </div>
    );
  }
}

export default MemberCard;
