import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import s from './SubNav.module.scss';
import home from './images/home.svg';

interface IProps {
  mainNavInfo: {
    url: string;
    name: string;
  };
  subNavInfo: {
    url: string;
    name: string;
  };
}

export class SubNav extends Component<IProps, Record<string, never>> {
  render() {
    const { mainNavInfo, subNavInfo } = this.props;
    return (
      <div className={s.container}>
        <Tooltip placement="bottomLeft" title="控制中心">
          <Link to={mainNavInfo.url} className={s.mainLink}>
            <img src={home} alt="" />
          </Link>
        </Tooltip>
        <span>{'>'}</span>
        <span className={s.currentLink}>{subNavInfo.name}</span>
      </div>
    );
  }
}

export default SubNav;
