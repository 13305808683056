import React, { Component } from 'react';

import s from './MobileHeader.module.scss';
import mobileLogo from '../../../../commonComponents/QsIcons/mobileLogo.svg';
import { getUrlParams } from '../../../../common';

class MobileHeader extends Component {
  render() {
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `?promotion=${promotion}` : '';

    return (
      <div className={s.container}>
        <a href={`/${promotionQuery}`}>
          <img src={mobileLogo} alt="mobileLogo" />
        </a>
      </div>
    );
  }
}

export default MobileHeader;
