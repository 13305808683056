import React, { Component } from 'react';

import pcLogo from '../../../../commonComponents/QsIcons/pcUnbind.svg';
import mobileLogo from '../../../../commonComponents/QsIcons/mobileUnbind.svg';
import pcLogoForError from '../images/pcError.svg';
import mobileLogoForError from '../images/mobileError.svg';

import s from './BaseCard.module.scss';
import { QsButtonFixedSize } from '../../../../commonComponents/QsButton/QsButton';

interface IProps {
  descChildren: React.ReactElement;
  logoUrl: string;
  buttonText: string;
  theme: 'blue' | 'yellow' | 'green';
  handleClick: () => void;
}

class BaseCard extends Component<IProps, Record<string, never>> {
  render() {
    return (
      <div className={s.container}>
        <img src={this.props.logoUrl} alt="logo" />
        {this.props.descChildren}
        <QsButtonFixedSize
          size="normal"
          type="primary"
          handleClick={this.props.handleClick}
        >
          <span>{this.props.buttonText}</span>
        </QsButtonFixedSize>
      </div>
    );
  }
}

interface MobileBaseCardIProps {
  handleClick: () => void;
  functionName: string;
}

class MobileBaseCard extends Component<MobileBaseCardIProps, Record<string, never>> {
  render() {
    return (
      <BaseCard
        descChildren={
          <>
            <span className={s.title}>设备未添加</span>
            <span className={s.desc}>
              抱歉，{this.props.functionName}功能需要您添加孩子的「手机设备」
            </span>
          </>
        }
        logoUrl={mobileLogo}
        buttonText="立即添加手机设备"
        theme="green"
        handleClick={this.props.handleClick}
      />
    );
  }
}

interface PcBaseCardIProps {
  handleClick: () => void;
  functionName: string;
}

class PcBaseCard extends Component<PcBaseCardIProps, Record<string, never>> {
  render() {
    return (
      <BaseCard
        descChildren={
          <>
            <span className={s.title}>设备未添加</span>
            <span className={s.desc}>
              抱歉，{this.props.functionName}功能需要您添加孩子的「电脑设备」
            </span>
          </>
        }
        logoUrl={pcLogo}
        buttonText="立即添加电脑设备"
        theme="green"
        handleClick={this.props.handleClick}
      />
    );
  }
}

interface MobileErrorBaseCardIProps {
  handleClick: () => void;
}

class MobileErrorBaseCard extends Component<
  MobileErrorBaseCardIProps,
  Record<string, never>
> {
  render() {
    return (
      <BaseCard
        descChildren={
          <div className={s.descContainer}>
            <span className={s.header}>孩子手机设备异常</span>
            <span className={s.errorTips}>请检查孩子手机网络状态</span>
          </div>
        }
        logoUrl={mobileLogoForError}
        buttonText="重试"
        theme="green"
        handleClick={this.props.handleClick}
      />
    );
  }
}

interface PcErrorBaseCardIProps {
  handleClick: () => void;
}

class PcErrorBaseCard extends Component<PcErrorBaseCardIProps, Record<string, never>> {
  render() {
    return (
      <BaseCard
        descChildren={
          <div className={s.descContainer}>
            <span className={s.header}>孩子电脑设备异常</span>
            <span className={s.errorTips}>请检查孩子电脑网络状态</span>
          </div>
        }
        logoUrl={pcLogoForError}
        buttonText="重试"
        theme="green"
        handleClick={this.props.handleClick}
      />
    );
  }
}

export { MobileBaseCard, PcBaseCard, MobileErrorBaseCard, PcErrorBaseCard };
