import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig, fetchPatchConfig } from '../common';
import {
  GET_DASHBOARD_SOFTWARE_COUNT,
  GET_SOFTWARE_LIST,
  GET_TERMINAL_INSTALLED,
  GET_SOFTWARE_DETAIL_NAMES,
  POST_SOFTWARE_SETTING,
  GET_UNINSTALL_COUNT,
} from './constants';
import { getTerminalIds, getTerminalById } from './terminal';

async function getInstallCount() {
  return fetch(`/services/tianxun/apiv2/Software/InstalledCount`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'install count fetch error' }));
}

async function getUninstallCount(day) {
  return fetch(
    `/services/tianxun/apiv2/Software/UninstalledCount?day=${day}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'uninstall count fetch error' }));
}

async function getRestrictedCount() {
  return fetch(`/services/tianxun/apiv2/Software/RestrictedCount`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'restricted count fetch error' }));
}

async function getDashboardSoftwareCount(day) {
  const uninstallCount = await getUninstallCount(day);
  const installCount = await getInstallCount();
  const restrictedCount = await getRestrictedCount();
  return Promise.resolve({
    uninstallCount: uninstallCount.count || 0,
    installCount: installCount.count || 0,
    restrictedCount: restrictedCount.count || 0,
  });
}

export const getDashboardSoftwareCountAction = createActionAsync(
  GET_DASHBOARD_SOFTWARE_COUNT,
  getDashboardSoftwareCount,
  defaultActionOption
);

export const getDashboardSoftwareCountReducer = createReducerAsync(
  getDashboardSoftwareCountAction
);

export const getUninstallCountAction = createActionAsync(
  GET_UNINSTALL_COUNT,
  getUninstallCount,
  defaultActionOption
);

export const getUninstallCountReducer = createReducerAsync(getUninstallCountAction);

/// all in one send request for software list
/// args:
/// *classification*: all
/// *include*: microsoft / non-microsoft /all
/// *forbid*: all / 解压刻录 / 网络应用 /...etc
async function getSoftwareList(forbid, classification) {
  return fetch(
    `/services/tianxun/apiv2/Software/SoftwareList?classification=${classification}&include=non-microsoft&forbid=${forbid}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return {
        softwares:
          data.softwares
            ?.map((s) => ({ ...s, baseName: s.baseName === '' ? s.name : s.baseName }))
            ?.sort((a, b) => b.installCount - a.installCount) ?? [],
        policySoftwares: data?.softwares
          ?.map((s) => ({ ...s, baseName: s.baseName === '' ? s.name : s.baseName }))
          ?.sort((a, b) => b.installCount - a.installCount)
          ?.filter((software) => software.forbidden || software.uninstall),
      };
    })
    .catch(() => ({ success: false, message: 'software list fetch error' }));
}

export const getSoftwareListAction = createActionAsync(
  GET_SOFTWARE_LIST,
  getSoftwareList,
  defaultActionOption
);

export const getSoftwareListReducer = createReducerAsync(getSoftwareListAction);

async function getTerminalInstalled(software) {
  const filter = [
    {
      type: 'software',
      value: {
        nameArray: [software],
        installed: true,
      },
    },
    {
      type: 'controlled',
      value: 'unused',
    },
  ];
  const sort = [{ type: 'hostName', order: 'desc' }];

  const { terminalIds } = await getTerminalIds(filter, sort);
  const terminals = await getTerminalById(terminalIds);

  return {
    software,
    terminals,
  };
}

export const getTerminalInstalledAction = createActionAsync(
  GET_TERMINAL_INSTALLED,
  getTerminalInstalled,
  defaultActionOption
);

export const getTerminalInstalledReducer = createReducerAsync(getTerminalInstalledAction);

async function getSoftwareDetailNames(software, dispatch, getState) {
  const encodedSoftware = encodeURIComponent(software);
  const data = getState().getSoftwareDetailNamesResult?.data ?? {};
  const newData = { ...data };

  return fetch(
    `/services/tianxun/apiv2/Software/VersionInfo?name=${encodedSoftware}`,
    fetchGetConfig()
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const res = await resp.json();
      newData[software] = res;
      return newData;
    })
    .catch(() => ({ success: false, message: 'software detail names fetch error' }));
}

export const getSoftwareDetailNamesAction = createActionAsync(
  GET_SOFTWARE_DETAIL_NAMES,
  getSoftwareDetailNames,
  defaultActionOption
);

export const getSoftwareDetailNamesReducer = createReducerAsync(
  getSoftwareDetailNamesAction
);

function postSoftwareSetting(orgId, softwarePolicies) {
  // 调整时间24:00至23:59
  for (let i = 0; i < softwarePolicies.length; i++) {
    const element = softwarePolicies[i];
    for (let j = 0; j < element.timeRange.length; j++) {
      const item = element.timeRange[j];
      item.end = item.end === '24:00' ? '23:59' : item.end;
    }
  }
  return fetch(
    '/services/tianxun/apiv2/softwarePolicies',
    fetchPatchConfig({ orgId, softwarePolicies })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, data: 'success' };
    })
    .catch(() => ({ success: false, data: 'post policy setting fetch error' }));
}

export const postSoftwareSettingAction = createActionAsync(
  POST_SOFTWARE_SETTING,
  postSoftwareSetting,
  defaultActionOption
);

export const postSoftwareSettingReducer = createReducerAsync(postSoftwareSettingAction);
