import { createActionAsync } from 'redux-act-async';
import { isEmpty } from 'lodash';

import createReducerAsync from './reducer/createReducerAsync';
import fetch, { customFetch } from './fetch.server';
import {
  defaultActionOption,
  fetchPostConfig,
  fetchGetConfig,
  fetchPatchConfig,
} from '../common';
import {
  GET_MOBILE_DEVICE,
  UNBIND_MOBILE_DEVICE,
  GET_MOBILE_APP_LIST,
  UPDATE_MOBILE_APP_SWITCH,
  UPDATE_APP_TIME_POLICY,
  CLEAR_UNBIND_MOBILE_DEVICE,
} from './constants';
import { getOrgInfo } from './user';

function mobileDeviceName(deviceInfo) {
  if (deviceInfo['mobileInfo'] == null && deviceInfo['deviceType'] != 'iOS') {
    return '';
  }
  const mobileInfo = deviceInfo['mobileInfo'];

  let name;
  if (deviceInfo['deviceType'] === 'iOS') {
    name = '苹果手机';
  } else {
    const marketName =
      mobileInfo['marketName'] === null || isEmpty(mobileInfo['marketName'])
        ? mobileInfo['model']
        : mobileInfo['marketName'];
    name = `${mobileInfo['manufacture']} ${marketName}`;
  }

  return name;
}

export async function getMobileDevice() {
  const orgInfo = await getOrgInfo();
  const bodyData = {
    parentId: orgInfo.org.org_id,
  };
  const result = await customFetch(
    '/api/mb/v1/mobiles/find',
    fetchPostConfig(bodyData),
    'json'
  );

  if (result.success) {
    if (result.data === 'not found') {
      return {
        success: true,
        data: [],
      };
    }
    const mobile = result.data.filter((value) => {
      return value['deviceType'] !== 'Windows';
    });

    const mobileInfo = mobile.map((ele) => ({ ...ele, name: mobileDeviceName(ele) }));

    return {
      success: true,
      data: mobileInfo,
    };
  } else {
    return result;
  }
}

export const getMobileDeviceAction = createActionAsync(
  GET_MOBILE_DEVICE,
  getMobileDevice,
  defaultActionOption
);

export const getMobileDeviceReducer = createReducerAsync(getMobileDeviceAction);

async function unbindMobileDevice(deviceType, id) {
  try {
    let resp;
    if (deviceType === 'Android') {
      resp = await fetch('/api/mb/v1/mobiles/unbind/android', fetchPostConfig({ id }));
    } else if (deviceType === 'iOS') {
      resp = await fetch('/api/mb/v1/mobiles/unbind/ios', fetchPostConfig({ id }));
    } else {
      throw new Error(`Invalid deviceType ${deviceType}`);
    }
    if (!resp.ok) {
      throw new Error('Unbound failed');
    }
    return { success: true, data: 'success' };
  } catch (e) {
    return { success: false, message: 'fetch error' };
  }
}

export const unbindMobileDeviceAction = createActionAsync(
  UNBIND_MOBILE_DEVICE,
  unbindMobileDevice,
  defaultActionOption
);

function clearUnbindMobileDevice() {
  return Promise.resolve({ success: '', data: '' });
}

export const clearUnbindMobileDeviceAction = createActionAsync(
  CLEAR_UNBIND_MOBILE_DEVICE,
  clearUnbindMobileDevice,
  defaultActionOption
);

export const unbindMobileDeviceReducer = createReducerAsync([
  unbindMobileDeviceAction,
  clearUnbindMobileDeviceAction,
]);

async function getMobileAppList(id) {
  return await customFetch(
    `/api/mb/v1/applicationControls/${id}`,
    fetchGetConfig(),
    'json'
  );
}

export const getMobileAppListAction = createActionAsync(
  GET_MOBILE_APP_LIST,
  getMobileAppList,
  defaultActionOption
);

export const getMobileAppListReducer = createReducerAsync(getMobileAppListAction);

async function updateMobileAppSwitch(id, enable) {
  const bodyData = {
    enable,
  };
  return fetch(`/api/mb/v1/applicationControls/${id}/state`, fetchPatchConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateMobileAppSwitchAction = createActionAsync(
  UPDATE_MOBILE_APP_SWITCH,
  updateMobileAppSwitch,
  defaultActionOption
);

export const updateMobileAppSwitchReducer = createReducerAsync(
  updateMobileAppSwitchAction
);

async function updateAppTimePolicy(id, regularAppControls) {
  const bodyData = {
    regularAppControls,
  };
  return fetch(`/api/mb/v1/applicationControls/${id}/regular`, fetchPatchConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const updateAppTimePolicyAction = createActionAsync(
  UPDATE_APP_TIME_POLICY,
  updateAppTimePolicy,
  defaultActionOption
);

export const updateAppTimePolicyReducer = createReducerAsync(updateAppTimePolicyAction);
