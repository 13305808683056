import React, { Component } from 'react';

import s from './MemberExpireModal.module.scss';
import memberIcon from './images/memberIcon.svg';
// import PurchaseModal from '../PurchaseModal/PurchaseModal';
import { isZGYD } from '../../../utils/lib';
import { QsModalBaisc } from '../../../commonComponents/QsModal/QsModal';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

interface IProps {
  visible: boolean;
  handleClose: () => void;
}

class MemberExpireModal extends Component<IProps, any> {
  render() {
    return (
      <QsModalBaisc
        closable={true}
        visible={this.props.visible}
        onCancel={this.props.handleClose}
      >
        <div className={s.content}>
          <img src={memberIcon} alt="memberIcon" />
          <div>
            您的{!!isZGYD() ? '' : '青松守护'}会员已到期，开通会员，即可开启全面守护！
          </div>
          <Link to={`/price`}>
            <Button type="primary">
              <span>立即开通</span>
            </Button>
          </Link>
          {/* <PurchaseModal name="年付会员" btnText="立即开通" btnSize="middle" /> */}
        </div>
      </QsModalBaisc>
    );
  }
}

export default MemberExpireModal;
