import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import s from './ActivityRule.module.scss';
import macPro from './images/macPro.svg';
import jingdongCard from './images/jingdongCard.svg';
import qingsong from './images/qingsong.svg';
import { getUrlParams } from '../../../common';

interface IProps {
  anchorId?: string;
  isPage?: boolean;
  isLogin?: boolean;
}

class ActivityRule extends Component<IProps, Record<string, never>> {
  scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      const ele = document.getElementById(anchorName);
      if (ele) {
        ele.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  };

  render() {
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `&promotion=${promotion}` : '';
    const redirect = `?redirect=${window.location.pathname}`;
    return (
      <div className={`${this.props.isPage ? '' : s.ruleContainer}`}>
        <div className={s.title}>活动说明： </div>
        <div className={s.ruleItems}>
          <div>
            <span>1 .活动对象：</span>
            <span>青松守护全体用户；</span>
          </div>
          <div>
            <span>2.活动时间：</span>
            <span>2020年07月01日00点00分至2020年07月17日23点59分；</span>
          </div>
          <div>
            <span>3.活动规则：</span>
            <span>
              活动期间，写下您的
              {this.props.isPage ? (
                <Link
                  to={
                    this.props.isLogin
                      ? '/feedback'
                      : `/login${redirect}${promotionQuery}`
                  }
                  className={s.anchor}
                >
                  青松守护内测体验反馈
                </Link>
              ) : (
                <span
                  className={s.anchor}
                  onClick={() => {
                    this.scrollToAnchor(this.props.anchorId || '');
                  }}
                >
                  青松守护内测体验反馈
                </span>
              )}
              ，活动结束后，官方将评选出最具贡献的 111 位用户，送出豪华大礼，
              <a
                href="https://bbs.cloudtrust.com.cn/thread-1270-1-1.html"
                target="_blank"
                rel="noopener noreferrer"
                className={s.anchor}
              >
                查看评分细则
              </a>
              ；
            </span>
          </div>
          <div>
            <span>4.奖品发放：</span>
            <span>
              获奖用户名单将在活动结束后公示，官方将通过用户注册时填写的手机号码联系获奖用户发放奖品。
            </span>
          </div>
          <div>
            注：本次活动秉持公平、公正、公开原则，由启明星辰集团多个部门参与评审，活动最终解释权归青松守护官方所有。
          </div>
        </div>
        {this.props.isPage && <div className={s.title}>活动礼品： </div>}
        <div className={`${this.props.isPage ? s.pagePrize : s.prizeContainer}`}>
          <div>
            <div>一等奖（1名）</div>
            <img src={macPro} alt="macPro" />
            <div>MacBook Pro</div>
          </div>
          <div>
            <div>二等奖（10名）</div>
            <img src={jingdongCard} alt="jingdongCard" />
            <div>京东 1000 元购物卡</div>
          </div>
          <div>
            <div>三等奖（100名）</div>
            <img src={qingsong} alt="qingsong" />
            <div>青松守护 1 年使用权</div>
          </div>
        </div>
      </div>
    );
  }
}
export default ActivityRule;
