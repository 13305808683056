import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import s from './NewsDetail.module.scss';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import { getUrlParams } from '../../../common';
import {
  getArticleByIdAction,
  getLabelsAction,
  getLikesArticleAction,
  preAndNextArticleAction,
  updateArticleHitsAction,
} from '../../../redux/articles';
import HotLabels from '../../components/HotLabels/HotLabels';
import ArticleLabels from '../../components/ArticleLabels/ArticleLabels';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  getArticleByIdResult: any;
  getArticleByIdAction: (id: string) => void;
  getLabelsAction: () => void;
  getLabelsResult: any;
  getLikesArticleAction: () => void;
  getLikesArticleResult: any;
  preAndNextArticleAction: (articleId: string) => void;
  preAndNextArticleResult: any;
  updateArticleHitsAction: (id: string) => void;
}

class NewsDetail extends Component<IProps, Record<string, never>> {
  async componentDidMount() {
    const id = getUrlParams('id') || '';
    await this.props.updateArticleHitsAction(id);
    await Promise.all([
      this.props.getArticleByIdAction(id),
      this.props.preAndNextArticleAction(id),
      this.props.getLabelsAction(),
      this.props.getLikesArticleAction(),
    ]);
  }

  mapLabel = (labelIds: string) => {
    const labels = this.props.getLabelsResult.data || [];
    return labels
      .map((label: any) => {
        if (labelIds.includes(label.id)) {
          return label;
        }
      })
      .filter((item: string) => !!item);
  };

  renderNav = () => {
    const article = this.props.getArticleByIdResult?.data || {};
    return (
      <div className={s.nav}>
        <Link to="/">{!!isZGYD() ? '首页' : '青松守护首页'}</Link>
        <span className={s.icon}>&gt;</span>
        <Link to="/news">新闻动态</Link>
        <span className={s.icon}>&gt;</span>
        <span>{article.title}</span>
      </div>
    );
  };

  renderHeader = () => {
    const article = this.props.getArticleByIdResult?.data || {};
    const labels = this.mapLabel(article.labelIds || []);
    return (
      <>
        <h1 className={s.title}>{article.title}</h1>
        <div className={s.time}>
          <span>{article.writer}</span>
          <span>
            {moment.unix(article.lastModifiedTime).format('YYYY年MM月DD日 HH:mm')}
          </span>
          <span>{article.hits} 人阅读</span>
        </div>
        <div>
          <ArticleLabels labels={labels} />
        </div>
      </>
    );
  };

  render() {
    const article = this.props.getArticleByIdResult?.data || {};
    const likedArticles = this.props.getLikesArticleResult.data || [];
    const pre = this.props.preAndNextArticleResult?.data?.prev[0];
    const next = this.props.preAndNextArticleResult?.data?.next[0];

    return (
      <HomeLayout showFooterBg={false}>
        <div className={s.container}>
          {this.renderNav()}
          <HotLabels />
          {this.renderHeader()}
          <div className={s.content}>
            <ReactMarkdown>{article.content}</ReactMarkdown>
          </div>
          <div className={s.prevAndNext}>
            {pre && (
              <div>
                上一篇：
                <a href={`/newsDetail?id=${pre.id}`}>{pre.title}</a>
              </div>
            )}
            {next && (
              <div>
                下一篇：
                <a href={`/newsDetail?id=${next.id}`}>{next.title}</a>
              </div>
            )}
          </div>
          <div className={s.prefer}>大家都爱看</div>
          <div className={s.preferLinks}>
            {likedArticles.map((article: any) => (
              <div key={article.id}>
                <a
                  href={`/newsDetail?id=${article.articleId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {article.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      </HomeLayout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getArticleByIdResult: state.getArticleByIdResult.data,
    getLabelsResult: state.getLabelsResult.data,
    getLikesArticleResult: state.getLikesArticleResult.data,
    preAndNextArticleResult: state.preAndNextArticleResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getArticleByIdAction: bindActionCreators(getArticleByIdAction, dispatch),
    getLabelsAction: bindActionCreators(getLabelsAction, dispatch),
    getLikesArticleAction: bindActionCreators(getLikesArticleAction, dispatch),
    preAndNextArticleAction: bindActionCreators(preAndNextArticleAction, dispatch),
    updateArticleHitsAction: bindActionCreators(updateArticleHitsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
