import { createActionAsync } from 'redux-act-async';
import JWT from 'jsonwebtoken';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig, isMobile } from '../common';
import { REGISTER, RESET_REGISTER_ERROR } from './constants';

function register(data) {
  return fetch('/services/tianxun/apiv2/user/register', fetchPostConfig(data))
    .then(async (resp) => {
      const result = await resp.json();
      if (result.success) {
        const token = JWT.sign(
          {
            userId: result.userId,
          },
          'autoLogin'
        );
        const redirect = isMobile() ? '/?type=register' : '/dashboard';

        return await fetch(`/user/autoLogin?token=${token}`).then(async (resp) => {
          if (resp.ok) {
            window.location.href = redirect;
          } else {
            return result;
          }
        });
      } else {
        return result;
      }
    })
    .catch(() => ({ success: false, message: 'register fetch error' }));
}

export const registerAction = createActionAsync(REGISTER, register, defaultActionOption);

function resetRegisterError() {
  return Promise.resolve({ reason: null });
}

export const resetRegisterErrorAction = createActionAsync(
  RESET_REGISTER_ERROR,
  resetRegisterError,
  defaultActionOption
);

export const registerReducer = createReducerAsync([
  registerAction,
  resetRegisterErrorAction,
]);
