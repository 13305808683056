import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import s from './MobileHome.module.scss';
import mobileLogo from '../../../../commonComponents/QsIcons/mobileLogo.svg';
import lockScreen from './images/lockScreen.png';
import webCtrl from './images/webCtrl.png';
import success from './images/success.svg';
import softwareCtrl from './images/softwareCtrl.png';
import remoteAssistant from './images/remoteAssistant.png';
import bannerImg from './images/banner.png';
// import wechatQrcode from './images/wechatQrcode.svg';
// import familyImg from './images/family.svg';
import middleImg from './images/middle.png';
import { checkLoginStateAction, getOrgInfoAction } from '../../../../redux/user';
import { getUrlParams } from '../../../../common';
import MobileFooter from '../../components/MobileFooter/MobileFooter';
import { QsModalBaisc } from '../../../../commonComponents/QsModal/QsModal';
import QrCode from '../../../../qsParent/components/QrCode/QrCode';
import { getParentReleaseInfoAction } from '../../../../redux/parentReleaseInfo';
// import titleImage from './images/title.png';

import timeIcon from '../../../../commonComponents/QsIcons/timeIcon.svg';
import FunctionCard from './FunctionCard';

import phoneIcon from './images/phone.png';
import deviceIcon from './images/device.png';

import applicationIcon from '../../../../commonComponents/QsIcons/applicationIcon.svg';
import remoteIcon from '../../../../commonComponents/QsIcons/remoteIcon.svg';
import learningModeIcon from '../../../../commonComponents/QsIcons/learningModeIcon.svg';
import variables from '../../../../variables.module.scss';
import { QsButtonMobileLogin } from '../../../../commonComponents/QsButton/QsButton';

interface IProps {
  checkLoginStateAction: () => void;
  getOrgInfoAction: () => void;
  checkLoginStateResult: any;
  getOrgInfoResult: any;
  getParentReleaseInfoAction: () => void;
  getParentReleaseInfoResult: any;
}

interface IStates {
  showRegister: boolean;
  modalClosed: boolean;
  showModal: boolean;
  showSuccess: boolean;
}

class MobileHome extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showRegister: false,
      modalClosed: false,
      showModal: false,
      showSuccess: false,
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.checkLoginStateAction(),
      this.props.getOrgInfoAction(),
      this.props.getParentReleaseInfoAction(),
    ]);

    window.addEventListener('scroll', this.handleScroll);
    const org = this.props.getOrgInfoResult || {};
    const { isLogin } = this.props.checkLoginStateResult;
    if (
      isLogin &&
      !localStorage.getItem(`${org.org_id}_first`) &&
      getUrlParams('type') === 'register'
    ) {
      this.setState({ showModal: true });
      localStorage.setItem(`${org.org_id}_first`, 'true');
    }
    if (
      isLogin &&
      !localStorage.getItem(`${org.org_id}_first`) &&
      getUrlParams('type') === 'login'
    ) {
      this.setState({ showSuccess: true });
      localStorage.setItem(`${org.org_id}_first`, 'true');
    }
  }

  handleScroll = () => {
    if (!this.state.showRegister) {
      this.setState({ showRegister: true });
    }
  };

  renderFuncs = () => {
    const items = [
      {
        title: '约定使用时间功能',
        desc: '管理孩子设备使用时长，合理规划娱乐休息时间',
        img: lockScreen,
      },
      {
        title: '上网守护功能',
        desc: '一键屏蔽与学习无关的网站，提高孩子网课学习效率',
        img: webCtrl,
      },
      {
        title: '应用守护功能',
        desc: '一键禁用游戏，帮助孩子在学习期间排除外界干扰，保障学习效率，提升学习成绩',
        img: softwareCtrl,
      },
      {
        title: '远程守护功能',
        desc: '一键远程至孩子设备，查看孩子设备使用情况，帮助孩子解决学习相关问题',
        img: remoteAssistant,
      },
    ];

    return items.map((item) => (
      <div key={item.title} className={s.funcContainer}>
        <div className={s.line} />
        <div className={s.funcTitle}>{item.title}</div>
        <div className={s.funcDesc}>{item.desc}</div>
        <img src={item.img} alt="" />
      </div>
    ));
  };

  getFunctionInfo = () => {
    return [
      {
        icon: phoneIcon,
        lineColor: variables.primaryColor,
        title: '手机守护',
        desc: '手机防沉迷，家长更放心',
        functionList: [
          {
            icon: timeIcon,
            color: variables.colorFunctionWarning,
            title: '约定使用时间',
            desc: '和孩子约定每天什么时候可以用手机，可以用多长时间。',
          },
          {
            icon: remoteIcon,
            color: variables.colorFunctionInfo,
            title: '亲子同屏',
            desc: '共享孩子的屏幕，和孩子同屏学习，共同成长。',
          },
          {
            icon: applicationIcon,
            color: variables.primaryColor,
            title: 'App 管理',
            desc: '设置可用 App 及其使用时长。',
          },
        ],
      },
      {
        icon: deviceIcon,
        lineColor: variables.primaryColor,
        title: '电脑守护',
        desc: '上网有过滤，网课更安心',
        functionList: [
          {
            icon: learningModeIcon,
            color: variables.colorFunctionError,
            title: '学习模式',
            desc: '在学习期间屏蔽游戏、短视频对孩子的干扰。',
          },
          {
            icon: timeIcon,
            color: variables.colorFunctionWarning,
            title: '约定使用时间',
            desc: '和孩子约定每天什么时候可以用电脑，可以用多长时间。',
          },
          {
            icon: remoteIcon,
            color: variables.colorFunctionInfo,
            title: '亲子同屏',
            desc: '共享孩子的屏幕，和孩子同屏学习，共同成长。',
          },
          {
            icon: applicationIcon,
            color: variables.primaryColor,
            title: 'App管理',
            desc: '设置可用App及其使用时间。',
          },
        ],
      },
    ];
  };

  renderheaderLogo = () => {
    return <img src={mobileLogo} alt="mobileLogo" />;
  };

  loginRegister = () => {
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `?promotion=${promotion}` : '';
    return (
      <Link to={`/login${promotionQuery}`}>
        <div className={s.loginBtn}>
          <QsButtonMobileLogin type="primary">
            <span>注册</span>
          </QsButtonMobileLogin>
        </div>
      </Link>
    );
  };

  render() {
    const { isLogin } = this.props.checkLoginStateResult;
    const { data } = this.props.getParentReleaseInfoResult;
    const url = data?.['downloadURL'] || '';
    return (
      <div>
        <LoginSuccessMessage visible={this.state.showSuccess} />
        <div className={s.containBox}>
          <div className={s.qsHeader}>
            <img src={mobileLogo} alt="" />
          </div>
          {/* <div className={s.qsTitle}>
          <img src={titleImage} alt="" />
        </div>
        <div className={s.qsTitleDesc}>
          随时随地管理孩子电子设备，让孩子健康有度上网！
        </div> */}

          <div className={s.qsMobileWechat}>
            <img src={bannerImg} alt="wechatQrcode" />
          </div>

          <div className={s.qsFamilyImg}>
            <div className={s.qsSecondTitle}>我们能为您的孩子做什么？</div>
            <img src={middleImg} alt="family img" />
            {/* <div className={s.qsSecondDesc}>同时管理手机和电脑，帮助您“青松”管理孩子</div> */}
          </div>
          <div>
            {this.getFunctionInfo().map((item: any) => {
              return (
                <FunctionCard
                  lineColor={item.lineColor}
                  key={item.title}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                  functionList={item.functionList}
                />
              );
            })}
          </div>
          {!isLogin && this.state.showRegister && this.loginRegister()}
          <SuccessModel
            url={url}
            visible={this.state.showModal}
            clickHandle={() => {
              this.setState({
                showModal: false,
              });
            }}
          />

          <div className={!isLogin ? s.footerBottom : ''}>
            <MobileFooter />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    checkLoginStateResult: state.checkLoginStateResult.data,
    getParentReleaseInfoResult: state.getParentReleaseInfoResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    getParentReleaseInfoAction: bindActionCreators(getParentReleaseInfoAction, dispatch),
  };
}

interface ISuccessProps {
  visible: boolean;
  clickHandle: () => void;
  url: string;
}

function SuccessModel(props: ISuccessProps) {
  return (
    <QsModalBaisc closable={false} visible={props.visible}>
      <div className={s.modelContainer}>
        <div>
          <span className={s.modelTitle}>恭喜您注册成功！</span>
        </div>
        <div className={s.desc}>
          <span>3天会员已发放，立即下载使用吧</span>
        </div>
        <div className={s.qrcodeBackground}>
          <QrCode value={props.url} elementId="download-parent" width={130} />
        </div>
        <div className={s.modelDesc}>
          <span>（保存截图，扫码下载）</span>
        </div>
        <div
          className={s.downloadBtn}
          onClick={() => {
            window.location.replace('/parentAppDownload');
            props.clickHandle();
          }}
        >
          下载青松守护
        </div>
        <div
          className={s.cancelDownBtn}
          onClick={() => {
            props.clickHandle();
          }}
        >
          取消
        </div>
        <div
          className={s.cancelDialogBtn}
          onClick={() => {
            props.clickHandle();
          }}
        ></div>
      </div>
    </QsModalBaisc>
  );
}

interface ILoginSuccess {
  visible: boolean;
}

function LoginSuccessMessage(props: ILoginSuccess) {
  console.log(props.visible);
  if (props.visible) {
    return (
      <div className={s.loginSuccess}>
        <div className={s.successTop}>
          <img src={success} alt="" />
          <span>您已登录成功！</span>
        </div>
        <div className={s.successBottom}>
          <span>请使用电脑端浏览器访问 qscare.cn 体验全部功能</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);
