import React, { useEffect } from 'react';
import QrCodeWithLogo from 'qrcode-with-logos';

interface IProps {
  value: string;
  elementId: string;
  width?: number;
  logoSrc: string;
}

function QrCodeLogo(props: IProps) {
  const renderQrCode = () => {
    const canvas: any = document.getElementById(props.elementId);
    new QrCodeWithLogo({
      canvas: canvas,
      content: props.value,
      width: props.width,
      logo: {
        src: props.logoSrc,
        borderRadius: 6,
        borderSize: 0,
        logoSize: 0.2,
      },
      nodeQrCodeOptions: {
        margin: 3,
      },
    });
  };

  useEffect(() => {
    renderQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return <canvas id={props.elementId} />;
}

export default QrCodeLogo;
