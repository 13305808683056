import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig, fetchPutConfig } from '../common';
import {
  GET_ALL_CONFIG,
  UPDATE_POLICY_SWITCHES,
  RESET_UPDATE_POLICY_SWITCHES,
} from './constants';

function getAllConfig() {
  return fetch('/services/tianxun/apiv2/config', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'config fetch error' }));
}

export const getAllConfigAction = createActionAsync(
  GET_ALL_CONFIG,
  getAllConfig,
  defaultActionOption
);

export const getAllConfigReducer = createReducerAsync(getAllConfigAction);

function updatePolicySwitches(data) {
  return fetch('/services/tianxun/apiv2/config', fetchPutConfig(data))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      return { success: true, message: 'update policy switches success' };
    })
    .catch(() => ({ success: false, message: 'update policy switches error' }));
}

export const updatePolicySwitchesAction = createActionAsync(
  UPDATE_POLICY_SWITCHES,
  updatePolicySwitches,
  defaultActionOption
);

function resetUpdatePolicySwitches() {
  return Promise.resolve({});
}

export const resetUpdatePolicySwitchesAction = createActionAsync(
  RESET_UPDATE_POLICY_SWITCHES,
  resetUpdatePolicySwitches,
  defaultActionOption
);

export const updatePolicySwitchesReducer = createReducerAsync([
  updatePolicySwitchesAction,
  resetUpdatePolicySwitchesAction,
]);
