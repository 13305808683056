import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getCurrentWeek, getUrlQuery } from '../../../common';
import { english2Chinese } from './utils';
import s from './TimeMgrNew.module.scss';
import { getMobileDeviceAction } from '../../../redux/mobileAppDevice';
import { getTerminalInfoAction } from '../../../redux/terminal';
import {
  getLockScreenControlsAction,
  resetUpdateLockScreenAction,
  updateLockScreenControlsAction,
} from '../../../redux/mobileLockScreen';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';
import MemberExpireModal from '../../components/MemberExpireModal/MemberExpireModal';
import BindFlowModal from '../../components/BindFlowModal/BindFlowModal';
import lockIcon from './images/lockIcon.svg';
import { isZGYD } from '../../../utils/lib';
import { QsButtonFixedSize } from '../../../commonComponents/QsButton/QsButton';
import MultiTimeSelector from '../../components/MultiTimeSelector/MultiTimeSelector';
import { ITimeData } from '../../components/MultiTimeSelector/TimeItem';

interface IProps {
  getLockScreenControlsAction: (mobileId: string) => void;
  getMobileDeviceAction: () => void;
  getMobileDeviceResult: any;
  getTerminalInfoAction: () => void;
  getTerminalInfoResult: any;
  getOrgInfoResult: any;
  getProductStateResult: any;
  getLockScreenControlsResult: any;
  updateLockScreenControlsResult: any;
  updateLockScreenControlsAction: (
    policyId: string,
    mode: string,
    customLockScreenRule: any,
    enable: boolean
  ) => void;
  resetUpdateLockScreenAction: () => void;
}

function TimeMgrNew(props: IProps) {
  const type: any = getUrlQuery('type');
  const today = getCurrentWeek();
  const [selectedWeek, setSelectedWeek] = useState(today);
  const [time, setTime] = useState<any>({});
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentTime, setCurrentTime] = useState([]);
  const [alertInfo, setAlertInfo] = useState(
    (): {
      type: 'success' | 'error';
      message: string;
    } => {
      return {
        type: 'success',
        message: '开启成功。',
      };
    }
  );

  useEffect(() => {
    document.title = !!isZGYD() ? '约定使用时间' : '青松守护 - 约定使用时间';
    const fetchData = async () => {
      await Promise.all([props.getMobileDeviceAction(), props.getTerminalInfoAction()]);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const success = props.updateLockScreenControlsResult.success;
    if (success) {
      const enable = props.getLockScreenControlsResult.data?.enable;
      setShowAlert(true);
      setAlertInfo({ type: 'success', message: enable ? '已关闭。' : '开启成功。' });
    } else if (success === false) {
      setShowAlert(true);
      setAlertInfo({ type: 'error', message: '操作失败，请稍后重试。' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateLockScreenControlsResult]);

  const mobileDeviceInfo = () => {
    const mobileDevice = props.getMobileDeviceResult.data || [];
    return mobileDevice[0] ? mobileDevice[0] : null;
  };

  const getPcTerminal = () => {
    const onlineTerminals = props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = props.getTerminalInfoResult.offlineTerminals || [];
    const terminalsArr = onlineTerminals.concat(offlineTerminals);

    const terminal =
      terminalsArr.length < 1
        ? null
        : {
            isOnline: terminalsArr[0].online,
            name: terminalsArr[0].hostName,
            system: terminalsArr[0].pcType,
          };

    return terminal;
  };

  const fetchMobileLockScreen = async () => {
    const mobileId = mobileDeviceInfo() ? mobileDeviceInfo().id : '';
    await props.getLockScreenControlsAction(mobileId);
  };

  const fetchPcLockScreen = async () => {
    const org = props.getOrgInfoResult || {};
    const orgId = org.org_id;
    await props.getLockScreenControlsAction(orgId);
  };

  const fetchLockScreen = () => {
    if (type === 'mobile') {
      fetchMobileLockScreen();
    } else if (type === 'pc') {
      fetchPcLockScreen();
    }
  };

  useEffect(() => {
    fetchLockScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getMobileDeviceResult, props.getTerminalInfoResult, props.getOrgInfoResult]);

  const getCurrentLockScreen = () => {
    const mode = props.getLockScreenControlsResult.data?.mode;
    let lockScreen;
    if (mode === 'custom') {
      lockScreen = props.getLockScreenControlsResult.data?.customLockScreenRule;
    } else if (mode === 'learning') {
      lockScreen = props.getLockScreenControlsResult.data?.presetLockScreenRule.learning;
    } else if (mode === 'zen') {
      lockScreen = props.getLockScreenControlsResult.data?.presetLockScreenRule.zen;
    }

    return lockScreen;
  };

  useEffect(() => {
    const defaultLockScreen = getCurrentLockScreen();
    setTime(defaultLockScreen || {});
    setCurrentTime((defaultLockScreen || {})[selectedWeek] || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getLockScreenControlsResult]);

  useEffect(() => {
    setCurrentTime(time[selectedWeek] || []);
  }, [time]);

  const handleWeekChange = (value: string) => {
    setSelectedWeek(value);
    setCurrentTime(time[value] || []);
  };

  const handleTimeChange = (value: any) => {
    setTime({ ...time, [selectedWeek]: value });
  };

  const isLimitExpired = () => {
    const availableLimit =
      props.getProductStateResult && props.getProductStateResult.availableLimit;

    return availableLimit && availableLimit.length === 0;
  };

  const handleSave = async (enable: boolean) => {
    const currentDevice = type === 'pc' ? getPcTerminal() : mobileDeviceInfo();
    if (isLimitExpired()) {
      setShowExpireModal(true);
    } else if (!currentDevice) {
      setShowBindModal(true);
    } else {
      const policyId = props.getLockScreenControlsResult?.data.id;
      await props.updateLockScreenControlsAction(policyId, 'custom', time, enable);
      await fetchLockScreen();
    }
  };

  const currentLockScreen = getCurrentLockScreen();
  const currentDefaultTime = currentLockScreen ? currentLockScreen[selectedWeek] : [];

  const enable = props.getLockScreenControlsResult.data?.enable;
  return (
    <div className={s.container}>
      <div className={s.title}>设定不可用时间段</div>
      <div className={s.tips}>
        选择对应日期，设置不可用时间；不可用时间内设备将被强制锁屏。
      </div>
      <div>
        <WeekSelect selected={selectedWeek} handleChange={handleWeekChange} />
        <div className={s.timeContainer}>
          {enable && (
            <div className={s.mask}>
              <div>
                <span className={s.lockIcon}>
                  <img src={lockIcon} alt="" />
                </span>
                功能生效中，暂不支持修改。
              </div>
            </div>
          )}
          <MultiTimeSelector
            desc="鼠标拖动“不可用时间”格子即可添加/删除不可用时间段。"
            cardColorDesc={['不可用', '可用']}
            defaultTime={currentDefaultTime}
            selectedTime={currentTime}
            selectedTimeContainerHeight={300}
            handleTimeChange={(value: ITimeData[]) => {
              handleTimeChange(value);
            }}
          />
        </div>
        <div className={s.btnContainer}>
          <QsButtonFixedSize
            size="normal"
            type={enable ? 'primaryOutline' : 'primary'}
            handleClick={() => {
              handleSave(!enable);
            }}
          >
            <span>{enable ? '关闭' : '开启'}</span>
          </QsButtonFixedSize>
        </div>
      </div>
      <QsAlert
        visible={showAlert}
        type={alertInfo.type}
        message={alertInfo.message}
        position="absolute"
        onClose={() => {
          props.resetUpdateLockScreenAction();
          setShowAlert(false);
        }}
      />
      <MemberExpireModal
        visible={showExpireModal}
        handleClose={() => {
          setShowExpireModal(false);
        }}
      />
      <BindFlowModal
        defaultType={type}
        visible={showBindModal}
        handleCancel={() => {
          setShowBindModal(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getTerminalInfoResult: state.getTerminalInfoResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    getLockScreenControlsResult: state.getLockScreenControlsResult.data,
    updateLockScreenControlsResult: state.updateLockScreenControlsResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
    getLockScreenControlsAction: bindActionCreators(
      getLockScreenControlsAction,
      dispatch
    ),
    updateLockScreenControlsAction: bindActionCreators(
      updateLockScreenControlsAction,
      dispatch
    ),
    resetUpdateLockScreenAction: bindActionCreators(
      resetUpdateLockScreenAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeMgrNew);

interface IWeekSelectProps {
  selected: string;
  handleChange: (value: string) => void;
}

function WeekSelect(props: IWeekSelectProps) {
  const today = getCurrentWeek();
  const weeks = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  return (
    <div className={s.header}>
      {weeks.map((item) => {
        const isToday = today === item;
        const selected = props.selected === item;
        return (
          <span
            key={item}
            className={selected ? s.weekSelected : ''}
            onClick={() => {
              props.handleChange(item);
            }}
          >
            {isToday ? '今天' : `周${english2Chinese[item]}`}
          </span>
        );
      })}
    </div>
  );
}
