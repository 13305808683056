import Promise from 'bluebird';
import fetch, { Request, Headers, Response } from 'node-fetch';
import AbortController from 'abort-controller';

fetch.Promise = Promise;
Response.Promise = Promise;

function localFetch(url, options) {
  return fetch(url, options);
}

function customFetch(url, config, responseType = 'json') {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 5000);
  const fetchConfig = {
    ...config,
    signal: controller.signal,
  };

  return localFetch(url, fetchConfig)
    .then(async (resp) => {
      if (resp.status === 429) {
        return { success: true, data: 'Too Many Requests' };
      }
      if (resp.status === 404) {
        return { success: true, data: 'not found' };
      }
      if (!resp.ok) return Promise.reject('fetch error');
      if (responseType === 'json') {
        const data = await resp.json();
        return { success: true, data };
      }
    })
    .catch(() => {
      return { success: false, data: 'fetch error' };
    })
    .finally(() => {
      clearTimeout(timeout);
    });
}

export { localFetch as default, Request, Headers, Response, customFetch };
