export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';
export const GET_PHONE_CAPTCHA = 'GET_PHONE_CAPTCHA';
export const RESET_PHONE_CAPTCHA_ERROR = 'RESET_PHONE_CAPTCHA_ERROR';
export const REGISTER = 'REGISTER';
export const RESET_REGISTER_ERROR = 'RESET_REGISTER_ERROR';
export const SEARCH_USER_BY_PHONE = 'SEARCH_USER_BY_PHONE';
export const RESET_SEARCH_USER_BY_PHONE = 'RESET_SEARCH_USER_BY_PHONE';
export const GET_ORG_INFO = 'GET_ORG_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_ALL_CONFIG = 'GET_ALL_CONFIG';
export const UPDATE_POLICY_SWITCHES = 'UPDATE_POLICY_SWITCHES';
export const GET_TERMINAL_INFO = 'GET_TERMINAL_INFO';
export const UPDATE_CLIENT_NAME = 'UPDATE_CLIENT_NAME';
export const DELETE_TERMINAL = 'DELETE_TERMINAL';
export const GET_CLIENT_CONFIG = 'GET_CLIENT_CONFIG';
export const UPDATE_UNINSTALL_PWD = 'UPDATE_UNINSTALL_PWD';
export const GET_LOCK_SCREEN_DURATIONS = 'GET_LOCK_SCREEN_DURATIONS';
export const UPDATE_LOCK_SCREEN_DURATIONS = 'UPDATE_LOCK_SCREEN_DURATIONS';
export const CLEAR_UPDATE_LOCK_SCREEN_DURATIONS = 'CLEAR_UPDATE_LOCK_SCREEN_DURATIONS';
export const DELETE_LOCK_SCREEN_DURATIONS = 'DELETE_LOCK_SCREEN_DURATIONS';
export const ADD_LOCK_SCREEN_DURATIONS = 'ADD_LOCK_SCREEN_DURATIONS';
export const GET_BLOCK_SITES_DETAILS = 'GET_BLOCK_SITES_DETAILS';
export const UPDATE_DEFAULT_WEBSITES = 'UPDATE_DEFAULT_WEBSITES';
export const GET_DASHBOARD_SOFTWARE_COUNT = 'GET_DASHBOARD_SOFTWARE_COUNT';
export const GET_REMOTE_7_DAYS_TIMES = 'GET_REMOTE_7_DAYS_TIMES';
export const GET_REMOTE_7_DAYS_EACH_DAY_TIMES = 'GET_REMOTE_7_DAYS_EACH_DAY_TIMES';
export const REFRESH_ORDER = 'REFRESH_ORDER';
export const GET_ONLINE_PAYMENT = 'GET_ONLINE_PAYMENT';
export const RESET_ONLINE_PAYMENT = 'RESET_ONLINE_PAYMENT';
export const CHECK_LOGIN_STATE = 'CHECK_LOGIN_STATE';
export const GET_SOFTWARE_LIST = 'GET_SOFTWARE_LIST';
export const GET_TERMINAL_INSTALLED = 'GET_TERMINAL_INSTALLED';
export const GET_SOFTWARE_DETAIL_NAMES = 'GET_SOFTWARE_DETAIL_NAMES';
export const POST_SOFTWARE_SETTING = 'POST_SOFTWARE_SETTING';
export const GET_UNINSTALL_COUNT = 'GET_UNINSTALL_COUNT';
export const GET_PAID_ORDERS = 'GET_PAID_ORDERS';
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const GET_PRODUCT_STATE = 'GET_PRODUCT_STATE';
export const COMPLETE_SUMMARY = 'COMPLETE_SUMMARY';
export const PUBLISH_FEEDBACK = 'PUBLISH_FEEDBACK';
export const GET_ALL_FEEDBACK = 'GET_ALL_FEEDBACK';
export const ALL_FEEDBACK_COUNTS = 'ALL_FEEDBACK_COUNTS';
export const GET_MINE_FEEDBACK = 'GET_MINE_FEEDBACK';
export const MINE_FEEDBACK_COUNTS = 'MINE_FEEDBACK_COUNTS';
export const FEEDBACK_LIKE = 'FEEDBACK_LIKE';
export const FEEDBACK_DELETE = 'FEEDBACK_DELETE';
export const ACTIVATE_PRODUCT = 'ACTIVATE_PRODUCT';
export const GET_PRICES_INFO = 'GET_PRICES_INFO';
export const FETCH_ORDER = 'FETCH_ORDER';
export const UPDATE_PAY_METHOD = 'UPDATE_PAY_METHOD';
export const ADD_CHILD_INFO = 'ADD_CHILD_INFO';
export const GET_CHILD_INFO = 'GET_CHILD_INFO';
export const UPDATE_CHILD_INFO = 'UPDATE_CHILD_INFO';
export const DELETE_CHILD_INFO = 'DELETE_CHILD_INFO';
export const GET_MOBILE_DEVICE = 'GET_MOBILE_DEVICE';
export const UNBIND_MOBILE_DEVICE = 'UNBIND_MOBILE_DEVICE';
export const CLEAR_UNBIND_MOBILE_DEVICE = 'CLEAR_UNBIND_MOBILE_DEVICE';
export const GET_ENVIRONMENTS = 'GET_ENVIRONMENTS';
export const GET_MOBILE_APP_LIST = 'GET_MOBILE_APP_LIST';
export const UPDATE_MOBILE_APP_SWITCH = 'UPDATE_MOBILE_APP_SWITCH';
export const UPDATE_APP_TIME_POLICY = 'UPDATE_APP_TIME_POLICY';
export const GET_BEHAVIOR_LIST = 'GET_BEHAVIOR_LIST';
export const GET_NEXT_BEHAVIOR_LIST = 'GET_NEXT_BEHAVIOR_LIST';
export const TOGGLE_OUTER_NAV_STATUS = 'TOGGLE_OUTER_NAV_STATUS';
export const CURRENT_CHILD = 'CURRENT_CHILD';
export const POSITION_POLICY_SWITCH = 'POSITION_POLICY_SWITCH';
export const POSITION_INFO = 'POSITION_INFO';
export const UPDATE_POSITION_POLICY_SWITCH = 'UPDATE_POSITION_POLICY_SWITCH';
export const SCREEN_SHOT_UPDATE_STATUS = 'SCREEN_SHOT_UPDATE_STATUS';
export const TRIGGER_SCREEN_SHOT = 'TRIGGER_SCREEN_SHOT';
export const COMMUNICATION_INFO = 'COMMUNICATION_INFO';
export const UPDATE_COMMUNICATION_POLICY_SWITCH = 'UPDATE_COMMUNICATION_POLICY_SWITCH';
export const UPDATE_PHONE_CALL_SWITCH = 'UPDATE_PHONE_CALL_SWITCH';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const ONE_KEY_BLOCK_SWITCH = 'ONE_KEY_BLOCK_SWITCH';
export const GET_HOT_LABELS = 'GET_HOT_LABELS';
export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLE_BY_ID = 'GET_ARTICLE_BY_ID';
export const GET_ARTICLE_COUNT = 'GET_ARTICLE_COUNT';
export const GET_LABELS = 'GET_LABELS';
export const GET_LIKES_ARTICLES = 'GET_LIKES_ARTICLES';
export const PREV_AND_NEXT_ARTICLES = 'PREV_AND_NEXT_ARTICLES';
export const UPDATE_ARTICLE_HITS = 'UPDATE_ARTICLE_HITS';
export const DELETE_MOBILE_LOCK_SCREEN_CONTROLS = 'DELETE_MOBILE_LOCK_SCREEN_CONTROLS';
export const DOWNLOAD_QS_INSTALLER = 'DOWNLOAD_QS_INSTALLER';
export const DOWNLOAD_QS_PC_CLIENT = 'DOWNLOAD_QS_PC_CLIENT';
export const DELETE_IMAGE_BY_ID = 'DELETE_IMAGE_BY_ID';
export const CHECK_SCREEN_SHOT_PERMISSION = 'CHECK_SCREEN_SHOT_PERMISSION';
export const GET_ALL_IMAGES = 'GET_ALL_IMAGES';
export const GET_ORIGINAL_BEHAVIOR_LIST = 'GET_ORIGINAL_BEHAVIOR_LIST';
export const GET_CHILDREN_AVATARS = 'GET_CHILDREN_AVATARS';
export const GET_TOGGLE_WEB_ACL = 'GET_TOGGLE_WEB_ACL';
export const UPDATE_TOGGLE_WEB_ACL = 'UPDATE_TOGGLE_WEB_ACL';
export const GET_BLACK_EXCEPTION = 'GET_BLACK_EXCEPTION';
export const ADD_BLACK_EXCEPTION = 'ADD_BLACK_EXCEPTION';
export const DELETE_BLACK_EXCEPTION = 'DELETE_BLACK_EXCEPTION';
export const UPDATE_BLACK_EXCEPTION = 'UPDATE_BLACK_EXCEPTION';
export const RESET_UPDATE_BLACK_EXCEPTION = 'RESET_UPDATE_BLACK_EXCEPTION';
export const RESET_DELETE_BLACK_EXCEPTION = 'RESET_DELETE_BLACK_EXCEPTION';
export const RESET_ADD_BLACK_EXCEPTION = 'RESET_ADD_BLACK_EXCEPTION';
export const RESET_UPDATE_DEFAULT_WEBSITES = 'RESET_UPDATE_DEFAULT_WEBSITES';
export const RESET_UPDATE_POLICY_SWITCHES = 'RESET_UPDATE_POLICY_SWITCHES';
export const RESET_UPDATE_TOGGLE_WEB_ACL = 'RESET_UPDATE_TOGGLE_WEB_ACL';
export const GET_WHITE_LIST = 'GET_WHITE_LIST';
export const ADD_WHITE_LIST = 'ADD_WHITE_LIST';
export const DELETE_WHITE_LIST = 'DELETE_WHITE_LIST';
export const UPDATE_WHITE_LIST = 'UPDATE_WHITE_LIST';
export const RESET_UPDATE_WHITE_LIST = 'RESET_UPDATE_WHITE_LIST';
export const RESET_ADD_WHITE_LIST = 'RESET_ADD_WHITE_LIST';
export const RESET_DELETE_WHITE_LIST = 'RESET_DELETE_WHITE_LIST';
export const GET_LOCK_SCREEN_CONTROLS = 'GET_LOCK_SCREEN_CONTROLS';
export const GET_PC_LOCK_SCREEN_CONTROLS = 'GET_PC_LOCK_SCREEN_CONTROLS';
export const UPDATE_LOCK_SCREEN_CONTROLS = 'UPDATE_LOCK_SCREEN_CONTROLS';
export const RESET_UPDATE_LOCK_SCREEN_CONTROLS = 'RESET_UPDATE_LOCK_SCREEN_CONTROLS';
export const GET_CUSTOM_BLACK_LIST = 'GET_CUSTOM_BLACK_LIST';
export const ADD_CUSTOM_BLACK_LIST = 'ADD_CUSTOM_BLACK_LIST';
export const RESET_ADD_CUSTOM_BLACK_LIST = 'RESET_ADD_CUSTOM_BLACK_LIST';
export const DELETE_CUSTOM_BLACK_LIST = 'DELETE_CUSTOM_BLACK_LIST';
export const RESET_DELETE_CUSTOM_BLACK_LIST = 'RESET_DELETE_CUSTOM_BLACK_LIST';
export const UPDATE_CUSTOM_BLACK_LIST = 'UPDATE_CUSTOM_BLACK_LIST';
export const RESET_UPDATE_CUSTOM_BLACK_LIST = 'RESET_UPDATE_CUSTOM_BLACK_LIST';
export const TOGGLE_CUSTOM_BLACK_LIST = 'TOGGLE_CUSTOM_BLACK_LIST';
export const RESET_TOGGLE_CUSTOM_BLACK_LIST = 'RESET_TOGGLE_CUSTOM_BLACK_LIST';
export const GET_IOS_RELEASE_INFO = 'GET_IOS_RELEASE_INFO';
export const PARENT_RELEASE_INFO = 'PARENT_RELEASE_INFO';
export const GET_PC_REMOTE_INFO = 'GET_PC_REMOTE_INFO';

export const REMOTE_DESKTOP_REQUEST = 'REMOTE_DESKTOP_REQUEST';
export const REMOTE_DESKTOP_VERIFIED = 'REMOTE_DESKTOP_VERIFIED';
export const REMOTE_DESKTOP_SUCCESS = 'REMOTE_DESKTOP_SUCCESS';
export const REMOTE_DESKTOP_FAILURE = 'REMOTE_DESKTOP_FAILURE';
export const REMOTE_DESKTOP_CLOSED = 'REMOTE_DESKTOP_CLOSED';
export const GET_ALL_FAQ = 'GET_ALL_FAQ';
export const GET_ALL_FAQ_CATEGORIES = 'GET_ALL_FAQ_CATEGORIES';
export const SEARCH_FAQ = 'SEARCH_FAQ';
export const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
export const GET_PARTICIPATE_INFO = 'GET_PARTICIPATE_INFO';
export const GET_ONLINE_COURSE_LIST = 'GET_ONLINE_COURSE_LIST';
export const GET_ONLINE_COURSE_ALL = 'GET_ONLINE_COURSE_ALL';
export const SUBMIT_WORK_ORDER = 'SUBMIT_WORK_ORDER';
export const CLEAR_SUBMIT_WORK_ORDER = 'CLEAR_SUBMIT_WORK_ORDER';
export const SET_ONLINE_COURSE = 'SET_ONLINE_COURSE';
export const CLEAR_SET_ONLINE_COURSE = 'CLEAR_SET_ONLINE_COURSE';
export const DELETE_ONLINE_COURSE = 'DELETE_ONLINE_COURSE';
export const EXCHANGE_LIMIT_BY_CODE = 'EXCHANGE_LIMIT_BY_CODE';
export const CREATE_ACTIVE_CODE = 'CREATE_ACTIVE_CODE';
export const GET_ACTIVE_CODE_BY_ORDER_ID = 'GET_ACTIVE_CODE_BY_ORDER_ID';
export const RESET_GET_ACTIVE_CODE_BY_ORDER_ID = 'RESET_GET_ACTIVE_CODE_BY_ORDER_ID';
export const RESET_EXCHANGE_LIMIT_BY_CODE_ERROR = 'RESET_EXCHANGE_LIMIT_BY_CODE_ERROR';
export const SET_GIFT_MODAL = 'SET_GIFT_MODAL';
export const GET_VERSION_INFO = 'GET_VERSION_INFO';
export const UPLOAD_DOMAIN_FILE = 'UPLOAD_DOMAIN_FILE';
export const GET_CUSTOM_FILE = 'GET_CUSTOM_FILE';
export const UPDATE_CUSTOM_FILE = 'UPDATE_CUSTOM_FILE';
export const DELETE_CUSTOM_FILE = 'DELETE_CUSTOM_FILE';
export const GET_WEB_CTRL_EFFECTIVE_TIME = 'GET_WEB_CTRL_EFFECTIVE_TIME';
export const DELETE_WEB_CTRL_EFFECTIVE_TIME = 'DELETE_WEB_CTRL_EFFECTIVE_TIME';
export const UPDATE_WEB_CTRL_EFFECTIVE_TIME = 'UPDATE_WEB_CTRL_EFFECTIVE_TIME';
export const RESET_UPDATE_WEB_CTRL_EFFECTIVE_TIME =
  'RESET_UPDATE_WEB_CTRL_EFFECTIVE_TIME';
