import React from 'react';

import Layout from './components/Layout/Layout';
import Account from './pages/Account/Account';
import SoftwareMgr from './apps/SoftwareMgr/SoftwareMgr';
import RemoteAssistant from './apps/RemoteAssistant/RemoteAssistant';
import Behavior from './apps/Behavior/Behavior';
import OnlineServiceMgr from './pages/OnlineService/OnlineService';
import PcRemoteConnect from './apps/RemoteAssistant/PcRemoteConnect/PcRemoteConnect';

import QsRoute from '../commonComponents/Auth/QsRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import LearningMode from './apps/LearningMode/LearningMode';
import TimeMgrNew from './apps/TimeMgrNew/TimeMgrNew';
import OrderInfo from './pages/OrderInfo/OrderInfo';

export default function qsParentPages() {
  const pages = [
    {
      link: '/dashboard',
      title: '控制中心',
      content: <Dashboard />,
    },
    {
      link: '/account',
      title: '账号信息',
      content: <Account />,
    },
    {
      link: '/orderInfo',
      title: '订单信息',
      content: <OrderInfo />,
    },
    {
      link: '/service',
      title: '在线客服',
      content: <OnlineServiceMgr />,
    },
    {
      link: '/timeMgr',
      title: '控制中心',
      subTitle: '约定使用时间',
      content: <TimeMgrNew />,
    },
    {
      link: '/webControls',
      title: '控制中心',
      subTitle: '学习模式',
      content: <LearningMode />,
    },
    {
      link: '/softwareMgr',
      title: '控制中心',
      subTitle: '软件管理',
      content: <SoftwareMgr />,
    },
    {
      link: '/remoteAssistant',
      title: '控制中心',
      subTitle: '亲子同屏',
      content: <RemoteAssistant />,
    },
    {
      link: '/behavior',
      title: '控制中心',
      subTitle: '行为记录',
      content: <Behavior />,
    },
  ];

  const pageInfo = pages.map((page) => (
    <QsRoute path={page.link} key={page.link} auth={true}>
      <Layout title={page.title} subTitle={page.subTitle} link={page.link}>
        {page.content}
      </Layout>
    </QsRoute>
  ));

  pageInfo.push(
    <QsRoute path="/pcRemoteConnect" key="/pcRemoteConnect" auth={true}>
      <PcRemoteConnect />
    </QsRoute>
  );

  return pageInfo;
}
