import React, { Component } from 'react';
import moment from 'moment';

import s from './Footer.module.scss';
import icpLogo from './images/icpLogo.png';
import { getUrlParams } from '../../../common';
import logo from './images/logo.svg';
import serviceQrcode from './images/serviceQrcode.png';
import { isZGYD } from '../../../utils/lib';
import variables from '../../../variables.module.scss';

interface IProps {
  showBackground?: boolean;
  ipcOnly?: boolean;
}

class Footer extends Component<IProps, Record<string, never>> {
  footerContent = () => {
    return (
      <div className={s.footerContent}>
        <div className={s.contact}>
          <div>
            <img src={logo} alt="" />
            <div className={s.emphasizeText}>
              <span>qscare@qq.com</span>
            </div>
          </div>
          <div className={s.leftContainer}>
            <div>
              <img src={serviceQrcode} alt="" />
              <div>扫码添加客服微信</div>
            </div>
            <div>
              <div className={s.title}>
                <span>全国官方客服</span>
              </div>
              <div className={s.normalText}>
                <span>服务时间：工作日 10:00-17:30</span>
              </div>
              <div className={s.emphasizeRightText}>
                <span>客服微信号：QingSongShouHu</span>
              </div>
            </div>
          </div>
        </div>
        <LinkItems />
        <IPC />
      </div>
    );
  };

  ZGYDFooterContent = () => {
    return (
      <div>
        <LinkItems />
        <IPC />
      </div>
    );
  };

  renderFooter = () => {
    if (!!this.props.ipcOnly) {
      return <IPC />;
    } else if (!!isZGYD()) {
      this.ZGYDFooterContent();
    } else {
      this.footerContent();
    }
  };

  render() {
    return (
      <div className={`${s.container} ${this.props.showBackground ? s.bg : ''}`}>
        <div className={s.content}>
          {!!this.props.ipcOnly ? (
            <IPC />
          ) : !!isZGYD() ? (
            this.ZGYDFooterContent()
          ) : (
            this.footerContent()
          )}
        </div>
      </div>
    );
  }
}

function LinkItems() {
  const promotion = getUrlParams('promotion');
  const promotionQuery = promotion ? `?promotion=${promotion}` : '';
  return (
    <div
      className={s.linkItems}
      style={{ backgroundColor: !!isZGYD() ? variables.colorMasterC2 : '' }}
    >
      <div>
        <a href={`/faq${promotionQuery}`}>帮助中心</a>
      </div>
      {!isZGYD() && (
        <div>
          <a href={`/about${promotionQuery}`}>关于我们</a>
        </div>
      )}
      <div>
        <a href={`/news${promotionQuery}`}>新闻动态</a>
      </div>
      <div>
        <a href={`/policy${promotionQuery}`}>隐私政策</a>
      </div>
      <div>
        <a href={`/agreement${promotionQuery}`}>用户协议</a>
      </div>
    </div>
  );
}

function IPC() {
  return (
    <div
      className={s.icpContainer}
      style={{ backgroundColor: !!isZGYD() ? variables.colorMasterC1 : '' }}
    >
      <div className={s.icpLink}>
        <a
          href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E5%8D%97%E4%BA%AC%E5%B7%9D%E9%99%80%E5%A4%A7%E5%8C%A0%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&code=ELMJx&type=xuke"
          target="_blank"
          rel="noopener noreferrer"
        >
          苏B2-20190842
        </a>
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
          苏ICP备19039170号
        </a>
        <img src={icpLogo} alt="icpLogo" />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010502010385"
          target="_blank"
          rel="noopener noreferrer"
        >
          苏公网安备 32010502010385号
        </a>
      </div>
      <div>© 2016-{moment().year()}南京川陀大匠信息技术有限公司版权所有</div>
    </div>
  );
}

export default Footer;
