import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import s from './Dashboard.module.scss';
import { getTerminalInfoAction } from '../../../redux/terminal';
import {
  getMobileAppListAction,
  getMobileDeviceAction,
} from '../../../redux/mobileAppDevice';
import { getProductStateAction } from '../../../redux/order';
import { getAllConfigAction } from '../../../redux/config';
import {
  getLockScreenControlsAction,
  getPcLockScreenControlsAction,
} from '../../../redux/mobileLockScreen';
import {
  getCurrentTime,
  getCurrentWeek,
  getUrlQuery,
  replaceUrlQuery,
} from '../../../common';
import RightContainer from './RightContainer/RightContainer';
import LeftContainer from './LeftContainer/LeftContainer';
import { toggleWebACLAction } from '../../../redux/webControls';
import UnbindPcStepPage from './UnbindPcStepPage';
import { isZGYD } from '../../../utils/lib';

interface IDashboardProps {
  getTerminalInfoAction: () => void;
  getTerminalInfoResult: any;
  getMobileDeviceAction: () => Promise<void>;
  getMobileDeviceResult: any;
  getProductStateAction: () => void;
  getProductStateResult: any;
  getAllConfigAction: () => void;
  getLockScreenControlsAction: (mobileId: string) => void;
  getPcLockScreenControlsAction: (orgId: string) => void;
  getOrgInfoResult: any;
  getMobileAppListAction: (mobileId: string) => void;
  getLockScreenControlsResult: any;
  toggleWebACLAction: () => void;
  getAllConfigResult: any;
  getPcLockScreenControlsResult: any;
}

function Dashboard(props: IDashboardProps) {
  const [currentDeviceType, setCurrentDeviceType] = useState<'pc' | 'mobile'>('pc');
  const [showUnBindStep, setShowUnBindStep] = useState(false);
  const [pcLockScreenInfo, setPcLockScreenInfo] = useState({
    locked: false,
    lockedCountdown: '',
  });
  const [mobileLockScreenInfo, setMobileLockScreenInfo] = useState({
    locked: false,
    lockedCountdown: '',
  });

  useEffect(() => {
    document.title = !!isZGYD() ? '控制中心' : '青松守护 - 控制中心';
    const type: any = getUrlQuery('type') || 'pc';
    setCurrentDeviceType(type);
    const fetchData = async () => {
      await Promise.all([
        props.getMobileDeviceAction(),
        props.getTerminalInfoAction(),
        props.getProductStateAction(),
        props.getAllConfigAction(),
        props.toggleWebACLAction(),
      ]);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMobileDevice = () => {
    const { data: mobileDevice, success } = props.getMobileDeviceResult || {};
    const mobileArr = success && _.isArray(mobileDevice) ? mobileDevice : [];
    const mobile =
      mobileArr.length < 1
        ? null
        : {
            id: mobileArr[0].id,
            deviceType: mobileArr[0].deviceType,
            isOnline: mobileArr[0].isOnline,
            name: mobileArr[0].name,
            system: mobileArr[0].mobileInfo.rom,
          };

    return mobile;
  };

  const calculateLockScreen = (lockScreenRule: any, type: 'pc' | 'mobile') => {
    let timerId: any;
    const currentWeek = getCurrentWeek();
    const currentTimeStr = getCurrentTime();

    const currentData = !!lockScreenRule ? lockScreenRule[currentWeek] : [];
    if (currentData.length > 0) {
      const currentTime = moment().unix();
      const year = moment().format('YYYY');
      const month = moment().format('MM');
      const day = moment().format('DD');
      const interval = 1000;

      currentData.forEach((item: any) => {
        const begin = item.begin;
        const end = item.end;
        const eventTime = moment(`${year}-${month}-${day} ${end}`).unix();
        const diffTime = eventTime - currentTime;
        let duration: any = moment.duration(diffTime * 1000, 'milliseconds');
        if (currentTimeStr >= begin && currentTimeStr <= end) {
          timerId = setInterval(function () {
            duration = moment.duration(duration - interval, 'milliseconds');
            if (duration.asSeconds() === 0) {
              clearInterval(timerId);
            }

            const hours = `${duration.hours()}`.padStart(2, '0');
            const minutes = `${duration.minutes()}`.padStart(2, '0');
            const seconds = `${duration.seconds()}`.padStart(2, '0');
            if (type === 'mobile') {
              setMobileLockScreenInfo({
                locked: true,
                lockedCountdown: `${hours}:${minutes}:${seconds}`,
              });
            } else {
              setPcLockScreenInfo({
                locked: true,
                lockedCountdown: `${hours}:${minutes}:${seconds}`,
              });
            }
          }, interval);
        } else {
          if (type === 'mobile') {
            setMobileLockScreenInfo({ locked: false, lockedCountdown: '' });
          } else {
            setPcLockScreenInfo({ locked: false, lockedCountdown: '' });
          }
        }
      });
    } else {
      if (type === 'mobile') {
        setMobileLockScreenInfo({ locked: false, lockedCountdown: '' });
      } else {
        setPcLockScreenInfo({ locked: false, lockedCountdown: '' });
      }
    }

    return timerId;
  };

  useEffect(() => {
    let timerId: any;
    const customLockScreenRule =
      props.getPcLockScreenControlsResult.data?.customLockScreenRule;
    const enable = props.getPcLockScreenControlsResult.data?.enable;
    if (enable) {
      timerId = calculateLockScreen(customLockScreenRule, 'pc');
    }
    return () => clearInterval(timerId);
  }, [props.getPcLockScreenControlsResult]);

  useEffect(() => {
    let timerId: any;
    const customLockScreenRule =
      props.getLockScreenControlsResult.data?.customLockScreenRule;
    const enable = props.getLockScreenControlsResult.data?.enable;
    if (enable) {
      timerId = calculateLockScreen(customLockScreenRule, 'mobile');
    }
    return () => clearInterval(timerId);
  }, [props.getLockScreenControlsResult]);

  const getPcTerminal = () => {
    const onlineTerminals = props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = props.getTerminalInfoResult.offlineTerminals || [];
    const terminalsArr = onlineTerminals.concat(offlineTerminals);

    const org = props.getOrgInfoResult || {};
    const orgId = org.org_id;

    const config = props.getAllConfigResult ? props.getAllConfigResult : {};

    const terminal =
      terminalsArr.length < 1
        ? null
        : {
            isOnline: terminalsArr[0].online,
            name: terminalsArr[0].hostName,
            system: terminalsArr[0].pcType,
            id: orgId,
            silentMode: config.silent_mode === 'true',
          };

    return terminal;
  };

  useEffect(() => {
    const org = props.getOrgInfoResult || {};
    const orgId = org.org_id;
    const pc = getPcTerminal();
    if (pc !== null) {
      const fetchData = async () => {
        await props.getPcLockScreenControlsAction(orgId);
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getOrgInfoResult, props.getTerminalInfoResult]);

  useEffect(() => {
    const mobile = getMobileDevice();

    if (mobile !== null) {
      const fetchData = async () => {
        await Promise.all([
          props.getLockScreenControlsAction(mobile.id),
          props.getMobileAppListAction(mobile.id),
        ]);
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getMobileDeviceResult]);

  const isLimitExpired = () => {
    const availableLimit =
      props.getProductStateResult && props.getProductStateResult.availableLimit;

    return availableLimit && availableLimit.length === 0;
  };

  const handleDeviceSwitch = () => {
    const type = currentDeviceType === 'pc' ? 'mobile' : 'pc';
    setCurrentDeviceType(type);
    replaceUrlQuery('type', type);
  };

  const handleUnBind = () => {
    setShowUnBindStep(true);
  };

  if (showUnBindStep) {
    return (
      <div className={s.container}>
        <UnbindPcStepPage onClick={() => setShowUnBindStep(false)}></UnbindPcStepPage>
      </div>
    );
  } else {
    return (
      <div className={s.container}>
        <LeftContainer
          currentDevice={currentDeviceType}
          handleDeviceSwitch={handleDeviceSwitch}
          pcInfo={getPcTerminal()}
          mobileInfo={getMobileDevice()}
          pcLockScreenInfo={pcLockScreenInfo}
          mobileLockScreenInfo={mobileLockScreenInfo}
          isLimitExpired={isLimitExpired()}
        />
        <RightContainer
          onUnBind={handleUnBind}
          currentDevice={currentDeviceType}
          deviceInfo={currentDeviceType === 'pc' ? getPcTerminal() : getMobileDevice()}
          isLimitExpired={isLimitExpired()}
          lockScreen={
            currentDeviceType === 'pc'
              ? pcLockScreenInfo.locked
              : mobileLockScreenInfo.locked
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getTerminalInfoResult: state.getTerminalInfoResult.data,
    getMobileDeviceResult: state.getMobileDeviceResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getLockScreenControlsResult: state.getLockScreenControlsResult.data,
    getPcLockScreenControlsResult: state.getPcLockScreenControlsResult.data,
    getAllConfigResult: state.getAllConfigResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    getAllConfigAction: bindActionCreators(getAllConfigAction, dispatch),
    getLockScreenControlsAction: bindActionCreators(
      getLockScreenControlsAction,
      dispatch
    ),
    getPcLockScreenControlsAction: bindActionCreators(
      getPcLockScreenControlsAction,
      dispatch
    ),
    getMobileAppListAction: bindActionCreators(getMobileAppListAction, dispatch),
    toggleWebACLAction: bindActionCreators(toggleWebACLAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
