import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import { customFetch } from './fetch.server';
import { defaultActionOption, fetchPostConfig, fetchPatchConfig } from '../common';
import {
  POSITION_POLICY_SWITCH,
  POSITION_INFO,
  UPDATE_POSITION_POLICY_SWITCH,
} from './constants';

async function policySwitch(mobileId) {
  return await customFetch(
    '/api/mb/v1/locationControls/find',
    fetchPostConfig({ mobileId }),
    'json'
  );
}

export const policySwitchAction = createActionAsync(
  POSITION_POLICY_SWITCH,
  policySwitch,
  defaultActionOption
);

async function updatePolicySwitch(id, enable) {
  return await customFetch(
    `/api/mb/v1/locationControls/${id}`,
    fetchPatchConfig({ enable }),
    'json'
  );
}

export const updatePolicySwitchAction = createActionAsync(
  UPDATE_POSITION_POLICY_SWITCH,
  updatePolicySwitch,
  defaultActionOption
);

export const policySwitchReducer = createReducerAsync([
  policySwitchAction,
  updatePolicySwitchAction,
]);

async function positionInfo(mobileId, duration) {
  const resp = await customFetch(
    '/api/mb/v1/locations/find',
    fetchPostConfig({ mobileId, duration }),
    'json'
  );

  if (resp.success) {
    const result = resp.data.map((item, index) => {
      return {
        id: index + 1,
        longitude: item.lng,
        latitude: item.lat,
        time: item.time,
        address: item.formattedAddress || '--',
        addressComponent: item.addressComponent || {},
        aois: item.aois || [],
        pois: item.pois || [],
      };
    });
    return { success: true, data: result };
  } else {
    return resp;
  }
}

export const positionInfoAction = createActionAsync(
  POSITION_INFO,
  positionInfo,
  defaultActionOption
);

export const positionInfoReducer = createReducerAsync(positionInfoAction);
