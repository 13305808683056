const productName = '青松守护';
const data: any = [
  {
    name: '一、我们如何收集和存储您的个人信息',
    items: [
      '对于您已经授权我们获取的个人信息，我们收集、使用相关信息的目的和用途详情如下：',
      `1. 当您注册${productName}帐号时，我们会收集一些个人信息，例如您的昵称、手机号码、和unionID。收集这些信息是为了帮助您完成帐号的注册，保护帐号安全。手机号码属于敏感信息，收集此类信息是为了满足相关法律、法规关于网络实名制要求。若您不提供这类信息，您可能无法正常使用我们的服务。您还可以根据自身需求选择填写地区、地址等信息。`,
      `2. 当您完善个人地址时，我们会在获得您的明示同意后，记录您的地理位置信息。该信息属于敏感信息，您拒绝提供该信息不影响您正常使用${productName}的其他功能。`,
      '3. 当您作为“家长端”，绑定“孩子端”时填写的信息。例如，所填写的昵称、性别、手机号码等个人信息',
      '4. “家长端”用户绑定“孩子端”开启的权限信息。在绑定“孩子端”后，家长为实现查看和管理功能需要获取孩子端手机或平板设备所需要的权限。“孩子端”的权限包含：',
      '（1）设备的应用列表，用于限制app使用',
      '（2）地理位置和定位权限，用于记录孩子的位置，该功能需孩子端授权并同意方可使用',
      '（3）设备使用情况统计，用于统计设备的使用情况',
      '（4）应用使用时长统计，用于统计应用的使用时长',
      '（5）设备电量等功能相关信息，用于记录设备信息，通知家长',
      '（6）设备管理器权限，辅助功能权限，用于防止“孩子端”应用被卸载',
      '5. 位置信息。当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。',
      '（1）在您使用服务时，我们可能会通过IP地址、GPS、WiFi或基站等途径获取您的地理位置信息；',
      '（2）您或其他用户在使用服务时提供的信息中可能包含您所在地理位置信息，例如您提供的帐号信息中可能包含的您所在地区信息，您或其他人共享的照片中包含的地理标记信息。',
      '6. 从第三方合作伙伴获取的信息。我们可能会获得您在使用第三方合作伙伴服务时所产生或分享的信息。例如，您使用微信或QQ帐户登录第三方合作伙伴服务时，我们会获得您登录第三方合作伙伴服务的名称、登录时间，方便您进行授权管理。请您仔细阅读第三方合作伙伴服务的用户协议或隐私政策。',
      `7.读取和写入外部存储设备权限。我们会将${productName}的应用信息缓存到外部存储设备，以便识别设备的登录状态和缓存管控相关信息。`,
      '8.基于剪切板授权的拓展功能。在您使用青松守护会员激活码兑换功能时，我们需要在本地访问您的剪切板权限，我们仅在本地识别出剪切板内容属于青松守护会员激活码指令时才会将其上传我们的服务器。除此之外，我们不会上传您剪切板的其他信息至我们的服务器。',
      '(1) 有些手机设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是本地处理还是上传，且由于统计方式的差异，不排除误报的可能。因此，如果您发现类似提示并有疑问，请与我们联系，以便我们定位问题。',
      '9.第三方SDK服务。当您同意我们的隐私政策时，我们所使用的第三方SDK服务会开始自动收集您的个人信息。针对不同的SDK第三方服务，自动收集的信息和搜集目的有所不同。以下为我们目前使用到的或将来可能会使用的第三方SDK服务：',
      '(1) 支付宝SDK。',
      '所属公司：支付宝（中国）网络技术有限公司',
      '服务类型：支付服务',
      '收集个人信息类型：设备信息（如IMEI、IMSI、SIM卡序列号、MAC、Android ID）、网络信息以及地理位置信息、获取正在运行的进程。',
      '使用目的：为您提供应用内的支付功能',
      '《支付宝隐私政策》: https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132',
      '(2) 微信分享SDK。',
      '服务类型：分享服务',
      '手机个人信息类型：服务日志信息、设备信息（设备型号、操作系统版本、唯一设备标识符(IMEI、MAC、Android ID、IDFA(iOS独有)）、电池、信号强度信息）、软件信息（包括安装应用列表、软件版本号、浏览器类型）、IP地址、位置信息（可能基于iOS系统还会通过IP地址、GPS、WLAN（如WIFI）或基站等途径获取地理位置信息）。',
      '使用目的：帮助您分享内容至第三方应用',
      '《腾讯隐私政策》: https://privacy.qq.com/policy/tencent-privacypolicy',
    ],
  },
  {
    name: '二、我们如何使用您的个人信息',
    items: [
      '我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。',
      '(1)向您提供服务；',
      '(2)满足您的个性化需求；',
      '(3)产品开发和服务优化；',
      '(4)安全保障。例如，我们会将您的信息用于身份验证、安全守护、存档备份等用途。',
      '为了让您有更好的用户体验，同时改善我们的服务或出于经您同意的其他用途，在符合相关法律、法规规定的前提下，我们可能会将通过某些服务所收集的信息用于我们的其他服务。',
      '为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。',
    ],
  },
  {
    name: '三、我们如何共享、转让、公开披露您的个人信息',
    items: [
      '本产品的核心功能当前不需您提供个人常用设备信息外的其他个人信息。后期如产品有变动，需要搜集和使用其他个人信息，我们会及时更新隐私政策，对您进行告知并在取得相应授权后进行。',
      '对于您已经授权我们获取的个人信息，我们将按照下列规则共享、转让、公开披露您的个人信息：',
      '（一）共享',
      '我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：',
      '1、在获取您明确同意的情况下，我们会与其他方共享您的个人信息。',
      '2、我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。',
      '3、在法律法规允许的范围内，为维护川陀大匠、川陀大匠的关联方或合作伙伴或社会公众利益、财产或安全免遭损害而有必要共享您的个人信息。',
      `4、与关联公司间共享：独立为您直接提供服务的关联方为实现功能所必需而收集和使用您的个人信息前，会明示告知您并获得您的明确同意。详情可阅读其相关产品“隐私政策”。您授权给${productName}的个人信息只有在您明确授权同意的情况下，才会与关联方共享必要个人信息，否则我们不会与关联方共享您的任何信息。若共享内容发生变更时，我们会再次明示告知您并征求您的明示同意。关联方对您信息的处理受相关协议约束。如关联公司要改变您个人信息的处理目的，将再次征求您的授权和同意。`,
      '5、与合作伙伴间共享：我们仅会出于合法的、正当的、必要的、特定的、明确的以及为实现本隐私政策中声明的目的，并在您明示授权同意的情况下,我们将会与我们的供应商、服务提供商、顾问、代理人、第三方广告商、应用程序开发者、开放平台或其他合作伙伴共享必要的个人信息。或是汇总后的信息、匿名化后的信息或者其他不会识别到您个人身份的信息，以提供更好的客户服务和用户体验。这些供应商、服务提供商、顾问、代理人可能为我们提供技术基础设施服务，分析我们服务的使用方式，衡量广告和服务的有效性，提供客户服务和支付服务，进行学术研究和调查，或提供法律、财务与技术顾问服务。例如，我们可能会不定期地邀请您参加抽奖活动。如您幸运中奖，我们需要向物流服务提供商提供您的个人信息，以便向您发送我们的奖品，或者安排合作伙伴提供服务。我们所有的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。',
      '目前，我们的授权合作伙伴包括以下类型：',
      '(1) 广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的人口统计信息（例如“位于北京的25岁男性，喜欢软件开发”），帮助他们了解其受众或顾客。',
      '(2) 供应商、服务提供商和其他合作伙伴。我们将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。',
      '对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。',
      '（二）转让',
      '我们不会将您的个人信息转让给其他任何公司、组织和个人，但以下情形除外：',
      '(1) 随着川陀大匠业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。',
      '(2) 在获得您明确同意的情形下转让，亦即获得您明确同意后，我们会向其他方转让我们已经获取的您的个人信息。',
      '（三）披露',
      '我们仅会在以下情况下，公开披露您的个人信息：',
      '(1) 已经获得您明确同意；',
      '(2) 在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息；',
      '(3) 在法律法规允许的范围内，为维护川陀大匠、川陀大匠的关联方或合作伙伴、或社会公众利益、财产或安全免遭损害而有必要披露您的个人信息；',
      '(4) 法律法规规定的其他情形。',
      '根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。',
    ],
  },
  {
    name: '四、我们如何保护您的个人信息',
    items: [
      '我们已采取符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。',
      '(1) 我们非常重视您个人信息的安全，将努力采取合理的安全措施来保护您的个人信息。',
      '(2) 如在使用本产品过程中，您提供了个人信息，我们会对可识别的个人身份信息进行加密传输和存储，以确保数据的保密性。',
      '(3) 我们对可能接触到您个人信息的工作人员采取最小够用授权原则，并定期核查访问人员名单和访问记录。',
      '(4) 我们存储您个人信息的服务器系统均为安全加固后的操作系统。我们会对服务器操作进行账户审计及监控。如果发现外部公告有安全问题的服务器操作系统，川陀大匠会在第一时间进行服务器安全升级，确保川陀大匠所有服务器系统及应用安全。',
      '(5) 我们为工作人员举办个人信息保护相关法律法规培训，以加强工作人员隐私保护意识。',
      '(6) 如果不幸发生我们的物理、技术或管理防护措施遭到破坏的事件，我们会及时启动应急预案，防止安全事件扩大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将要采取的措施等。',
    ],
  },
  {
    name: '五、您管理个人信息的权利',
    items: [
      `在您使用${productName}期间，您可以通过以下方式访问并管理您的个人信息：`,
      '（一）访问和更正您的个人信息',
      '本产品的核心功能不需您提供个人常用设备信息外的其他个人信息。如您在反馈问题时留取了您的个人信息，或者您需要了解我们收集处理您的信息情况，请按第九条的方式联系我们进行处理。',
      '（二）删除您的个人信息',
      '以下情况发生时，您有权要求川陀大匠删除您的个人信息：',
      '(1) 我们没有征求您的明确同意，收集了您的个人信息；',
      '(2) 我们处理您的个人信息违反了法律法规要求；',
      '(3) 我们违反了与您的约定来使用和处理您的个人信息；',
      '(4) 您卸载或者不再使用我们的产品（或服务）；',
      '(5) 我们停止对您提供服务。',
      '（三）撤回您的同意或授权',
      '在本款产品中，您可以通过以下方式撤回您对我们的同意和授权。',
      `(1) 您可以通过${productName}产品界面的“会员中心 - 权限管理 - 去设置”进入或直接通过手机本地“设置 - 权限管理 - ${productName}”进入您对${productName}授权的权限管理界面，开启或撤回对${productName}的授权和同意。`,
      '(2) 通过本隐私政策的“一、我们如何收集和使用您的个人信息”模块的功能描述中的具体关闭路径和方式，关闭相关功能。关闭即撤回对该功能相对应的授权。',
      '请您理解，每项业务功能需要一些基本的个人信息才能得以完成（见本隐私政策第一节），当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。',
      '（四）注销账号',
      `您有权注销您的${productName}账号，您可以通过访问 App“会员中心 - 账号信息 - 安全中心 - 注销账号”中进行在线操作。您注销成功后，除法律法规及相应的国家标准另有规定外，我们将删除您的个人信息或作匿名化处理。如果您无法通过上述方式访问、更正或删除您的个人信息以及注销账号，或者如果您认为青松守护存在违反法律的规定收集、使用您个人信息的情形，您可以通过我们的客服或通过本隐私政策提供的其他方式与我们取得联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的 15 天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。`,
      '对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。',
    ],
  },
  {
    name: '六、未成年人个人信息保护',
    items: [
      '未成年人在使用川陀大匠产品（或服务）时，需要监护人同意和授权。我们建议未成年父母或监护人为其阅读本隐私政策，并建议未成年人在提交个人信息之前寻求父母或监护人的同意和指导。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有疑问时，您可以通过本隐私政策第九条的联系方式与我们联系。',
    ],
  },
  {
    name: '七、隐私政策的适用范围',
    items: [
      `本隐私政策仅适用于${productName}。川陀大匠的其他产品和服务将使用相关产品或服务的隐私政策。特别需要说明的是，本隐私政策不适用以下情况：`,
      '(1) 将川陀大匠产品（或服务）嵌入到第三方产品（或服务），第三方产品（或服务）收集的信息。',
      '(2) 在川陀大匠产品（或服务）中接入的第三方服务、广告或其他公司、组织或个人所收集的信息。',
    ],
  },
  {
    name: '八、隐私政策的变更和修订',
    items: [
      '我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于本隐私政策的重大变更，我们还会提供显著的通知（例如，在软件改版或升级、或您重新登录时，以弹窗形式对您进行及时通知）。',
      '本政策所指的重大变更包括但不限于：',
      '(1) 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；',
      '(2) 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；',
      '(3) 个人信息共享、转让或公开披露的主要对象发生变化；',
      '(4) 您参与个人信息处理方面的权利及其行使方式发生重大变化；',
      '(5) 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；',
      '(6) 个人信息安全影响评估报告表明存在高风险时。',
    ],
  },
  {
    name: '九、如何联系我们',
    items: [
      `${productName}由川陀大匠提供，如果您对我们的隐私政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请通过以下方式与我们联系。`,
      '联系邮箱：qscare@qq.com',
      '在一般情况下，我们会在15个工作日内对您的请求予以答复。您也可以将反馈意见寄到如下地址：',
      '地址：南京市建邺区创智路1号新城科技园北纬国际中心A栋15层A座',
    ],
  },
  {
    name: '',
    items: [],
  },
  {
    name: '',
    items: [],
  },
];

export default data;
