import React, { useEffect, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { getUrlQuery } from '../../../../common';
import s from './Select.module.scss';

enum DataType {
  Web = 'web',
  Application = 'application',
  MobileApp = 'mobileApp',
}

interface IProps {
  defaultActive: string;
  onChange: (type: string) => void;
}

function Select(props: IProps) {
  const type: any = getUrlQuery('type');
  const [active, setActive] = useState(() => {
    return props.defaultActive;
  });

  useEffect(() => {
    setActive(props.defaultActive);
  }, [props.defaultActive]);

  const [showPanel, setShowPanel] = useState(false);

  let selectItems = [
    {
      name: '全部类型',
      type: 'all',
    },
    {
      name: '上网行为记录',
      type: DataType.Web,
    },
    {
      name: '软件使用记录',
      type: DataType.Application,
    },
    {
      name: 'App使用记录',
      type: DataType.MobileApp,
    },
  ];

  if (type === 'mobile') {
    selectItems = selectItems.filter((item) => item.type === DataType.MobileApp);
  } else if (type === 'pc') {
    selectItems = selectItems.filter((item) => item.type !== DataType.MobileApp);
  }

  const selected = selectItems.filter((item: any) => item.type === active);

  return (
    <div className={s.container}>
      <div
        className={showPanel ? s.selectActive : s.select}
        onClick={() => {
          setShowPanel(!showPanel);
        }}
      >
        <div className={s.borderStyle}>
          <span>{selected[0]?.name}</span>
          <span>{showPanel ? <AiOutlineUp /> : <AiOutlineDown />}</span>
        </div>
      </div>
      {showPanel && (
        <div className={s.selectPanel}>
          <ul className={s.options}>
            {selectItems.map((item: any) => {
              return (
                <li
                  key={item.type}
                  className={active === item.type ? s.active : ''}
                  onClick={() => {
                    setActive(item.type);
                    setShowPanel(false);
                    props.onChange(item.type);
                  }}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Select;
