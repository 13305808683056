import React, { useEffect, useState } from 'react';

import s from './QsTable.module.scss';
import QsCheckbox from '../QsCheckbox/QsCheckbox';
import NoData from '../NoData/NoData';

interface IColumn {
  title: string | React.ReactElement;
  dataIndex: string;
  key: string;
  render?: (record: any, rowRecord: any) => React.ReactElement;
  expendRender?: (record: any, rowRecord: any) => React.ReactElement;
  width?: string;
}

interface ITableItemProps {
  columns: IColumn[];
  dataItem: any;
  handleClick: (checked: boolean) => void;
  checked: boolean;
  handleMouseOver?: () => void;
  handleMouseLeave?: () => void;
  handleItemClick?: (currentKey: string, expend: boolean) => void;
  checkbox?: boolean;
  totalCheckbox?: boolean;
  clickedItem: string;
}

function TableItem(props: ITableItemProps) {
  const [showExpand, setShowExpand] = useState(false);
  const isExpand =
    props.dataItem.key === props.clickedItem &&
    showExpand &&
    props.dataItem?.expand?.length > 0;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        props.handleClick(!props.checked);
      }}
    >
      <div
        className={`${s.row} ${s.item} ${props.checked || isExpand ? s.itemChecked : ''}`}
        onMouseOver={props.handleMouseOver}
        onMouseLeave={props.handleMouseLeave}
        onClick={() => {
          let expand = !showExpand;
          if (props.dataItem.key != props.clickedItem) {
            expand = true;
          }
          setShowExpand(expand);
          props.handleItemClick?.(props.dataItem.key, expand);
        }}
      >
        {props.totalCheckbox && (
          <span className={s.checkbox}>
            {props.checkbox && (
              <QsCheckbox
                theme="green"
                onChange={(checked) => {
                  props.handleClick(checked);
                }}
                size="default"
                checked={props.checked}
              />
            )}
          </span>
        )}
        {props.columns.map((column) => {
          return (
            <span
              key={column.key}
              style={{ width: column.width, flex: !!column.width ? 'none' : '1' }}
            >
              {!!column.render
                ? column.render(props.dataItem[column.dataIndex], props.dataItem)
                : props.dataItem[column.dataIndex]}
            </span>
          );
        })}
      </div>
      {isExpand && (
        <div className={s.rowDetail}>
          {props.dataItem.expand.map((expend: any, index: number) => {
            const columnKeys = Object.keys(expend).filter((value) => value !== 'key');
            return (
              <div key={index.toString()}>
                {columnKeys.map((key) => {
                  const column = props.columns.filter(
                    (column) => column.dataIndex === key
                  )[0];
                  return (
                    <span
                      key={key}
                      style={{ width: column.width, flex: !!column.width ? 'none' : '1' }}
                    >
                      {column.expendRender
                        ? column.expendRender(expend[key], expend)
                        : expend[key]}
                    </span>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

interface IQsTableProps {
  columns: IColumn[];
  dataSource: any[];
  handleChange?: (checkedItems: any[]) => void;
  defaultCheckedItems?: any[];
  handleMouseOver?: (currentKey: string) => void;
  handleMouseLeave?: (currentKey: string) => void;
  handleItemClick?: (currentKey: string, expand: boolean) => void;
  handleScroll?: (e: any) => void;
  checkbox?: boolean;
  emptyMessage?: string;
  searchValue?: string;
  height?: string;
}

function QsTable(props: IQsTableProps) {
  const [checkedItems, setCheckedItems] = useState([]);
  const [filterCheckedItems, setFilterCheckedItems] = useState([]);
  const [clickedItem, setClickedItem] = useState('');

  useEffect(() => {
    if (!!props.defaultCheckedItems) {
      // @ts-ignore
      setCheckedItems(props.defaultCheckedItems);
    }
  }, [props.defaultCheckedItems]);

  useEffect(() => {
    const dataItems = props.dataSource.map((data) => data.key);
    const filterCheckedItems = checkedItems.filter((value) => dataItems.includes(value));
    setFilterCheckedItems([...filterCheckedItems]);
  }, [props.dataSource, checkedItems]);

  const handleTotalCheck = (checked: boolean) => {
    const dataWithCheckbox = props.dataSource.filter((data) => data.checkbox);
    let items: any[] = [];
    if (checked) {
      const allKeys: any[] = dataWithCheckbox.map((data) => data.key);
      items = [...allKeys];

      if (!!props.searchValue) {
        items = [...items, ...checkedItems];
      }
    } else {
      if (!!props.searchValue) {
        const dataItems = props.dataSource.map((data) => data.key);
        items = checkedItems.filter((value) => !dataItems.includes(value));
      } else {
        items = [];
      }
    }
    // @ts-ignore
    items = [...new Set(items)];
    // @ts-ignore
    setCheckedItems([...items]);
    props.handleChange?.(items);
  };

  const handleItemCheck = (checked: boolean, itemKey: string) => {
    // @ts-ignore
    const index = checkedItems.indexOf(itemKey);
    let items = checkedItems;
    if (checked) {
      if (index === -1) {
        // @ts-ignore
        items = [...items, itemKey];
      }
    } else {
      items.splice(index, 1);
    }
    setCheckedItems([...items]);
    props.handleChange?.(items);
  };
  const dataWithCheckbox = props.dataSource.filter((data) => data.checkbox);
  const totalChecked =
    filterCheckedItems.length !== 0 &&
    filterCheckedItems.length === dataWithCheckbox.length;

  return (
    <div className={s.container}>
      <div className={`${s.row} ${s.header}`}>
        {!!props.checkbox && (
          <span className={s.checkbox}>
            <QsCheckbox
              theme="green"
              onChange={handleTotalCheck}
              checked={totalChecked}
              size="default"
            />
          </span>
        )}
        {props.columns.map((column) => (
          <span
            key={column.key}
            style={{ width: column.width, flex: !!column.width ? 'none' : '1' }}
          >
            {column.title}
          </span>
        ))}
      </div>
      <div
        className={s.itemContainer}
        onScroll={props.handleScroll}
        style={{ height: props.height }}
      >
        {props.dataSource.length > 0 ? (
          props.dataSource.map((dataItem: any, index) => {
            // @ts-ignore
            const checked = checkedItems.includes(dataItem.key);
            return (
              <TableItem
                key={index.toString()}
                checkbox={dataItem.checkbox}
                totalCheckbox={props.checkbox}
                dataItem={dataItem}
                columns={props.columns}
                checked={checked}
                clickedItem={clickedItem}
                handleClick={(checked) => {
                  handleItemCheck(checked, dataItem.key);
                }}
                handleMouseOver={() => {
                  props.handleMouseOver?.(dataItem.key);
                }}
                handleMouseLeave={() => {
                  props.handleMouseLeave?.(dataItem.key);
                }}
                handleItemClick={(currentKey, expend) => {
                  setClickedItem(currentKey);
                  props.handleItemClick?.(currentKey, expend);
                }}
              />
            );
          })
        ) : (
          <div className={s.noData}>
            <NoData message={props.emptyMessage || '暂无数据'} />
          </div>
        )}
      </div>
    </div>
  );
}

export default QsTable;
