import React, { Component } from 'react';

import s from './BasicTabs.module.scss';

interface IItem {
  key: string;
  title: string | React.ReactElement;
  content: string | React.ReactElement;
}

interface IProps {
  type:
    | 'fillet'
    | 'rightAngle'
    | 'lineWithVerticalBar'
    | 'line'
    | 'rectangleWithoutLine'
    | 'rectangle';
  items: IItem[];
  defaultKey?: string;
  titleWidth?: string;
  onChange?: (key: string) => void | boolean | Promise<void>;
  position: 'below' | 'above';
  size?: 'default' | 'large';
  disableClick?: boolean;
}

interface IStates {
  currentKey: string;
}

class BasicTabs extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentKey: '',
    };
  }

  componentDidMount() {
    if (this.props.defaultKey) {
      this.setState({ currentKey: this.props.defaultKey });
    } else {
      this.setState({ currentKey: this.props.items[0].key });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (!!this.props.defaultKey && this.props.defaultKey !== prevProps.defaultKey) {
      this.setState({ currentKey: this.props.defaultKey });
    }
  }

  filletTitle = () => {
    return (
      <div className={s.title} style={{ width: this.props.titleWidth || '260px' }}>
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.disableClick) {
                return;
              }
              if (this.props.onChange) {
                const needConfirm = this.props.onChange(item.key);
                if (!needConfirm) {
                  this.setState({ currentKey: item.key });
                }
              } else {
                this.setState({ currentKey: item.key });
              }
            }}
            className={`${item.key === this.state.currentKey ? s.activeTitle : ''}`}
          >
            {item.title}
          </span>
        ))}
        <span
          className={`${s.slider} ${
            this.state.currentKey === this.props.items[0].key ? s.left : s.right
          }`}
        />
      </div>
    );
  };

  rightAngleTitle = () => {
    return (
      <div className={s.rightAngleTitle}>
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onChange) {
                const needConfirm = this.props.onChange(item.key);
                if (!needConfirm) {
                  this.setState({ currentKey: item.key });
                }
              } else {
                this.setState({ currentKey: item.key });
              }
            }}
            className={`${
              item.key === this.state.currentKey
                ? s.activeRightAngleTitle
                : s.normalRightAngleTitle
            }`}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  };

  lineWithVerticalBarTitle = () => {
    return (
      <div className={s.lineWithVerticalBarTitle}>
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onChange) {
                const needConfirm = this.props.onChange(item.key);
                if (!needConfirm) {
                  this.setState({ currentKey: item.key });
                }
              } else {
                this.setState({ currentKey: item.key });
              }
            }}
            className={`${
              item.key === this.state.currentKey
                ? s.activeLineWithVerticalBarTitle
                : s.lineWithVerticalBarTitle
            }`}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  };

  lineTitle = () => {
    return (
      <div className={`${this.props.size === 'large' ? s.lineLarge : ''} ${s.lineTitle}`}>
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onChange) {
                const needConfirm = this.props.onChange(item.key);
                if (!needConfirm) {
                  this.setState({ currentKey: item.key });
                }
              } else {
                this.setState({ currentKey: item.key });
              }
            }}
            className={`${
              item.key === this.state.currentKey ? s.activeLineTitle : s.normalLineTitle
            }`}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  };

  rectangleTitle = () => {
    return (
      <div
        className={`${this.props.size === 'large' ? s.rectangleLarge : ''} ${
          s.rectangleTitle
        }`}
      >
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onChange) {
                const needConfirm = this.props.onChange(item.key);
                if (!needConfirm) {
                  this.setState({ currentKey: item.key });
                }
              } else {
                this.setState({ currentKey: item.key });
              }
            }}
            className={`${
              item.key === this.state.currentKey
                ? s.activeRectangleTitle
                : s.normalRectangleTitle
            }`}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  };

  rectangleWithoutLineTitle = () => {
    return (
      <div
        className={`${this.props.size === 'large' ? s.rectangleWithoutLarge : ''} ${
          s.rectangleWithoutTitle
        }`}
      >
        {this.props.items.map((item) => (
          <span
            key={item.key}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.onChange) {
                this.props.onChange(item.key);
              }
              this.setState({ currentKey: item.key });
            }}
            className={`${
              item.key === this.state.currentKey
                ? s.activeRectangleWithoutTitle
                : s.normalRectangleWithoutTitle
            }`}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  };

  render() {
    const title = {
      fillet: this.filletTitle(),
      rightAngle: this.rightAngleTitle(),
      lineWithVerticalBar: this.lineWithVerticalBarTitle(),
      line: this.lineTitle(),
      rectangle: this.rectangleTitle(),
      rectangleWithoutLine: this.rectangleWithoutLineTitle(),
    };
    return (
      <div className={s.container}>
        {this.props.position === 'above' && title[this.props.type]}
        <div>
          {this.props.items.map((item) => {
            if (item.key === this.state.currentKey) {
              return item.content;
            }
          })}
        </div>
        {this.props.position === 'below' && title[this.props.type]}
      </div>
    );
  }
}

export default BasicTabs;
