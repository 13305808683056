import React from 'react';
import noData from '../../commonComponents/QsIcons/noData.svg';
import s from './NoData.module.scss';

interface IProps {
  message: string;
}

function NoData(props: IProps) {
  return (
    <div className={s.container}>
      <img src={noData} alt="no data" />
      <div className={s.desc}>
        <span>{props.message}</span>
      </div>
    </div>
  );
}

export default NoData;
