import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import s from './LearningMode.module.scss';
import whiteList from './images/whiteList.svg';
import blackList from './images/blackList.svg';
import RoundSwitch from '../../components/RoundSwitch/RoundSwitch';
import WhiteListMode from './WhiteListMode';
import BlackListMode from './BlackListMode';
import { getAllConfigAction, updatePolicySwitchesAction } from '../../../redux/config';
import { getProductStateAction } from '../../../redux/order';
import MemberExpireModal from '../../components/MemberExpireModal/MemberExpireModal';
import { getTerminalInfoAction } from '../../../redux/terminal';
import BindFlowModal from '../../components/BindFlowModal/BindFlowModal';
import { toggleWebACLAction, updateToggleWebACLAction } from '../../../redux/webControls';
import { isZGYD } from '../../../utils/lib';
import { QsInfoModal } from '../../../commonComponents/QsModal/QsModal';

interface IProps {
  getAllConfigAction: () => void;
  getAllConfigResult: any;
  updatePolicySwitchesAction: (data: { blocksite_mode: 'white' | 'black' }) => void;
  getProductStateAction: () => void;
  getProductStateResult: any;
  getTerminalInfoResult: any;
  getTerminalInfoAction: () => void;
  toggleWebACLAction: () => any;
  updateToggleWebACLAction: (enable: boolean) => void;
  toggleWebACLResult: any;
}

function LearningMode(props: IProps) {
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSwitchModeModal, setShowSwitchModeModal] = useState(false);

  useEffect(() => {
    document.title = !!isZGYD() ? '学习模式' : '青松守护 - 学习模式';
    async function fetchData() {
      await Promise.all([
        props.getAllConfigAction(),
        props.getProductStateAction(),
        props.getTerminalInfoAction(),
        props.toggleWebACLAction(),
      ]);

      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlackAndWhiteSwitchChange = async (isChecked: boolean) => {
    const prevEnable = props.toggleWebACLResult.enable;

    if (prevEnable === 'true') {
      setShowSwitchModeModal(true);
    } else {
      const mode = isChecked ? 'black' : 'white';
      await props.updatePolicySwitchesAction({ blocksite_mode: mode });
      await props.getAllConfigAction();
    }
  };

  const isLimitExpired = () => {
    const availableLimit =
      props.getProductStateResult && props.getProductStateResult.availableLimit;

    return availableLimit && availableLimit.length === 0;
  };

  const getPcTerminal = () => {
    const onlineTerminals = props.getTerminalInfoResult.onlineTerminals || [];
    const offlineTerminals = props.getTerminalInfoResult.offlineTerminals || [];
    const terminalsArr = onlineTerminals.concat(offlineTerminals);

    const terminal =
      terminalsArr.length < 1
        ? null
        : {
            isOnline: terminalsArr[0].online,
            name: terminalsArr[0].hostName,
            system: terminalsArr[0].pcType,
          };

    return terminal;
  };

  const handleSwitchBtnClick = async (enable: boolean) => {
    if (isLimitExpired()) {
      setShowExpireModal(true);
    } else if (!getPcTerminal()) {
      setShowBindModal(true);
    } else {
      await props.updateToggleWebACLAction(enable);
      await props.toggleWebACLAction();
    }
  };

  const mode = props.getAllConfigResult?.blocksite_mode;
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className={s.container}>
      <div className={s.leftContainer}>
        <div className={s.switchContainer}>
          <div className={s.switchTop}>
            <img src={mode == 'black' ? blackList : whiteList} alt="" />
          </div>
          <div className={s.switchBottom}>
            <div>当前模式</div>
            <div>
              {mode == 'black'
                ? '黑名单模式下，孩子将不能访问右侧您设置的网站。'
                : '白名单模式下，孩子只能访问您设置的允许访问的网站。'}
            </div>
            <RoundSwitch
              isChecked={mode == 'black'}
              onChange={handleBlackAndWhiteSwitchChange}
            />
          </div>
        </div>
      </div>
      <div className={s.rightContainer}>
        {mode == 'white' ? (
          <WhiteListMode handleSwitchBtnClick={handleSwitchBtnClick} />
        ) : (
          <BlackListMode handleSwitchBtnClick={handleSwitchBtnClick} />
        )}
      </div>
      <QsInfoModal
        visible={showSwitchModeModal}
        text="请先关闭当前模式后，再进行切换。"
        onOk={() => {
          setShowSwitchModeModal(false);
        }}
      />
      <MemberExpireModal
        visible={showExpireModal}
        handleClose={() => {
          setShowExpireModal(false);
        }}
      />
      <BindFlowModal
        defaultType="pc"
        visible={showBindModal}
        handleCancel={() => {
          setShowBindModal(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    updateToggleWebACLResult: state.updateToggleWebACLResult.data,
    getAllConfigResult: state.getAllConfigResult.data,
    getProductStateResult: state.getProductStateResult.data,
    getTerminalInfoResult: state.getTerminalInfoResult.data,
    toggleWebACLResult: state.toggleWebACLResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getAllConfigAction: bindActionCreators(getAllConfigAction, dispatch),
    updatePolicySwitchesAction: bindActionCreators(updatePolicySwitchesAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    getTerminalInfoAction: bindActionCreators(getTerminalInfoAction, dispatch),
    toggleWebACLAction: bindActionCreators(toggleWebACLAction, dispatch),
    updateToggleWebACLAction: bindActionCreators(updateToggleWebACLAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningMode);
