import React, { useEffect, useState } from 'react';
import { Switch, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './RightContainer.module.scss';
import softwareMgrIcon from './images/softwareMgrIcon.svg';
import timeMgrIcon from './images/timeMgrIcon.svg';
import remoteAssistIcon from './images/remoteAssistIcon.svg';
import onlineCourseIcon from './images/onlineCourseIcon.svg';
import unbindIcon from './images/unbindIcon.svg';
import { CONSTANT, DeviceStatus } from '../LeftContainer/LeftContainer';
import { getAllConfigAction, updatePolicySwitchesAction } from '../../../../redux/config';
import {
  clearUnbindMobileDeviceAction,
  getMobileDeviceAction,
  unbindMobileDeviceAction,
} from '../../../../redux/mobileAppDevice';
import QsAlert from '../../../../commonComponents/QsAlert/QsAlert';
import MemberExpireModal from '../../../components/MemberExpireModal/MemberExpireModal';
import { Link } from 'react-router-dom';
import BehaviorOverview from '../BehaviorOverview/BehaviorOverview';
import { QsButton } from '../../../../commonComponents/QsButton/QsButton';

interface IProps {
  currentDevice: 'pc' | 'mobile';
  deviceInfo: any;
  isLimitExpired: boolean;

  getLockScreenControlsResult: any;
  getPcLockScreenControlsResult: any;
  getAllConfigAction: () => void;
  getAllConfigResult: any;
  updatePolicySwitchesAction: (data: { [key: string]: any }) => void;
  unbindMobileDeviceAction: (deviceType: string, id: string) => void;
  unbindMobileDeviceResult: any;
  getMobileDeviceAction: () => Promise<void>;
  clearUnbindMobileDeviceAction: () => void;
  getMobileAppListResult: any;
  getProductStateResult: any;
  toggleWebACLResult: any;
  lockScreen: boolean;
  onUnBind: () => void;
}

function RightContainer(props: IProps) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [switchValue, setSwitchValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    const config = props.getAllConfigResult ? props.getAllConfigResult : {};
    setSwitchValue(config.silent_mode === 'true');
  }, [props.getAllConfigResult]);

  useEffect(() => {
    const { data } = props.unbindMobileDeviceResult;

    if (data !== '' && !!data) {
      setShowAlert(true);
      setAlertMessage(data === 'success' ? '解绑成功！' : '解绑失败，请重试！');
      setAlertType(data === 'success' ? 'success' : 'error');
      const fetchData = async () => {
        await props.getMobileDeviceAction();
        await props.clearUnbindMobileDeviceAction();
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.unbindMobileDeviceResult]);

  useEffect(() => {
    setIsOpen(props?.deviceInfo?.id && !switchValue && isClose);
  }, [props?.deviceInfo]);

  const isPc = props.currentDevice === 'pc';
  const pcSwitchValue = props.getAllConfigResult.software_mgmt;
  const functionInfoPc = [
    {
      icon: onlineCourseIcon,
      name: '学习模式',
      url: '/webControls?type=pc',
      open: props.toggleWebACLResult?.enable === 'true',
    },
    {
      icon: timeMgrIcon,
      name: '约定使用时间',
      url: '/timeMgr?type=pc',
      open: props.deviceInfo ? props.getPcLockScreenControlsResult.data?.enable : false,
    },
    {
      icon: remoteAssistIcon,
      name: '亲子同屏',
      url: '/remoteAssistant?type=pc',
      open: false,
      text: '开启',
    },
    {
      icon: softwareMgrIcon,
      name: '软件管理',
      url: '/softwareMgr?type=pc',
      open: pcSwitchValue === 'true',
      text: '去设置',
    },
  ];
  const functionInfoMobile = [
    {
      icon: timeMgrIcon,
      name: '约定使用时间',
      url: '/timeMgr?type=mobile',
      open: props.deviceInfo ? props.getLockScreenControlsResult.data?.enable : false,
    },
    {
      icon: remoteAssistIcon,
      name: '亲子同屏',
      url: '/remoteAssistant?type=mobile',
      open: false,
      text: '开启',
    },
    {
      icon: softwareMgrIcon,
      name: 'App管理',
      url: '/softwareMgr?type=mobile',
      open: props.deviceInfo ? props.getMobileAppListResult.data?.enable : false,
    },
  ];

  const updateConfig = async (data: any) => {
    await props.updatePolicySwitchesAction(data);
    await props.getAllConfigAction();
  };

  const closeTooltip = () => {
    setIsOpen(false);
    setIsClose(false);
  };

  const handPcSlientModeSwitch = async (checked: boolean) => {
    setSwitchValue(checked);
    setIsOpen(!checked && props?.deviceInfo?.id && isClose);

    await updateConfig({ silent_mode: checked });
    if (checked) {
      await updateConfig({ silent_mode_shutdown_timer: checked });
      await updateConfig({ silent_mode_lock_screen: checked });
    }
  };

  const deleteMobile = async () => {
    await props.unbindMobileDeviceAction(
      props.deviceInfo.deviceType,
      props.deviceInfo.id
    );
  };

  const unbindMobile = () => {
    modal.confirm({
      title: '确认解绑此设备吗？',
      icon: <ExclamationCircleOutlined />,
      content: <span>解绑成功后，该设备将失去守护。</span>,
      cancelText: '取消',
      okText: '确认',
      centered: true,
      width: 480,
      className: s.modal,
      onOk: deleteMobile,
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleUnbind = () => {
    if (isPc) {
      props.onUnBind();
    } else {
      unbindMobile();
    }
  };

  const currentDeviceName = isPc ? CONSTANT.textPc : CONSTANT.textMobile;
  const functionInfo: any = isPc ? functionInfoPc : functionInfoMobile;

  return (
    <div className={s.container}>
      <div className={s.title}>
        <div className={s.deviceName}>
          孩子{currentDeviceName}
          {props.deviceInfo && (
            <QsButton type="default" size="small" handleClick={handleUnbind}>
              <span className={s.unbind}>
                <img src={unbindIcon} alt="" />
                <span>解绑</span>
              </span>
            </QsButton>
          )}
          {props.deviceInfo && (
            <div className={s.deviceStatus}>
              <DeviceStatus isOnline={props.deviceInfo?.isOnline} />
            </div>
          )}
        </div>
        {props.currentDevice === 'pc' && (
          <div className={s.pcHideMode}>
            客户端隐身模式
            <Tooltip
              color="#1ED38F"
              // trigger="''"
              overlayStyle={{ whiteSpace: 'nowrap' }}
              open={isOpen}
              destroyTooltipOnHide={true}
              placement="bottomRight"
              title={
                <div>
                  开启后青松守护应用图标将被隐藏，孩子将不会感知到被管控
                  <CloseOutlined
                    style={{ width: '.8em', height: '.8em', marginLeft: '.3em' }}
                    onClick={closeTooltip}
                  />
                </div>
              }
            >
              <Switch
                className={s.switch}
                checked={switchValue}
                size={window.innerHeight === 768 ? 'small' : 'default'}
                onChange={handPcSlientModeSwitch}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {!props.deviceInfo ? (
        <div className={s.deviceInfoEmpty}>
          <div className={s.deviceInfo}>
            <div>
              <span>设备型号：</span>
              <span>--</span>
            </div>
            <div>
              <span>系统型号：</span>
              <span>--</span>
            </div>
            <div>
              <span>当前状态：</span>
              <span>--</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.deviceInfo}>
          <div>
            <span>设备型号：</span>
            <span>{props.deviceInfo.name}</span>
          </div>
          <div>
            <span>系统型号：</span>
            <span>{props.deviceInfo.system}</span>
          </div>
          <div>
            <span>当前状态：</span>
            {!props.deviceInfo?.isOnline ? (
              <span>--</span>
            ) : props.lockScreen ? (
              <span className={s.lockScreen}>锁屏中</span>
            ) : (
              <span>未锁屏</span>
            )}
          </div>
        </div>
      )}
      <div className={s.functionContainer}>
        {functionInfo.map((item: any) => (
          <FunctionCard
            key={item.name}
            data={item}
            isLimitExpired={props.isLimitExpired}
          />
        ))}
      </div>
      <div className={s.behaviorContainer}>
        <BehaviorOverview currentDevice={props.currentDevice} />
      </div>
      <QsAlert
        visible={showAlert}
        type={alertType}
        position="absolute"
        message={alertMessage}
        onClose={() => {
          setShowAlert(false);
        }}
      />
      {contextHolder}
    </div>
  );
}

interface IFunctionCardProps {
  data: any;
  isLimitExpired: boolean;
}

function FunctionCard(props: IFunctionCardProps) {
  const [showExpireModal, setShowExpireModal] = useState(false);

  return (
    <>
      <Link
        className={s.functionCard}
        to={props.isLimitExpired ? '#' : props.data.url}
        onClick={() => {
          if (props.isLimitExpired) {
            setShowExpireModal(true);
          }
        }}
      >
        <div>
          <img src={props.data.icon} alt="" />
          <div className={s.functionName}>{props.data.name}</div>
          {props.data.open ? (
            <div className={s.setted}>已设置 &gt;</div>
          ) : (
            <div className={s.setting}>{props.data.text || '未设置'} &gt;</div>
          )}
        </div>
      </Link>
      <MemberExpireModal
        visible={showExpireModal}
        handleClose={() => {
          setShowExpireModal(false);
        }}
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    getLockScreenControlsResult: state.getLockScreenControlsResult.data,
    getPcLockScreenControlsResult: state.getPcLockScreenControlsResult.data,
    getAllConfigResult: state.getAllConfigResult.data,
    unbindMobileDeviceResult: state.unbindMobileDeviceResult.data,
    getMobileAppListResult: state.getMobileAppListResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    toggleWebACLResult: state.toggleWebACLResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getAllConfigAction: bindActionCreators(getAllConfigAction, dispatch),
    updatePolicySwitchesAction: bindActionCreators(updatePolicySwitchesAction, dispatch),
    unbindMobileDeviceAction: bindActionCreators(unbindMobileDeviceAction, dispatch),
    getMobileDeviceAction: bindActionCreators(getMobileDeviceAction, dispatch),
    clearUnbindMobileDeviceAction: bindActionCreators(
      clearUnbindMobileDeviceAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightContainer);
