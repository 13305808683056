import currency from 'currency.js';
import cnchar from 'cnchar';
import queryString from 'query-string';

export const defaultActionOption = { noRethrow: true };

export function fetchPostConfig(body: any) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return config;
}

export function fetchPostFormDataConfig(formData: any) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
    credentials: 'include',
  };
  return config;
}

export function fetchPutConfig(body: any) {
  const config = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return config;
}

export function fetchPatchConfig(body: any) {
  const config = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return config;
}

export function fetchGetConfig(organizationId: any = '') {
  const config = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  if (!!organizationId) {
    // @ts-ignore
    config.headers['organization-id'] = organizationId;
  }
  return config;
}

export function fetchDelConfig(body: any) {
  const config = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'include',
  };
  return config;
}

export const regPattern = {
  EMAIL: /^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/,
  NAME: /^[a-zA-Z0-9\s_()（）\-\u4e00-\u9fa5]+$/,
  PASSWORD: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]/,
  PHONE: /^1\d{10}$/,
  DOMAIN_REGEX: /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,
  ILLEGAL_DOMAIN_REGEX: /[ <>#%{}|^~[\]\\]/,
};

export function getCurrentTimeStamp() {
  const dateTime = Date.now();

  return Math.floor(dateTime / 1000);
}

export function addMinutesToCurrentTime(minutes: number) {
  const d1 = new Date();
  const d2: any = new Date(d1);
  d2.setMinutes(d1.getMinutes() + minutes);

  return Math.floor(d2 / 1000);
}

export function formatMoney(value: any, symbol = '¥ ') {
  return currency(value, { symbol, precision: 2 });
}

export function smsCodeErrormessage() {
  return {
    'Non-existent verification code': '手机验证码错误。',
    'invalid verifyCode': '手机验证码错误。',
    locked: '手机验证码错误次数太多, 请1小时后再进行尝试。',
    'wait a moment': '获取手机验证码太频繁，请60s后重新获取。',
    'sms fetch error': '验证码发送失败，请重试！',
  };
}

export function isMobile() {
  const RE_MOBILE =
    /Android.*Mobile|NokiaN9.*Mobile|iP(?:hone|od)|Blackberry|IEMobile|webOS|BB10|Opera Mini|Phone/i;
  return RE_MOBILE.test(navigator.userAgent);
}

export function getUrlParams(queryName: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(queryName);
}

export function getChineseInitialLetter(chinese: string) {
  const str: any = cnchar.spell(chinese);
  return str.charAt(0).toUpperCase();
}

export function alphaList() {
  return [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
}

export function getUrlQuery(name: string) {
  const query = queryString.parse(window.location.search);
  return query[name];
}

export function replaceUrlQuery(name: string, value: string) {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    `?${name}=${value}`;
  window.history.pushState({ path: newurl }, '', newurl);
}

export function getCurrentWeek() {
  let str = '';
  const week = new Date().getDay();
  if (week == 0) {
    str = 'sunday';
  } else if (week == 1) {
    str = 'monday';
  } else if (week == 2) {
    str = 'tuesday';
  } else if (week == 3) {
    str = 'wednesday';
  } else if (week == 4) {
    str = 'thursday';
  } else if (week == 5) {
    str = 'friday';
  } else if (week == 6) {
    str = 'saturday';
  }
  return str;
}

export function getCurrentTime() {
  const current = new Date();
  const hours = current.getHours();
  const minutes = current.getMinutes();
  const hourStr = hours < 10 ? `0${hours}` : hours.toString();
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes.toString();
  return `${hourStr}:${minuteStr}`;
}
