import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Pagination } from 'antd';

import s from './News.module.scss';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import {
  getArticlesAction,
  getLabelsAction,
  getArticleCountAction,
  getArticleByIdAction,
} from '../../../redux/articles';
import { getUrlParams } from '../../../common';
import HotLabels from '../../components/HotLabels/HotLabels';
import ArticleLabels from '../../components/ArticleLabels/ArticleLabels';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  getArticlesAction: (page: number, limit: number, labelId: string | null) => void;
  getArticlesResult: any;
  getLabelsAction: () => void;
  getLabelsResult: any;
  getArticleCountAction: (labelId: string | null) => void;
  getArticleCountResult: any;
  getArticleByIdAction: (id: string) => void;
}

interface IStates {
  currentPage: number;
}

const PAGE_SIZE = 5;

class News extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  async componentDidMount() {
    const queryLabelId = getUrlParams('labelId');
    await Promise.all([
      this.props.getArticlesAction(this.state.currentPage, PAGE_SIZE, queryLabelId),
      this.props.getLabelsAction(),
      this.props.getArticleCountAction(queryLabelId),
    ]);
  }

  mapLabel = (labelIds: string) => {
    const labels = this.props.getLabelsResult.data || [];
    return labels
      .map((label: any) => {
        if (labelIds.includes(label.id)) {
          return label;
        }
      })
      .filter((item: string) => !!item);
  };

  renderNewsItem = () => {
    const articles = this.props.getArticlesResult.data || [];
    const data = articles.map((item: any) => {
      return {
        id: item.id,
        title: item.title,
        link: '',
        time: moment.unix(item.lastModifiedTime).format('YYYY年MM月DD日 HH:mm'),
        hits: item.hits,
        labels: this.mapLabel(item.labelIds),
        content: item.content,
      };
    });

    return data.map((item: any) => (
      <a
        key={item.id}
        href={`/newsDetail?id=${item.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={s.newsItem}>
          <div className={s.title}>{item.title}</div>
          <div className={s.time}>
            <span>{item.time}</span>
            <span>{item.hits} 人阅读</span>
          </div>
          <div className={s.labels}>
            <ArticleLabels labels={item.labels} />
          </div>
          <div className={s.content}>{item.content}</div>
        </div>
      </a>
    ));
  };

  render() {
    const total = this.props.getArticleCountResult.data?.count || 0;
    const queryLabelId = getUrlParams('labelId');
    return (
      <HomeLayout showFooterBg={false}>
        <div className={s.container}>
          <div className={s.nav}>
            <Link to="/">{!!isZGYD() ? '首页' : '青松守护首页'}</Link>
            <span className={s.navIcon}>&gt;</span>
            {!!queryLabelId ? (
              <>
                <a href="/news">新闻动态</a>
                <span className={s.navIcon}>&gt;</span>
                <span>{this.mapLabel(queryLabelId)[0]?.name}</span>
              </>
            ) : (
              <span>新闻动态</span>
            )}
          </div>
          {queryLabelId && <HotLabels />}
          {this.renderNewsItem()}
          <div className={s.pagination}>
            <Pagination
              total={total}
              showTotal={(total) => `共 ${total} 条`}
              defaultPageSize={PAGE_SIZE}
              defaultCurrent={this.state.currentPage}
              showSizeChanger={false}
              hideOnSinglePage
              onChange={async (page) => {
                await this.props.getArticlesAction(page, PAGE_SIZE, queryLabelId);
              }}
            />
          </div>
        </div>
      </HomeLayout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getArticlesResult: state.getArticlesResult.data,
    getLabelsResult: state.getLabelsResult.data,
    getArticleCountResult: state.getArticleCountResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getArticlesAction: bindActionCreators(getArticlesAction, dispatch),
    getLabelsAction: bindActionCreators(getLabelsAction, dispatch),
    getArticleCountAction: bindActionCreators(getArticleCountAction, dispatch),
    getArticleByIdAction: bindActionCreators(getArticleByIdAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
