import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig, fetchGetConfig } from '../common';
import { LOGIN, RESET_LOGIN_ERROR, LOGOUT } from './constants';

function login(data) {
  return fetch('/user/auth', fetchPostConfig(data))
    .then(async (resp) => {
      if (resp.ok) {
        window.location.href = data.redirect;
      } else {
        const data = await resp.json();
        return data;
      }
    })
    .catch(() => ({ reason: 'login fetch error' }));
}

export const loginAction = createActionAsync(LOGIN, login, defaultActionOption);

function resetLoginError() {
  return Promise.resolve({ reason: null });
}

export const resetLoginErrorAction = createActionAsync(
  RESET_LOGIN_ERROR,
  resetLoginError,
  defaultActionOption
);

export const loginReducer = createReducerAsync([loginAction, resetLoginErrorAction]);

function logout() {
  return fetch('/user/logout', fetchGetConfig())
    .then(async (resp) => {
      if (resp.ok) {
        window.location.href = '/';
      } else {
        const data = await resp.json();
        return data;
      }
    })
    .catch(() => ({ reason: 'logout fetch error' }));
}

export const logoutAction = createActionAsync(LOGOUT, logout, defaultActionOption);

export const logoutReducer = createReducerAsync(logoutAction);
