import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import s from './SetWebsite.module.scss';
import searchEmptyIcon from '../images/searchEmptyIcon.svg';
import fireIcon from '../images/fireIcon.svg';
import selectedIcon from '../images/selectedIcon.svg';
import helpIcon from '../images/helpIcon.svg';
import backIcon from '../images/backIcon.svg';
import {
  clearSetOnlineCourseAction,
  clearSubmitWorkOrderAction,
  deleteOnlineCourseAction,
  getOnlineCourseAllAction,
  getOnlineCourseListAction,
  setOnlineCourseAction,
  submitWorkOrderAction,
} from '../../../../redux/onlineCourse';
import QsAlert from '../../../../commonComponents/QsAlert/QsAlert';
import {
  deleteCustomFileAction,
  getCustomFileAction,
  updateCustomFileAction,
  uploadDomainFileAction,
} from '../../../../redux/webControls';
import {
  SubmitWorkOrderModal,
  WhiteListCustomAddModal,
} from '../WebsiteModal/WebsiteModal';
import {
  QsConfirmModal,
  QsNormalModal,
} from '../../../../commonComponents/QsModal/QsModal';
import { QsTextButton } from '../../../../commonComponents/QsButton/QsButton';
import QsInput from '../../../../commonComponents/QsInput/QsInput';

interface IProps {
  getUserInfoResult: any;
  getOnlineCourseListAction: (courseName: string) => void;
  getOnlineCourseListResult: any;
  submitWorkOrderAction: (
    phone: string,
    websiteName: string,
    websiteDomain: string,
    websiteType: number
  ) => void;
  submitWorkOrderResult: any;
  setOnlineCourseAction: (courseIds: string[]) => void;
  setOnlineCourseResult: any;
  getOrgInfoResult: any;
  deleteOnlineCourseAction: (courseId: string) => void;
  clearSubmitWorkOrderAction: () => void;
  getProductStateResult: any;
  getOnlineCourseAllAction: () => void;
  getOnlineCourseAllResult: any;
  clearSetOnlineCourseAction: () => void;
  getCustomFileAction: (mode: string) => void;
  getCustomFileResult: any;
  updateCustomFileAction: (categoryID: number, forbidden: boolean) => void;
  uploadDomainFileAction: (mode: string, file: any) => void;
  deleteCustomFileAction: (categoryID: number) => void;
  uploadDomainFileResult: any;
}

function AddWebsite(props: IProps) {
  const scrollEl = useRef(null);

  const [selectedItem, setSelectedItem] = useState<any[]>([]);
  const [showWorkOrder, setShowWorkOrder] = useState(false);
  const [showCustomAddModal, setShowCustomAddModal] = useState(false);
  const [showWebsiteSelectModal, setShowWebsiteSelectModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showAllBtn, setShowAllBtn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [uploadFileError, setUploadFileError] = useState(false);
  const [alertInfo, setAlertInfo] = useState(
    (): {
      type: 'success' | 'error';
      message: string;
    } => {
      return {
        type: 'success',
        message: '设置成功。',
      };
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        props.getOnlineCourseListAction(''),
        props.getOnlineCourseAllAction(),
        props.getCustomFileAction('white'),
      ]);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onlineWebsiteAll = props.getOnlineCourseAllResult.data?.records || [];

    const orgId = props.getOrgInfoResult?.org_id || '';
    let selectedWebsites = [];
    if (orgId !== '') {
      selectedWebsites = onlineWebsiteAll
        .filter((item: any) => item.orgIdList?.includes(orgId))
        .map((item: any) => ({ name: item.onlineClassName, id: item._id }));
    }

    const customWebsites = props.getCustomFileResult.data || [];
    const selectedCustomWebsites = customWebsites
      .filter((item: any) => item.forbidden)
      .map((item: any) => ({
        name: item.name,
        id: item.id,
        type: 'custom',
      }));

    const allSelected = selectedWebsites.concat(selectedCustomWebsites);
    setSelectedItem([...allSelected]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getOnlineCourseAllResult, props.getOrgInfoResult, props.getCustomFileResult]);

  useEffect(() => {
    const success = props.setOnlineCourseResult.success;
    if (success === true) {
      setShowAlert(true);
      setAlertInfo({ type: 'success', message: '设置成功。' });
    } else if (success === false) {
      setShowAlert(true);
      setAlertInfo({ type: 'error', message: '设置失败' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setOnlineCourseResult]);

  useEffect(() => {
    const success = props.submitWorkOrderResult.success;
    if (success === true) {
      setShowWorkOrder(false);
      setShowAlert(true);
      setAlertInfo({
        type: 'success',
        message: '提交成功，我们将在 1 个工作日内帮您添加。',
      });
    } else if (success === false) {
      setShowAlert(true);
      setAlertInfo({
        type: 'error',
        message: '提交失败，请稍后重试。',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submitWorkOrderResult]);

  useEffect(() => {
    if (!props.uploadDomainFileResult.success && props.uploadDomainFileResult.errDesc) {
      setUploadFileError(true);
    } else {
      setShowCustomAddModal(false);
    }
  }, [props.uploadDomainFileResult]);

  const mapData = (type: any) => {
    const onlineWebsiteList = props.getOnlineCourseListResult.data?.records || [];
    return onlineWebsiteList
      .filter((item: any) => {
        const condition: any = {
          hot: item.hot === 1,
          course: item.hot !== 1 && (item.type === 0 || item.type === undefined),
          tool: item.hot !== 1 && item.type === 1,
        };

        return condition[type];
      })
      .map((item: any) => ({
        name: item.onlineClassName,
        id: item._id,
        defaultSelected: selectedItem.filter((i) => i.id === item._id).length > 0,
      }));
  };

  const hotWebsites = mapData('hot');
  const courseWebsites = mapData('course');
  const toolWebsites = mapData('tool');
  const customData = props.getCustomFileResult.data || [];
  const customWebsites = customData.map((item: any) => {
    return {
      name: item.name,
      id: item.id,
      defaultSelected: selectedItem.filter((i) => i.id === item.id).length > 0,
      type: 'custom',
    };
  });

  const searchEmpty =
    hotWebsites.length === 0 && courseWebsites.length === 0 && toolWebsites.length === 0;

  const handleSelect = (item: any) => {
    const contains = selectedItem.filter((i) => i.id === item.id).length > 0;
    if (contains) {
      const arr = selectedItem.filter((i) => i.id !== item.id);
      setSelectedItem([...arr]);
    } else {
      selectedItem.push(item);
      setSelectedItem([...selectedItem]);
    }
  };

  const handleDelete = async (item: any) => {
    await props.deleteCustomFileAction(item.id);
    await props.getCustomFileAction('white');
  };

  const handlePublicWebsites = async () => {
    const courseIds = selectedItem
      .filter((i) => i.type !== 'custom')
      .map((item) => item.id);
    await props.setOnlineCourseAction(courseIds);
    await Promise.all([
      props.getOnlineCourseListAction(''),
      props.getOnlineCourseAllAction(),
    ]);
  };

  const handleCustomWebsites = () => {
    const customSelected = selectedItem.filter((item) => item.type == 'custom');
    const customDefaultData = props.getCustomFileResult.data || [];
    customDefaultData.forEach(async (item: any) => {
      const selected = customSelected.filter((i) => i.id == item.id);
      if (selected.length > 0) {
        await props.updateCustomFileAction(item.id, true);
      } else {
        await props.updateCustomFileAction(item.id, false);
      }
      await props.getCustomFileAction('white');
    });
  };

  const handleCommit = async () => {
    await handlePublicWebsites();
    await handleCustomWebsites();

    props.clearSetOnlineCourseAction();
    setShowWebsiteSelectModal(false);
    emptySearch();
  };

  const handleSearch = async (value: string) => {
    await props.getOnlineCourseListAction(value);
  };

  const emptySearch = () => {
    handleSearch('');
    setShowAllBtn(false);
    setSearchValue('');
  };

  const backAllBtn = (
    <div className={s.showAllBtn} onClick={emptySearch}>
      <img src={backIcon} alt="" />
      返回全部网站
    </div>
  );

  const websiteSelectContent = () => {
    return (
      <>
        <div className={s.searchContainer}>
          <QsInput
            value={searchValue}
            type="search"
            placeholder="搜索网站"
            allowClear
            width={280}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onSearch={(value) => {
              handleSearch(value);
              if (value) {
                setShowAllBtn(true);
              } else {
                setShowAllBtn(false);
              }
            }}
          />
          <div className={s.workOrder}>
            <span>
              <img src={helpIcon} alt="" />
              找不到我要的网站？您可选择
              <QsTextButton
                type="primary"
                handleClick={() => {
                  setShowCustomAddModal(true);
                }}
              >
                <span>自行添加</span>
              </QsTextButton>
              或
              <QsTextButton
                type="primary"
                handleClick={() => {
                  setShowWorkOrder(true);
                }}
              >
                <span>提交工单（客服帮忙添加）</span>
              </QsTextButton>
            </span>
          </div>
        </div>
        <div className={s.websiteContainer} ref={scrollEl}>
          {searchEmpty ? (
            <div>
              {showAllBtn && backAllBtn}

              <div className={s.searchEmpty}>
                <img src={searchEmptyIcon} alt="" />
                搜索结果为空
              </div>
            </div>
          ) : (
            <div className={s.itemsContainer}>
              {showAllBtn && backAllBtn}
              {customWebsites.length > 0 && (
                <WebsitesClassifyItem
                  title="我添加的"
                  isHot={false}
                  deletable={true}
                  websitesData={customWebsites}
                  handleSelect={handleSelect}
                  handleDelete={handleDelete}
                />
              )}
              {hotWebsites.length > 0 && (
                <WebsitesClassifyItem
                  title="热门推荐"
                  isHot={true}
                  websitesData={hotWebsites}
                  handleSelect={handleSelect}
                />
              )}
              {courseWebsites.length > 0 && (
                <WebsitesClassifyItem
                  title="网课类网站"
                  websitesData={courseWebsites}
                  handleSelect={handleSelect}
                />
              )}
              {toolWebsites.length > 0 && (
                <WebsitesClassifyItem
                  title="工具类网站"
                  websitesData={toolWebsites}
                  handleSelect={handleSelect}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <div
        className={s.addBtn}
        onClick={() => {
          setShowWebsiteSelectModal(true);
        }}
      >
        + 添加允许访问的网站
      </div>
      <QsNormalModal
        visible={showWebsiteSelectModal}
        title="选择网站"
        width={800}
        okBtnDisable={searchEmpty}
        onCancel={async () => {
          setShowWebsiteSelectModal(false);
          emptySearch();
        }}
        onOk={handleCommit}
      >
        {websiteSelectContent()}
      </QsNormalModal>
      <QsAlert
        type={alertInfo.type}
        message={alertInfo.message}
        position="fixed"
        visible={showAlert}
        closable={false}
        onClose={() => {
          props.clearSetOnlineCourseAction();
          setShowAlert(false);
        }}
      />
      <SubmitWorkOrderModal
        visible={showWorkOrder}
        handleCancel={() => {
          setShowWorkOrder(false);
        }}
        handleConfirm={async (values) => {
          const { name, domain, type } = values;
          const userPhone = props.getUserInfoResult?.phone_number;
          await props.submitWorkOrderAction(userPhone, name, domain, type);
        }}
      />
      <WhiteListCustomAddModal
        visible={showCustomAddModal}
        hasError={uploadFileError}
        errorMsg={props.uploadDomainFileResult.errDesc}
        onAlertClose={() => {
          setUploadFileError(false);
        }}
        handleCancel={() => {
          setShowCustomAddModal(false);
        }}
        handleConfirm={async (values) => {
          const { file } = values;
          await props.uploadDomainFileAction('white', file);
          await props.getCustomFileAction('white');
        }}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getOnlineCourseListResult: state.getOnlineCourseListResult.data,
    submitWorkOrderResult: state.submitWorkOrderResult.data,
    getUserInfoResult: state.getUserInfoResult.data.user,
    setOnlineCourseResult: state.setOnlineCourseResult.data,
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getProductStateResult: state.getProductStateResult.data.data,
    getOnlineCourseAllResult: state.getOnlineCourseAllResult.data,
    getCustomFileResult: state.getCustomFileResult.data,
    uploadDomainFileResult: state.uploadDomainFileResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOnlineCourseListAction: bindActionCreators(getOnlineCourseListAction, dispatch),
    submitWorkOrderAction: bindActionCreators(submitWorkOrderAction, dispatch),
    setOnlineCourseAction: bindActionCreators(setOnlineCourseAction, dispatch),
    deleteOnlineCourseAction: bindActionCreators(deleteOnlineCourseAction, dispatch),
    clearSubmitWorkOrderAction: bindActionCreators(clearSubmitWorkOrderAction, dispatch),
    getOnlineCourseAllAction: bindActionCreators(getOnlineCourseAllAction, dispatch),
    clearSetOnlineCourseAction: bindActionCreators(clearSetOnlineCourseAction, dispatch),
    getCustomFileAction: bindActionCreators(getCustomFileAction, dispatch),
    updateCustomFileAction: bindActionCreators(updateCustomFileAction, dispatch),
    uploadDomainFileAction: bindActionCreators(uploadDomainFileAction, dispatch),
    deleteCustomFileAction: bindActionCreators(deleteCustomFileAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWebsite);

interface IWebsitesClassifyItemProps {
  title: string;
  websitesData: any[];
  handleSelect: (item: any) => void;
  isHot?: boolean;
  deletable?: boolean;
  handleDelete?: (item: any) => void;
}

function WebsitesClassifyItem(props: IWebsitesClassifyItemProps) {
  return (
    <div>
      <div className={s.websiteTitle}>
        {props.title}
        {props.isHot && <img src={fireIcon} />}
      </div>
      <div className={s.websites}>
        {props.websitesData.map((item) => (
          <WebsiteSelectItem
            data={item}
            key={item.id}
            deletable={props.deletable}
            handleDelete={() => {
              if (props.handleDelete) {
                props.handleDelete(item);
              }
            }}
            handleSelect={() => {
              props.handleSelect(item);
            }}
          />
        ))}
      </div>
    </div>
  );
}

interface IWebsiteSelectItemProps {
  data: any;
  handleSelect: () => void;
  deletable?: boolean;
  handleDelete?: () => void;
}

function WebsiteSelectItem(props: IWebsiteSelectItemProps) {
  const [selected, setSelected] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setSelected(props.data.defaultSelected);
  }, [props.data]);

  return (
    <div
      className={`${s.websiteItem} ${selected ? s.websiteItemSelected : ''}`}
      onClick={() => {
        setSelected(!selected);
        props.handleSelect();
      }}
    >
      {props.data.name}
      {props.deletable && (
        <span
          className={`${s.deleteIcon} ${selected ? s.deleteIconSelected : ''}`}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          <CloseOutlined />
        </span>
      )}
      {selected && <img src={selectedIcon} alt="" />}
      <QsConfirmModal
        visible={modalVisible}
        title="确认删除此网站吗？"
        desc="删除后，将同时删除您添加的域名文件。"
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          if (props.handleDelete) {
            props.handleDelete();
          }
        }}
      />
    </div>
  );
}
