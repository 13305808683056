import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig } from '../common';
import {
  PUBLISH_FEEDBACK,
  GET_ALL_FEEDBACK,
  ALL_FEEDBACK_COUNTS,
  GET_MINE_FEEDBACK,
  MINE_FEEDBACK_COUNTS,
  FEEDBACK_LIKE,
  FEEDBACK_DELETE,
} from './constants';

function publishFeedback(orgId, userName, content) {
  const bodyData = {
    org_id: orgId,
    user_name: userName,
    content,
  };
  return fetch('/api/org/api/feedbacks', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 403) return Promise.reject();
      const data = await resp.json();
      if (resp.status === 201) {
        return { success: true, data };
      }
      return { success: false, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const publishFeedbackAction = createActionAsync(
  PUBLISH_FEEDBACK,
  publishFeedback,
  defaultActionOption
);

export const publishFeedbackReducer = createReducerAsync(publishFeedbackAction);

function getAllFeedback(page = 0, orgId) {
  const bodyData = {
    query: 'get_all',
    org_id: orgId,
    page_size: 15,
    page,
  };
  return fetch('/api/org/api/feedback_querys', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getAllFeedbackAction = createActionAsync(
  GET_ALL_FEEDBACK,
  getAllFeedback,
  defaultActionOption
);

export const getAllFeedbackReducer = createReducerAsync(getAllFeedbackAction);

function allFeedbackCounts() {
  const bodyData = {
    query: 'count_all',
  };
  return fetch('/api/org/api/feedback_querys', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const allFeedbackCountsAction = createActionAsync(
  ALL_FEEDBACK_COUNTS,
  allFeedbackCounts,
  defaultActionOption
);

export const allFeedbackCountsReducer = createReducerAsync(allFeedbackCountsAction);

function getMineFeedback(page = 0, orgId) {
  const bodyData = {
    query: 'get_mine',
    org_id: orgId,
    page_size: 15,
    page,
  };
  return fetch('/api/org/api/feedback_querys', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const getMineFeedbackAction = createActionAsync(
  GET_MINE_FEEDBACK,
  getMineFeedback,
  defaultActionOption
);

export const getMineFeedbackReducer = createReducerAsync(getMineFeedbackAction);

function mineFeedbackCounts(orgId) {
  const bodyData = {
    query: 'count_mine',
    org_id: orgId,
  };
  return fetch('/api/org/api/feedback_querys', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const mineFeedbackCountsAction = createActionAsync(
  MINE_FEEDBACK_COUNTS,
  mineFeedbackCounts,
  defaultActionOption
);

export const mineFeedbackCountsReducer = createReducerAsync(mineFeedbackCountsAction);

function feedBackLike(id, orgId, cancel) {
  const bodyData = {
    id,
    org_id: orgId,
  };
  if (cancel) {
    bodyData['unlike'] = true;
  }

  return fetch('/api/org/api/feedback_likes', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const feedBackLikeAction = createActionAsync(
  FEEDBACK_LIKE,
  feedBackLike,
  defaultActionOption
);

export const feedBackLikeReducer = createReducerAsync(feedBackLikeAction);

function feedBackDelete(id, orgId) {
  const bodyData = {
    id,
    org_id: orgId,
  };

  return fetch('/api/org/api/feedback_removes', fetchPostConfig(bodyData))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const feedBackDeleteAction = createActionAsync(
  FEEDBACK_DELETE,
  feedBackDelete,
  defaultActionOption
);

export const feedBackDeleteReducer = createReducerAsync(feedBackDeleteAction);
