import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig } from '../common';
import { GET_VERSION_INFO } from './constants';

function getVersionInfo() {
  return fetch('/api/ibg/qs/versionControl/latest', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, data: { message: 'fail' } }));
}

export const getVersionInfoAction = createActionAsync(
  GET_VERSION_INFO,
  getVersionInfo,
  defaultActionOption
);

export const getVersionInfoReducer = createReducerAsync(getVersionInfoAction);
