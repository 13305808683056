import React, { Component } from 'react';
import s from './HotLabels.module.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getHotLabelsAction } from '../../../redux/articles';
import ArticleLabels from '../ArticleLabels/ArticleLabels';

interface IProps {
  getHotLabelsAction: () => void;
  getHotLabelsResult: any;
}

class HotLabels extends Component<IProps, Record<string, never>> {
  async componentDidMount() {
    await this.props.getHotLabelsAction();
  }

  render() {
    const hotLabels = this.props.getHotLabelsResult.data || [];
    return (
      <div className={s.container}>
        <span className={s.labelTitle}>热门标签:</span>
        <span>
          <ArticleLabels labels={hotLabels} />
        </span>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getHotLabelsResult: state.getHotLabelsResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getHotLabelsAction: bindActionCreators(getHotLabelsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HotLabels);
