import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './LeftNavigation.module.scss';
import MemberCard from '../MemberCard/MemberCard';
import { getProductStateAction } from '../../../redux/order';
import { getChildInfoAction } from '../../../redux/childInfo';
import memberIcon from './images/memberCardIcon.svg';
import expiredMemberIcon from './images/expiredCardIcon.svg';
import { Tooltip } from 'antd';
import logo from './images/logo.svg';
import miniZGYDLogo from '../../../ZGYDAssets/miniZGYDLogo.svg';
import dashboardZGYDLogo from '../../../ZGYDAssets/zgydVLogo.png';
import miniLogo from './images/miniLogo.svg';
import accountIconNormal from './images/accountIconNormal.png';
import accountIconActive from './images/accountIconActive.png';
import dashboardIconActive from './images/dashboardIconActive.png';
import dashboardIconNormal from './images/dashboardIconNormal.png';
import orderIconActive from './images/orderIconActive.png';
import orderIconNormal from './images/orderIconNormal.png';
import onlineServiceIconActive from './images/onlineServiceIconActive.png';
import onlineServiceIconNormal from './images/onlineServiceIconNormal.png';
import backIcon from './images/backIcon.png';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  getProductStateAction: () => void;
  getProductStateResult: any;
  getChildInfoAction: () => void;
  getChildInfoResult: any;
}

interface IStates {
  showMinMemberCard: boolean;
  currentWidth: number;
  currentTimer: any;
}

class LeftNavigation extends Component<IProps, IStates> {
  minMemberCardRef: React.RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      showMinMemberCard: false,
      currentWidth: 1092,
      currentTimer: '',
    };

    this.minMemberCardRef = React.createRef();
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setState({
      currentWidth: window.innerWidth,
    });
    await this.props.getProductStateAction();
    await this.props.getChildInfoAction();
  }

  handleClickOutside = (event: any) => {
    // @ts-ignore
    if (
      this.minMemberCardRef &&
      this.minMemberCardRef.current !== null &&
      !this.minMemberCardRef?.current?.contains(event.target)
    ) {
      this.setState({ showMinMemberCard: false });
    }
  };

  setMinMemberCardRef = (node: any) => {
    this.minMemberCardRef = node;
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      currentWidth: window.innerWidth,
    });
  };

  isInnerPaths = () => {
    const paths = [
      '/dashboard',
      '/behavior',
      '/mobileApp',
      '/lockScreen',
      '/webControls',
      '/softwareMgr',
      '/remoteAssistant',
      '/callMgr',
      '/screenShot',
      '/position',
      '/mobileLockScreen',
      '/timeMgr',
    ];

    const currentPath = window.location.pathname;

    return paths.includes(currentPath);
  };

  handleMouseEnter = () => {
    clearTimeout(this.state.currentTimer);
    this.setState({ showMinMemberCard: true });
  };

  handleMouseLeave = () => {
    const t = setTimeout(() => {
      this.setState({ showMinMemberCard: false });
    }, 100);

    this.setState({
      currentTimer: t,
    });
  };

  minNav = (links: any[]) => {
    const currentPath = window.location.pathname;
    const availableLimit =
      this.props.getProductStateResult &&
      this.props.getProductStateResult.availableLimit[0];
    return (
      <div className={s.minNavContainer}>
        <div>
          <div className={s.homeLink}>
            <Link to="/">
              <img
                src={!!isZGYD() ? miniZGYDLogo : miniLogo}
                alt="logo"
                className={s.miniLogoStyle}
              />
            </Link>
          </div>
          {links.map((item) => {
            let isActive = item.link === currentPath;
            if (item.link === '/dashboard') {
              isActive = item.link === currentPath || this.isInnerPaths();
            }
            return (
              // eslint-disable-next-line react/jsx-key
              <Tooltip placement="right" title={item.name} key={item.link}>
                <div className={s.minLinkItemGroup} key={item.link}>
                  <Link
                    to={item.disable ? '#' : item.link}
                    className={`${s.minLinkItem} ${
                      isActive ? s.activeMinLink : s.normalLink
                    } ${item.disable ? s.disableLink : ''}`}
                    key={item.name}
                  >
                    <img
                      src={isActive ? item.activeIcon : item.normalIcon}
                      alt=""
                      className={s.navIcon}
                    />
                  </Link>
                  {isActive ? <div className={s.minBottomExtra} /> : null}
                </div>
              </Tooltip>
            );
          })}
        </div>
        <div
          className={s.queen}
          ref={this.setMinMemberCardRef}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleMouseEnter}
        >
          <img
            src={availableLimit ? memberIcon : expiredMemberIcon}
            alt="icon"
            className={s.memberIcon}
          />

          {this.state.showMinMemberCard && (
            <div className={s.minMemberCard}>
              <MemberCard availableLimit={availableLimit} />
            </div>
          )}
        </div>
      </div>
    );
  };

  fullNav = (links: any[]) => {
    const currentPath = window.location.pathname;
    const availableLimit =
      this.props.getProductStateResult &&
      this.props.getProductStateResult.availableLimit[0];
    return (
      <div className={s.container}>
        <div className={s.links}>
          <div className={s.homeLink}>
            <Link to="/">
              {!!isZGYD() ? (
                <img
                  style={{
                    width: '140px',
                    display: 'flex',
                    margin: '15px auto 25px auto',
                  }}
                  src={dashboardZGYDLogo}
                  alt=""
                />
              ) : (
                <img src={logo} alt="logo" />
              )}
            </Link>
          </div>
          {links.map((item) => {
            let isActive = item.link === currentPath;
            if (item.link === '/dashboard') {
              isActive = item.link === currentPath || this.isInnerPaths();
            }
            return (
              <div key={item.link} className={s.linkItemGroup}>
                <Link
                  to={item.disable ? '#' : item.link}
                  className={`${s.linkItem} ${isActive ? s.activeLink : s.normalLink} ${
                    item.disable ? s.disableLink : ''
                  }`}
                  key={item.name}
                >
                  <img
                    src={isActive ? item.activeIcon : item.normalIcon}
                    alt=""
                    className={s.navIcon}
                  />
                  <span>{item.name}</span>
                </Link>
                {isActive ? <div className={s.bottomExtra} /> : null}
              </div>
            );
          })}
        </div>
        <div className={s.cardContainer}>
          <MemberCard availableLimit={availableLimit} />
        </div>
      </div>
    );
  };

  render() {
    const childInfos = this.props.getChildInfoResult.data || [];
    // eslint-disable-next-line no-var
    var links = [
      {
        name: '控制中心',
        link: '/dashboard',
        normalIcon: dashboardIconNormal,
        activeIcon: dashboardIconActive,
      },
      {
        name: '孩子设备',
        link: '/device',
        disable: childInfos.length === 0,
      },
      {
        name: '账号信息',
        link: '/account',
        normalIcon: accountIconNormal,
        activeIcon: accountIconActive,
      },
      {
        name: '订单信息',
        link: '/orderInfo',
        normalIcon: orderIconNormal,
        activeIcon: orderIconActive,
      },
      {
        name: '返回首页',
        link: '/',
        normalIcon: backIcon,
        activeIcon: backIcon,
      },
    ];

    if (!isZGYD()) {
      links.splice(4, 0, {
        name: '在线客服',
        link: '/service',
        normalIcon: onlineServiceIconNormal,
        activeIcon: onlineServiceIconActive,
      });
    }
    links = links.filter((ele) => ele.name !== '孩子设备');

    return (
      <div className={s.root}>
        {this.state.currentWidth <= 1366 ? this.minNav(links) : this.fullNav(links)}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getProductStateResult: state.getProductStateResult.data.data,
    getChildInfoResult: state.getChildInfoResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    getChildInfoAction: bindActionCreators(getChildInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
