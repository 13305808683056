import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig, fetchPatchConfig } from '../common';
import {
  GET_HOT_LABELS,
  GET_ARTICLES,
  GET_ARTICLE_BY_ID,
  GET_ARTICLE_COUNT,
  GET_LABELS,
  GET_LIKES_ARTICLES,
  PREV_AND_NEXT_ARTICLES,
  UPDATE_ARTICLE_HITS,
} from './constants';

function getHotLabels() {
  return fetch('/api/ibg/labels/hot', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getHotLabelsAction = createActionAsync(
  GET_HOT_LABELS,
  getHotLabels,
  defaultActionOption
);

function getLabels() {
  return fetch('/api/ibg/labels', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getLabelsAction = createActionAsync(
  GET_LABELS,
  getLabels,
  defaultActionOption
);

export const getLabelsReducer = createReducerAsync(getLabelsAction);

export const getHotLabelsReducer = createReducerAsync(getHotLabelsAction);

function getArticles(page, limit, labelId) {
  const url = !!labelId
    ? `/api/ibg/articles?page=${page}&limit=${limit}&labelId=${labelId}&likesTop=false`
    : `/api/ibg/articles?page=${page}&limit=${limit}&likesTop=false`;
  return fetch(url, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getArticlesAction = createActionAsync(
  GET_ARTICLES,
  getArticles,
  defaultActionOption
);

export const getArticlesReducer = createReducerAsync(getArticlesAction);

function getArticleById(id) {
  return fetch(`/api/ibg/articles/${id}`, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getArticleByIdAction = createActionAsync(
  GET_ARTICLE_BY_ID,
  getArticleById,
  defaultActionOption
);

export const getArticleByIdReducer = createReducerAsync(getArticleByIdAction);

function getArticleCount(labelId) {
  const url = !!labelId
    ? `/api/ibg/articles/count?labelId=${labelId}`
    : `/api/ibg/articles/count`;
  return fetch(url, fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getArticleCountAction = createActionAsync(
  GET_ARTICLE_COUNT,
  getArticleCount,
  defaultActionOption
);

export const getArticleCountReducer = createReducerAsync(getArticleCountAction);

function getLikesArticle() {
  return fetch('/api/ibg/likesArticles', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const getLikesArticleAction = createActionAsync(
  GET_LIKES_ARTICLES,
  getLikesArticle,
  defaultActionOption
);

export const getLikesArticleReducer = createReducerAsync(getLikesArticleAction);

async function preAndNextArticle(currentArticleId) {
  const allArticles = await getArticles(1, null);
  const ids = allArticles.data.map((article) => article.id);
  const currentIndex = ids.indexOf(currentArticleId);
  const prevArticle = allArticles.data.filter((article) => {
    return ids[currentIndex - 1] === article.id;
  });
  const nextArticle = allArticles.data.filter((article) => {
    return ids[currentIndex + 1] === article.id;
  });

  return Promise.resolve({
    success: true,
    data: { prev: prevArticle, next: nextArticle },
  });
}

export const preAndNextArticleAction = createActionAsync(
  PREV_AND_NEXT_ARTICLES,
  preAndNextArticle,
  defaultActionOption
);

export const preAndNextArticleReducer = createReducerAsync(preAndNextArticleAction);

function updateArticleHits(id) {
  return fetch(`/api/ibg/articles/${id}/hits`, fetchPatchConfig({}))
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'FetchError' }));
}

export const updateArticleHitsAction = createActionAsync(
  UPDATE_ARTICLE_HITS,
  updateArticleHits,
  defaultActionOption
);

export const updateArticleHitsReducer = createReducerAsync(updateArticleHitsAction);
