import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import s from './Account.module.scss';
import { regPattern } from '../../../common';
import FormTemplate from '../../../home/components/FormTemplate/FormTemplate';
import {
  getOrgInfoAction,
  getUserInfoAction,
  searchUserByPhoneAction,
  updatePhoneNumberAction,
} from '../../../redux/user';
import { getProductStateAction, getPricesInfoAction } from '../../../redux/order';
import { resetPhoneCaptchaErrorAction } from '../../../redux/getPhoneCaptcha';
import { smsCodeErrormessage } from '../../../common';
// import PurchaseModal from '../../components/PurchaseModal/PurchaseModal';
// import QsModal from '../../../commonComponents/QsModal/QsModal';
// import PurchaseModal from '../../components/PurchaseModal/PurchaseModal';
import {
  exchangeLimitByCodeAction,
  resetExchangeLimitByCodeErrorAction,
} from '../../../redux/product';
import { isZGYD } from '../../../utils/lib';
import QsAlert from '../../../commonComponents/QsAlert/QsAlert';
import { QsNormalModal } from '../../../commonComponents/QsModal/QsModal';
import { QsButton } from '../../../commonComponents/QsButton/QsButton';

const smsCodeErrorMessage: any = smsCodeErrormessage();

interface IProps {
  getOrgInfoAction: () => void;
  getUserInfoAction: (userId: string) => void;
  getOrgInfoResult: any;
  getUserInfoResult: any;
  searchUserByPhoneAction: (phone: string) => void;
  searchUserByPhoneResult: any;
  updatePhoneNumberAction: (
    userId: string,
    newPhoneNumber: number,
    smsCode: number
  ) => void;
  updatePhoneNumberResult: any;
  getProductStateAction: () => void;
  getProductStateResult: any;
  getPhoneCaptchaResult: any;
  resetPhoneCaptchaErrorAction: () => void;
  getPricesInfoAction: () => void;
  getPricesInfoResult: any;
  exchangeLimitByCodeAction: (code: string) => void;
  exchangeLimitByCodeResult: any;
  resetExchangeLimitByCodeErrorAction: () => void;
}

function Account(props: IProps) {
  const [showModal, setShowModal] = useState(false);
  const [disableCaptchaBtn, setDisableCaptchaBtn] = useState(true);
  const [errorInfo, setErrorInfo] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [isCodeEmpty, setIsCodeEmpty] = useState(false);
  const [activeCodeAlertType, setActiveCodeAlertType] = useState<'error' | 'success'>(
    'success'
  );
  const [activeCodeAlertMessage, setActiveCodeAlertMessage] = useState('');

  async function fetchData() {
    await props.getOrgInfoAction();
    await Promise.all([props.getProductStateAction(), props.getPricesInfoAction()]);
  }

  useEffect(() => {
    document.title = !!isZGYD() ? '个人中心' : '青松守护 - 个人中心';
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchOrgData() {
    await props.getUserInfoAction(props.getOrgInfoResult.owner_id);
  }

  useEffect(() => {
    if (props.getOrgInfoResult?.hasOwnProperty('owner_id')) {
      fetchOrgData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getOrgInfoResult]);

  useEffect(() => {
    if (
      props.getPhoneCaptchaResult.hasOwnProperty('success') &&
      !props.getPhoneCaptchaResult.success
    ) {
      showErrAlert(props.getPhoneCaptchaResult.message);
    }
  }, [props.getPhoneCaptchaResult]);

  useEffect(() => {
    const result = props.exchangeLimitByCodeResult.data?.message;
    if (result === 'success') {
      getProductState();
      setActiveCodeAlertType('success');
      setActiveCodeAlertMessage('兑换成功');
    } else if (
      result === 'activeCode not found' ||
      result === 'activeCode is already used'
    ) {
      setActiveCodeAlertType('error');
      setActiveCodeAlertMessage('激活码无效，请确认后输入');
    } else if (result !== undefined) {
      setActiveCodeAlertType('error');
      setActiveCodeAlertMessage('兑换失败，请稍后重试');
    }
  }, [props.exchangeLimitByCodeResult]);

  const getProductState = async () => {
    await props.getProductStateAction();
  };

  const phoneValidator = async (rule: any, value: string): Promise<any> => {
    if (!value) {
      return Promise.reject('请输入手机号码');
    }
    if (!regPattern.PHONE.test(value)) {
      return Promise.reject('手机号格式错误');
    }
    const result: any = await props.searchUserByPhoneAction(value);
    const user = result.response.user;
    if (user && user.length > 0) {
      setDisableCaptchaBtn(true);
      return Promise.reject('该手机号码已注册。');
    }
    setDisableCaptchaBtn(false);
    return Promise.resolve();
  };

  const captchaValidator = async (rule: any, value: string): Promise<any> => {
    if (!value) {
      return Promise.reject('请输入短信验证码');
    }
    return Promise.resolve();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (values: any) => {
    const userInfo = props.getUserInfoResult || {};
    await props.resetPhoneCaptchaErrorAction();
    const result: any = await props.updatePhoneNumberAction(
      userInfo.user_id,
      values.phone,
      values.smsCode
    );
    if (!result.response.success) {
      showErrAlert(result.response.message);
    }
  };

  const showErrAlert = (message: string) => {
    setErrorInfo(smsCodeErrorMessage[message] || '保存失败！');
  };

  const modifyPhoneNumber = () => {
    const forItems: any = [
      {
        name: 'phone',
        placeholder: '您的新手机号码',
        type: 'text',
        validateTrigger: 'onBlur',
        label: '手机号码：',
        rules: [
          {
            validator: phoneValidator,
          },
        ],
      },
      {
        name: 'smsCode',
        placeholder: '短信验证码',
        type: 'text',
        validateTrigger: 'onBlur',
        label: '验证码：',
        rules: [
          {
            validator: captchaValidator,
          },
        ],
      },
    ];
    const formSubmitBtn = (
      <div className={s.formBtn}>
        <QsButton htmlType="submit" size="normal" type="primary">
          <span>完成修改</span>
        </QsButton>
        <QsButton type="outline" size="normal" handleClick={closeModal}>
          <span>取消</span>
        </QsButton>
      </div>
    );
    return (
      <div className={s.modifyBtn}>
        <QsButton
          type="primaryOutline"
          size="small"
          handleClick={() => {
            setShowModal(true);
          }}
        >
          <span>更换号码</span>
        </QsButton>
        <QsNormalModal
          visible={showModal}
          title="修改绑定手机号码"
          onCancel={closeModal}
          okBtnHtmlType="submit"
          conentStyle={{ padding: '0' }}
          customFooter={null}
        >
          <div className={s.modalContent}>
            <FormTemplate
              forItems={forItems}
              formName="modifyPhoneNumber"
              btnText="完成修改"
              handleSubmit={handleSubmit}
              customFooter={formSubmitBtn}
              disableCaptchaBtn={disableCaptchaBtn}
            />
            <QsAlert
              visible={!!errorInfo}
              type="error"
              message={errorInfo}
              position="fixed"
              onClose={() => {
                setErrorInfo('');
              }}
            />
          </div>
        </QsNormalModal>
      </div>
    );
  };

  const exchangeLimit = async () => {
    if (activationCode === '') {
      setIsCodeEmpty(true);
      return;
    }
    setIsCodeEmpty(false);
    await props.exchangeLimitByCodeAction(activationCode);
  };

  const availableLimitArr = props.getProductStateResult
    ? props.getProductStateResult.availableLimit
    : [];
  const limit = availableLimitArr.length > 0 ? availableLimitArr[0] : null;
  const expiredTime = limit ? moment.unix(limit.mode.end).format('YYYY年MM月DD日') : '--';

  return (
    <div>
      <InfoCard title="基本信息">
        <div className={s.detail}>
          <div>
            <span className={s.itemTitle}>手机号码：</span>
            <span>{props.getUserInfoResult?.phone_number}</span>
            {modifyPhoneNumber()}
          </div>
        </div>
      </InfoCard>
      <InfoCard title="会员信息">
        <div>
          <div className={s.detail}>
            <div>
              <span className={s.itemTitle}>会员状态：</span>
              <span className={s.limitInfo}>
                {limit ? (
                  <span>
                    会员将于
                    <span>{expiredTime}</span>到期
                  </span>
                ) : (
                  <span className={s.expired}>已到期</span>
                )}

                <Link to={`/price`}>
                  {/* type="ghost" */}
                  <Button ghost type="primary" size="small">
                    <span>{limit ? '立即续费' : '立即开通'}</span>
                  </Button>
                </Link>
                {/* <PurchaseModal
                  name="年付会员"
                  btnText={limit ? '立即续费' : '立即开通'}
                  btnSize="small"
                  customBtn={
                    <span className={s.modifyBtn}>
                      <QsButton type="primaryOutline" size="small">
                        <span>{limit ? '立即续费' : '立即开通'}</span>
                      </QsButton>
                    </span>
                  }
                /> */}
              </span>
            </div>
          </div>
          <div className={s.activationCode}>
            <span className={s.itemTitle}>会员激活码兑换：</span>
            <div className={s.inputContainer}>
              <Input
                className={isCodeEmpty ? s.inputError : ''}
                placeholder="请输入激活码"
                onChange={(e) => {
                  setActivationCode(e.target.value);
                }}
              />
              {isCodeEmpty && <span>请输入激活码</span>}
            </div>
            <QsButton type="primary" size="normal" handleClick={exchangeLimit}>
              <span>立即兑换</span>
            </QsButton>
          </div>
        </div>
      </InfoCard>
      <QsAlert
        visible={!!activeCodeAlertMessage}
        type={activeCodeAlertType}
        message={activeCodeAlertMessage}
        position="absolute"
        onClose={() => {
          setActiveCodeAlertMessage('');
          props.resetExchangeLimitByCodeErrorAction();
        }}
      />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    getOrgInfoResult: state.getOrgInfoResult.data.org,
    getUserInfoResult: state.getUserInfoResult.data.user,
    searchUserByPhoneResult: state.searchUserByPhoneResult.data,
    updatePhoneNumberResult: state.updatePhoneNumberResult.data,
    getProductStateResult: state.getProductStateResult.data.data,
    getPhoneCaptchaResult: state.getPhoneCaptchaResult.data,
    getPricesInfoResult: state.getPricesInfoResult.data.data,
    exchangeLimitByCodeResult: state.exchangeLimitByCodeResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getOrgInfoAction: bindActionCreators(getOrgInfoAction, dispatch),
    getUserInfoAction: bindActionCreators(getUserInfoAction, dispatch),
    searchUserByPhoneAction: bindActionCreators(searchUserByPhoneAction, dispatch),
    updatePhoneNumberAction: bindActionCreators(updatePhoneNumberAction, dispatch),
    getProductStateAction: bindActionCreators(getProductStateAction, dispatch),
    getPricesInfoAction: bindActionCreators(getPricesInfoAction, dispatch),
    exchangeLimitByCodeAction: bindActionCreators(exchangeLimitByCodeAction, dispatch),
    resetExchangeLimitByCodeErrorAction: bindActionCreators(
      resetExchangeLimitByCodeErrorAction,
      dispatch
    ),
    resetPhoneCaptchaErrorAction: bindActionCreators(
      resetPhoneCaptchaErrorAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);

interface IInfoCardProps {
  title: string;
  children: React.ReactElement;
}

function InfoCard(props: IInfoCardProps) {
  return (
    <div className={s.infoCard}>
      <div className={s.title}>{props.title}</div>
      {props.children}
    </div>
  );
}
