import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig } from '../common';
import { DOWNLOAD_QS_PC_CLIENT, DOWNLOAD_QS_INSTALLER } from './constants';

function fetchQsInstallerUrl() {
  return fetch('/download/clientInstaller', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const fetchQsInstallerUrlAction = createActionAsync(
  DOWNLOAD_QS_INSTALLER,
  fetchQsInstallerUrl,
  defaultActionOption
);

export const fetchQsInstallerUrlReducer = createReducerAsync(fetchQsInstallerUrlAction);

function fetchQsPcClientUrl() {
  return fetch('/download/clientSetup', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const fetchQsPcClientUrlAction = createActionAsync(
  DOWNLOAD_QS_PC_CLIENT,
  fetchQsPcClientUrl,
  defaultActionOption
);

export const fetchQsPcClientUrlReducer = createReducerAsync(fetchQsPcClientUrlAction);
