import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import qrCodeImg from './images/qrCodeImg.png';
import s from './MobileFormLayout.module.scss';
import logo from '../../../../commonComponents/QsIcons/mobileLogo.svg';
import { getUrlParams } from '../../../../common';

interface IProps {
  title: string;
  children: React.ReactNode;
}

class MobileFormLayout extends Component<IProps, Record<string, never>> {
  render() {
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `?promotion=${promotion}` : '';
    return (
      <div className={s.container}>
        <div className={s.header}>
          <Link to={`/${promotionQuery}`}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={s.titleBox}>
          <div className={s.title}>{this.props.title}</div>
          <div className={s.titleTips}></div>
        </div>
        {this.props.children}

        <img className={s.qrcode} src={qrCodeImg} alt="family img" />
      </div>
    );
  }
}

export default MobileFormLayout;
