import React from 'react';
import s from './AppLayout.module.scss';
import QsTrapezoidTab from '../../../commonComponents/QsTabs/QsTrapezoidTab/QsTrapezoidTab';

interface IProps {
  pcContent?: React.ReactElement | null;
  mobileContent?: React.ReactElement | null;
  handleTabClick?: (key: string) => void;
  tabItems?: any;
}

interface ITabTitleProps {
  icon: string;
  text: string;
}

export function TabTitle(props: ITabTitleProps) {
  const { icon, text } = props;
  return (
    <div className={s.tabTitle}>
      <span>
        <img src={icon} />
        <span className={s.titleText}>{text}</span>
      </span>
    </div>
  );
}

export default function AppLayout(props: IProps) {
  const { handleTabClick } = props;

  if (props.tabItems?.length === 0) {
    return null;
  } else {
    return (
      <div className={s.container}>
        <QsTrapezoidTab
          defaultKey={props.tabItems[0].key}
          tabBarList={props.tabItems || []}
          onChange={(key: any) => {
            handleTabClick?.(key);
          }}
        />
      </div>
    );
  }
}
