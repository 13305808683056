import React from 'react';
import { Popover } from 'antd';
import descIcon from './images/descIcon.svg';
import s from './AnniversaryPopover.module.scss';

function GroupPurchaseDescPopover() {
  const content = (
    <ol className={s.content}>
      <li>活动时间：2023.08.01 - 2023.08.31；</li>
      <li>活动期内购买年付会员可额外获赠半年会员；</li>
      <li>赠送的半年会员将于订单生成后7个工作日内到账。</li>
    </ol>
  );
  return (
    <Popover
      content={content}
      title="活动规则"
      trigger="hover"
      placement="topLeft"
      overlayClassName={s.popover}
      overlayInnerStyle={{ padding: 0 }}
    >
      <img src={descIcon} alt="" className={s.descIcon} />
    </Popover>
  );
}

export default GroupPurchaseDescPopover;
