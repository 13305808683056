import { createActionAsync } from 'redux-act-async';

import createReducerAsync from './reducer/createReducerAsync';
import fetch from './fetch.server';
import { defaultActionOption, fetchPostConfig, fetchPutConfig } from '../common';
import {
  ACTIVATE_PRODUCT,
  EXCHANGE_LIMIT_BY_CODE,
  RESET_EXCHANGE_LIMIT_BY_CODE_ERROR,
  CREATE_ACTIVE_CODE,
  GET_ACTIVE_CODE_BY_ORDER_ID,
  RESET_GET_ACTIVE_CODE_BY_ORDER_ID,
} from './constants';
import { getOrgInfo } from './user';

function activateProduct() {
  const data = {
    name: 'tianxun',
  };
  return fetch(
    '/services/tianxun/apiv2/private/organization/activateProduct',
    fetchPostConfig(data)
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const activateProductAction = createActionAsync(
  ACTIVATE_PRODUCT,
  activateProduct,
  defaultActionOption
);

export const activateProductReducer = createReducerAsync(activateProductAction);

async function exchangeLimitByCode(code) {
  const orgInfo = await getOrgInfo();
  const data = {
    orgId: orgInfo.org.org_id,
    productName: 'tianxun',
    days: 365,
    activeCode: code,
  };
  return fetch('/api/ibg/qs/activeCode', fetchPutConfig(data))
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 404 && resp.status !== 409) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const exchangeLimitByCodeAction = createActionAsync(
  EXCHANGE_LIMIT_BY_CODE,
  exchangeLimitByCode,
  defaultActionOption
);

function resetExchangeLimitByCodeError() {
  return Promise.resolve({ message: null });
}

export const resetExchangeLimitByCodeErrorAction = createActionAsync(
  RESET_EXCHANGE_LIMIT_BY_CODE_ERROR,
  resetExchangeLimitByCodeError,
  defaultActionOption
);

export const exchangeLimitByCodeReducer = createReducerAsync([
  exchangeLimitByCodeAction,
  resetExchangeLimitByCodeErrorAction,
]);

async function createActiveCode(orderId) {
  const orgInfo = await getOrgInfo();
  const data = {
    operator: orgInfo.org.org_id,
    number: 3,
    orderId,
  };
  return fetch('/api/ibg/qs/activeCode', fetchPostConfig(data))
    .then(async (resp) => {
      if (!resp.ok && resp.status !== 404 && resp.status !== 409) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const createActiveCodeAction = createActionAsync(
  CREATE_ACTIVE_CODE,
  createActiveCode,
  defaultActionOption
);

export const createActiveCodeReducer = createReducerAsync(createActiveCodeAction);

export async function queryActiveCode(orderId) {
  const orgInfo = await getOrgInfo();
  return fetch(
    `/api/ibg/qs/activeCode/query`,
    fetchPostConfig({ orderId, orgId: orgInfo.org.org_id })
  )
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return { success: true, data };
    })
    .catch(() => ({ success: false, message: 'fetch error' }));
}

export const queryActiveCodeAction = createActionAsync(
  GET_ACTIVE_CODE_BY_ORDER_ID,
  queryActiveCode,
  defaultActionOption
);

function resetGetActiveCode() {
  return Promise.resolve({});
}

export const resetGetActiveCodeAction = createActionAsync(
  RESET_GET_ACTIVE_CODE_BY_ORDER_ID,
  resetGetActiveCode,
  defaultActionOption
);

export const queryActiveCodeReducer = createReducerAsync([
  queryActiveCodeAction,
  resetGetActiveCodeAction,
]);
