import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { isEmpty } from 'lodash';

import s from './SoftwareOperation.module.scss';
import OperationToolbar, {
  OperationItem,
} from '../../../components/OperationToolbar/OperationToolbar';
import { QsButtonFixedSize } from '../../../../commonComponents/QsButton/QsButton';
import MultiTimeSelector from '../../../components/MultiTimeSelector/MultiTimeSelector';
import { ITimeData } from '../../../components/MultiTimeSelector/TimeItem';

interface IProps {
  checkedCount: number;
  visible: boolean;
  handleClose: () => void;
  status: 'FullDisable' | 'FullEnable' | 'PartialEnable' | null;
  handleChange: (
    status: 'FullDisable' | 'FullEnable' | 'PartialEnable',
    timeRange: Array<any>
  ) => Promise<void>;
  timeRange: string;
  isHuawei?: boolean;
}

function SoftwareOperation(props: IProps) {
  const [status, setStatus] = useState<
    'FullDisable' | 'FullEnable' | 'PartialEnable' | null
  >(null);
  const [tmpTimeRange, setTmpTimeRange] = useState<any>([]);
  const [defaultTimeRange, setDefaultTimeRange] = useState<any>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setStatus(props.status);
    setTmpTimeRange([]);
    setDefaultTimeRange([]);
  }, [props.status]);

  useEffect(() => {
    if (!isEmpty(props.timeRange)) {
      const tmpTimeRangeArray = props.timeRange.split('、');
      const data = tmpTimeRangeArray.map((item: any) => {
        const tmpArray = item.split('-');
        return {
          begin: tmpArray[0].trim(),
          end: tmpArray[1].trim(),
        };
      });

      setTmpTimeRange(data);
      setDefaultTimeRange(data);
    }
  }, [props.timeRange]);

  const handleOperation = async (
    status: 'FullDisable' | 'FullEnable' | 'PartialEnable',
    timeRange: Array<any>
  ) => {
    if (status === 'PartialEnable' && timeRange.length <= 0) {
      setStatus('FullEnable');
      await props.handleChange('FullEnable', []);
    } else {
      setStatus(status);
      await props.handleChange(status, timeRange);
    }
  };

  const visibleChange = (val: any) => {
    setVisible(val);
  };

  const partialEnable = () => {
    const content = (
      <div className={s.popContent}>
        <MultiTimeSelector
          desc="鼠标拖动“可用时间”格子即可添加/删除可用时间段。"
          cardColorDesc={['可用', '不可用']}
          colorType="green"
          defaultTime={defaultTimeRange}
          selectedTime={tmpTimeRange}
          timeCardHeight={40}
          handleTimeChange={(value: ITimeData[]) => {
            setTmpTimeRange(value);
          }}
        />
        <div className={s.btn}>
          <QsButtonFixedSize
            size="middle"
            type="primary"
            handleClick={() => {
              handleOperation('PartialEnable', tmpTimeRange);
              setVisible(false);
            }}
          >
            <span>保存</span>
          </QsButtonFixedSize>
        </div>
      </div>
    );
    return (
      <Popover
        arrowPointAtCenter
        content={content}
        open={visible}
        trigger="click"
        onOpenChange={visibleChange}
      >
        <div className={s.operation}>限时可用</div>
      </Popover>
    );
  };

  if (!props.visible) {
    return null;
  } else {
    return (
      <OperationToolbar
        checkedCount={props.checkedCount}
        handleClose={() => {
          props.handleClose();
          setStatus(null);
        }}
      >
        <>
          <OperationItem
            id="FullEnable"
            currentId={status}
            theme="green"
            content="自由可用"
            handleClick={() => handleOperation('FullEnable', [])}
          />
          <OperationItem
            id="PartialEnable"
            currentId={status}
            theme="green"
            content={partialEnable()}
            handleClick={() => {
              setStatus('PartialEnable');
            }}
          />
          <OperationItem
            id="FullDisable"
            currentId={status}
            theme="red"
            content="完全禁用"
            handleClick={() => handleOperation('FullDisable', [])}
          />
        </>
      </OperationToolbar>
    );
  }
}

export default SoftwareOperation;
