import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import s from './Activity.module.scss';
import logo from '../../../commonComponents/QsIcons/logo.svg';
import ActivityRule from '../../components/ActivityRule/ActivityRule';
import Footer from '../../components/Footer/Footer';
import { checkLoginStateAction } from '../../../redux/user';
import { getUrlParams } from '../../../common';
import CompleteInfoModal from '../../components/CompleteInfoModal/CompleteInfoModal';

interface IProps {
  checkLoginStateAction: () => void;
  checkLoginStateResult: any;
}

class Activity extends Component<IProps, Record<string, never>> {
  async componentDidMount() {
    document.title = '青松守护';
    await this.props.checkLoginStateAction();
  }

  render() {
    const { isLogin } = this.props.checkLoginStateResult;
    const promotion = getUrlParams('promotion');
    const promotionQuery = promotion ? `&promotion=${promotion}` : '';
    const redirect = `?redirect=${window.location.pathname}`;
    return (
      <div className={s.container}>
        <div className={s.header}>
          <div>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.title}>
            <div>为 青松守护 提出您的宝贵建议 </div>
            <div>并拿走您的 MacBook Pro </div>
            <Link
              to={isLogin ? '/feedback' : `/login${redirect}${promotionQuery}`}
              className={s.link}
            >
              立即参与 <MdKeyboardBackspace />
            </Link>
          </div>
          <ActivityRule isPage={true} isLogin={isLogin} />
        </div>
        <Footer ipcOnly={true} />
        <CompleteInfoModal />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
