import React from 'react';
import s from './VNCLoader.module.scss';
import ipSvg from './ip.svg';
import processGif from './process.gif';
import errorIcon from './errorIcon.svg';
import { isZGYD } from '../../../../utils/lib';
import { QsButton } from '../../../../commonComponents/QsButton/QsButton';

interface IProps {
  className: string;
  config: any;
  status: string;
}

interface IState {
  seconds: number;
}

class VNCLoader extends React.Component<IProps, IState> {
  myInterval: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      seconds: 60,
    };
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState(({ seconds }) => {
        if (seconds > 0) {
          return {
            seconds: seconds - 1,
          };
        } else {
          clearInterval(this.myInterval);
          return {
            seconds: 0,
          };
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  onDisConnect = () => {
    window.close();
  };

  onReConnect = () => {
    window.location.reload();
  };

  getHead() {
    const { clientName, ip } = this.props.config;
    return (
      <div className={s.head}>
        <p>{`正在连接 ${clientName}`}</p>
        <p>
          <img alt="img" src={ipSvg} />
          {`  ${ip}`}
        </p>
      </div>
    );
  }

  render() {
    function getCard(config: any) {
      const name = config.status === 'error' ? s.error : s.normal;
      return (
        <div className={`${s.card} ${name}`}>
          <img src={processGif} className={s.separation} alt="process" />
          <div className={s.content}>
            <div>
              {config.status === 'error' && <img src={errorIcon} alt="connect error" />}
              <p className={`${s.title} ${name}`}>{config.title}</p>
            </div>
            <p className={s.subTitle}>{config.subTitle}</p>
            <div className={s.action}>{config.actions}</div>
          </div>
        </div>
      );
    }

    const subTitle = `您还需要等待${this.state.seconds}秒`;
    const requestTitle = '客户端远程模块正在初始化中...';
    const verfiedTitle = !!isZGYD()
      ? '正在连接客户端桌面...'
      : '青松守护正在连接客户端桌面...';
    const errorTitle = '远程连接失败，请检查您的网络情况。';
    const disConnectText = '取消';
    const reConnectText = '重新尝试';
    const closedText = '新连接建立,该连接已被关闭';

    const className = this.props.className;

    const disConnectBtn = (
      <span className={s.disConnect}>
        <QsButton key={1} type="outline" size="normal" handleClick={this.onDisConnect}>
          <span>{disConnectText}</span>
        </QsButton>
      </span>
    );

    const reconnectBtn = (
      <QsButton key={2} type="primary" size="normal" handleClick={this.onReConnect}>
        <span>{reConnectText}</span>
      </QsButton>
    );
    const stateMap = {
      request: (
        <div className={className}>
          {this.getHead()}
          {getCard({
            status: 'request',
            title: requestTitle,
            subTitle,
            actions: [disConnectBtn],
          })}
        </div>
      ),

      verified: (
        <div className={className}>
          {this.getHead()}
          {getCard({
            status: 'verified',
            title: verfiedTitle,
            subTitle,
            actions: [disConnectBtn],
          })}
        </div>
      ),

      success: null,

      error: (
        <div className={className}>
          {this.getHead()}
          {getCard({
            status: 'error',
            title: errorTitle,
            subTitle: '',
            actions: [disConnectBtn, reconnectBtn],
          })}
        </div>
      ),

      closed: (
        <div className={className}>
          {this.getHead()}
          {getCard({
            status: 'error',
            title: closedText,
            subTitle: '',
            actions: [disConnectBtn, reconnectBtn],
          })}
        </div>
      ),
    };
    // @ts-ignore
    return stateMap[this.props.status];
  }
}

export default VNCLoader;
