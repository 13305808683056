import React from 'react';
import s from './FunctionCard.module.scss';

interface IProps {
  icon: string;
  title: string;
  desc: string;
  functionList: Array<any>[];
  lineColor: string;
}

function FunctionCard(props: IProps) {
  const { icon, title, desc, functionList, lineColor } = props;
  return (
    <div className={s.container}>
      <div className={s.title}>
        {title}
        <span
          className={s.line}
          style={{ backgroundColor: lineColor, display: 'inline-block' }}
        ></span>
      </div>

      <div className={s.desc}>{desc}</div>
      <div>
        {functionList.map((item: any) => {
          return (
            <div key={item.title} className={s.detailInfo}>
              <div
                style={{
                  backgroundColor: item.color,
                  boxShadow: `2px 4px 8px 0 ${item.color}33`,
                  marginTop: 8,
                }}
              >
                <img style={{ width: 15 }} src={item.icon} alt="" />
              </div>

              <div>
                <div>{item.title}</div>
                <div>{item.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={s.bgBox}>
        <img src={icon} alt="" />
      </div>
    </div>
  );
}

export default FunctionCard;
