import { combineReducers } from 'redux';
import { loginReducer as loginResult, logoutReducer as logoutResult } from '../login.js';
import { getPhoneCaptchaReducer as getPhoneCaptchaResult } from '../getPhoneCaptcha';
import { registerReducer as registerResult } from '../register';
import {
  searchUserByPhoneReducer as searchUserByPhoneResult,
  getOrgInfoReducer as getOrgInfoResult,
  getUserInfoReducer as getUserInfoResult,
  checkLoginStateReducer as checkLoginStateResult,
  updatePhoneNumberReducer as updatePhoneNumberResult,
  completeSummaryReducer as completeSummaryResult,
  setGiftModalReducer as setGiftModalResult,
} from '../user';
import {
  getAllConfigReducer as getAllConfigResult,
  updatePolicySwitchesReducer as updatePolicySwitchesResult,
} from '../config';
import {
  getTerminalInfoReducer as getTerminalInfoResult,
  updateClientNameReducer as updateClientNameResult,
  deleteTerminalReducer as deleteTerminalResult,
} from '../terminal';
import {
  getClientConfigReducer as getClientConfigResult,
  updateUninstallPwdReducer as updateUninstallPwdResult,
} from '../clientConfig';
import {
  getLockScreenDurationsReducer as getLockScreenDurationsResult,
  updateLockScreenReducer as updateLockScreenResult,
  deleteLockScreenReducer as deleteLockScreenResult,
  addLockScreenReducer as addLockScreenResult,
} from '../lockScreen';
import {
  getBlockSitesDetailsReducer as getBlockSitesDetailsResult,
  updateDefaultWebsitesReducer as updateDefaultWebsitesResult,
  toggleWebACLReducer as toggleWebACLResult,
  updateToggleWebACLReducer as updateToggleWebACLResult,
  getBlackExceptionReducer as getBlackExceptionResult,
  addBlackExceptionReducer as addBlackExceptionResult,
  deleteBlackExceptionReducer as deleteBlackExceptionResult,
  updateBlackExceptionReducer as updateBlackExceptionResult,
  getWhiteListReducer as getWhiteListResult,
  addWhiteListReducer as addWhiteListResult,
  deleteWhiteListReducer as deleteWhiteListResult,
  updateWhiteListReducer as updateWhiteListResult,
  getCustomBlackListReducer as getCustomBlackListResult,
  addCustomBlackListReducer as addCustomBlackListResult,
  deleteCustomBlackListReducer as deleteCustomBlackListResult,
  updateCustomBlackListReducer as updateCustomBlackListResult,
  toggleCustomBlackListReducer as toggleCustomBlackListResult,
  uploadDomainFileReducer as uploadDomainFileResult,
  getCustomFileReducer as getCustomFileResult,
  updateCustomFileReducer as updateCustomFileResult,
  deleteCustomFileReducer as deleteCustomFileResult,
  getEffectiveTimeReducer as getEffectiveTimeResult,
  updateEffectiveTimeReducer as updateEffectiveTimeResult,
  deleteEffectiveTimeReducer as deleteEffectiveTimesult,
} from '../webControls';
import {
  getDashboardSoftwareCountReducer as getDashboardSoftwareCountResult,
  getSoftwareListReducer as getSoftwareListResult,
  getTerminalInstalledReducer as getTerminalInstalledResult,
  getSoftwareDetailNamesReducer as getSoftwareDetailNamesResult,
  postSoftwareSettingReducer as postSoftwareSettingResult,
  getUninstallCountReducer as getUninstallCountResult,
} from '../software';
import {
  refreshOrderReducer as refreshOrderResult,
  getOnlinePaymentReducer as getOnlinePaymentResult,
  getPaidOrdersReducer as getPaidOrdersResult,
  getProductStateReducer as getProductStateResult,
  getPricesInfoReducer as getPricesInfoResult,
  fetchOrderReducer as fetchOrderResult,
  updatePayMethodReducer as updatePayMethodResult,
} from '../order';
import {
  publishFeedbackReducer as publishFeedbackResult,
  getAllFeedbackReducer as getAllFeedbackResult,
  allFeedbackCountsReducer as allFeedbackCountsResult,
  getMineFeedbackReducer as getMineFeedbackResult,
  mineFeedbackCountsReducer as mineFeedbackCountsResult,
  feedBackLikeReducer as feedBackLikeResult,
  feedBackDeleteReducer as feedBackDeleteResult,
} from '../feedback';
import {
  activateProductReducer as activateProductResult,
  exchangeLimitByCodeReducer as exchangeLimitByCodeResult,
  createActiveCodeReducer as createActiveCodeResult,
  queryActiveCodeReducer as queryActiveCodeResult,
} from '../product';
import {
  addChildInfoReducer as addChildInfoResult,
  getChildInfoReducer as getChildInfoResult,
  deleteChildInfoReducer as deleteChildInfoResult,
  updateChildInfoReducer as updateChildInfoResult,
  currentChildReducer as currentChildResult,
  getChildrenAvatarsReducer as getChildrenAvatarsResult,
} from '../childInfo';
import {
  getMobileDeviceReducer as getMobileDeviceResult,
  unbindMobileDeviceReducer as unbindMobileDeviceResult,
  getMobileAppListReducer as getMobileAppListResult,
  updateMobileAppSwitchReducer as updateMobileAppSwitchResult,
  updateAppTimePolicyReducer as updateAppTimePolicyResult,
} from '../mobileAppDevice';
import { getEnvironmentsReducer as getEnvironmentsResult } from '../environments';
import {
  getBehaviorListReducer as getBehaviorListResult,
  getOriginalBehaviorListReducer as getOriginalBehaviorListResult,
} from '../behavior';
import {
  policySwitchReducer as policySwitchResult,
  positionInfoReducer as positionInfoResult,
} from '../position';
import {
  screenShotUpdateStatusReducer as screenShotUpdateStatusResult,
  triggerScreenShotReducer as triggerScreenShotResult,
  deleteImageByIdReducer as deleteImageByIdResult,
  checkScreenShotPermissionReducer as checkPermissionResult,
  getAllImagesReducer as getAllImagesResult,
} from '../screenShot';
import {
  communicationInfoReducer as communicationInfoResult,
  updateCommunicationPolicySwitchReducer as updateCommunicationPolicySwitchResult,
  updatePhoneCallSwitchReducer as updatePhoneCallSwitchResult,
  addContactReducer as addContactResult,
  deleteContactReducer as deleteContactResult,
  updateContactReducer as updateContactResult,
  oneKeyBlockSwitchReducer as oneKeyBlockSwitchResult,
} from '../call';
import {
  getHotLabelsReducer as getHotLabelsResult,
  getArticlesReducer as getArticlesResult,
  getArticleByIdReducer as getArticleByIdResult,
  getArticleCountReducer as getArticleCountResult,
  getLabelsReducer as getLabelsResult,
  getLikesArticleReducer as getLikesArticleResult,
  preAndNextArticleReducer as preAndNextArticleResult,
  updateArticleHitsReducer as updateArticleHitsResult,
} from '../articles';
import {
  getLockScreenControlsReducer as getLockScreenControlsResult,
  getPcLockScreenControlsReducer as getPcLockScreenControlsResult,
  updateLockScreenControlsReducer as updateLockScreenControlsResult,
} from '../mobileLockScreen';
import {
  fetchQsInstallerUrlReducer as fetchQsInstallerUrlResult,
  fetchQsPcClientUrlReducer as fetchQsPcClientUrlResult,
} from '../downloadPCClient';
import { getIosReleaseInfoReducer as getIosReleaseInfoResult } from '../iosRelease';
import { getParentReleaseInfoReducer as getParentReleaseInfoResult } from '../parentReleaseInfo';
import { getPcRemoteInfoReducer as getPcRemoteInfoResult } from '../pcRemote';
import remoteDesktop from './remoteDesktop';
import {
  getAllFaqReducer as getAllFaqResult,
  getAllCategoriesReducer as getAllCategoriesResult,
  searchFaqReducer as searchFaqResult,
} from '../faq';

import {
  getActivityListReducer as getActivityListResult,
  getParticipateInfoReducer as getParticipateInfoResult,
} from '../invitationActivity';
import {
  getOnlineCourseListReducer as getOnlineCourseListResult,
  submitWorkOrderReducer as submitWorkOrderResult,
  setOnlineCourseReducer as setOnlineCourseResult,
  deleteOnlineCourseReducer as deleteOnlineCourseResult,
  getOnlineCourseAllReducer as getOnlineCourseAllResult,
} from '../onlineCourse';
import { getVersionInfoReducer as getVersionInfoResult } from '../versionInfo';

const rootReducer = combineReducers({
  loginResult,
  getPhoneCaptchaResult,
  registerResult,
  searchUserByPhoneResult,
  getOrgInfoResult,
  getAllConfigResult,
  updatePolicySwitchesResult,
  getTerminalInfoResult,
  updateClientNameResult,
  deleteTerminalResult,
  getClientConfigResult,
  updateUninstallPwdResult,
  logoutResult,
  getLockScreenDurationsResult,
  updateLockScreenResult,
  deleteLockScreenResult,
  addLockScreenResult,
  getBlockSitesDetailsResult,
  updateDefaultWebsitesResult,
  getDashboardSoftwareCountResult,
  getSoftwareListResult,
  getTerminalInstalledResult,
  getSoftwareDetailNamesResult,
  postSoftwareSettingResult,
  getUninstallCountResult,
  refreshOrderResult,
  getOnlinePaymentResult,
  getUserInfoResult,
  checkLoginStateResult,
  getPaidOrdersResult,
  updatePhoneNumberResult,
  getProductStateResult,
  completeSummaryResult,
  publishFeedbackResult,
  getAllFeedbackResult,
  allFeedbackCountsResult,
  getMineFeedbackResult,
  mineFeedbackCountsResult,
  feedBackLikeResult,
  feedBackDeleteResult,
  activateProductResult,
  getPricesInfoResult,
  fetchOrderResult,
  updatePayMethodResult,
  addChildInfoResult,
  getChildInfoResult,
  deleteChildInfoResult,
  updateChildInfoResult,
  getMobileDeviceResult,
  unbindMobileDeviceResult,
  getEnvironmentsResult,
  getMobileAppListResult,
  updateMobileAppSwitchResult,
  updateAppTimePolicyResult,
  getBehaviorListResult,
  currentChildResult,
  policySwitchResult,
  positionInfoResult,
  screenShotUpdateStatusResult,
  triggerScreenShotResult,
  communicationInfoResult,
  updateCommunicationPolicySwitchResult,
  updatePhoneCallSwitchResult,
  addContactResult,
  deleteContactResult,
  updateContactResult,
  oneKeyBlockSwitchResult,
  getHotLabelsResult,
  getArticlesResult,
  getArticleByIdResult,
  getArticleCountResult,
  getLabelsResult,
  getLikesArticleResult,
  preAndNextArticleResult,
  updateArticleHitsResult,
  fetchQsInstallerUrlResult,
  deleteImageByIdResult,
  checkPermissionResult,
  getAllImagesResult,
  getOriginalBehaviorListResult,
  getChildrenAvatarsResult,
  toggleWebACLResult,
  updateToggleWebACLResult,
  getBlackExceptionResult,
  addBlackExceptionResult,
  deleteBlackExceptionResult,
  updateBlackExceptionResult,
  getWhiteListResult,
  addWhiteListResult,
  deleteWhiteListResult,
  updateWhiteListResult,
  getLockScreenControlsResult,
  updateLockScreenControlsResult,
  getCustomBlackListResult,
  addCustomBlackListResult,
  deleteCustomBlackListResult,
  updateCustomBlackListResult,
  toggleCustomBlackListResult,
  getIosReleaseInfoResult,
  getParentReleaseInfoResult,
  getPcRemoteInfoResult,
  remoteDesktop,
  getAllFaqResult,
  getAllCategoriesResult,
  searchFaqResult,
  getActivityListResult,
  getParticipateInfoResult,
  getPcLockScreenControlsResult,
  getOnlineCourseListResult,
  submitWorkOrderResult,
  setOnlineCourseResult,
  deleteOnlineCourseResult,
  getOnlineCourseAllResult,
  exchangeLimitByCodeResult,
  setGiftModalResult,
  getVersionInfoResult,
  uploadDomainFileResult,
  getCustomFileResult,
  updateCustomFileResult,
  deleteCustomFileResult,
  createActiveCodeResult,
  queryActiveCodeResult,
  fetchQsPcClientUrlResult,
  getEffectiveTimeResult,
  updateEffectiveTimeResult,
  deleteEffectiveTimesult,
});

export default rootReducer;
