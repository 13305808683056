import fetch from './fetch.server';
import { defaultActionOption, fetchGetConfig } from '../common';
import { createActionAsync } from 'redux-act-async';
import { GET_ENVIRONMENTS } from './constants';
import createReducerAsync from './reducer/createReducerAsync';

function getEnvironments() {
  return fetch('/environments', fetchGetConfig())
    .then(async (resp) => {
      if (!resp.ok) return Promise.reject();
      const data = await resp.json();
      return data;
    })
    .catch(() => ({ success: false, message: '' }));
}

export const getEnvironmentsAction = createActionAsync(
  GET_ENVIRONMENTS,
  getEnvironments,
  defaultActionOption
);

export const getEnvironmentsReducer = createReducerAsync(getEnvironmentsAction);
