import React from 'react';
import PropTypes from 'prop-types';
const qrcode = require('jr-qrcode');

const loadImg = (src) => {
  const paths = Array.isArray(src) ? src : [src];
  const promise = [];
  paths.forEach((path) => {
    promise.push(
      new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = path;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          console.log('图片加载失败');
        };
      })
    );
  });
  return Promise.all(promise);
};
const getImageData = (url, src, id) => {
  const imgsrc = qrcode.getQrBase64(url);
  const canvas = document.createElement('canvas');
  const width = 350;
  const height = 393;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  loadImg([imgsrc, src]).then(([img1, img2]) => {
    ctx.drawImage(img2, 0, 0, width, height);
    ctx.drawImage(img1, 85, 140, 180, 180);
    ctx.fillStyle = 'rgba(0,0,0,0.3)';
    ctx.fillRect(width - 80, height * 2 - 60, 200, 40);
    ctx.save();
    const imageURL = canvas.toDataURL('image/png');
    document.getElementById(id).setAttribute('src', imageURL);
  });
};

export default class QrCodeWithLogo extends React.Component {
  render() {
    const { url, picSrc, id } = this.props;
    getImageData(url, picSrc, id);
    return (
      <div>
        <img alt={id} id={id} />
      </div>
    );
  }
}

QrCodeWithLogo.propTypes = {
  url: PropTypes.string.isRequired,
  picSrc: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
