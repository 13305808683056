import React, { useContext, createContext, useEffect } from 'react';
import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

import MobileTips from '../../home/mobile/pages/MobileTips/MobileTips';
import { checkLoginStateAction } from '../../redux/user';

const cfRouterContext = createContext({
  isOnMobileBrowser: (): boolean => {
    return false;
  },
});

export function useRouterContext() {
  return useContext(cfRouterContext);
}

export function useProvideRouteContext() {
  const isOnMobileBrowser = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  return {
    isOnMobileBrowser,
  };
}

export function QsRouter({ children }: { children: any }) {
  const auth = useProvideRouteContext();

  return (
    <cfRouterContext.Provider value={auth}>
      <Router>{children}</Router>
    </cfRouterContext.Provider>
  );
}

function QsRoute({
  children,
  auth = false,
  mobile = false,
  invisibleWhenAuthenticated = false,
  checkLoginStateResult = {},
  checkLoginStateAction = () => {
    console.log();
  },
  ...rest
}: {
  children: any;
  path: string;
  key?: string;
  auth?: boolean;
  mobile?: boolean;
  invisibleWhenAuthenticated?: boolean;
  exact?: boolean;
  checkLoginStateResult: any;
  checkLoginStateAction: () => void;
}) {
  const context = useRouterContext();

  useEffect(() => {
    async function fetchData() {
      await checkLoginStateAction();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authRequired = auth;
  const mobileCompatible = mobile;

  const { isLogin } = checkLoginStateResult;

  function render({ location }: { location: any }) {
    if (invisibleWhenAuthenticated && isLogin) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      );
    }

    if (authRequired && !isEmpty(checkLoginStateResult) && !isLogin) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      );
    }

    if (context.isOnMobileBrowser() && !mobileCompatible) {
      return <MobileTips />;
    }

    return children;
  }

  return <Route {...rest} render={render} />;
}

function mapStateToProps(state: any) {
  return {
    checkLoginStateResult: state.checkLoginStateResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QsRoute);
