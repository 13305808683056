import React from 'react';
import bindWindowsImg from './images/bindWindowsImg.svg';
import s from './BindWindows.module.scss';
import windowsIcon from './images/windowsIcon.svg';
import { QsButtonFixedSize } from '../../../commonComponents/QsButton/QsButton';

interface IProps {
  onClick?: () => void;
}

function BindWindows(props: IProps) {
  return (
    <div className={s.bindWindowsCard}>
      <div className={s.bindWindowsCardTitle}>下载「孩子端」</div>
      <img src={bindWindowsImg} alt="" />
      <div className={s.bindWindowsCardDesc}>下载「孩子端」安装在您孩子的电脑上</div>
      <div style={{ width: '350px', margin: '0 auto' }}>
        <QsButtonFixedSize
          size="middle"
          type="primary"
          handleClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
        >
          <span>
            <img
              src={windowsIcon}
              alt=""
              className={s.windowIcon}
              style={{ marginRight: '10px', width: '20px' }}
            />
            Windows「孩子端」下载
          </span>
        </QsButtonFixedSize>
      </div>
    </div>
  );
}

export default BindWindows;
