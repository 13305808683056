import React, { useEffect, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import s from './Faq.module.scss';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import titleIcon from './images/titleIcon.png';
import faqIcon from './images/faqIcon.svg';
import {
  getAllFaqAction,
  getAllCategoriesAction,
  searchFaqAction,
} from '../../../redux/faq';
import QsInput from '../../../commonComponents/QsInput/QsInput';

interface IProps {
  getAllFaqAction: () => void;
  getAllCategoriesAction: () => void;
  searchFaqAction: (searchValue: string) => void;
  getAllFaqResult: any;
  getAllCategoriesResult: any;
  searchFaqResult: any;
}

function Faq(props: IProps) {
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('hot');
  const [isLoading, setIsLoading] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(54);

  useEffect(() => {
    async function getData() {
      await Promise.all([props.getAllFaqAction(), props.getAllCategoriesAction()]);

      setIsLoading(false);
    }

    getData();
  }, []);

  const navData = () => {
    const categories = props.getAllCategoriesResult.data?.categories;
    const faqs = props.getAllFaqResult.data || [];
    const navData: any[] = [
      {
        name: '热门问题',
        key: 'hot',
      },
    ];

    Object.keys(categories).forEach((item) => {
      const questions = faqs
        .filter((faq: any) => faq.category.toString() === item)
        .map((data: any) => data.question);
      navData.push({
        name: categories[item],
        key: item,
        questions: questions,
      });
    });

    return navData;
  };

  const hotQuestions = () => {
    const faqs = props.getAllFaqResult.data || [];
    const hot = faqs
      .filter((item: any) => item.isHot)
      .map((value: any) => value.question);
    return hot;
  };

  if (isLoading) {
    return null;
  }
  const faqs = props.getAllFaqResult.data || [];
  const categories = props.getAllCategoriesResult.data?.categories;
  const data = faqs.map((item: any) => {
    return {
      category: categories[item.category.toString()],
      question: item.question,
      answer: item.answer,
    };
  });

  let currentContent: any = {};
  if (currentQuestion !== 'hot') {
    currentContent = data.filter((item: any) => item.question === currentQuestion)[0];
  }

  const getHeaderHeight = (value: number) => {
    setHeaderHeight(value);
  };

  return (
    <HomeLayout showFooterBg={true} getHeaderHeight={getHeaderHeight}>
      <div className={s.content}>
        {showSearchResult ? (
          <FaqWithSearch
            defaultSearchValue={searchValue}
            searchResult={props.searchFaqResult.data || []}
            handleClick={(value: string) => {
              setShowSearchResult(false);
              setCurrentQuestion(value);
            }}
            handleSearch={async (value: string) => {
              await props.searchFaqAction(value);
            }}
          />
        ) : (
          <FaqWithDefault
            headerHeight={headerHeight}
            navData={navData()}
            hotQuestions={hotQuestions()}
            handleSearch={async (value: string) => {
              await props.searchFaqAction(value);
              setShowSearchResult(true);
              setSearchValue(value);
            }}
            handleNavClick={(value: string) => {
              setCurrentQuestion(value);
            }}
            currentQuestion={currentQuestion}
            currentContent={currentContent}
          />
        )}
      </div>
    </HomeLayout>
  );
}

function mapStateToProps(state: any) {
  return {
    getAllFaqResult: state.getAllFaqResult.data,
    getAllCategoriesResult: state.getAllCategoriesResult.data,
    searchFaqResult: state.searchFaqResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getAllFaqAction: bindActionCreators(getAllFaqAction, dispatch),
    getAllCategoriesAction: bindActionCreators(getAllCategoriesAction, dispatch),
    searchFaqAction: bindActionCreators(searchFaqAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);

function FaqWithDefault(props: any) {
  const hotQuestion = () => {
    return props.hotQuestions.map((item: any, index: number) => (
      <div
        key={index}
        className={s.hotQuestion}
        onClick={() => {
          props.handleNavClick(item);
        }}
      >
        {item}
      </div>
    ));
  };

  const navItems = props.navData.map((item: any) => {
    return (
      <NavItem
        key={item.key}
        name={item.name}
        questions={item.questions}
        currentQuestion={props.currentQuestion}
        handleClick={(value: string) => {
          props.handleNavClick(value);
        }}
      />
    );
  });

  return (
    <div className={s.container} style={{ paddingTop: `${props.headerHeight + 46}px` }}>
      <div className={s.nav}>
        <img src={titleIcon} alt="title" />
        {navItems}
      </div>
      <div className={s.info}>
        <div className={s.search}>
          <QsInput
            type="search"
            defaultValue={props.defaultSearchValue}
            placeholder="请输入问题"
            allowClear
            width={446}
            onSearch={(value) => {
              if (!value) {
                return;
              }
              props.handleSearch(value);
            }}
          />
        </div>
        <div className={s.detail}>
          {props.currentQuestion === 'hot' ? (
            hotQuestion()
          ) : (
            <div>
              <div className={s.label}>{props.currentContent.category}</div>
              <div className={s.contentTitle}>{props.currentContent.question}</div>
              <div className={s.contentInfo}>
                <ReactMarkdown>{props.currentContent.answer}</ReactMarkdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function FaqWithSearch(props: any) {
  return (
    <div className={s.container}>
      <div className={s.searchContainer}>
        <div className={s.searchTitle}>
          <img src={titleIcon} alt="title" />
          <div className={s.search}>
            <QsInput
              type="search"
              allowClear
              defaultValue={props.defaultSearchValue}
              placeholder="请输入问题"
              width={446}
              onSearch={(value) => {
                if (!value) {
                  return;
                }
                props.handleSearch(value);
              }}
            />
          </div>
        </div>
        {props.searchResult.length === 0 ? (
          <div className={s.searchEmpty}>
            <div>暂无内容</div>
            <div
              className={s.back}
              onClick={() => {
                props.handleClick('hot');
              }}
            >
              立即返回
            </div>
          </div>
        ) : (
          <div className={s.searchContent}>
            {props.searchResult.map((item: any) => (
              <div
                key={item.id}
                className={s.searchItem}
                onClick={() => {
                  props.handleClick(item.question);
                }}
              >
                <img src={faqIcon} alt="icon" />
                <div>
                  <div className={s.searchTitle}>{item.question}</div>
                  <div className={s.searchAnswer}>
                    {item.answer?.split('**').join('').split('*').join('')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function NavItem(props: any) {
  const [showQuestions, setShowQuestions] = useState(false);
  let labelSelected = props.currentQuestion === 'hot' && props.name === '热门问题';

  if (
    !!props.questions &&
    props.questions.includes(props.currentQuestion) &&
    !showQuestions
  ) {
    labelSelected = true;
  }

  const questions = () => {
    return props.questions.map((item: any, index: number) => {
      const selected = props.currentQuestion === item;
      return (
        <div
          key={index}
          className={`${s.questionItem} ${
            selected ? s.activeTitle : s.normaQuestionItem
          }`}
          onClick={() => {
            props.handleClick(item);
          }}
        >
          <span>{item}</span>
        </div>
      );
    });
  };

  return (
    <div>
      <div
        className={`${s.titleItem} ${labelSelected ? s.activeTitle : s.normalTitle}`}
        onClick={() => {
          setShowQuestions(!showQuestions);
          if (props.name === '热门问题') {
            props.handleClick('hot');
          }
        }}
      >
        <span>{props.name}</span>
        {props.questions && (showQuestions ? <AiOutlineUp /> : <AiOutlineDown />)}
      </div>
      {showQuestions && props.questions && questions()}
    </div>
  );
}
