import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import s from './FunctionTab.module.scss';
import PWebImage from './images/PWeb.svg';
import zgydPWebImage from '../../../ZGYDAssets/zgydPWebImage.svg';
import PAndroidImage from './images/PAndroid.svg';
import CWebImage from './images/CWeb.svg';
import zgydCWebImage from '../../../ZGYDAssets/zgydCWebImage.svg';
import CAndroidImage from './images/CAndroid.svg';
import tipsIcon from './images/tipsIcon.svg';
import { connect } from 'react-redux';
import { getParentReleaseInfoAction } from '../../../redux/parentReleaseInfo';
import { bindActionCreators } from 'redux';
import { getEnvironmentsAction } from '../../../redux/environments';
import { fetchQsPcClientUrlAction } from '../../../redux/downloadPCClient';
import {
  QrCodeChildAndroidDownload,
  QrCodeParentAndroidDownload,
} from './QrCodeDownload';
import { childIcon, parentIcon } from '../../../commonComponents/QsIcons/commonIcons';
import LoginRegisterModal from '../../components/LoginRegisterModal/LoginRegisterModal';
import { checkLoginStateAction } from '../../../redux/user';
import { QsHBigButton } from '../../../commonComponents/QsHomeButton/QsHomeButton';
import { getVersionInfoAction } from '../../../redux/versionInfo';
import moment from 'moment';
import { isZGYD } from '../../../utils/lib';

interface IProps {
  defaultActive: string;
  defaultType: 'child' | 'parent';
  getParentReleaseInfoAction: () => void;
  getParentReleaseInfoResult: any;
  getEnvironmentsAction: () => void;
  getEnvironmentsResult: any;
  fetchQsPcClientUrlAction: () => void;
  fetchQsPcClientUrlResult: any;
  checkLoginStateAction: () => void;
  checkLoginStateResult: any;
  getVersionInfoAction: () => void;
  getVersionInfoResult: any;
}

function FunctionTab(props: IProps) {
  const [active, setActive] = useState(() => {
    return props.defaultActive;
  });

  const [activeType, setActiveType] = useState(() => {
    return props.defaultType;
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        props.getParentReleaseInfoAction(),
        props.getEnvironmentsAction(),
        props.fetchQsPcClientUrlAction(),
        props.checkLoginStateAction(),
        props.getVersionInfoAction(),
      ]);
    }
    document.title = !!isZGYD()
      ? '下载_家长管控软件下载'
      : '青松守护_下载_家长管控软件下载 - 青松守护';

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = props.getVersionInfoResult;
  const versionInfo = data?.result || {};
  const webVersion = versionInfo.web ? versionInfo.web[0] : {};
  const appbVersion = versionInfo.app ? versionInfo.app[0] : {};

  const listData = [
    {
      name: 'Web',
      key: 'p_web',
    },
    {
      name: 'Android',
      key: 'p_android',
    },
  ];

  const listChildData = [
    {
      name: 'Windows',
      key: 'c_web',
    },
    {
      name: 'Android',
      key: 'c_android',
    },
  ];

  const tabContentList: any[] = [
    {
      key: 'p_web',
      title: !!isZGYD() ? 'Web 控制中心' : '青松守护 Web 控制中心',
      updateTime: webVersion?.createdAt || '2021.04.15',
      logLink: '/changelog',
      enable: true,
      imgUrl: !!isZGYD() ? zgydPWebImage : PWebImage,
      btnText: '进入控制中心',
      versionName: webVersion?.versionName,
      href: props.checkLoginStateResult?.isLogin ? '/dashboard' : null,
      handleClick: () => {
        const { isLogin } = props.checkLoginStateResult;
        if (!isLogin) {
          setShowModal(true);
        }
      },
    },
    {
      key: 'p_android',
      title: !!isZGYD() ? 'Android 家长端' : '青松守护 Android 家长端',
      updateTime: appbVersion?.createdAt || '2021.04.15',
      logLink: '/changelog',
      enable: true,
      imgUrl: PAndroidImage,
      btnText: '立即下载',
      versionName: appbVersion?.versionName,
      handleClick: () => {
        const url = props.getParentReleaseInfoResult?.data['downloadURL'] || '';
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      qrCode: (
        <QrCodeParentAndroidDownload
          url={`${window.location.origin}/parentAppDownload`}
        />
      ),
    },
    {
      key: 'c_web',
      title: !!isZGYD() ? 'Windows 孩子端' : '青松守护 Windows 孩子端',
      tips: '支持 Windows 7、Windows 10、Windows 11 系统电脑。',
      updateTime: '2023.04.26',
      versionName: '3.1',
      logLink: '/changelog',
      enable: true,
      imgUrl: !!isZGYD() ? zgydCWebImage : CWebImage,
      btnText: '立即下载',
      handleClick: () => {
        const url = props.fetchQsPcClientUrlResult.downloadUrl || '';
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
    {
      key: 'c_android',
      title: !!isZGYD() ? 'Android 孩子端' : '青松守护 Android 孩子端',
      updateTime: '2023.02.21',
      logLink: '/changelog',
      enable: true,
      imgUrl: CAndroidImage,
      btnText: '立即下载',
      versionName: '0.99',
      disableDownloadBtn: true,
      handleClick: () => {
        const { APP_DOWNLOAD_URL: appChildDownload } = props.getEnvironmentsResult;
        const link = document.createElement('a');
        link.href = appChildDownload;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      qrCode: <QrCodeChildAndroidDownload />,
    },
  ];

  const tabContentVariant = {
    active: {
      display: 'flex',
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    inactive: {
      opacity: 0,
      y: 10,
      display: 'none',
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.tabContainer}>
          <div className={s.functionPanel}>
            <div className={activeType === 'parent' ? s.activeTitle : s.normalTitle}>
              {parentIcon}
              <span>家长端</span>
            </div>
            <div className={s.items}>
              <ul>
                {listData.map((i) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      onMouseEnter={() => {
                        setActive(i.key);
                        setActiveType('parent');
                      }}
                      className={i.key === active ? s.active : s.normal}
                    >
                      <span>{i.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={s.functionPanel}>
            <div className={activeType === 'child' ? s.activeTitle : s.normalTitle}>
              {childIcon}
              <span>孩子端</span>
            </div>
            <div className={s.items}>
              <ul>
                {listChildData.map((i) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      onMouseEnter={() => {
                        setActive(i.key);
                        setActiveType('child');
                      }}
                      className={i.key === active ? s.active : s.normal}
                    >
                      <span>{i.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {tabContentList.map((item: any) => {
          return (
            <motion.div
              key={item.key}
              variants={tabContentVariant}
              animate={active === item.key ? 'active' : 'inactive'}
              initial="inactive"
              className={s.tabContent}
            >
              <motion.div>
                <div className={s.tabTitle}>{item.title}</div>
                {item.tips && (
                  <div className={s.tips}>
                    <img src={tipsIcon} alt="" />
                    <span>{item.tips}</span>
                  </div>
                )}
                {!!item.enable ? (
                  <div className={s.info}>
                    <div className={s.desc}>
                      <span>
                        <span>版本号：{item.versionName || '2.1'}</span>
                        <div className={s.verticalBar} />
                      </span>
                      <span>
                        <span>
                          更新时间：{moment(item.updateTime).format('YYYY.MM.DD')}
                        </span>
                        {/* <div className={s.verticalBar} /> */}
                        {/* <a href={item.logLink} target="_black" className={s.logLink}>
                          更新日志 {'>>'}
                        </a> */}
                      </span>
                    </div>
                    <div className={s.textCenter}>
                      开发者：南京川陀大匠信息技术有限公司
                    </div>
                  </div>
                ) : (
                  <div className={`${s.info} ${s.textCenter}`}>
                    开发者：南京川陀大匠信息技术有限公司
                  </div>
                )}
                {item.disableDownloadBtn ? (
                  <div></div>
                ) : (
                  <div className={s.btn}>
                    {item.href ? (
                      <QsHBigButton
                        type="primary"
                        theme="green"
                        href={item.href}
                        disable={!item.enable}
                      >
                        {item.btnText}
                      </QsHBigButton>
                    ) : (
                      <QsHBigButton
                        type="primary"
                        theme="green"
                        onClick={item.handleClick}
                        disable={!item.enable}
                      >
                        {item.btnText}
                      </QsHBigButton>
                    )}
                  </div>
                )}
              </motion.div>
              <div className={s.imageContainer}>
                <img src={item.imgUrl} alt="" />
                {item.qrCode ?? item.qrCode}
              </div>
            </motion.div>
          );
        })}
      </div>
      <LoginRegisterModal
        visible={showModal}
        redirect="/dashboard"
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    getParentReleaseInfoResult: state.getParentReleaseInfoResult.data,
    getEnvironmentsResult: state.getEnvironmentsResult.data,
    fetchQsPcClientUrlResult: state.fetchQsPcClientUrlResult.data,
    checkLoginStateResult: state.checkLoginStateResult.data,
    getVersionInfoResult: state.getVersionInfoResult.data,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getParentReleaseInfoAction: bindActionCreators(getParentReleaseInfoAction, dispatch),
    getEnvironmentsAction: bindActionCreators(getEnvironmentsAction, dispatch),
    fetchQsPcClientUrlAction: bindActionCreators(fetchQsPcClientUrlAction, dispatch),
    checkLoginStateAction: bindActionCreators(checkLoginStateAction, dispatch),
    getVersionInfoAction: bindActionCreators(getVersionInfoAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunctionTab);
